import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import DashboardIndex from './DashboardIndex';

const produrl = 'https://showcaseapi.trezi.com/dashboards/';

const MonthlyUsers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set loading to true

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = produrl + 'api/v1/data/monthly/users';
        const response = await axios.get(endpoint);
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        setError('Error making API call');
        console.error('Error making API call:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call fetchData function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  const exportToExcel = () => {
    if (data.length === 0) return; // Do nothing if there's no data

    let dateRanges, dataByDateRange, products;

    dateRanges = [...new Set(data.map(item => item.month))];
    products = [...new Set(data.map(item => item.product))];
    dataByDateRange = dateRanges.map(month => {
      const monthData = data.filter(item => item.month === month);
      const countsByProduct = products.map(product => {
        const productData = monthData.find(item => item.product === product);
        return productData ? productData.count : 0;
      });
      return { dateRange: month, countsByProduct };
    });

    const headers = ['Month', ...products.map(product => `${product} Count`)];
    const worksheetData = [
      headers,
      ...dataByDateRange.map(item => [item.dateRange, ...item.countsByProduct])
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Monthly Active Users');
    XLSX.writeFile(workbook, 'Monthly_Active_Users.xlsx');
  };

  const renderTable = () => {
    if (loading) return <p>Loading...</p>; // Show loading indicator

    if (error) return <p>{error}</p>; // Show error message if API call fails

    if (data.length === 0) return null; // Render nothing if data is empty

    let dateRanges, dataByDateRange, products;

    // const columns = Object.keys(data[0]);

    dateRanges = [...new Set(data.map(item => item.month))];
    products = [...new Set(data.map(item => item.product))];
    dataByDateRange = dateRanges.map(month => {
      const monthData = data.filter(item => item.month === month);
      const countsByProduct = products.map(product => {
        const productData = monthData.find(item => item.product === product);
        return productData ? productData.count : 0;
      });
      return { dateRange: month, countsByProduct };
    });

    return (
      <div>
        <h2 id="heading">Monthly Active Users</h2>
        <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
        <div className='other_table_container'>
          <table className="result-table">
            <thead>
              <tr>
                <th>Month</th>
                {products.map((product, index) => (
                  <th key={index}>{product} Count</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {dataByDateRange.map((item, index) => (
                <tr key={index}>
                  <td>{item.dateRange}</td>
                  {item.countsByProduct.map((count, idx) => (
                    <td key={idx}>{count}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
        <DashboardIndex/>
    <div className='home-container'>
      <div className='options-container'>
        {/* No button required as data is fetched automatically */}
      </div>
      <div className="data-container">
        {renderTable()}
      </div>
    </div>
    </>
  );
};

export default MonthlyUsers;
