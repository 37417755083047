import React, { useState, useEffect } from 'react';
import DashboardIndex from './DashboardIndex';
import { getAllClientIds, submitClientDetails } from '../services/ProductsService';
import * as XLSX from 'xlsx';

const ClientDetails = () => {
  const [selectedDateFrom, setSelectedDateFrom] = useState('');
  const [selectedDateTo, setSelectedDateTo] = useState('');
  const [clientIds, setClientIds] = useState([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchClientIds = async () => {
      try {
        const response = await getAllClientIds();
        if (response.status === 200) {
          console.log(response);
          setClientIds(response.data.sort());
        } else {
          console.error('Failed to fetch client data.');
        }
      } catch (error) {
        console.error('Error fetching client IDs:', error);
      }
    };
    fetchClientIds();
  }, []);

  const handleDateFromChange = (event) => {
    setSelectedDateFrom(event.target.value);
  };

  const handleDateToChange = (event) => {
    setSelectedDateTo(event.target.value);
  };

  const handleClientIdChange = (event) => {
    setSelectedClientId(event.target.value);
  };

  const handleSubmit = async () => {
    if (selectedClientId) {
      // setLoading(true);
      setError(null);
      try {
        const response = await submitClientDetails(selectedClientId, selectedDateFrom, selectedDateTo);
        if (response.status === 200) {
          console.log('API call successful:', response.data);
          if(response.data.length==0)
            alert("No data found");
          setData(response.data); // Set the fetched data to state for rendering in the table
        } else {
          console.error('API call failed:', response.statusText);
          setError('API call failed');
        }
      } catch (error) {
        console.error('Error during API call:', error);
        setError('Error during API call');
      } finally {
        // setLoading(false);
      }
    } else {
      alert('Client ID is required.');
    }
  };

  const exportToExcel = () => {
    const headers = ['Date', 'Transactio ID', 'Transaction Type', 'Points','Remarks','Service'];
    const worksheetData = [
      headers,
      ...data.map(item => [item.transactionId, item.transactionType, item.points,item.remarks,item.treziService])
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Detailed Daily Users');
    XLSX.writeFile(workbook, 'Detailed_Daily_Users.xlsx');
  };

  const renderTable = () => {
    if (loading) return <p>Loading...</p>; // Show loading indicator
  
    if (error) return <p>{error}</p>; // Show error message if API call fails
  
    if (data.length === 0) return null; // Render nothing if data is empty
  
    return (
      <div>
        {/* <h2 id="heading">Client Transaction Details</h2>
        <div id="button">
          <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
        </div> */}
        <div className='other_table_container'>
          <table className="result-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Transaction ID</th>
                <th>Transaction Type</th>
                <th>Points</th>
                <th>Remarks</th>
                <th>Service</th>
              </tr>
            </thead>
            <tbody>
              {data
                .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort in descending order by 'date'
                .map((entry, index) => (
                  <tr key={index}>
                    <td>{new Date(entry.date).toLocaleDateString()}</td> {/* Display only date */}
                    <td>{entry.transactionId}</td>
                    <td>{entry.transactionType}</td>
                    <td>{entry.points}</td>
                    <td>{entry.remarks}</td>
                    <td>{entry.treziService}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };
  


  return (
    <div>
      <DashboardIndex />
      <div className="ClientName">
        <label>
          <h3 id="heading">Client ID</h3>
          <select value={selectedClientId} onChange={handleClientIdChange}>
            <option value="">Select a Client ID</option>
            {clientIds.map((client, index) => (
              <option key={index} value={client}>
                {client}
              </option>
            ))}
          </select>
        </label>

        <br /><br />

        <label>
          <h3 id="heading">Date from</h3>
          <input
            type="date"
            value={selectedDateFrom}
            onChange={handleDateFromChange}
          />
        </label>

        <br /><br />

        <label>
          <h3 id="heading">Date to</h3>
          <input
            type="date"
            value={selectedDateTo}
            onChange={handleDateToChange}
          />
        </label>

        <br /><br />
        <div className='show'>
        <button id="showbutton" onClick={handleSubmit} disabled={!selectedClientId}>
          Submit
        </button>
        </div>

      </div>
      <div className="data-container">
        {renderTable()}
      </div>
    </div>
  );
};

export default ClientDetails;
