
import React, { useEffect, useState } from 'react';
import HTMLFlipBook from 'react-pageflip';
import './FlipbookVert.css'; // Import the new CSS file for the new flipbook
import { getFlipbookByFlipbookIdentifier, getProductsByIdForflipbook, downloadProductqrcode } from "../../services/ProductsService";
import { useParams } from 'react-router-dom';
import LoadingImage from  "../../assests/public/Loading-03-03.png";
import TreziLogo from  "../../assests/public/Group 10 (2).png";
import html2pdf from 'html2pdf.js';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
    Col,
    Container,
    Row,
    Card,
} from "react-bootstrap";
function withParams(Component) {
    return (props) => {
        const params = useParams(); // Fetch the route parameters using React Router's useParams hook
        return <Component {...props} params={params} />;
    };
}
const styles = `
    .page-content {
        padding: 20px;
    }
    .page-header {
        margin-top: 20px;
    }
    .flipbook-cont1 {
        margin-top: 20px;
    }
    .flipbook-page {
        page-break-inside: avoid;
    }
    .qr-code-container {
        margin-top: 20px;
    }
    .footer {
        margin-top: 20px;
        text-align: center;
    }
    .brand-logo-container {
        text-align: center;
        margin-bottom: 20px;
    }
`;

const FlipbookLSDouble = ({ params }) => {
    const [flipbookData, setFlipbookData] = useState({});
    const [flipbookFieldsData, setFlipbookFieldsData] = useState([]);
    const [flipbookTemplateIdentifier, setFlipbookTemplateIdentifier] = useState([]);
    const [productData, setProductData] = useState([]);
    const [newLink, setNewLink] = useState('');
    const { flipBookIdentifier } = params;
    console.log('Flipbook Identifier from route:', flipBookIdentifier);
    const getCookie = (name) => {
        const cookieString = document.cookie;
        const cookies = cookieString.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };
    useEffect(() => {
        const styleElement = document.createElement('style');
        styleElement.innerHTML = styles;
        document.head.appendChild(styleElement);
    }, []);
    useEffect(() => {
        const fetchData = async () => {
            const organizationIdentifier = getCookie('orgIdentifier');
            try {
                const SelectedFlipBook = "flipBook-csm@trezi.com-110666d3-0fe6-4892-ac7d-051011b2aa0d";
                console.log('Selectedtemplate:', SelectedFlipBook);
                if (SelectedFlipBook) {
                    const response = await getFlipbookByFlipbookIdentifier(flipBookIdentifier);
                    setFlipbookData(response.data);
                    if (response.data && response.data.flipBookFields) {
                        setFlipbookFieldsData(response.data.flipBookFields);
                        setFlipbookTemplateIdentifier(response.data.templateIdentifier);
                        const productIds = response.data.productIds || [];
                        console.log(productIds);
                        const productDataArray = await Promise.all(
                            productIds.map(async (productId) => {
                                try {
                                    const productResponse = await getProductsByIdForflipbook(productId);
                                    return productResponse.data; // Adjust this based on your API response structure
                                } catch (error) {
                                    console.error('Error fetching product data:', error);
                                    return null;
                                }
                            })
                        );
                        const filteredProductData = productDataArray.filter((data) => data !== null && data !== undefined);
                        console.log('Filtered Product Data:', filteredProductData);

                        const updatedProductData = await Promise.all(filteredProductData.map(async (product) => {
                            const url = window.location.origin;
                            const linkInput = product?.shareLink;
                            const linkWithoutPrefix = linkInput.replace(/.*\/v1\/products\//, '');
                            const newLink = `${url}/products/${organizationIdentifier}/${linkWithoutPrefix}`;
                              setNewLink(newLink);
                              console.log(newLink ,"test")
                            const qrcodeResponse = await downloadProductqrcode(newLink);
                            if (qrcodeResponse && qrcodeResponse.data && qrcodeResponse.data.qrCodeBytes) {
                                return {
                                    ...product,
                                    newLink,
                                    qrcodeResponse: qrcodeResponse.data.qrCodeBytes
                                };
                            } else {
                                return product;
                            }
                        }));

                        setProductData(updatedProductData);
                        console.log('Updated Product Data:', updatedProductData);
                    }
                }
            } catch (error) {
                console.error('Error fetching flipbook data:', error);
            }
        };
        fetchData();
        console.log(flipbookData);
        console.log(flipbookFieldsData);
        console.log(productData);
    }, [flipBookIdentifier]);
    const titleStyle = {
        fontSize: '17px',
        fontWeight: '600', // semi bold
        color: 'gray' // Great
      };
      const generatePageContent = (product, categoryName, brandLogoBase64, FlipbookName, isFirstPage ,isLastPage) => {
        let headerContent = '';
        if (isFirstPage) {
            headerContent = `
            <div class="row-container mt-3">
                <div class="down-category-name">${categoryName}</div>
                <div classname="down-logo-container">
                    <img class="flipbook-logo" src="${brandLogoBase64}" alt="brandlogo">
                </div>
            </div>
            `;
        }
        let poweredByTrezi = '';
        if (isLastPage) {
            poweredByTrezi = `
            <div class="powered-by-trezi mt-3">
            <div  class="Powered" >
            Powered by 
            </div>
            <img class="sales-trezi-logo" src="${TreziLogo}" alt="Trezi Logo" >   
            </div>
            `;
        }

        const productDetails = flipbookData &&  flipbookData.attributes
        .map((attribute) => {
          if (product.hasOwnProperty(attribute)) {
            return `
              <div key="${attribute}" class="mt-1">
                <span class="label">${attribute.charAt(0).toUpperCase() + attribute.slice(1)}:</span>
                <span class="data">${product[attribute]}</span>
              </div>
            `;
          }
          return ''; // Ignore attributes not present in flipbookData.attributes
        })
        .join(''); // Join the generated HTML elements into a single string
        return `
            <div>
                ${headerContent}
            </div>
            <div class="row mt-3">
                <div class="col-md-7">
                <div class="vert-tz-showcase-frame">
                <img class="flipbook-image" src="${product.thumbnailBase64}" alt="Thumbnail">
            </div>
                </div>
                <div class="col-md-5 upper-spacing">
                    <div class="flipbook-cont1">
                    <div class="title-data">${product.title}</div>
                    ${productDetails}
                  </div>
                    </div>
                    <div class="qr-code-container">
                        <img src="data:image/png;base64,${product.qrcodeResponse}" alt="QR Code" class="down-qr-code-image mt-2">
                        <span class="scan-text">Scan this for more details</span>
                    </div>
                </div>
            </div>
            <div >
            ${poweredByTrezi}
            </div>
        `;
    };
    
    

  
const handleDownload = async () => {
    const flipbookContainer = document.createElement('div');
    const brandLogoBase64 = await getImageDataURL(flipbookData.brandLogoUrl);
    const categoryName = flipbookFieldsData[0].value;
    const FlipbookName = flipbookFieldsData[3].value;
    let isFirstPage = true;

    try {
        // Convert image URLs to base64 and adjust size
        const frontCoverImageDataURL = await getImageDataURL(flipbookFieldsData.find(field => field.name === 'frontCoverImage').value, 'small');
        const backCoverImageDataURL = await getImageDataURL(flipbookFieldsData.find(field => field.name === 'backCoverImage').value, 'small');
        const productImageDataURLs = await Promise.all(productData.map(async (product) => {
            const thumbnailBase64 = await getImageDataURL(product.thumbnailUrl);
            return {
                ...product,
                thumbnailBase64
            };
        }));

        // Append the front cover image to flipbook container
        const frontCoverImageElement = document.createElement('img');
        frontCoverImageElement.src = frontCoverImageDataURL;
        frontCoverImageElement.style.maxWidth = "100%";
        flipbookContainer.appendChild(frontCoverImageElement);

        // Append product pages to flipbook container
        productImageDataURLs.forEach((product, index) => {
            const isLastPage = index === productData.length - 1;
            const pageContent = generatePageContent(product, categoryName, brandLogoBase64, FlipbookName, isFirstPage ,isLastPage);
            const pageContainer = document.createElement('div');
            pageContainer.innerHTML = pageContent;
            flipbookContainer.appendChild(pageContainer);
            isFirstPage = false;

            // Add a page break before appending the back cover page
            if (index === productData.length - 1) {
                const pageBreak = document.createElement('div');
                pageBreak.style.pageBreakAfter = 'always';
                flipbookContainer.appendChild(pageBreak);
            }
        });

        // Append the back cover image to flipbook container
        const backCoverImageElement = document.createElement('img');
        backCoverImageElement.src = backCoverImageDataURL;
        backCoverImageElement.style.maxWidth = "100%";
        flipbookContainer.appendChild(backCoverImageElement);

        // Generate PDF from the flipbook container
        await html2pdf().from(flipbookContainer).save();
    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};
    
    
    
    
    const getImageDataURL = async (url, size) => {
        try {
            const response = await fetch(url);
            if (!response.ok) {
                throw new Error(`Failed to fetch image at ${url}`);
            }
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error fetching image:', error);
            throw error;
        }
    };
    useEffect(() => {
        console.log('Updated flipbookData:', flipbookData);
        console.log('Updated flipbookFieldsData:', flipbookFieldsData);
        console.log('Updated templateFieldsData:', flipbookTemplateIdentifier);
        document.title = 'Flipbook';
    }, [flipbookData, flipbookFieldsData, flipbookTemplateIdentifier]);

    const groupedProducts = [];
    for (let i = 0; i < productData.length; i += 2) {
        groupedProducts.push(productData.slice(i, i + 2));
    }
    
    const isMobile = window.innerWidth <= 768; 
    return productData &&  productData.length == 0 ? (<div class="Loading-image-container" ><img src={LoadingImage} alt="Logo" class="animated-logo"  /></div>) : (
        <div>
      <div className='mt-4'>
<button onClick={handleDownload}>Download Flipbook as PDF</button>
</div>
<div className="center-container">
            <div className="tz-flipbook-container">
        
            <HTMLFlipBook
        width={80} // Adjust the width as needed
        height={110} 
      size="stretch"
      minWidth={500}
      maxWidth={600}
      minHeight={600}
      maxHeight={800}
      drawShadow={true}
      flippingTime={600}
      useMouseEvents={true}
      pageMode="single"
      mobileScrollSupport = {true}  
      style={{ marginTop: '20px' }}
    >
      {/* Hard page at the start */}
      <div className="demoPage hard">
      </div>
      <div className="demoPage" >      
        <div className='full-size-container' 
    style={{  backgroundImage: `url("${flipbookFieldsData[1]?.value}")`, // Use the imported image as background
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',}}>
   {/* <div className="logo-container">
                        <img src={flipbookData.brandLogoUrl} alt="Logo" />
                    </div> */}
        </div>
    </div>
    
   {groupedProducts && groupedProducts.map((productPair, index) => (
                                    <div className="demoPage" key={index} style={{ width: "75%" }}>
                                        <div className="row-container">
                                            <div className="sec-category-name">
                                                {flipbookFieldsData[0]?.value}
                                            </div>
                                            <div className="logo-container">
                                                <img className=''  style={{ width: "60%" }} src={flipbookData.brandLogoUrl} alt="Logo" />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
    {productPair && productPair.map((product, idx) => (
        <div key={idx} className={`sales-product-info ${productPair.length === 1 ? 'single-product' : ''}`}>
            <div className='col-md-6'>
                <Card
                    className="mt-3"
                    style={{
                        border: "1px solid #C7CFDE",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                    <div className="card-thumbnail-container">
                        <img src={product.thumbnailUrl} className='card-thumbnail'  alt="Product Thumbnail" />
                    </div>
                </Card>
            </div>

            <div className='col-md-6 '>
                <div className=''>
                    <div className='mt-2'>
                    <OverlayTrigger
      placement="top"
      overlay={<Tooltip id="tooltip">Click Here</Tooltip>}
    >
  <a href={product.newLink} target="_blank" rel="noopener noreferrer" className="title-link">
        <span className="title-data">{product.title}</span>
    </a>
    </OverlayTrigger>
                    </div>
                    <div className='flipbook-cont1 mt-3'>
                    {/* <div>
                            <span className="label">Product TZN:</span>
                            <span className="data">{product.productTzn}</span>
                        </div>
                        <div>
                            <span className="label">Brand:</span>
                            <span className="data">{product.brand}</span>
                        </div>
                        <div>
                            <span className="label">Model Number:</span>
                            <span className="data">{product.modelNumber}</span>
                        </div>
                        <div>
                            <span className="label">Model Name:</span>
                            <span className="data">{product.modelName}</span>
                        </div>
                        <div>
                            <span className="label">SKU:</span>
                            <span className="data">{product.sku}</span>
                        </div> */}
                   { flipbookData && flipbookData.attributes.map((attribute) => (
      product.hasOwnProperty(attribute) && (
        <div key={attribute} className="product-detail mt-1">
          <span className="label">{attribute.charAt(0).toUpperCase() + attribute.slice(1)}:</span>
          <span className="data">{product[attribute]}</span>
        </div>
      )
    ))}
                        <div className="image-text-container2">
                        <img className="qr-code-image-2" src={`data:image/png;base64,${product.qrcodeResponse}`} alt="Base64 Image" />
                        <span className="scan-text">
                            Scan this for more details
                        </span>
                    </div>
                    </div>
                </div>
               
            </div>
        </div>
    ))}
</div>
             
                                        <div className="footer mb-2">
                                            Powered by Trezi
                                          <div><img class="sales-trezi-logo" src={TreziLogo} alt="Trezi Logo" ></img>
                                            </div>  
                                        </div>
                                    </div>
                                ))}
                                   

<div className="demoPage" >      
        <div className='full-size-container' 
       style={{  backgroundImage: `url("${flipbookFieldsData[2]?.value}")`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',}}>
   {/* <div className="logo-container">
                        <img src={flipbookData.brandLogoUrl} alt="Logo" />
                    </div> */}
        </div>
    </div>
    </HTMLFlipBook>
         
                </div>
                </div>
   
        </div>
       
    );

};
export default withParams(FlipbookLSDouble);
