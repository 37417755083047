import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import "./EditproductUpload.css"; // Replace with your custom CSS file name
import { getAllCustomerSucessCategory, customerSuccessProductUpload, getProducts,updateProductSingleProductforcsm2d, getProductByIdforCustomer ,updateProductSingleProductforcsm } from "../services/ProductsService";
import Swal from 'sweetalert2';
import { FaUpload } from "react-icons/fa";
import Loader from "../context/loader.js";
import { useParams } from "react-router-dom";
function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

class EditSingleUpload extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCluster: '',
            clusters: [], // Add this state variable to store available clusters
            attributes: [], // Add this state variable to store attributes based on the selected cluster
            attributeValues: {},
            image1File: null, // Add image1File state variable
            image2File: null, // Add image2File state variable
            image3File: null, // Add image3File state variable
            image4File: null, // Add image3File state variable
            isLens3DModelUploaded: false,
            lensModel3DFile: null,
            isViewer3DModelUploaded: false,
            viewerModel3DFile: null,
            errorMessage: "",
            isLoading: false,
            isLoadingProduct: true,
            data: {},
            thumbnailFiles: [null, null, null , null], 
            isClusterSelected: false,
            textureImagePreviewURL: '',
        };
    }

    componentDidMount() {
        this.fetchProductById();
        document.title = "Product Details Edit";
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.params.productId !== this.props.params.productId ||
            this.state.isUpdated !== prevState.isUpdated
        ) {
            this.fetchProductById();
        }
    }

  
    async fetchProductById() {
        debugger;
        const { productId } = this.props.params;
        try {
          const cacheBuster = Math.random(); // Generate a random number for cache-busting
          const product = await getProductByIdforCustomer(productId, cacheBuster);
          const ProductData = product.data;
          console.log(ProductData);
          this.setState({ data: ProductData, isLoadingProduct: false });
        } catch (err) {
          console.log(err);
          this.setState({ isLoadingProduct: false });
        }
      }
      handleAttributeValueChange = (e, clusterName, attributeIndex) => {
        const { data } = this.state;
        const updatedData = { ...data };
      debugger;
        if (updatedData.clusters && updatedData.clusters[clusterName]) {
          updatedData.clusters[clusterName][attributeIndex].value = e.target.value;
          this.setState({ data: updatedData });
        }
      };
      handleImageChange = (file, clusterName, index) => {
        const { data } = this.state;
        const updatedData = { ...data };
        const reader = new FileReader();
      debugger;
        reader.onload = (e) => {
          this.setState({
            textureImagePreviewURL: e.target.result,
          });
        };
        this.setState({
          textureFile: file, // Clear the preview when no file is selected
        });
        if (file) {
          reader.readAsDataURL(file);
          if (updatedData.clusters && updatedData.clusters[clusterName]) {
            updatedData.clusters[clusterName][index].value =file.name;
            this.setState({ data: updatedData });
          }
        } else {
          this.setState({
            textureImagePreviewURL: '', // Clear the preview when no file is selected
          });
        }
      };
      
    handleInputChange = (event) => {
        const { name, value } = event.target;
        if (name === "price" && isNaN(value)) {
          return;
        }
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            [name]: value,
          },
        }));
      };     

    handleCancel = (e) => {
        window.location.href = "/CustomerSuccessTable";
    }
    handleSubmit = (e) => {
      if (e) e.preventDefault();
      const {
       data, is3DSelected
      } = this.state;
      if (data.isProduct3D) {
            this.handleSubmit3d();
      } else {
        this.handleSubmit2d();
      }
    };
    handleSubmit3d = async (e) => {
      if (e) e.preventDefault();
      debugger;
        const {
            data,
            thumbnailFiles,
            lensModel3DFileObject,
            viewerModel3DFileObject,
            attributes,
            isClusterSelected,
            image1File,
            image2File,
            image3File,
            image4File,
            textureFile
          } = this.state;
          const { productId} = this.props.params;
        const formData = new FormData();
        formData.append("productId", data.productId);
        formData.append("clientId", data.clientId);
        formData.append("title", data.title);
        formData.append("categoryName", data.categoryName);
        formData.append("SKU", data.sku);
        formData.append("brand", data.brand);
        formData.append("price", data.price);
        formData.append("modelName", data.modelName);
        formData.append("modelNumber", data.modelNumber);
        formData.append("IFCCode", data.ifccode);
        formData.append("searchKeywords", data.searchKeywords);
        formData.append("bakedImageUrl", data.bakedImageUrl);
        formData.append("description", data.description);
        const { clusters } = data;
            const flattenedClusters = Object.keys(clusters).flatMap((clusterName) => clusters[clusterName]);
  formData.append('additionalAttributes', JSON.stringify(flattenedClusters));
        // formData.append('additionalAttributes', JSON.stringify(updatedAttributes));
        if (lensModel3DFileObject) {
            formData.append('iosImage', lensModel3DFileObject);
          }
          if (viewerModel3DFileObject) {
            formData.append('viewerImage', viewerModel3DFileObject);
          }
        thumbnailFiles.forEach((file, index) => {
          if (file) {
              formData.append(`thumbnail${index}`, file);
          } else if (data.thumbnails && data.thumbnails[index-1]) {
              formData.append(`thumbnail${index}`, data.thumbnails[index-1].fileUrl);
          }
      });
        try {
          const response = await updateProductSingleProductforcsm(formData);
          Swal.fire({
            icon: 'success',
            title: 'Product Updated Successfully',
            text: 'The product details have been updated successfully!',
            confirmButtonText: 'OK',
          });
          this.fetchProductById();
        } catch (error) {
          if(error){
            Swal.fire({
              icon: 'error',
              title: 'Product cannot be updated',
              text: 'Something went wrong',
              confirmButtonText: 'OK',
            });
            return;
          }
        }
      };
      
      handleSubmit2d = async (e) => {
        if (e) e.preventDefault();
        debugger;
        const {
            data,thumbnailFiles,
            textureFile
          } = this.state;
          const { productId} = this.props.params;
        const formData = new FormData();
        formData.append("productId", data.productId);
        formData.append("clientId", data.clientId);
        formData.append("title", data.title);
        formData.append("categoryName", data.categoryName);
        formData.append("SKU", data.sku);
        formData.append("brand", data.brand);
        formData.append("price", data.price);
        formData.append("modelName", data.modelName);
        formData.append("modelNumber", data.modelNumber);
        formData.append("IFCCode", data.ifccode);
        formData.append("searchKeywords", data.searchKeywords);
        formData.append("bakedImageUrl", data.bakedImageUrl);
        formData.append("description", data.description);
        const { clusters } = data;
            const flattenedClusters = Object.keys(clusters).flatMap((clusterName) => clusters[clusterName]);
  formData.append('additionalAttributes', JSON.stringify(flattenedClusters));
  thumbnailFiles.forEach((file, index) => {
    if (file) {
        formData.append(`thumbnail${index}`, file);
    } else if (data.thumbnails && data.thumbnails[index-1]) {
        formData.append(`thumbnail${index}`, data.thumbnails[index-1].fileUrl);
    }
});
 
if (textureFile) {
  formData.append("textureFile", textureFile);
} else {
  formData.append("textureFile", data.textureFile);
} 
          // formData.append("textureFile",textureFile);       
        try {
          const response = await updateProductSingleProductforcsm2d(formData);
          Swal.fire({
            icon: 'success',
            title: 'Product Updated Successfully',
            text: 'The product details have been updated successfully!',
            confirmButtonText: 'OK',
          });
          this.fetchProductById();
        } catch (error) {
          if(error){
            Swal.fire({
              icon: 'error',
              title: 'Product cannot be updated',
              text: 'Something went wrong',
              confirmButtonText: 'OK',
            });
            return;
          }
        }
      };
    // Add this function to the ProductSingleUpload class
    isImageFileSizeValid = (file) => {
        const maxSize = 3 * 1024 * 1024; // 3MB in bytes
        return file.size <= maxSize;
    };
    handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
      debugger;
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            const naturalWidth = image.naturalWidth;
            const naturalHeight = image.naturalHeight;

            if (naturalWidth >= 747 && naturalHeight >= 747) {
                this.updateThumbnailFile(index, file);
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Image Size Error',
                    text: `Image size must be at least ${747}x${747} pixels.`,
                });
                event.target.value = '';
            }
        };
    }
};
    // Add this function to the ProductSingleUpload class
    isSupported3DModelFile = (file) => {
      debugger;
      const supportedFormats = [".glb", ".obj", ".stl", ".fbx" , ".tzi" , ".usdz"];
      const fileName = file.name.toLowerCase();
      return supportedFormats.some((format) => fileName.endsWith(format));
    };    
    updateThumbnailFile = (index, file) => {
      debugger;
       this.setState((prevState) => {
         const thumbnailFiles = [...prevState.thumbnailFiles];
         thumbnailFiles[index] = file; // Update the thumbnailFiles array at the specified index with the new file
         return { thumbnailFiles };
       });
     };
    
    
    handleLens3DFileUpload = (e) => {
      const file = e.target.files[0];
      debugger;
      if (this.isSupported3DModelFile(file)) {
        this.setState({
          lensModel3DFile: file ? URL.createObjectURL(file) : null,
          isLens3DModelUploaded: !!file,
          lensModel3DFileObject: file, // Store the actual File object for later use
        });
      } else {
        // Handle the error case for invalid file format
        Swal.fire({
          icon: 'error',
          title: 'Invalid 3D Model Format',
          text: 'Supported formats: .glb, .obj, .stl, .fbx, .gltf, .tzi',
        });
      }
    };
    
    handleViewer3DFileUpload = (e) => {
      debugger;
      const file = e.target.files[0];
      if (this.isSupported3DModelFile(file)) {
        this.setState({
          viewerModel3DFile: file ? URL.createObjectURL(file) : null,
          isViewer3DModelUploaded: !!file,
          viewerModel3DFileObject: file, // Store the actual File object for later use
        });
      } else {
        // Handle the error case for invalid file format
        Swal.fire({
          icon: 'error',
          title: 'Invalid 3D Model Format',
          text: 'Supported formats: .glb, .obj, .stl, .fbx, .gltf, .tzi',
        });
      }
    };
    
handleClusterItemClick = (clusterName) => {
  debugger;
  const { clusters } = this.state.data;
  const selectedCluster = clusters[clusterName];
  if (selectedCluster) {
      // Extract the attributes from the selected cluster
      const attributes = selectedCluster.map((attribute) => ({
          name: attribute.name,
          value: attribute.value,
          variableType: attribute.variableType,
          isHidden: attribute.isHidden,
          clusterName: attribute.clusterName,
      }));
      this.setState({
          selectedCluster: clusterName,
          attributes,
          isClusterSelected: true, // Set the flag to indicate that a cluster is selected
      });
  }
};

      
    render() {
        const {
            image1File,
            selectedCluster, attributes, attributeValues,
            errorMessage,
            model3DFile,
            isLoading,
            customerId,
            data,
            thumbnailFiles
          } = this.state;
          

          const extractNumbersFromUrl = (url) => {
            if (!url) {
                return null;
            }
            const match = url.match(/_(\d+|mandatory)_/);
            return match ? match[1] : null;
        };
        
          const matchedThumbnails = [null, null, null, null];
          if (data.thumbnails && Array.isArray(data.thumbnails)) {
            data.thumbnails.forEach((thumbnail) => {
              if (thumbnail) {
                const extractedValue = extractNumbersFromUrl(thumbnail.fileUrl);
                const index = extractedValue === 'mandatory' ? 1 : parseInt(extractedValue);
                matchedThumbnails[index] = thumbnail;
                console.log(extractedValue , "extractedvalue")
            }
            });
        }

        return (
            <div className="custom-upload-catalog-container">
                {isLoading && (
                    <Loader />
                )}
                <Container fluid >
                    <h1 className='upload-heading'>Edit Product</h1>
                    <div className={`status-box ${data.status === 'Published' ? 'green-background' : ''}`}>
    Status: {data.status}
</div>

                    <Row className="custom-product-details-row mt-4">
                    <Col md={2}  className='mt-3'>
        <div>
                  {[1, 2, 3 , 4].map((index) => {
                    const thumbnail = matchedThumbnails[index];
                    const imageSrc = thumbnailFiles[index] ? URL.createObjectURL(thumbnailFiles[index]) : (thumbnail ? thumbnail.fileUrl : null);
                    return (
                      <Card key={index} className="custom-image-card" style={{ width: "100%", height: "150px" }}>
                        <div className='file-select-center'>
                          {imageSrc ? (
                            <img
                              src={imageSrc}
                              alt={`Thumbnail ${index}`}
                              className="uploaded-image"
                            />
                          ) : (
                            <label htmlFor={`image${index}File`} className="file-upload-label">
                              <FaUpload size={30} className="upload-icon" />
                              <span>Upload Image {index}</span>
                            </label>
                          )}
                          <input
                            type="file"
                            id={`image${index}File`}
                            accept=".jpg,.png,.svg"
                            onChange={(event) => this.handleImageUpload(event, index)}
                            style={{ width: "100%", maxWidth: "200px" }}
                          />
                        </div>
                      </Card>
                    );
                  })}
                </div>
        </Col>

   {/* Second Column (Big Box) */}
                        <Col md={5} className="custom-product-details-frame">
                        <Card className="custom-product-view-card mt-3" style={{ border: "1px solid #C7CFDE", backgroundColor: "#FFFFFF", width: "100%", height: "30%" }}>
    <div className="file-select-center">
     
        <label htmlFor="lens3DFile" className="file-upload-label">
          <FaUpload size={30} className="upload-icon" />
          <span>Upload object for ios...</span>
        </label>
      <input type="file" disabled={data.isProduct3D === false}  accept=".usdz" onChange={this.handleLens3DFileUpload} id="ios3DFile" />
    </div>
  </Card>
  {/* 3D Model Upload Section */}
  <Card className="custom-product-view-card mt-3" style={{ border: "1px solid #C7CFDE", backgroundColor: "#FFFFFF", width: "100%", height: "30%" }}>
    <div className="file-select-center">
   <label htmlFor="viewer3DFile" className="file-upload-label">
          <FaUpload size={30} className="upload-icon" />
          <span>Upload object for mobile view...</span>
        </label>
      <input type="file" accept=".glb" disabled={data.isProduct3D === false} onChange={this.handleViewer3DFileUpload} id="viewer3DFile" />
    </div>
  </Card>
                        </Col>
                        <Col md={5} className="custom-product-details">
                        <Card className="custom-product-details-card generaldetailscrollbar mt-3" style={{ width: "100%", height: "7%" }}>
                                <h1 className="heading-imp">Select Product Type</h1>
                                <div className='tz-border-up'>
                                {data.isProduct3D ? (
        <>
            <div className='mt-2 mb-3'>3D</div>
        </>
    ) : (
        <>
            <div className='mt-2 mb-3'>2D</div>
        </>
    )}
                                </div>
                            </Card>
                            <Card className="custom-product-details-card generaldetailscrollbar mt-3" style={{ width: "100%", height: "8%" }}>
                                <h1 className="heading-imp">Customer Details</h1>
                                <div className='tz-border-up'>
                                    <div className=''>
                                        <form className='form-manage' >
                                            <div className="form-row">
                                            <label>Customer ID*</label>
                        <input
                          type="text"
                          name="clientId"
                          value={data.clientId}
                          onChange={this.handleInputChange}
                          required
                          disabled
                        />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Card>
                            <Card className="custom-product-details-card generaldetailscrollbar mt-3" style={{ width: "100%", height: "8%" }}>
                                <h1 className="heading-imp">Add Url</h1>
                                <div className='tz-border-up'>
                                    <div className=''>
                                        <form className='form-manage' >
                                            <div className="form-row">
                                            <label>Vectary Url*</label>
                        <input
                          type="text"
                          name="bakedImageUrl"
                          value={data.bakedImageUrl}
                          onChange={this.handleInputChange}
                          required
                        />
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </Card>
                            <Card className="custom-product-details-card  generaldetailscrollbar mt-3" style={{ width: "100%", height: "30%" }}>
                                <h1 className="heading-imp">Product General Details</h1>
                                {/* Error message */}
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                <div className=''>
                                    <form className='form-manage' onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <label>Product Title:</label>
                                            <input
    type="text"
    name="title"
    value={data.title}
    onChange={this.handleInputChange}
    required
  />

                                        </div>

                                        <div className="form-row">
             <label>Select Category:</label>
      <input
  type="text"
  name="categoryName"
  value={data.categoryName} // Should be this.state.data.categoryName
  onChange={this.handleInputChange}
  required
  disabled
/>
</div>


                                        <div className="form-row">
                                            <label>SKU:</label>
                                            <input
                                                type="text"
                                                name="sku"
                                                value={data.sku}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-row">
                                            <label>Brand Name:</label>
                                            <input
                                                type="text"
                                                name="brand"
                                                value={data.brand}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>
                                        <div className="form-row">
                                            <label>Price:</label>
                                            <input
                                                type="price"
                                                name="price"
                                                value={data.price}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-row">
                                            <label>Model Name:</label>
                                            <input
                                                type="text"
                                                name="modelName"
                                                value={data.modelName}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-row">
                                            <label>Model Number:</label>
                                            <input
                                                type="text"
                                                name="modelNumber"
                                                value={data.modelNumber}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-row">
                                            <label>IFC Code:</label>
                                            <input
                                                type="text"
                                                name="ifccode"
                                                value={data.ifccode}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>

                                        <div className="form-row">
                                            <label>Search Keywords:</label>
                                            <input
                                                type="text"
                                                name="searchKeywords"
                                                value={data.searchKeywords}
                                                onChange={this.handleInputChange}
                                                required
                                            />
                                        </div>

                                    </form>
                                </div>
                            </Card>
                            <Card className="custom-product-details-card scrollbar-attach mt-4" style={{ width: "100%", height: "18%" }}>
                                <h1 className="heading-imp">Product Attributes</h1>
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                <div className='tz-border-up' style={{ display: "flex" }}>
                                    <div className='tz-border-side' style={{ flex: 0.5 }}>
                                        <h2 className="heading-imp">Cluster</h2>
                                        <ul className="product-single-upload-list">
      {data.clusterNames &&
        data.clusterNames.map((clusterName, index) => (
          <li
            key={index}
            onClick={() => this.handleClusterItemClick(clusterName)}
            className={selectedCluster === clusterName ? "selected-cluster" : ""}
          >
            {clusterName}
          </li>
        ))}
    </ul>
                                    </div>
                                    <div style={{ flex: 1 }}>
                <h2 className="heading-imp">Attributes</h2>
                {data.clusterNames &&
  data.clusterNames.map((clusterName, index) => (
    <div key={index}>
      {data.clusters[clusterName] && (
         <form>   <ul className="product-single-upload-list">
         {data.clusters[clusterName].map((attribute, attrIndex) => (
           <li key={attrIndex}>
             <div className="attribute-row" key={index}>
        <label className="attribute-label">{attribute.name}:</label>
        {clusterName === "Color" ? (
          <>
            <div className="color-input-container">
              <div
        className="color-preview"
        style={{ backgroundColor: attribute.value || "transparent", color: "gray" }}
      ></div>
            <input
            type="text"
            value={attribute.value}
            onChange={(e) => this.handleAttributeValueChange(e, clusterName, attrIndex)}
            className="attribute-input"
          /></div></>
        ) : clusterName === "Texture" && attribute.name === "Texture Name" ? (
          <input
            type="text"
            name={attribute.name}
            value={attribute.value || ""}
            onChange={(e) => this.handleAttributeValueChange(e, clusterName, attrIndex)}
            className="attribute-input"
            placeholder={attribute.name}
          />
        )  : clusterName === "Texture" && attribute.name === "Texture Image" ? (
          <div className="texture-input-container">
            {attribute.value && attribute.value.startsWith("http") ? (
              <img
                src={attribute.value}
                alt="Texture Preview"
                className="texture-preview"
              />
            ) : (
              this.state.textureImagePreviewURL ? (
                <img
                  src={this.state.textureImagePreviewURL}
                  alt="Texture Preview"
                  className="texture-preview"
                />
              ) : (
                "Image not found"
              )
            )}
            <input
              type="file"
              name={attribute.name}
              onChange={(e) => this.handleImageChange(e.target.files[0], clusterName, attrIndex)}
              className="attribute-input"
            />
          </div>
        )  : (
          // Default case: render a text input
          <input
            type="text"
            value={attribute.value}
            onChange={(e) => this.handleAttributeValueChange(e, clusterName, attrIndex)}
            className="attribute-input"
          />
        )}
        
      </div>
           </li>
         ))}
       </ul></form>
      )}
    </div>
  ))}
            </div>

                                </div>
                            </Card>
                            <Card className="custom-product-details-card mt-4" style={{ width: "100%", height: "8%" }}>
                                <h1 className="heading-imp">Product Description</h1>
                                <div className='tz-border-up'>
                                    <textarea
                                        style={{ height: "7vh" }}
                                        name="description"
                                        rows={5} // You can adjust the number of rows to fit the desired height of the text box
                                        cols={70} // You can adjust the number of columns to fit the desired width of the text box
                                        value={data.description || ''}
                                        onChange={this.handleInputChange}
                                        placeholder="Enter product description here..."
                                    />
                                </div>
                            </Card>
                            <div className='tz-arrang-button mt-4'>
                                <Button variant='outlined' onClick={this.handleCancel}>
                                    Cancel
                                </Button>
                                <Button variant='primary' onClick={this.handleSubmit}>
                                   Update
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
export default withParams(EditSingleUpload);