import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import DashboardIndex from './DashboardIndex';

const produrl = 'https://showcaseapi.trezi.com/dashboards/';

const DetailedDailyUsers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set loading to true

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = produrl + 'api/v1/data/daily/users/detailed';
        const response = await axios.get(endpoint);
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        setError('Error making API call');
        console.error('Error making API call:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call fetchData function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  const exportToExcel = () => {
    const headers = ['Date', 'Product', 'User'];
    const worksheetData = [
      headers,
      ...data.map(item => [item.date, item.product, item.user])
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Detailed Daily Users');
    XLSX.writeFile(workbook, 'Detailed_Daily_Users.xlsx');
  };

  const renderTable = () => {
    if (loading) return <p>Loading...</p>; // Show loading indicator

    if (error) return <p>{error}</p>; // Show error message if API call fails

    if (data.length === 0) return null; // Render nothing if data is empty

    return (
      <div>
        <h2 id="heading" >Detailed Daily Active Users</h2>
        <div id="button">
          <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
        </div>
        <div className='other_table_container'>
  <table className="result-table">
    <thead>
      <tr>
        <th>Date</th>
        <th>Product</th>
        <th>User</th>
      </tr>
    </thead>
    <tbody>
      {data
        .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort in descending order by 'date'
        .map((entry, index) => (
          <tr key={index}>
            <td>{new Date(entry.date).toLocaleDateString()}</td> {/* Display only date */}
            <td>{entry.product}</td>
            <td>{entry.user}</td>
          </tr>
        ))}
    </tbody>
  </table>
</div>


      </div>
    );
  };

  return (
    <>
        <DashboardIndex/>
    <div className='home-container'>
      <div className='options-container'>
        {/* No button required as data is fetched automatically */}
      </div>
      <div className="data-container">
        {renderTable()}
      </div>
    </div>
    </>
  );
};

export default DetailedDailyUsers;
