import React, { Component } from 'react';
import { Table, Form, Dropdown ,Stack ,Pagination,Modal, Button } from 'react-bootstrap'; // Import required components from react-bootstrap
// import './CustomerSuccessTable.css';
import { getInventoryLog ,downloadProductforcsm , getClientCsmCategories, getProducts} from "../../services/ProductsService";
import ReactPaginate from 'react-paginate';
import EditIcon from "../../assests/public/edit (1).png"
import Swal from 'sweetalert2';
import Loader from "../../context/loader.js";
import AccordionComponent from './AccordionComponent';
import './InventoryLog.css'
import { error } from 'jquery';

class InventoryLog extends Component {
    constructor(props) {
      super(props);
      this.state = {
      Data: [] ,
      isAnyCheckboxSelected: false,
        sortColumn: '', // Provide initial value for sorting
        sortDirection: 'asc', // Provide initial value for sorting direction
        currentPage: 1, // Provide initial value for the current page
        itemsPerPage: 10, 
        pageNumbers: [],   
        filteredData: [],
        isDownloadOptionsActive: false,
        isLoading: false,
        isBulkUploadModalOpen: false,
        openAccordionId: null,
        error: "",
      };
    }
    componentDidMount() {
      document.title = 'Inventory Log';
    this.fetchAllinventoryLog();
    this.filterData()
    }
    
    componentDidUpdate(prevProps, prevState) {
      debugger;
      if (
        prevProps.searchQuery !== this.props.searchQuery ||
        this.state.isUpdated !== prevState.isUpdated ||
        prevState.productData !== this.state.productData
      ) {
        this.filterData();
      }
      if (prevState.isUpdated !== this.state.isUpdated) {
        this.setState({ productData: [] });
      }
    }
   
    filterData = () => {
      const { searchQuery } = this.props;
      const { Data } = this.state;
      if (!searchQuery) {
        this.setState({ filteredData: Data });
        return;
      } else {
        const filteredData = Data.filter(
          (item) =>
            item.batchNumber.toLowerCase().startsWith(searchQuery.toLowerCase()) 
        );
    
        this.setState({ filteredData: filteredData });
      }
    };
    
    handlePageChange = (selectedPage) => {
      debugger;
      this.setState({ currentPage: selectedPage });
    };
    
    handleSort = (columnName) => {
        const { sortColumn, sortDirection, filteredData } = this.state;
        const newSortDirection =
          sortColumn === columnName ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        const sortedData = [...filteredData];
        sortedData.sort((a, b) => {
          if (columnName === 'batchNumber') {
            // Convert batch numbers to integers and compare them
            const numA = parseInt(a.batchNumber.split('_')[1], 10);
            const numB = parseInt(b.batchNumber.split('_')[1], 10);
            return newSortDirection === 'asc' ? numA - numB : numB - numA;
          }
          return 0;
        });
      
        this.setState({
          filteredData: sortedData,
          sortColumn: columnName,
          sortDirection: newSortDirection,
        });
      };
      
  
      async fetchAllinventoryLog() {
        debugger;
        try {
          const logs = await getInventoryLog();
      
          this.setState(
            {
              Data: logs.data,
              filteredData :logs.data
            },
          );
          console.log(logs , "aaaa")
        } catch (err) {
          if(err.response.status === 401){
            Swal.fire({
              title: 'Unauthorized!',
              text: 'Jwt token Expired. Please Login again.',
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000 // 2 seconds
            }).then(() => {
              window.location.href = "/";
            });
          } else {
            throw new Error('Request failed with status ' + err.response.status);
          }
        }
      }
      
    handleLineClick = (batchNumber) => {
        this.setState((prevState) => ({
          openAccordionId: prevState.openAccordionId === batchNumber ? null : batchNumber,
        }));
      };
      
      
    render() {
      const { sortColumn, sortDirection, Data , toggleAccordion ,isLoading ,filteredData ,openAccordionId  , isBulkUploadModalOpen , bulkUploadClientId } = this.state;
      const {
        currentPage,
        itemsPerPage,
      } = this.state;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(Data.length / itemsPerPage); i++) {
          pageNumbers.push(i);
        }
        const TimestampInfo = (time) => {
            if (time === null || time === 0) {
              return "-";
            }
            const timestamp = time;
            const datetimeObj = new Date(timestamp);
      
            const month = datetimeObj.toLocaleString("default", { month: "short" });
            const date = datetimeObj.getDate();
            const day = datetimeObj.toLocaleString("en-US", { weekday: "long" });
            const year = datetimeObj.getFullYear();
      
            return (
              <div>
                <span className="tz-month custom-font-12">
                  {month} {date}, {year}
                </span>{" "}
                <br />
                <span className="tz-day custom-font-12">{day}</span>
              </div>
            );
          };
        const displayedData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
     
      return (
        <div className="centered-content-container">
     <Stack className='table-inventory-product-container '>
      <div className="upload-heading">
   Inventory Log
      </div>
          <div className='table-inventory mt-4'>
          <Table hover borderless className="product-customer-table ">
            <thead className="list-view-inventory-table-head">
              <tr>
              <th className="first-product-column-th custom-font-10">
                  <div
                    className="d-flex sort-column align-items-center"
                    onClick={() => this.handleSort('batchNumber')}
                  >
                    <span className="custom-font-15 custom-table-header">Batch ID</span>
                    <div className="tz-sort-icons">
                      <button
                        className={`sort-button ${
                          sortColumn === 'batchNumber' &&
                          sortDirection === 'asc' &&
                          'active'
                        }`}
                      >
                        <span className="bi bi-caret-up-fill"></span>
                        <span className="bi bi-caret-down-fill"></span>
                      </button>
                    </div>
                  </div>
                </th>
             <th className="fifth-product-column-th">
                <span className="custom-font-15 product-checkbox custom-table-header">File Name</span>
                </th>
                {/* Title & Tzn Number */}
                <th className="second-product-column-th custom-font-10">
                    <span className="custom-font-15 custom-table-header">Date</span>
                </th>
  
                 {/* Product Tittle */}
                 <th className="fifth-product-column-th custom-font-10">
                    <span className="custom-font-15 custom-table-header">Start Time</span>
                </th>
                <th className="fifth-product-column-th custom-font-10">
                    <span className="custom-font-15 custom-table-header">End Time</span>
                </th>
                 <th className="fourth-product-column-th custom-font-10">
                    <span className="custom-font-15 custom-table-header">Uploaded By</span>
                </th> 

                 <th className="fifth-product-column-th custom-font-15">
                    <span className="custom-font-15 custom-table-header">Total Record</span>
                </th>
                   <th className="fifth-product-column-th custom-font-15">
                    <span className="custom-font-15 custom-table-header">Passed Record</span>
                </th>
                 <th className="third-product-column-th custom-font-15">
                    <span className="custom-font-15 custom-table-header">Failed Record</span>
                </th>
              </tr>
            </thead>
  
            {/* Table body */}
            <tbody className="tz-table-body">
            {displayedData?.map((item ,index) => (
                    <React.Fragment key={item.id}>
                <tr className="tz-inventory-table-body-row mt-2"  key={item.batchNumber} onClick={() => this.handleLineClick(item.batchNumber)} >
                  <td className="first-product-column w-30">
                  {item.batchNumber}
                  </td>
                  <td className="first-product-column">
                  <div className="product-container">
                    
                    <span>
                      <div className="product-title custom-font-12">
                      {item.fileNames[0]}
                      </div>
                      <div className="product-serial custom-font-12">
                      {item.fileNames[1]}
                      </div>
                    </span>
              </div>
                    </td>
                  <td
                    className="second-product-column "
                  >
                    <div className="name-container">
                    
                          <span>
                            {/* <div className="product-title custom-font-12">
                              {item?.title}
                            </div> */}
                            <div className="custom-font-12">
                            {TimestampInfo(item.uploadDate)}
                            </div>
                          </span>
                    </div>
                  </td>
                  <td className="fifth-product-column">
                  {item.startTime ? new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' }).format(new Date(item.startTime)) : ""}
         </td>
                    <td className="fifth-product-column">
                    {item.startTime ? new Intl.DateTimeFormat('en-US', { hour: '2-digit', minute: '2-digit' }).format(new Date(item.startTime)) : ""}
                    </td>
                    <td className="fourth-product-column">
                        <div className="name-container">
                    
                    <span>
                      {/* <div className="product-title custom-font-12">
                        {item?.title}
                      </div> */}
                      <div className="custom-font-12">
                      {item.uploadedBy}
                      </div>
                    </span>
              </div>
  </td>
                    <td className="fifth-product-column">
                        <span className='inventory-records custom-font-12'>
                        {item.totalRecords}
                        </span>
                    </td>
                    <td className="fifth-product-column">
                      {item.passedRecords}
                    </td>
                       {/* Dropdown for changing status */}
                  <td className="third-product-column custom-font">
                  {item.failedRecords}
                  </td>
                </tr>
                {openAccordionId === item.batchNumber && (
  <AccordionComponent item={item} isOpen={true} onClick={() => this.handleLineClick(item.batchNumber)} />
)}
                  </React.Fragment>
              ))}
            </tbody>
          </Table>
          </div>
        </Stack>
        <div  style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
    {/* <span style={{ color: '#6A6D74', fontWeight: '400', fontStyle: 'normal' }} className="custom-font-14">
      Showing {indexOfFirstItem + 1} - {Math.min(indexOfLastItem, Data.length)} of {Data.length} items
    </span> */}
    <ReactPaginate
              pageCount={Math.ceil(Data.length / itemsPerPage)}
              pageRangeDisplayed={5}
              marginPagesDisplayed={1}
              onPageChange={(selectedPage) => this.handlePageChange(selectedPage.selected + 1)}
              containerClassName="pagination"
              activeClassName="pagination-item-active"
            />
  </div>
        </div>
     
      );
    }
  }
  export default InventoryLog;