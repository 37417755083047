// export const baseUrl = "https://catalogsmanagementservice.azurewebsites.net/catalogservice/api/v1/";
// export const baseUrlUser = "https://usersmanagementservice.azurewebsites.net/userservice/api/v1/";
// export const baseUrlSubscribe = "https://subscriptionsmanagementservice.azurewebsites.net/subscriptionservice/api/v1/";
// export const baseUrlDiscount = "https://discountsmanagementservice.azurewebsites.net/discountservice/api/v1/";
// export const baseUrlUser = "http://125.63.115.254:8080/userservice/api/v1/";
// export const baseUrl = "http://125.63.103.149:8080/catalogservice/api/v1/";
// export const baseUrlUser = "http://125.63.103.149:8080/userservice/api/v1/";
// export const baseUrlSubscribe = "http://125.63.103.149:8080/subscriptionservice/api/v1/";
// export const baseUrlDiscount = "http://125.63.103.149:8080/discountservice/api/v1/";
// export const baseUrl = "http://125.63.103.149:8080/catalogservice/api/v1/";
// export const baseUrlUser = "http://125.63.103.149:8080/uus/api/v1/";
// export const baseUrlUser2 = "http://125.63.103.149:8080/userservice/api/v1/";
// export const baseUrlSubscribe = "http://125.63.103.149:8080/subscriptionservice/api/v1/";
// export const baseUrlDiscount = "http://125.63.103.149:8080/discountservice/api/v1/";
// export const baseUrlVendorManage = "http://125.63.103.149:8080/vendors/api/v1/";
// Gcp Qc urls
// export const baseUrl = "http://35.230.124.86:8080/catalogservice/api/v1/";
// export const baseUrlUser = "http://35.230.124.86:8080/userservice/api/v1/";
// export const baseUrlSubscribe = "http://35.230.124.86:8080/subscriptionservice/api/v1/";
// export const baseUrlDiscount = "http://35.230.124.86:8080/discountservice/api/v1/";
// Gcp Prod qc-
// export const baseUrl = "https://demoapi.trezi.com/catalogservice/api/v1/";
// export const baseUrlUser = "https://demoapi.trezi.com/userservice/api/v1/";
// export const baseUrlSubscribe = "https://demoapi.trezi.com/subscriptionservice/api/v1/";
// export const baseUrlDiscount = "https://demoapi.trezi.com/discountservice/api/v1/";
// export const baseUrlDashboard = "https://demoapi.trezi.com/dashboards/api/v1/";
// main gcp prod api's
export const baseUrl = "https://showcaseapi.trezi.com/catalogservice/api/v1/";
export const baseUrlUser = "https://showcaseapi.trezi.com/uus/api/v1/";
export const baseUrlSubscribe = "https://showcaseapi.trezi.com/subscriptionservice/api/v1/";
export const baseUrlDiscount = "https://showcaseapi.trezi.com/discountservice/api/v1/";
export const baseUrlVendorManage = "https://showcaseapi.trezi.com/vendors/api/v1/";
 export const baseUrlDashboard = "https://showcaseapi.trezi.com/dashboards/api/v1/";
export const getCookie = (name) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      const cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].split("=");
        if (cookie[0] === name) {
          resolve(cookie[1]);
          return;
        }
      }
      resolve(null);
    }, 100);
  });
};


export const getTokenFromLocalStorage = () => {
  return localStorage.getItem('treziAccessToken'); // Assuming your JWT token is stored with the key 'jwt'
};

export const interceptor = (axiosInstance) => {
  debugger;
  axiosInstance.interceptors.request.use(async (request) => {
    const token = getTokenFromLocalStorage();
    console.log("token", token);
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  });

  return axiosInstance;
};