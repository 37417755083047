import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import DashboardIndex from './DashboardIndex';

const produrl = 'https://showcaseapi.trezi.com/dashboards/';

const FlipbookUsed = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set loading to true

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = produrl + 'api/v1/data/daily/flip-book/metrics';
        const response = await axios.get(endpoint);
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        setError('Error making API call');
        console.error('Error making API call:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call fetchData function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  const exportToExcel = () => {
    if (data.length === 0) return; // Do nothing if there's no data

    const columns = Object.keys(data[0]);
    const headers = columns.map(column => column.charAt(0).toUpperCase() + column.slice(1));
    const worksheetData = [
      headers,
      ...data.map(item => columns.map(column => item[column] || ''))
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Flipbook Used');
    XLSX.writeFile(workbook, 'Flipbook_Used.xlsx');
  };

  const renderTable = () => {
    if (loading) return <p>Loading...</p>; // Show loading indicator

    if (error) return <p>{error}</p>; // Show error message if API call fails

    if (data.length === 0) return null; // Render nothing if data is empty

    const columns = Object.keys(data[0]);

    return (
      <div>
        <h2 id="heading">Showcase Flipbook Used</h2>
        <div id="button">
        <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
        </div>
     
        <div className='other_table_container'>
          <table className="result-table">
            <thead>
              <tr>
                {columns.map((column) => (
                  <th key={column}>{column.charAt(0).toUpperCase() + column.slice(1)}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((item, index) => (
                <tr key={index}>
                  {columns.map((column) => (
                    <td key={column}>{item[column]}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
    <DashboardIndex/>
<div className='home-container'>
  <div className='options-container'>
    {/* No button required as data is fetched automatically */}
  </div>
  <div className="data-container">
    {renderTable()}
  </div>
</div>
</>
  );
};

export default FlipbookUsed;
