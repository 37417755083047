import React, { Component } from 'react';
import "./Productattribute.css";
import "./ProductCluster.css";
import { Nav, Form, Dropdown, Button, Modal, FormCheck } from "react-bootstrap";
import { Grid } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon, ArrowDownward } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import { baseUrl } from '../../utils/common';
import { createCluster, getAllAttributes, getAllClusters, getSingleCluster, updateProductCluster, deleteClusters } from "../../services/ProductsService";
import Swal from 'sweetalert2';
import attributeIcon from "../../assests/public/attributes.png"
import categoryIcon from "../../assests/public/category_360.png"
const url = baseUrl;
class ProductCluster extends Component {
  constructor(props) {
    super(props);

    this.state = {
      attributes: [],
      clusters: [],
      showModal: false,
      sortDirection: 'asc',
      sortedBy: null,
      searchQuery: '',
      currentPage: 1,
      itemsPerPage: 9,
      name: '',
      variableType: '',
      label: '',
      isEdit: false,
      selectedAttributes: [],
      attributeSearchQuery: '',
    };
  }

  handleSearch = (event) => {
    this.setState({ searchQuery: event.target.value, currentPage: 1 });
  };  

  componentDidMount() {
    this.fetchAllAttributes();
    document.title = 'Product Clusters';
    this.fetchAllClusters();
  }

  fetchAllAttributes = () => {
    getAllAttributes()
      .then((data) => {
        this.setState({ attributes: data });
      })
      .catch((error) => {
        console.error('Error retrieving attributes:', error);
      });
  };

  fetchAllClusters = () => {
    debugger;
    getAllClusters()
      .then((data) => {
        if (data.response && data.response.status === 404) {
          console.log("Error 404: Customer categories not found.");
          this.setState({ clusters: [] });
        } else {
          console.log("Data retrieved:", data);
          this.setState({ clusters: data });
        }
        // this.setState({ clusters: data });
      })
      .catch((error) => {
        if (error.message === 'Request failed with status code 409') {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Attribute creation failed. Attribute already exists.',
          });
        }
          else if(error.response.status === 401){
            Swal.fire({
              title: 'Unauthorized!',
              text: 'Jwt token Expired. Please Login again.',
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000 // 2 seconds
            }).then(() => {
              window.location.href = "/";
            });
          } else {
            throw new Error('Request failed with status ' + error.response.status);
          }
      });
  };

  handleEdit = async (label) => {
    try {
      debugger;
      const cluster = await getSingleCluster(label);
      this.setState({
        showModal: true,
        name: cluster.name,
        oldName : cluster.name,
        label: cluster.label,
        selectedAttributes: cluster.attributes,
        isEdit: true,
        handleUpdateCluster: this.handleUpdateCluster.bind(this), // Bind the function
      });
    } catch (error) {
      console.error('Error retrieving cluster:', error);
    }
  };
  
  handleUpdateCluster = async () => {
    debugger;
    const { name, label, selectedAttributes, oldName } = this.state;
    const enteredValue = name.replace(/\s+/g, '_').toLowerCase();
    const attributeNames = selectedAttributes.map(attribute => attribute.name);
  
    try {
      const response = await updateProductCluster(
        oldName,
        name,
        label,
        enteredValue,
        attributeNames
      );
  
      if (response.status === 200 ) {
        Swal.fire({
          icon: 'success',
          title: 'Cluster Updated',
          text: 'The cluster has been updated successfully.',
        });
        this.fetchAllClusters();
        this.handleCloseModal();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error Updating Cluster',
          text: 'You cannot Update default clusters Texture/Color.',
        });
      }
    } catch (error) {
      // An error occurred while making the request or within updateProductCluster.
      console.error('Error updating cluster:', error);
      Swal.fire({
        icon: 'error',
        title: 'Network Error',
        text: 'An error occurred while updating the cluster. Please try again later.',
      });
    }
  }; 

  handleDelete = (label) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This action cannot be undone. Are you sure you want to delete the Cluster?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteClusters(label)
          .then((data) => {
            debugger;
            if (data.status === 200) {
              console.log('Cluster deleted:', data);
              const updatedClusters = this.state.clusters.filter(cluster => cluster.label !== label);
              this.setState({ clusters: updatedClusters }, () => {
                // Check if the current page becomes empty after deletion
                if (this.state.currentPage > 1 && updatedClusters.length <= ((this.state.currentPage - 1) * this.state.itemsPerPage)) {
                  // If the current page is now empty, move to the previous page
                  this.handlePaginationClick(this.state.currentPage - 1);
                }
              });
              Swal.fire({
                icon: 'success',
                title: 'Cluster Deleted',
                text: 'The Cluster has been deleted successfully.',
              });
              this.fetchAllClusters();
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Default Color and Texture cannot deleted.',
              });
            }
          })
          .catch((error) => {
            console.error('Error deleting Cluster:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'An error occurred while deleting the Cluster.',
            });
          });
      }
    });
  };
  

  handleSaveCluster = () => {
    debugger;
    const { name, selectedAttributes } = this.state;
    const enteredValue = name.replace(/\s+/g, '_').toLowerCase();
    const clusterData = {
      name: this.state.name,
      label: enteredValue,
      attributeNames: this.state.selectedAttributes.map((attribute) => attribute.name),
    };
    
    createCluster(clusterData)
      .then((data) => {
        console.log('Cluster created:', data);
        this.setState({
          showModal: false,
          name: '',
          selectedAttributes: [],
        });
        Swal.fire({
          icon: 'success',
          title: 'Cluster Created Successfully',
          text: 'The Cluster data has been submitted successfully.',
        });
        this.fetchAllClusters();
      })
      .catch((error) => {
        console.error('Error creating Cluster:', error);
        if (error.message === 'Request failed with status code 409') {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Cluster creation failed. Cluster already exists.',
          });
        }
      });
  };

  handleCreateModal = () => {
    this.fetchAllAttributes();
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      name: '',
      label: '',
      selectedAttributes: [], // Clear selected attributes
      isEdit: '',
      searchQuery: '',
      attributeSearchQuery: '', // Clear attribute search query
    });
  };
  
  handleSort = (columnName) => {
    const { clusters, sortDirection } = this.state;
    const sortedClusters = [...clusters].sort((a, b) => {
      if (a[columnName] < b[columnName]) {
        return sortDirection === 'asc' ? -1 : 1;
      }
      if (a[columnName] > b[columnName]) {
        return sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
    this.setState({
      clusters: sortedClusters,
      sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
      sortedBy: columnName,
    });
  };

  handlePaginationClick = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };
  // Check if an attribute is selected
  isAttributeSelected = (attribute) => {
    const { selectedAttributes } = this.state;
    return selectedAttributes.some((selectedAttribute) => selectedAttribute.label === attribute.label);
  };

  // Toggle attribute selection
  toggleAttributeSelection = (attribute) => {
    const { selectedAttributes } = this.state;
    const isSelected = this.isAttributeSelected(attribute);

    if (isSelected) {
      // Remove attribute from selectedAttributes
      const updatedAttributes = selectedAttributes.filter((selectedAttribute) => selectedAttribute.label !== attribute.label);
      this.setState({ selectedAttributes: updatedAttributes });
    } else {
      // Add attribute to selectedAttributes
      const updatedAttributes = [...selectedAttributes, attribute];
      this.setState({ selectedAttributes: updatedAttributes });
    }
  };
  render() {
    const { attributes, showModal, sortedBy, sortDirection, searchQuery, currentPage, itemsPerPage, selectedAttributes, clusters } = this.state;
    const filteredClusters = clusters.filter((cluster) =>
    cluster.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  
  const indexOfLastCluster = currentPage * itemsPerPage;
  const indexOfFirstCluster = indexOfLastCluster - itemsPerPage;
  const currentClusters = filteredClusters.slice(indexOfFirstCluster, indexOfLastCluster);

    return (
      <div className="cluster-container ">
        <div className='cluster-tz-heading'>Product Clusters</div>
        <div className="tz-table-container mt-4">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
              <Form className="d-flex tz-attr-textbox-with-icon i tz-attr-search-textbox-container">
                <Form.Control
                  type="search"
                  placeholder="Search Clusters"
                  className="tz-textbox"
                  aria-label="Search"
                  value={searchQuery}
                  onChange={this.handleSearch}
                />
                <i className="bi bi-search"></i>
              </Form>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div  >
                <Button style={{width :"125px"}} className='tz-small-devices-button' onClick={this.handleCreateModal}>Create New</Button>
              </div>
            </Grid>
          </Grid>
          <div className="cluster-container">
            {currentClusters.map((cluster) => (
              <div key={cluster.id} className="cluster-box">
                <div className="cluster-header">
                  <div className="cluster-name">{cluster.name}</div>
                  <div>
          {cluster.customerCategoryNames && cluster.customerCategoryNames.length > 0 ? (
            <>
              <EditIcon  disabled>Edit</EditIcon>
              <DeleteIcon  disabled>Delete</DeleteIcon>
            </>
          ) : (
            <>
              <EditIcon className='edit-icon' onClick={() => this.handleEdit(cluster.label)}>Edit</EditIcon>
              <DeleteIcon className='edit-icon' onClick={() => this.handleDelete(cluster.label)}>Delete</DeleteIcon>
            </>
          )}
        </div>
                </div>
                <div >
  <div className="total-attributes mt-2">
  <img src={attributeIcon} alt="Attribute Icon" className="attribute-icon" /> {cluster.attributes.length} Attributes
  </div>
  <div className="attribute-names">
    {cluster.attributes.map((attribute, index) => (
      <span className = "tz-attr-name" key={attribute.name}>
        {attribute.name}
        {index !== cluster.attributes.length - 1 && ', '}
      </span>
    ))}
  </div>
  <div className="cluster-category-name mt-2"> 
    <img src={categoryIcon} alt="Attribute Icon" className="category-icon" /> {cluster.customerCategoryNames.length} Category</div>
</div>
 <div className="tz-category-name">
 {cluster.customerCategoryNames.join(', ')}
  </div>
              </div>
            ))}
          </div>
          <div className="pagination">
          <ReactPaginate
            pageCount={Math.ceil(filteredClusters.length / itemsPerPage)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            // onPageChange={this.handlePaginationClick}
            onPageChange={(selectedPage) => this.handlePaginationClick(selectedPage.selected + 1)}
            containerClassName="pagination"
            activeClassName="pagination-item-active"
          />
        </div>
        </div>
        {showModal && (
          <Modal show={showModal} onHide={this.handleCloseModal} centered={true} backdrop="static" keyboard={false} dialogClassName="tz-my-modal" attribute={attributes} >
            <Modal.Header closeButton>
              <Modal.Title>{this.state.isEdit ? 'Edit Cluster' : 'Create Cluster'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="clusterName">
                  <Form.Label className="tz-form-label mt-3">Cluster Name</Form.Label>
                  <Form.Control
                    className="tz-atr-form-control"
                    type="text"
                    placeholder="Enter Cluster name"
                    value={this.state.name}
                    onChange={(event) => this.setState({ name: event.target.value })}
                  />
                </Form.Group>
                <Form.Group controlId="clusterType">
      <Form.Label className="tz-form-label mt-3">Select Attributes</Form.Label>
      <div className="tz-cluster-select-formcontrol">
        <Form.Control
          className="tz-atr-form-control"
          type="text"
          placeholder="Search"   
          value={this.state.attributeSearchQuery}
          onChange={(event) => this.setState({ attributeSearchQuery: event.target.value })}  
        />
        <div className="attribute-checkbox-container mt-2">
        {attributes
  .filter((attribute) =>
    attribute.name.toLowerCase().includes(this.state.attributeSearchQuery.toLowerCase())
  ).map((attribute) => (
            <FormCheck
              key={attribute.label}
              id={`attribute-${attribute.label}`}
              label={attribute.name}
              checked={this.isAttributeSelected(attribute)}
              onChange={() => this.toggleAttributeSelection(attribute)}
            />
          ))}
        </div>
      </div>
    </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal} className='tz-primary-btn reverse btn btn-secondary'>Close</Button>
              <Button variant="primary"  className='tz-primary-btn' onClick={this.state.isEdit ? this.handleUpdateCluster : this.handleSaveCluster}>
                {this.state.isEdit ? 'Update' : 'Create'}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    );
  }
}

export default ProductCluster;
