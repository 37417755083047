import React from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const DeliveryDateModal = ({ isOpen, onClose, onSave, onDateChange, modalTitle }) => {
  // Get the current date in the format "YYYY-MM-DD"
  const currentDate = new Date().toISOString().split('T')[0];

  return (
    <Modal show={isOpen} onHide={onClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="deliveryDatePicker">
          <Form.Label className='custom-text-12'>Select Date</Form.Label>
          <Form.Control
            type="date"
            onChange={(e) => onDateChange(e.target.value)}
            min={currentDate} // Set the minimum date to the current date
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={onSave}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeliveryDateModal;
