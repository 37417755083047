import React, { Component } from 'react';
import { Table, Form, Dropdown ,Stack ,Pagination,Modal, Button  } from 'react-bootstrap'; // Import required components from react-bootstrap
// import './CustomerSuccessTable.css';
import {  getOrderManagement,getOrderAnalytics ,OderStatusAccepted , OderStatusDispatch , OderStatusDeliver, OderStatusRejected} from "../../services/ProductsService";
import ReactPaginate from 'react-paginate';
import EditIcon from "../../assests/public/edit (1).png"
import Swal from 'sweetalert2';
import Loader from "../../context/loader.js";
import AccordionOrderDetails from './AccordionOrderDetails.jsx';
import './InventoryLog.css';
import './OrderManagemet.css';
import Icon1 from '../../assests/public/Icon-01.png';
import Icon2 from '../../assests/public/Icon-02.png';
import Icon3 from '../../assests/public/Icon-03.png';
import Icon4 from '../../assests/public/Icon-04.png';
import ArrowIcon from '../../assests/public/Group 3159.png';
import Icon5 from '../../assests/public/Group 3303.png';
import DeliveryDateModal from './DeliveryDateModal'; 
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const dropdownOptionsMap = {
    New: ['Accepted', 'Rejected'],
    Accepted: ['Dispatched'],
    Dispatched: ['Delivered'],
    Rejected: [],
  }; 
class OrderManagement extends Component {
    constructor(props) {
      super(props);
      this.state = {
      Data:[],
      OderstaticsData:[],
       isAnyCheckboxSelected: false,
        sortColumn: '', // Provide initial value for sorting
        sortDirection: 'asc', // Provide initial value for sorting direction
        currentPage: 1, // Provide initial value for the current page
        itemsPerPage: 10, 
        pageNumbers: [],   
        filteredData: [],
        isDownloadOptionsActive: false,
        isLoading: false,
        isBulkUploadModalOpen: false,
        openAccordionId: null,
        error: "",
        selectedStatus: {}, 
        isDeliveryDateModalOpen: false,
        selectedOrderIdForDeliveryDate: null,
        selectedEstimatedDeliveryDate: null,
        isCommentModalOpen: false,
  modalTitle: '', // You need to provide a default title or update it dynamically
  comment: '', 
  isOrderDetailsOpen  : false,
      };
    }
    componentDidMount() {
      document.title = 'Order Management';
        this.fetchOrdersByStatus();
        this.fetchOrderAnalytics();
    }
    
    componentDidUpdate(prevProps, prevState) {
      debugger;
      if (
        prevProps.searchQuery !== this.props.searchQuery ||
        this.state.isUpdated !== prevState.isUpdated ||
        prevState.productData !== this.state.productData
      ) {
        this.filterData();
      }

    }
   
    handlePageChange = (selectedPage) => {
      debugger;
      this.setState({ currentPage: selectedPage });
    };
    
    handleSort = (columnName) => {
        const { sortColumn, sortDirection, filteredData } = this.state;
        const newSortDirection =
          sortColumn === columnName ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
        const sortedData = [...filteredData];
        sortedData.sort((a, b) => {
          if (columnName === 'batchNumber') {
            // Convert batch numbers to integers and compare them
            const numA = parseInt(a.batchNumber.split('_')[1], 10);
            const numB = parseInt(b.batchNumber.split('_')[1], 10);
            return newSortDirection === 'asc' ? numA - numB : numB - numA;
          }
          return 0;
        });
      
        this.setState({
          filteredData: sortedData,
          sortColumn: columnName,
          sortDirection: newSortDirection,
        });
      };
      handleStatusChange = async (orderId, selectedStatus) => {
        this.setState((prevState) => ({
          selectedStatus: {
            ...prevState.selectedStatus,
            [orderId]: selectedStatus,
          },
        }));
      
        if (selectedStatus === 'Accepted' || selectedStatus === 'Dispatched') {
          this.setState({
            isDeliveryDateModalOpen: true,
            selectedOrderIdForDeliveryDate: orderId,
            deliveryDateModalHeading:
              selectedStatus === 'Dispatched'
                ? 'Estimated Dispatched Date'
                : 'Estimated Delivery Date',
          });
        } else if (selectedStatus === 'Rejected') {
          this.setState({
            isCommentModalOpen: true,
            selectedOrderIdForComment: orderId,
            selectedStatusForComment: selectedStatus,
          });
        } else if (selectedStatus === 'Delivered') {
          try {
          const deliver=  await OderStatusDeliver(orderId);
          if(deliver.status === 200){
            try {
                const logs = await getOrderManagement('Dispatched');
                await this.fetchOrderAnalytics();
                this.setState({
                  Data: logs.data,
                });
              } catch (err) {
                console.log(err);  
                if (err.response && err.response.status === 404) {
                  await this.fetchOrderAnalytics();
                  this.setState({
                    Data: [],
                  });
                 return;
                } else {
                  Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'An error occurred. Please try again later.',
                  });
                }
              }
          }
            this.setState({
              isCommentModalOpen: false,
              selectedOrderIdForComment: null,
              selectedStatusForComment: '',
              comment: '',
            });
          } catch (error) {
            console.error('Error updating status to Delivered:', error);
            // Handle the error as needed
          }
        } else {
          // The 'else' block for other status values
          this.setState({
            isDeliveryDateModalOpen: false,
            isCommentModalOpen: false,
            selectedOrderIdForDeliveryDate: null,
            selectedOrderIdForComment: null,
            selectedStatusForComment: '',
            deliveryDateModalHeading: '',
          });
        }
      };
      
      
        handleCommentChange = (event) => {
            this.setState({ comment: event.target.value });
          };
          
          handleCommentModalClose = () => {
            this.setState({
              isCommentModalOpen: false,
              modalTitle: '', // Optionally reset the modal title
              comment: '',
              selectedStatus: {} // Optionally reset the comment
            });
          };
      handleEstimatedDeliveryDateChange = (date) => {
        this.setState({ selectedEstimatedDeliveryDate: date });
      };
      handleSaveComment = async () => {
        try {
          const { selectedOrderIdForComment, comment } = this.state;
          await OderStatusRejected(selectedOrderIdForComment, comment);
          console.log('Before fetching order analytics');
          await this.fetchOrderAnalytics();
          console.log('After fetching order analytics');
          try {
            const logs = await getOrderManagement('New');
            this.setState({
              Data: logs.data,
            });
          } catch (err) {
            console.log(err);  
            if (err.response && err.response.status === 404) {
              this.setState({
                Data: [],
                isCommentModalOpen: false,
                selectedOrderIdForComment: null,
                selectedStatusForComment: '',
                comment: '',
              });          
            } else {
                this.setState({
                    Data: [],
                  });
                  return;
            }
          }
          this.setState({
            isCommentModalOpen: false,
            selectedOrderIdForComment: null,
            selectedStatusForComment: '',
            comment: '',
          });
        } catch (error) {
          console.error('Error saving comment:', error);
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to save comment. Please try again later.',
          });
        }
      };
      handleSaveDeliveryDate = async () => {
        try {
          const {
            selectedOrderIdForDeliveryDate,
            selectedEstimatedDeliveryDate,
            selectedStatus,
          } = this.state;
      
          let response;
      
          if (selectedStatus[selectedOrderIdForDeliveryDate] === 'Dispatched') {
            debugger;
            response = await OderStatusDispatch(selectedOrderIdForDeliveryDate, selectedEstimatedDeliveryDate);
          } else {
            response = await OderStatusAccepted(selectedOrderIdForDeliveryDate, selectedEstimatedDeliveryDate);
          }
      
          if (response.status === 200) {
            console.log("Success");
            await this.fetchOrderAnalytics();
            const logs = await getOrderManagement(selectedStatus[selectedOrderIdForDeliveryDate] === 'Dispatched' ? 'Accepted' : 'New');   
            this.setState({
              Data: logs.data,
              isDeliveryDateModalOpen: false,
              selectedOrderIdForDeliveryDate: null,
              selectedEstimatedDeliveryDate: null,
              deliveryDateModalHeading: '',
            });
          }
        } catch (error) {
        if(error.response.status === 404){
          this.setState({
            Data: [],
            isDeliveryDateModalOpen: false,
            selectedOrderIdForDeliveryDate: null,
            selectedEstimatedDeliveryDate: null,
            deliveryDateModalHeading: '',
          });
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Failed to select date. Please try again later.',
          });
        }
        } finally {
          // Ensure these are always set regardless of the outcome
          this.setState({
            isDeliveryDateModalOpen: false,
            selectedOrderIdForDeliveryDate: null,
            selectedEstimatedDeliveryDate: null,
            deliveryDateModalHeading: '',
          });
        }
      };
      
      async fetchOrdersByStatus(status) {
        const selectedStatus = status ? status : 'New';
        debugger;
        try {
          const logs = await getOrderManagement(selectedStatus);
      
          this.setState({
            Data: logs.data,
          });
      
          console.log(logs, "aaaa");
        } catch (err) {
          console.log(err);  
          if (err.response && err.response.status === 400) {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Bad request. Please check your input or try again later.',
            });
          } else {
            this.setState({
                Data: [],
              });
            return;
          }
        }
      }
    async fetchOrderAnalytics() {
      debugger;
      try {
        const logs = await getOrderAnalytics();
  
        this.setState(
          {
            OderstaticsData : logs.data,
          },
        );
        console.log(logs , "aaaa")
      } catch (err) {
        console.log(err);
      }
    }
    handleBoxClick = (status) => {
        if (status === 'Rejected') {
          this.setState({
            selectedStatus: status,
            isRejectedBlockClicked: true,
          });
        } else {
          // For other boxes, set the flag to false
          this.setState({
            selectedStatus: status,
            isRejectedBlockClicked: false,
          });
        }
      
        // Fetch orders based on the selected status
        this.fetchOrdersByStatus(status);
      };
      
    handleLineClick = (batchNumber) => {
        this.setState((prevState) => ({
          openAccordionId: prevState.openAccordionId === batchNumber ? null : batchNumber,
          isOrderDetailsOpen: !prevState.isOrderDetailsOpen,
        }));
      };
      getColorForStatus(status) {
        const colorMap = {
          Accepted: 'accepted-color',
          Dispatched: 'dispatched-color',
          Delivered: 'delivered-color',
          Rejected: 'rejected-color',
        };
        return colorMap[status];
      }
      
      renderDropdownOptions(status) {
        const dropdownOptions = dropdownOptionsMap[status] || [];
        return (
          <Dropdown.Menu className='custom-dropdown-menu' style={{ width: '50px !important'}}>
            {dropdownOptions.map((option) => (
              <Dropdown.Item   style={{padding : "0px"}}        className={`custom-dropdown-item ${this.getColorForStatus(option)}`}  key={option}
             eventKey={option} >
                {option}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        );
      }
    
    //   handleBoxClick = (status) => {
    //     this.setState({ selectedStatus: status });
    //     this.fetchOrdersByStatus(status);
    //   };
      renderOrderStatistics() {
        const orderStatistics =  this.state.OderstaticsData;
        return (
            <div className="responsive-boxes-container mt-4">
        <div className="responsive-box"  onClick={() => this.handleBoxClick('New')}>
            <div className='mt-4'><img  alt='icon' src={Icon1} width={25} />
            </div>
            <div className='mt-1 text-bold'>New Orders
            </div>
            <div className='mt-1 text-bold'>{orderStatistics.newOrders}
            </div>
            </div>
        <div className="responsive-box-1"  onClick={() => this.handleBoxClick('Accepted')}> 
         <div className='mt-4'><img  alt='icon' src={Icon2} width={25} />
            </div>
            <div className='mt-1 text-bold'>Accepted
            </div>
            <div className='mt-1 text-bold'>{orderStatistics.acceptedOrders}
            </div>
            </div>
        <div className="responsive-box-2" onClick={() => this.handleBoxClick('Dispatched')}>   
        <div className='mt-4'><img  alt='icon' src={Icon3} width={25} />
            </div>
            <div className='mt-1 text-bold'>Dispatched
            </div>
            <div className='mt-1 text-bold'>{orderStatistics.dispatchedOrders}
            </div>
            </div>
        <div className="responsive-box-3"  onClick={() => this.handleBoxClick('Delivered')}> 
          <div className='mt-4'><img  alt='icon' src={Icon4} width={25} />
            </div>
            <div className='mt-1 text-bold'>Delivered 
            </div>
            <div className='mt-1 text-bold'>{orderStatistics.deliveredOrders}
            </div>
            </div>
        <div className="responsive-box-4" onClick={() => this.handleBoxClick('Rejected')} >   
        <div className='mt-4'><img  alt='icon' src={Icon2} width={25} />
            </div>
            <div className='mt-1 text-bold'>Rejected
            </div>
            <div className='mt-1 text-bold'>{orderStatistics.rejectedOrders}
            </div>
            </div>
        <div className="responsive-box-5" >
        <div className='mt-4'><img  alt='icon' src={Icon5} width={25} />
            </div>
            <div className='mt-1 text-bold'>Total Orders
            </div>
            <div className='mt-1 text-bold'>{orderStatistics.totalOrders}
            </div>
            </div>
            
      </div>
        );
      }
   
    render() {
      const { sortColumn, sortDirection, Data , toggleAccordion ,isLoading ,filteredData ,openAccordionId  , isBulkUploadModalOpen , bulkUploadClientId } = this.state;
      const {
        currentPage,
        itemsPerPage,
      } = this.state;
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      
        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(Data.length / itemsPerPage); i++) {
          pageNumbers.push(i);
        }
        const TimestampInfo = (time) => {
            if (time === null || time === 0) {
              return "-";
            }
            const timestamp = time;
            const datetimeObj = new Date(timestamp);
      
            const month = datetimeObj.toLocaleString("default", { month: "short" });
            const date = datetimeObj.getDate();
            const day = datetimeObj.toLocaleString("en-US", { weekday: "long" });
            const year = datetimeObj.getFullYear();
      
            return (
              <div>
                <span className="tz-month custom-font-12">
                  {month} {date}, {year}
                </span>{" "}
                <br />
                <span className="tz-day custom-font-12">{day}</span>
              </div>
            );
          };
        const displayedData = Data.slice(indexOfFirstItem, indexOfLastItem);
     
      return (
        <div className="centered-content-container">
     <Stack className='table-inventory-product-container '>
      <div className="upload-heading">
      Order Management
      </div>
      {this.renderOrderStatistics()} 
          <div className='table-inventory mt-4'>
          <Table hover borderless className="order-management-table">
            <thead className="order-management-list-view-inventory-table-head" >
              <tr>
              <th className="first-product-column-th custom-font-10">
                  <div
                    className="d-flex sort-column align-items-center"
                    onClick={() => this.handleSort('batchNumber')}
                  >
                    <span className="custom-font-15 custom-table-header">Order ID</span>
                    <div className="tz-sort-icons">
                      <button
                        className={`sort-button ${
                          sortColumn === 'batchNumber' &&
                          sortDirection === 'asc' &&
                          'active'
                        }`}
                      >
                        <span className="bi bi-caret-up-fill"></span>
                        <span className="bi bi-caret-down-fill"></span>
                      </button>
                    </div>
                  </div>
                </th>
             <th className="fifth-product-column-th">
                <span className="custom-font-15 product-checkbox custom-table-header">Sales Email-Address</span>
                </th>
                {/* Title & Tzn Number */}
                <th className="second-product-column-th custom-font-10">
                    <span className="custom-font-15 custom-table-header">Order Placed</span>
                </th>
  
                 {/* Product Tittle */}
                 <th className="fifth-product-column-th custom-font-10">
                    <span className="custom-font-15 custom-table-header">No. of Products</span>
                </th>
                <th className="fifth-product-column-th custom-font-10">
                    <span className="custom-font-15 custom-table-header">Total Amount(Rs.)</span>
                </th>
                 <th className="fourth-product-column-th custom-font-10">
                    <span className="custom-font-15 custom-table-header ml-4">Status</span>
                </th> 

                 <th className="fifth-product-column-th custom-font-15">
                    <span className="custom-font-15 custom-table-header">Order Details</span>
                </th>
      
  {this.state.selectedStatus === 'Accepted' && (
      <th className="fifth-product-column-th custom-font-10">
    <span className="custom-font-15 custom-table-header">Estimated Dispatched Date</span>
    </th>
  )}
  {this.state.selectedStatus === 'Dispatched' && (
    <th className="fifth-product-column-th custom-font-10">
    <span className="custom-font-15 custom-table-header">Expected Delivery Date</span>
    </th>
  )}
  {this.state.selectedStatus === 'Delivered' && (
    <th className="fifth-product-column-th custom-font-10">
    <span className="custom-font-15 custom-table-header">Delivered Date</span>
    </th>
  )}


                {this.state.isRejectedBlockClicked && (
                <th className="fifth-product-column-th custom-font-15">
                    <span className="custom-font-15 custom-table-header">Rejection Reason</span>
                </th>
                )}
              </tr>
            </thead>
  
            {/* Table body */}
            <tbody className="tz-table-body">
            {displayedData?.map((item ,index) => (
                    <React.Fragment key={item.id}>
                <tr className="tz-inventory-table-body-row mt-2"  key={item.batchNumber}  >
                  <td className="first-product-column w-30">
                  {item.orderId}
                  </td>
                  <td className="first-product-column">
                  <div className="name-container">
                    
                    <span>
                      <div className="product-title custom-font-12">
                      {item.salesUserId}
                      </div>
                      {/* <div className="product-serial custom-font-12">
                      {item.orderPlaced}
                      </div> */}
                    </span>
              </div>
                    </td>
                  <td
                    className="second-product-column "
                  >
                    <div className="name-container">
                    
                          <span>
                            {/* <div className="product-title custom-font-12">
                              {item?.title}
                            </div> */}
                            <div className="custom-font-12">
                            {TimestampInfo(item.orderDate)}
                            </div>
                          </span>
                    </div>
                  </td>
                  <td className="fifth-product-column">
                  {item.itemCount}
         </td>
         <td className="fifth-product-column">
  {new Intl.NumberFormat('en-IN').format(item.totalAmount)}
</td>

                    <td className="fourth-product-column">
  <div className="name-container">
    <span>
    <Dropdown
  onSelect={(selectedStatus) => this.handleStatusChange(item.orderId, selectedStatus)}
>
<Dropdown.Toggle
  variant="success"
  id="dropdown-basic"
  style={{
    backgroundColor:
      item.status === 'New'
        ? '#FFEBD6'
        : item.status === 'Accepted'
        ? '#E8FAE0'
        : item.status === 'Dispatched'
        ? '#CEE4FF' 
        : item.status === 'Delivered'
        ? '#CFF4ED'
        : item.status === 'Rejected'
        ? '#F8D3DC'
        : '#FFEBD6',
    border:
      item.status === 'New'
        ? '1px solid #FFEBD6'
        : item.status === 'Accepted'
        ? '1px solid #E8FAE0'
        : item.status === 'Dispatched'
        ? '1px solid #CEE4FF'
        : item.status === 'Delivered'
        ? '#CFF4ED'
        : item.status === 'Rejected'
        ? '1px solid #F8D3DC'
        : '1px solid #FFEBD6',
    color:
      item.status === 'New'
        ? '#FF8C00'
        : item.status === 'Accepted'
        ? '#4CAF50'
        : item.status === 'Dispatched'
        ? '#1E90FF'
        : item.status === 'Delivered'
        ? '#4CAF50'
        : item.status === 'Rejected'
        ? '#FF6347'
        : '#FF8C00',
  }}
>
  {item.status}
</Dropdown.Toggle>
  {this.renderDropdownOptions(this.state.selectedStatus[item.orderId] || item.status)}
</Dropdown>

    </span>
  </div>
</td>

                    <td className="fifth-product-column" onClick={() => this.handleLineClick(item.orderId)}>
                        <span className='inventory-records custom-font-12'>
                        {this.state.isOrderDetailsOpen ? (
                             <ExpandLessIcon className="arrow-icon-up" />
    ) : (
        <ExpandMoreIcon className="arrow-icon-down" />
    )}
                        </span>
                    </td>
                    {this.state.isRejectedBlockClicked && (
                 <td className="fifth-product-column">
                         {item.rejectionReason}
             </td>
                )}
        {item.status === 'Accepted' && (
          <td className="fifth-product-column">
            {item.estimatedDispachDate ? TimestampInfo(item.estimatedDispachDate) : '-'}
          </td>
        )}
        {item.status === 'Dispatched' && (
          <td className="fifth-product-column">
            {item.estimatedDeliveryDate ? TimestampInfo(item.estimatedDeliveryDate) : '-'}
          </td>
        )}
          {item.status === 'Delivered' && (
          <td className="fifth-product-column">
            {item.estimatedDeliveryDate ? TimestampInfo(item.estimatedDeliveryDate) : '-'}
          </td>
        )}
                </tr> 
                {openAccordionId === item.orderId && (
     <AccordionOrderDetails item={item} isOpen={true} onClick={() => this.handleLineClick(item.orderId)} />
)}
                  </React.Fragment>
              ))}
            </tbody>
          </Table>
          </div>
        </Stack>
        <div  style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
    {/* <span style={{ color: '#6A6D74', fontWeight: '400', fontStyle: 'normal' }} className="custom-font-14">
      Showing {indexOfFirstItem + 1} - {Math.min(indexOfLastItem, Data.length)} of {Data.length} items
    </span> */}
     {this.state.isDeliveryDateModalOpen && (
  <DeliveryDateModal
    isOpen={this.state.isDeliveryDateModalOpen}
    onClose={() => this.setState({ isDeliveryDateModalOpen: false , selectedStatus: {}})}
    onSave={this.handleSaveDeliveryDate}
    onDateChange={this.handleEstimatedDeliveryDateChange}
    modalTitle={
      this.state.selectedStatus[this.state.selectedOrderIdForDeliveryDate] === 'Dispatched'
        ? 'Estimated Delivery Date'
        : 'Estimated Dispatched Date'
    }
  />
)}
  {this.state.isCommentModalOpen && (
  <Modal show={this.state.isCommentModalOpen} onHide={this.handleCommentModalClose} centered>
    <Modal.Header closeButton>
      <Modal.Title>Rejection Reason</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Form.Group controlId="Rejection">
        <Form.Label>{this.state.modalTitle}</Form.Label>
        <Form.Control
          type="text"
          value={this.state.comment}
          onChange={this.handleCommentChange}
        />
      </Form.Group>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={this.handleSaveComment}>
        Save
      </Button>
    </Modal.Footer>
  </Modal>
)}

    <ReactPaginate
              pageCount={Math.ceil(Data.length / itemsPerPage)}
              pageRangeDisplayed={5}
              marginPagesDisplayed={1}
              onPageChange={(selectedPage) => this.handlePageChange(selectedPage.selected + 1)}
              containerClassName="pagination"
              activeClassName="pagination-item-active"
            />
  </div>
        </div>
     
      );
    }
  }
  export default OrderManagement;