import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button , Stack } from "react-bootstrap";
import "./EditSingleProduct.css"; // Replace with your custom CSS file name
import {  getProductById ,updateProductforthumbnails } from "../services/ProductsService";
import Swal from 'sweetalert2';
import { FaUpload } from "react-icons/fa";
import Loader from "../context/loader.js";
import { useParams } from "react-router-dom";
import arrowRight from '../assests/arrow-right.svg';

function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}

class EditSingleProduct extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedCluster: '',
            clusters: [], // Add this state variable to store available clusters
            attributes: [], // Add this state variable to store attributes based on the selected cluster
            attributeValues: {},
            image1File: null, // Add image1File state variable
            image2File: null, // Add image2File state variable
            image3File: null, // Add image3File state variable
            isLens3DModelUploaded: false,
            lensModel3DFile: null,
            isViewer3DModelUploaded: false,
            viewerModel3DFile: null,
            errorMessage: "",
            isLoading: false,
            isLoadingProduct: true,
            data: {},
            thumbnailFiles: [null, null, null], 
            isClusterSelected: false,
            customerCatageory :[],
        };
    }

    componentDidMount() {
        this.fetchProductById();
        document.title = "Product Details Edit";
    }
    getCookie = (name) => {
      const cookieString = document.cookie;
      const cookies = cookieString.split(';');
     for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.params.productId !== this.props.params.productId ||
            this.state.isUpdated !== prevState.isUpdated
        ) {
            this.fetchProductById();
        }
    }

    handleItem = (arg) => {
      this.setState(s => ({ ...s, selected: arg }))
    }
    async fetchProductById() {
        debugger;
        const { productId } = this.props.params;
        try {
          const product = await getProductById(productId);
          const ProductData = product;
          console.log(ProductData);
          this.setState({ data: ProductData, isLoadingProduct: false });
        } catch (err) {
          console.log(err);
          this.setState({ isLoadingProduct: false });
        }
      }
      handleAttributeValueChange = (e, attribute) => {
        debugger;
        const updatedAttributes = [...this.state.data.additionalAttributes]; // Clone the attributes array
        const index = updatedAttributes.findIndex((attr) => attr.name === attribute.name); 
        if (index !== -1) {
          updatedAttributes[index].value = e.target.value;
          this.setState((prevState) => ({
            data: {
              ...prevState.data,
              additionalAttributes: updatedAttributes,
            },
          }));
        }
      };
      

    handleCancel = (e) => {
        window.location.href = "/product-catalog";
    }
    handleSubmit = async (e) => {
      debugger;
        e.preventDefault();
        const organizationIdentifier = this.getCookie('organizationIdentifier');
        const {
            data,
            thumbnailFiles,
            attributes,
          } = this.state;
          const { productId} = this.props.params;
        const formData = new FormData();
        formData.append("productId", data.productId);
        const updatedAttributes = data.additionalAttributes;
        formData.append('additionalAttributes', JSON.stringify(updatedAttributes));
          formData.append("thumbnail1", data.thumbnails[0]?.file);
          formData.append("thumbnail2", data.thumbnails[1]?.file);
          formData.append("thumbnail3", data.thumbnails[2]?.file);          
        try {
          const response = await updateProductforthumbnails(formData);
          Swal.fire({
            icon: 'success',
            title: 'Product Updated Successfully',
            text: 'The product details have been updated successfully!',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              this.fetchProductById();
              window.location.href = `/products/${organizationIdentifier}/${data.productId}`;
            }
          });
          setTimeout(() => {
            this.fetchProductById();
            window.location.href = `/products/${organizationIdentifier}/${data.productId}`;
          }, 2000);
        } catch (error) {
          // Handle API error (e.g., show error message)
          console.error("Error updating product:", error);
        }
      };
 
    isImageFileSizeValid = (file) => {
        const maxSize = 3 * 1024 * 1024; // 3MB in bytes
        return file.size <= maxSize;
    };
    handleImage1Upload = (event, index) => {
      const file = event.target.files[0];
      if (this.isImageFileSizeValid(file)) {
        this.updateThumbnailFile(index, file); // Update the thumbnailFiles array
      } else {
        // Handle the error case for invalid file size
        Swal.fire({
          icon: 'error',
          title: 'Invalid Image Size',
          text: 'Image size should not exceed 3MB.',
        });
      }
    };
  
    handleImage2Upload = (event, index) => {
      const file = event.target.files[0];
      if (file) {
        this.updateThumbnailFile(index, file);
      }
    };
  
    handleImage3Upload = (event, index) => {
      const file = event.target.files[0];
      if (file) {
        this.updateThumbnailFile(index, file);
      }
    };  
    updateThumbnailFile = (index, file) => {
      this.setState((prevState) => {
        const thumbnailFiles = [...prevState.thumbnailFiles];
        thumbnailFiles[index] = file;
    
        const newData = { ...prevState.data };
        if (!newData.thumbnails) {
          newData.thumbnails = [];
        }
    
        if (!newData.thumbnails[index]) {
          newData.thumbnails[index] = {}; // Initialize the thumbnail object
        }
    
        newData.thumbnails[index] = file ? { file } : null; // Update to null if no file
    
        return { data: newData, thumbnailFiles };
      });
    };
    
    handleClusterItemClick = (cluster) => {
      debugger;
      const { data } = this.state;
      const selectedCategory = data.categoryName;
        const selectedCluster = cluster;
    
        if (selectedCluster) {
          const attributes = data.additionalAttributes;
          this.setState((prevState) => ({
            selectedCluster: selectedCluster,
            attributes: attributes,
            attributeValues: {
              ...prevState.attributeValues, 
              ...attributes.reduce((values, attribute) => {
                values[attribute.name] = prevState.attributeValues[attribute.name] || {
                  value: '',
                  variableType: attribute.variableType,
                  clusterName: selectedCluster.name,
                };
                return values;
              }, {}),
            },
          }));
        }
     
    };
    handleChange = (e, parentIndex) => {
      debugger;
      if (parentIndex !== undefined && parentIndex !== null) { // for handling additional attributes state
        this.setState(s => ({
          ...s, additionalAttributes: s.additionalAttributes.map((item, index) =>
            index === parentIndex ? { ...item, ["value"]: e.target.value } : item),
        }))
        return
      }
      this.setState({ [e.target.name]: e.target.value });
    };

      
    render() {
        const {
            image1File,
            selectedCluster, attributes, attributeValues,
            errorMessage,
            customerCatageory,
            isLoading,
            customerId,
            data,
            thumbnailFiles,
            handleChange
          } = this.state;
          
        return (
            <div className="custom-upload-catalog-container">
                {isLoading && (
                    <Loader />
                )}
                <Container fluid >
                    {/* <h1 className='upload-heading'>Edit Product</h1> */}
                    <Stack
            direction="horizontal"
            gap={3}
            className="list-dashboard-header"
          ></Stack>
                    <div className="custom-font-20 product-catalog">
           Edit Product Details
            </div>
                    <Row className="custom-product-details-row mt-4">
                        <Col md={1}>
                        </Col>
                    <Col md={3} className="custom-small-image-container">
                    <Card className="custom-image-card" style={{ width: "100%", height: "150px" }}>
  <div className='file-select-center'>
    {data.thumbnails && data.thumbnails[0] ? (
      <img
        src={thumbnailFiles[0] ? URL.createObjectURL(thumbnailFiles[0]) : data.thumbnails[0].fileUrl}
        alt={`Thumbnail 0`}
        style={{ width: "100%", height: "100px" , padding:"10px" }}
      />
    ) : (
      <div>
        {thumbnailFiles[0] ? (
          <img
          src={URL.createObjectURL(thumbnailFiles[0])}
            alt={`Thumbnail 0`}
            style={{ width: "100%", height: "100px" , padding:"10px" }}
          />
        ) : (
          <label htmlFor="image1File" className="file-upload-label">
            <FaUpload size={30} className="upload-icon" />
            <span>Upload Image 1</span>
          </label>
        )}
      </div>
    )}
    <input
      type="file"
      id="image1File"
      accept=".jpg,.png,.svg"
      onChange={(event) => this.handleImage1Upload(event, 0)}
    />
  </div>
</Card>

  <Card className="custom-image-card mt-3" style={{ width: "100%", height: "150px" }}>
    <div className='file-select-center'>
    {data.thumbnails && data.thumbnails[1] ? (
  <img
    src={thumbnailFiles[1] ? URL.createObjectURL(thumbnailFiles[1]) : data.thumbnails[1].fileUrl}
    alt={`Thumbnail 2`}
    style={{ width: "100%", height: "100px" , padding:"10px" }}
  />
) : (
  <div>
        {thumbnailFiles[1] ? (
          <img
            src={URL.createObjectURL(thumbnailFiles[1])}
            alt={`Thumbnail 2`}
            style={{ width: "100%", height: "100px" , padding:"10px" }}
          />
        ) : (
          <label htmlFor="image2File" className="file-upload-label">
            <FaUpload size={30} className="upload-icon" />
            <span>Upload Image 2</span>
          </label>
        )}
      </div>
)}
      <input type="file" id="image2ile" accept=".jpg,.png,.svg"   onChange={(event) => this.handleImage2Upload(event, 1)} />
    </div>
  </Card>
  <Card className="custom-image-card mt-3" style={{width: "100%", height: "150px"}}>
    <div className='file-select-center'>
    {data.thumbnails && data.thumbnails[2] ? (
  <img
    src={thumbnailFiles[2] ? URL.createObjectURL(thumbnailFiles[2]) : data.thumbnails[2].fileUrl}
    alt={`Thumbnail 3`}
    style={{ width: "100%", height: "100px" , padding:"10px" }}
  />
  
) : (
  <div>
        {thumbnailFiles[2] ? (
          <img
            src={URL.createObjectURL(thumbnailFiles[2])}
            alt={`Thumbnail 3`}
            style={{ width: "100%", height: "80px" }}
          />
        ) : (
          <label htmlFor="image3File" className="file-upload-label">
            <FaUpload size={30} className="upload-icon" />
            <span>Upload Image 3</span>
          </label>
        )}
      </div>
)}
      <input type="file" id="image3File" accept=".jpg,.png,.svg" onChange={(event) => this.handleImage3Upload(event, 2)}  />
    </div>
  </Card>
</Col>

                        <Col className="custom-product-details generaldetailscrollbar" style={{ height: "100%" }} >
                            <Card className="custom-product-details-card   mt-3" style={{ width: "100%" }}>
                                <h1 className="heading-imp">Product General Details</h1>
                                {/* Error message */}
                                {errorMessage && (
                                    <div className="alert alert-danger" role="alert">
                                        {errorMessage}
                                    </div>
                                )}
                                <div className=''>
                                    <form className='form-manage' onSubmit={this.handleSubmit}>
   <div style={{ marginBottom: '1rem' }} className="tz-modal-section title">
            <p>Product</p>
            <div className="tz-modal-section-content">
              <p>Product Title</p>
              <input required name='title' type='text' placeholder="Product title" onChange={handleChange} disabled value={data.title} />
            </div>
          </div>

          <div className="tz-modal-section title">
            <p>Product Attributes</p>
            <div className="tz-modal-menu-selection">
              <div className="tz-modal-menu">
                <p className="tz-modal-menu-title">Cluster</p>
                <p onClick={() => this.handleItem(0)} className={this.state.selected === 0 ? "tz-modal-menu-selected" : ''}>Mandatory Specifications <img src={arrowRight} alt="arrow right" /></p>
                {data.additionalAttributes?.length > 0 && <p onClick={() => this.handleItem(1)} className={this.state.selected === 1 ? "tz-modal-menu-selected" : ''} > Feature <img src={arrowRight} alt="arrow right" /></p>}
              </div>
              <div className="tz-modal-menu-item-content">
                <p className="uniq">Attributes With Value</p>
                {
                  this.state.selected === 0 ?
                    <section>
                      <div>
                        <p>Title</p>
                        <input required onChange={handleChange} name='title' type='text' disabled value={data.title} />
                      </div>

                      <div>
                        <p>SKU</p>
                        <input name='sku' onChange={handleChange} type='text' disabled value={data.sku} />
                      </div>

                      <div>
                        <p>Brand</p>
                        <input name='brand' onChange={handleChange}  type='text' disabled value={data.brand} />
                      </div>

                      <div>
                        <p>Model Number</p>
                        <input name='modelNumber' onChange={handleChange}  type='text' disabled value={data.modelNumber} />
                      </div>

                      <div>
                        <p>Model Name</p>
                        <input name='modelName' onChange={handleChange} type='text' disabled value={data.modelName} />
                      </div>
                    </section>
                    :
                  <div>
                     <section>
                     {data.additionalAttributes?.length > 0 && data.additionalAttributes?.map((v, parentIndex) => {
  return (
    <div key={v.name}>
      <p>{v.name}</p>
      <input
        name={v.name}
        onChange={(e) => this.handleAttributeValueChange(e, v)} // Pass the attribute object as the second argument
        type='text'
        value={v.value}
      />
    </div>
  );
})}
        </section> </div>
                }

              </div>
            </div>
          </div>

          <div className="tz-modal-section title">
            <p>Product Description</p>
            <div>
              <p>
                <textarea className="tz-modal-padded-textbox tz-modal-no-border-textbox" disabled name='description' value={data.description} type='text'>{data.description}</textarea>
              </p>
            </div>
          </div>
                                    </form>
                                </div>
                            </Card>
                            <div className='tz-arrang-button mt-4'>
                                <Button variant='outlined' onClick={this.handleCancel}>
                                    Cancel
                                </Button>
                                <Button variant='primary' onClick={this.handleSubmit}>
                                   Update
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default withParams(EditSingleProduct);