import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import DashboardIndex from './DashboardIndex';

const produrl = 'https://showcaseapi.trezi.com/dashboards/';

const DailyUsers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set loading to true

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = produrl + 'api/v1/data/daily/users';
        const response = await axios.get(endpoint);
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        setError('Error making API call');
        console.error('Error making API call:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call fetchData function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  const exportToExcel = () => {
    const headers = ['Date', ...new Set(data.map(item => item.product).filter(product => product !== null))];
    const worksheetData = [
      headers,
      ...data.map(item => [
        item.date,
        ...headers.slice(1).map(product => {
          if (item.product === product) {
            return item.count;
          } else {
            const productData = data.find(d => d.date === item.date && d.product === product);
            return productData ? productData.count : 0;
          }
        })
      ])
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daily Users');
    XLSX.writeFile(workbook, 'Daily_Users.xlsx');
  };

  const renderTable = () => {
    if (loading) return <p>Loading...</p>; // Show loading indicator

    if (error) return <p>{error}</p>; // Show error message if API call fails

    if (data.length === 0) return null; // Render nothing if data is empty

    let dateRanges, dataByDateRange, products;

    dateRanges = [...new Set(data.map(item => item.date))];
    products = [...new Set(data.map(item => item.product))];
    dataByDateRange = dateRanges.map(date => {
      const dateData = data.filter(item => item.date === date);
      const countsByProduct = products.map(product => {
        const productData = dateData.find(item => item.product === product);
        return productData ? productData.count : 0;
      });
      return { dateRange: date, countsByProduct };
    });

    return (
      <div>
        <h2 id="heading">Daily Active Users</h2>
        <div id="button">
          <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
        </div>
        <div className='other_table_container'>
  <table className="result-table">
    <thead>
      <tr>
        <th>{'Daily'}</th>
        {products.map((product, index) => (
          <th key={index}>{product} Count</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {dataByDateRange
        .sort((a, b) => new Date(b.dateRange) - new Date(a.dateRange)) 
        .map((item, index) => (
          <tr key={index}>
            <td>{new Date(item.dateRange).toLocaleDateString()}</td> 
            {item.countsByProduct.map((count, idx) => (
              <td key={idx}>{count}</td>
            ))}
          </tr>
        ))}
    </tbody>
  </table>
</div>

      </div>
    );
  };

  return (
    <>
        <DashboardIndex/>
    <div className='home-container'>
      <div className='options-container'>
        {/* No button required as data is fetched automatically */}
      </div>
      <div className="data-container">
        {renderTable()}
      </div>
    </div>
    </>
  );
};

export default DailyUsers;
