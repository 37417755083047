import React from 'react';
import { Card , Table} from 'react-bootstrap';
import "../Header/AccordionContent.css";
import logolink from "../../assests/public/Small size error icon 1.1 (1).png"
import logolink2 from "../../assests/public/Error Icon with text.png"
import Icon1 from '../../assests/public/13-2-chair-free-download-png 2.png';
import Icon2 from '../../assests/public/8-2-chair-high-quality-png 1.png';
import Icon3 from '../../assests/public/4-2-chair-png 2.png';
import { getInventoryLog ,downloadProductforcsm , getClientCsmCategories, getProducts} from "../../services/ProductsService";
class AccordionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    data: [] ,
    };
  }
  componentDidMount() {
    document.title = 'Inventory Log';
  this.fetchInventoryLog();
  }
  async fetchInventoryLog() {
    debugger;
    try {
      const logs = await getInventoryLog();

      this.setState(
        {
          data: logs.data,
        },
      );
    } catch (err) {
      console.log(err);
    }
  }
  
  render() {
    const { item, isOpen, onClick } = this.props;

    if (item.orderDetails && item.orderDetails.length > 0) {
      // const { errorLogs, rowNumber } = item.bulkUploadErrors[0];

      return (
        <tr className='tz-inventory-table-body-row mt-2'>
          <td colSpan="9" onClick={onClick}>
            <Card>
              <Card.Header style={{backgroundColor :"rgba(245, 250, 255, 1)"}} >
                <Card.Title>
                  <div className='custom-table-header text-bold'> Buyer Details
                  </div>
            </Card.Title>
              </Card.Header>
              <Card.Body className="custom-card-body">
              <div className={`accordion-container ${isOpen ? 'open' : ''}`}>
              <div className="row">
  <div className="col-md-6">
    <div><strong>Name:</strong> <span>{item.address.name}</span></div>
    <div><strong>Email:</strong>  {item.address.email}</div>
    <div><strong>Phone:</strong>  {item.address.phone}</div>
  </div>
  <div className="col-md-6">
    <div>
      <strong> Delivery Address: </strong> 
      {item.address.address}, {item.address.city},{item.address.landmark},
      {item.address.state}, {item.address.landmark}
    </div>
    <div><strong> Address Type: </strong> {item.address.addressType}</div>
  </div>
</div>


  <Table className="custom-table mt-2"   >
    <thead style={{backgroundColor :"rgba(211, 220, 234, 0.75)"}}>
      <tr >
        <th className="custom-table-header" >Product Image</th>
        <th className="custom-table-header">Product Name</th>
        <th className="custom-table-header">Product ID</th>
        <th className="custom-table-header">Color</th>
        <th className="custom-table-header">Product Quantity</th>
        <th className="custom-table-header">Product Price(Rs.)</th>
        <th className="custom-table-header">Total Price(Rs.)</th>
      </tr>
    </thead>
    <tbody className='custom-tbody'>
    {item.orderDetails.map((orderitem, index) => (
        <tr  className="tz-error-table-body-row "  key={index} >
             <td className='custom-text'><img alt="productImage" src={orderitem.thumbnailUrl} width={50} height={50}/></td>
          <td  className='custom-text' >{orderitem.title}</td>
          <td  className='custom-text' >{orderitem.productId}</td>
          <td className='custom-text' style={{ marginRight: '5px' }}>
  <div style={{ display: 'flex', alignItems: 'center' }}>
    <div style={{ backgroundColor: orderitem.color, width: '20px', height: '20px', marginRight: '5px' }}></div>
    {orderitem.color}
  </div>
</td>
          <td  className='custom-text' >{orderitem.quantity}</td>
          <td className='custom-text'>
  {new Intl.NumberFormat('en-IN').format(orderitem.price)}
</td>
<td className='custom-text'>
  {new Intl.NumberFormat('en-IN').format(orderitem.quantity * orderitem.price)}
</td>
        </tr>
      ))}  
    </tbody>
  </Table>
</div>

              </Card.Body>
            </Card>
          </td>
        </tr>
      );
    } else {
      // Render a blank accordion if bulkUploadErrors is empty
      return (
        <tr className='tz-inventory-table-body-row mt-2'>
          <td colSpan="9" onClick={onClick}>
            <Card>
              <Card.Header>
                <Card.Title>Errors</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className={`accordion-container ${isOpen ? 'open' : ''}`}>
                  <div className='row'>
                    <div className="col-12 custom-text">
                      No Order Found.
                     </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      );
    }
  }
}

export default AccordionComponent;
