// TemplatePage.js
import React, { Component } from 'react';
import './TemplatePage.css'; // You can create a CSS file for styling
import {
  Col,
  Container,
  Row,
  Card,
  Tooltip,
  OverlayTrigger,
  Navbar,
  Stack,
  Button,
  Form,
  Modal,
  FormControl
} from "react-bootstrap";
import Logobluesofa from  "../../assests/public/BluesofaCover.jpg";
import Logobluesofapage3 from "../../assests/public/backpageBluesofa.jpg";
import backgroundImageselector from "../../assests/public/group.png"
import { useParams } from "react-router-dom";
import Loader from '../../context/loader';
import './BlueSofaTemplatePage.css'; 
import Swal from 'sweetalert2';
import html2pdf from 'html2pdf.js';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import dragIndicator from "../../assests/public/drag_indicator_360.png";
import { getAllPublishedCustomerCategories,getTemplateByTemplateIdentifier,getAllCustomerAttributes, getAllCustomerCategories, getProductbycustomerCategory, createFlipbook } from "../../services/ProductsService";
function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class Portraitblue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      previewLogoURL: null,
      coverPageBackgroundImage: null, // Separate state for cover page background
      backPageBackgroundImage: null,
      isEditingPremiumSofaText: false,
      isEditingUniqueDesign: false,
      flipbookName: '', // Initial text
      editedUniqueDesignText: '', // Initial text
      showLogoSelectBox: false,
      selectedLogoImage: null,
      isEditingField: '', // Initialize with an empty string or the default field you want to edit
      selectedBackgroundImage: backgroundImageselector,
      showNextContent: false,
      categories: [],
      selectedCategory: null,
      selectedProductIds: [],
      selectedCoverImage : null,
      selectedBackImage:null,
      showLoader: false,
      showUploadModal: false,
      selectedImage: null,
      showModal: false,
      selectedCategoryName :null,
      productSearchQuery: '',
      productSearchQuery: '',
      attributeSearchQuery :'',
      displayAttributes: [],
      error: '',
      selectedAttributes: [],
    };
    this.handleCardClick = this.handleCardClick.bind(this);
  }

  componentDidMount() {
    this.fetchCategories();
    this.getTemplateByIdentifier();
  }
  async getTemplateByIdentifier() {
    try {
      const template = await getTemplateByTemplateIdentifier('portrait_blue'); 
      const retrievedTemplateData = template.data;
      this.setState({
        coverPageBackgroundImage: retrievedTemplateData.templateFields.find(field => field.name === "frontCoverImage").value,
        backPageBackgroundImage: retrievedTemplateData.templateFields.find(field => field.name === "backCoverImage").value,
        // Set other necessary fields/images accordingly
      });
    } catch (error) {
      console.error('Error fetching template:', error);
      if(error.response.status === 401){
        Swal.fire({
          title: 'Unauthorized!',
          text: 'Jwt token Expired. Please Login again.',
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000 // 2 seconds
        }).then(() => {
          window.location.href = "/";
        });
      }
    }
  }
  handleProductSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    const { products } = this.state;
  
    const filteredProducts = products.filter((product) =>
      product.title.toLowerCase().includes(searchQuery)
    );
  
    this.setState({ productSearchQuery: searchQuery, filteredProducts });
  };
  
  async fetchCategories() {
    debugger;
    try {
      const categories = await getAllPublishedCustomerCategories(); // Fetch categories
      this.setState({ categories });
    } catch (error) {
      // Handle error
      console.error('Error fetching categories:', error);
    }
  }
  handleCategorySelect = async (selectedCategoryId, selectedCategoryName) => {
    debugger;
    try {
        const { selectedCategory } = this.state;
        if (selectedCategory === selectedCategoryId) {
            this.setState({
                selectedCategory: null,
                products: [],
                selectedProductIds: [],
                selectedCategoryName: ''
            });
        } else {
            const  attributes = await getAllCustomerAttributes(selectedCategoryId);
            const attributesValue = attributes.attributes;
            if (attributes) {
                const productsResponse = await getProductbycustomerCategory(selectedCategoryId);
                if(productsResponse.status=== 200){
                  this.setState({selectedCategoryName :selectedCategoryName, selectedAttributes :[] , selectedCategory: selectedCategoryId, products : productsResponse.data ,attributes:attributesValue, selectedProductIds: [], productSearchQuery: '',});
                  console.log(productsResponse.data) 
                }else{
                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No published product is listed under this category.'
                });
                this.setState({
                  selectedCategory: null,
                  products: [],
                  selectedCategoryName: ''
              });
                return;
                }        
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'No published product is listed under this category.'
                });
                this.setState({
                    selectedCategory: null,
                    products: [],
                    selectedCategoryName: ''
                });
            }
        }
    } catch (error) {
        console.error('Error fetching products for the selected category:', error);
        // Handle error gracefully, maybe show a generic error message to the user
    }
};

  handleCreate = async () => {
    const {
      selectedLogoImage,
    flipbookName,
      selectedCoverImage,
      selectedBackImage,
      selectedProductIds,
      coverPageBackgroundImage,
    backPageBackgroundImage,
    selectedCategory,
    selectedCategoryName,
    selectedAttributes
    } = this.state;
  debugger

  this.setState({ showLoader: true });
    const formdata = new FormData();
    formdata.append("name", flipbookName);
    formdata.append("templateIdentifier", "portrait_blue");
    formdata.append("attributes", JSON.stringify(selectedAttributes));
    formdata.append("productIds", JSON.stringify(selectedProductIds)); // Convert to string
    const coverImageName = selectedCoverImage ? selectedCoverImage.name : coverPageBackgroundImage || '';
  const backImageName = selectedBackImage ? selectedBackImage.name : backPageBackgroundImage || '';
  if (!selectedCoverImage || !selectedBackImage) {
    this.setState({ showLoader: false });
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Please select all required images.',
    });
    return; // Stop execution if any image is missing
  }
  if (!selectedProductIds || selectedProductIds.length === 0) {
    this.setState({ showLoader: false });
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Please select at least one product.',
    });
    return; // Stop execution if no products are selected
  }

  const templateFields = [
    {
      name: 'categoryName',
      value: selectedCategoryName.name , 
      page: 'Front',
      font: {
        family: 'Arial',
        size: 12,
      },
      type: 'String',
    },
    {
      name: 'frontCoverImage',
      value: coverImageName ,
      page: 'Front',
      font: {
        family: 'Times New Roman',
        size: 14,
      },
      type: 'Image',
    },
    {
      name: 'backCoverImage',
      value: backImageName, 
      page: 'Back',
      font: {
        family: 'Times New Roman',
        size: 14,
      },
      type: 'Image',
    },
    {
      name: 'name',
      value: flipbookName ,
      page: 'Front',
      font: {
        family: 'Times New Roman',
        size: 14,
      },
      type: 'String',
    },
  ];
  
    formdata.append("templateFields", JSON.stringify(templateFields)); // Convert to string
    const images = [ selectedCoverImage||coverPageBackgroundImage, selectedBackImage || backPageBackgroundImage];
    formdata.append("previewImage", selectedCoverImage || coverPageBackgroundImage);
    images.forEach((image, index) => {
      formdata.append(`imageFiles[${index}]`, image);
    });
    const imagesName = [ 'frontCoverImage', 'backCoverImage'];
    // formdata.append("fieldNamesPerImage", JSON.stringify(['logo', 'coverPageBackground', 'backPageBackground']));
    imagesName.forEach((name, index) => {
      formdata.append(`fieldNamesPerImage[${index}]`, name);
    });

    try {
      const response = await createFlipbook(formdata);
      this.setState({ showLoader: false });
      if (response.status === 201) {
        await Swal.fire({
          icon: 'success',
          title: 'Success!',
          text: 'FlipBook successfully created',
        });
        window.location.href = `/e-catalog`;
      }else if (response.status === 400){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to create FlipBook. Check the Template Fields.',
        });  
      }
      else if (response.status === 409){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'FlipBook already exists with name',
        });  
      }
    } catch (error) {
      this.setState({ showLoader: false });
      if (error.response.status === 409){
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'FlipBook already exists with name',
        });  
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'An error occurred while creating FlipBook.',
        });
      }
    }
  };

  handleFileChange = (event) => {
    const fileInput = event.target;
const file = fileInput.files[0];
debugger;
if (file) {
  const { size, type } = file;
  const [minWidth, minHeight] = [591, 887];
  const [maxWidth, maxHeight] = [1182, 1774];

  if (type.startsWith('image/')) {
    const reader = new FileReader();

    reader.onload = (event) => {
      const imageUrl = event.target.result;
      const tempImg = new Image();

      tempImg.onload = () => {
        const imageWidth = tempImg.width;
        const imageHeight = tempImg.height;

        if (
          imageWidth >= minWidth &&
          imageHeight >= minHeight &&
          imageWidth <= maxWidth &&
          imageHeight <= maxHeight
        ) {
          // Image meets size requirements
          this.setState({ selectedCoverImage: file });
          this.handleBackgroundImageChange(imageUrl, true);
        } else {
          // Invalid image size
          Swal.fire({
            icon: 'error',
            title: 'Invalid Image Size',
            text: 'Please select an image with dimensions between 591*887 and 1182*1774 pixels.',
          });
        }
      };

      tempImg.src = imageUrl;
    };

    reader.readAsDataURL(file);
  } else {
    // Display an alert for invalid file size or type using SweetAlert
    Swal.fire({
      icon: 'error',
      title: 'Invalid Image Size',
      text: 'Please select an image with dimensions between 591*887 and 1182*1774 pixels.',
    });
  }
  
  // Clear the input value to allow selecting the same file again
  fileInput.value = null;
}
  };
  
  
 
  handleBackFileChange = (event) => {
    const fileInput = event.target;
    const file = fileInput.files[0];
    debugger;
    if (file) {
      const { size, type } = file;
      const [minWidth, minHeight] = [591, 887];
      const [maxWidth, maxHeight] = [1182, 1774];
      if (type.startsWith('image/')) {
        const reader = new FileReader();
    
        reader.onload = (event) => {
          const imageUrl = event.target.result;
          const tempImg = new Image();
    
          tempImg.onload = () => {
            const imageWidth = tempImg.width;
            const imageHeight = tempImg.height;
    
            if (
              imageWidth >= minWidth &&
              imageHeight >= minHeight &&
              imageWidth <= maxWidth &&
              imageHeight <= maxHeight
            ) {
             // Image meets size requirements
             this.setState({ selectedBackImage: file });
             this.handleBackgroundImageChange(imageUrl, false);
            } else {
              // Invalid image size
              Swal.fire({
                icon: 'error',
                title: 'Invalid Image Size',
                text: 'Please select an image with dimensions between 591*887 and 1182*1774 pixels.',
              });
            }
          };
    
          tempImg.src = imageUrl;
        };
    
        reader.readAsDataURL(file);
      } else {
        // Display an alert for invalid file size or type using SweetAlert
        Swal.fire({
          icon: 'error',
          title: 'Invalid Image Size',
          text: 'Please select an image with dimensions between 591*887 and 1182*1774 pixels.',
        });
      }
      
      // Clear the input value to allow selecting the same file again
      fileInput.value = null;
    }
  };
  
  
  
  handleBackgroundImageChange = (newImageSrc) => {
    const { isEditingFrontPage } = this.state;
    if (isEditingFrontPage) {
      this.setState({ coverPageBackgroundImage: newImageSrc });
    } else {
      this.setState({ backPageBackgroundImage: newImageSrc });
    }
  };

  handleCardClick(imageSrc) {
    debugger;
    if (imageSrc === Logobluesofa) {
      this.setState({
        selectedImage: Logobluesofa,
        isEditingFrontPage: true,
        isEditingBackPage: false,
      });
    } else if (imageSrc === Logobluesofapage3) {
      this.setState({
        selectedImage: Logobluesofapage3,
        isEditingFrontPage: false,
        isEditingBackPage: true,
      });
    }
  }
  handleCancel = () => {
    window.location.href = '/e-catalog'; // Replace with your desired URL
  };
  handleCreateCancel = () => {
    window.location.href = `/templatePage/portrait_blue`;
  }
  handleModalOpen = () => {
    debugger;
    this.setState({ showModal: true });
  };

  handleModalClose = () => {
    this.setState({ showModal: false  , flipbookName :""});
  };
  handleFlipbookNameChange = (event) => {
    this.setState({ flipbookName: event.target.value });
  };


  handleFrontPageClick = () => {
    this.setState({
      selectedImage: Logobluesofa,
      isEditingFrontPage: true,
      isEditingBackPage: false,
    });
  };
  handleNextClick = () => {
    const { selectedCoverImage, selectedBackImage } = this.state;
  
    if (!selectedCoverImage || !selectedBackImage) {
      this.setState({ showLoader: false });
  
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Please select all required images.',
      });
  
      return; // Stop execution if any image is missing
    }
  
    this.setState({ showNextContent: true });
  };
  handleBackPageClick = () => {
    this.setState({
      selectedImage: Logobluesofapage3,
      isEditingFrontPage: false,
      isEditingBackPage: true,
    });
  };
  handleUniqueDesignInputChange = (e) => {
    this.setState({ editedUniqueDesignText: e.target.value });
  };
  handleInputBlur = () => {
    this.setState({ isEditingPremiumSofaText: false, isEditingUniqueDesign: false, isEditingField: '' });
  };
  handleLogoClick = () => {
    debugger;
    this.setState({ showLogoSelectBox: true });
  };

  handleLogoImageSelect = (selectedImageFile) => {
    debugger;
    const imgSrc = URL.createObjectURL(selectedImageFile);
  this.setState({
      selectedLogoImage: selectedImageFile,
      showLogoSelectBox: false,
      previewLogoURL: imgSrc,
    });
  };
  
  
  handleFieldEdit = (fieldName) => {
    this.setState({ isEditingField: fieldName });
  };

  handleAttributeSelect = (attribute) => {
    debugger;
    const { selectedAttributes } = this.state;
    const isSelected = selectedAttributes.includes(attribute);
    if (isSelected) {
      const updatedAttributes = selectedAttributes.filter((selectedAttr) => selectedAttr !== attribute);
      this.setState({ selectedAttributes: updatedAttributes });
    } else {
      if (selectedAttributes.length < 5) {
        this.setState((prevState) => ({
          selectedAttributes: [...prevState.selectedAttributes, attribute],
          error: ''
        }));
      } else {
        console.log("You can select up to 5 attributes.");
        this.setState({ error: 'You can select up to 5 attributes.' });
      }
    }
  };
  handleAttributeSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    const { attributes } = this.state;
  
    const filteredAttributes = attributes.filter((attribute) =>
      attributes.title.toLowerCase().includes(searchQuery)
    );
  
    this.setState({ attributeSearchQuery: searchQuery, filteredAttributes });
  };
  handleProductSelect = (productId) => {
    this.setState((prevState) => {
      const { selectedProductIds } = prevState;
      const updatedProductIds = selectedProductIds.includes(productId)
        ? selectedProductIds.filter((id) => id !== productId)
        : [...selectedProductIds, productId];

      return { selectedProductIds: updatedProductIds };
    });
  };
  handleCategorySearch = (event) => {
    const searchQuery = event.target.value;
    const { categories } = this.state;
    const filteredCategories = categories.filter(category =>
      category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    this.setState({ searchQuery, filteredCategories });
  };
  handleClear = () => { 
    this.setState({
      products: [],
      selectedProductIds: [],
      selectedAttributes: [] // Reset selected attributes to blank
    });
  };
  onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
  debugger;
    const { attributes, selectedAttributes } = this.state;
    const reorderedAttributes = Array.from(attributes);
    const [reorderedItem] = reorderedAttributes.splice(result.source.index, 1);
    reorderedAttributes.splice(result.destination.index, 0, reorderedItem);
  
    const reorderedSelectedAttributes = reorderedAttributes.filter(attr =>
      selectedAttributes.includes(attr)
    );
  
    this.setState({
      attributes: reorderedAttributes,
      selectedAttributes: reorderedSelectedAttributes
    });
  };
  render() {
    const { selectedImage, isEditingPremiumSofaText,
      isEditingFrontPage,
      isEditingBackPage,
      showModal, flipbookName,
      coverPageBackgroundImage,
      backPageBackgroundImage,
      showNextContent,
      categories, selectedCategory,
      selectedProductIds,
      showLoader,
      filteredProducts,
      products ,
      filteredAttributes,
      productSearchQuery,
      showEcatalogPage,
      attributes,
      attributeSearchQuery, error
    } = this.state;
    const { searchQuery, filteredCategories } = this.state;
    const displayCategories = searchQuery ? filteredCategories : categories;
    const displayAttributes = attributeSearchQuery ? filteredAttributes : attributes;
    const displayProducts = productSearchQuery ? filteredProducts : products;

    return (
      <div className="preview-tz-category-catalog-container">
        <Stack
          direction="horizontal"
          gap={3}
          className="blue-list-dashboard-header"
        >
          <div className="custom-font-20 product-catalog">
            Create E-Catalog
          </div>
        </Stack>
        <Container fluid >
        {showLoader && (
         <Loader/>
        )}
          <div>
            {!showNextContent ? (
              <Container fluid className ="blue-tz-product-details-container">
 <>
                <Row className="tz-product-details-row lg-3">
                  <>
                    <Col  sm={12} md={6} lg={4} xl={3}
                      style={{ paddingLeft: "0", paddingRight: "2px" }}
                      className="small-image-container"
                    >
                      <Card
                        style={{
                          minWidth: "150px",
                          minHeight: "150px",
                          maxWidth: "200px",
                          marginBottom: "20px",
                          cursor: "pointer",
                          borderRadius: "0px"
                        }}
                        onClick={() => this.handleCardClick(Logobluesofa)}
                        onMouseEnter={(e) => e.target.classList.add('hover-effect')}
                        onMouseLeave={(e) => e.target.classList.remove('hover-effect')}
                      >
                        <Card.Body>
                          <img
                            src={coverPageBackgroundImage}
                            alt="Cover Page"
                            className="responsive-img"
                          />
                          <div className="heading-preview-tz-showcase-frame ">
                            Cover Page
                          </div>
                        </Card.Body>
                      </Card>
                      <Card
                        style={{
                          minWidth: "150px",
                          minHeight: "150px",
                          maxWidth: "200px",
                          marginBottom: "20px",
                          cursor: "pointer",
                          borderRadius: "0px"
                        }}
                        onClick={() => this.handleCardClick(Logobluesofapage3)}
                        onMouseEnter={(e) => e.target.classList.add('hover-effect')}
                        onMouseLeave={(e) => e.target.classList.remove('hover-effect')}
                      >
                        <Card.Body>
                          <img
                            src={backPageBackgroundImage}
                            alt="Back Page"
                            className="responsive-img"
                          />
                          <div className="heading-preview-tz-showcase-frame ">
                            Back Page
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                    <Col className="blue-tz-product-details-frame">
                      <Card
                        className="blue-tz-product-view-card"
                        style={{
                          border: "1px solid #C7CFDE",
                          backgroundImage: (typeof isEditingFrontPage === 'undefined')
                          ? `url(${coverPageBackgroundImage})`
                          : (isEditingFrontPage ? `url(${coverPageBackgroundImage})` : `url(${backPageBackgroundImage})`),
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          // backgroundRepeat: 'no-repeat',
                        }}
                      >
                      </Card>
                    </Col>
                    {isEditingFrontPage && (
                             <Row
                             style={{ paddingLeft: "10px", paddingRight: "0px" }}
                             className="preview-small-image-container"
                           >
                                  <div className='file-input-box'>
    <label htmlFor="fileInput" className="custom-file-upload">
      Choose an image
    </label>
    <input
      type="file"
      id="fileInput"
      accept="image/*"
      ref={(input) => (this.fileInput = input)}
      onChange={this.handleFileChange}
      style={{ display: 'none' }}
    />
  </div>
                           </Row>
                          )}
                            {isEditingBackPage && (
                           <Row
                           style={{ paddingLeft: "10px", paddingRight: "0px" }}
                           className="preview-small-image-container"
                         >
                                      <div className='file-input-box'>
    <label htmlFor="fileInput" className="custom-file-upload">
      Choose an image
    </label>
    <input
      type="file"
      id="fileInput"
      accept="image/*"
      ref={(input) => (this.fileInput = input)}
      onChange={this.handleBackFileChange}
      style={{ display: 'none' }}
    />
  </div>
                         </Row>
                          )}
                  </>
                </Row>
                <Row className="justify-content-end mt-4">
                  <Col className="text-right">
                  <Button variant="primary" onClick={this.handleCancel} className="mr-2 flipbook-button">
      Cancel
    </Button>
                    <Button variant="primary" onClick={this.handleNextClick} >Next</Button>
                  </Col>
                </Row>
              </>
              </Container>
            ) 
            : (
              <div>
                <Container fluid  className="blue-tz-category-details-container">
                  <Row className='container-height'>
                    <Col md={3} className="temp-left-container border-right">
                    <div className="box-content">
                      <div className="">
                        <span className='category-name' >Select Category</span>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <Form className="position-relative tz-template-search-container" onSubmit={(e) => {
       e.preventDefault(); 
}}>
                          <Form.Control
                            type="search"
                            placeholder="Search Category"
                            className="tz-right-template-search-input"
                            aria-label="Search"
                            onChange={this.handleCategorySearch}
                            value={this.state.searchQuery}
                          />
                          <i className="bi bi-search tz-search-icon"></i>
                        </Form>
                      </div>
                      <div  className=''>
                      <div className="category-container scrollable-container">
                          { displayCategories && displayCategories.map(category => (
                            <div className='select-category-box' key={category.name}>
                            <div className='category-item'>
                            <input
                                type='checkbox'
                                id={`category_${category.id}`}
                                value={category.label}
                                checked={this.state.selectedCategory === category.label}
                                onChange={() => this.handleCategorySelect(category.label , category)}
                                style={{marginTop :"8px"}}
                              />
                              <label className='spacing'  htmlFor={`category_${category.name}`}>{category.name}</label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                      </div>
                     
                      <div className="box-content">
<div className="">
<div className="d-flex justify-content-between">
    <span className='category-name'>Select Attribute</span>
    <button className='' style={{ border: "none", color: "rgba(0, 112, 220, 1)", background: "none", cursor: "pointer" }} onClick={this.handleClear}>Clear all</button>
  </div>
                      </div>
                      <div className="d-flex align-items-center mt-3">
                        <Form className="position-relative tz-template-search-container" onSubmit={(e) => {
       e.preventDefault(); 
}}>
                          <Form.Control
                            type="search"
                            placeholder="Search Attribute"
                            className="tz-right-template-search-input"
                            aria-label="Search"
                            onChange={this.handleAttributeSearch}
                            value={this.state.searchQuery}
                          />
                          <i className="bi bi-search tz-search-icon"></i>
                        </Form>
                      </div>
                      <div className="mt-1">
                      {error && <p style={{ color: 'red' , fontSize :"9px" }}>{error}</p>}
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="attributes">
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef} className="categories-list scrollable-container">
                { displayAttributes && displayAttributes.map((attribute , index) => (
                  <Draggable key={attribute} draggableId={attribute} index={index}>
                    {(provided) => (
                      <div
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        // className='select-category-box'
                      >
                       <div className='select-category-box'>
  <div className='row'>
    <div className='col-10'>
    <input
      type='checkbox'
      id={`attribute_${attribute}`}
      value={attribute}
      checked={this.state.selectedAttributes.includes(attribute)}
      onChange={() => this.handleAttributeSelect(attribute)}
      style={{ marginTop: '8px' }}
    />
     <label className='spacing' htmlFor={`attribute_${attribute}`}>
      {attribute && attribute.charAt(0).toUpperCase() + attribute.slice(1)}
    </label>
    </div>
      <div className='col-1'>
      <span >
    <img width={20} alt='drag' style={{marginTop :'6px'}} src={dragIndicator} />
  </span>
      </div>
  </div>
</div>

                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
</div>
                    </Col>
                    <Col md={9} className="temp-right-container border-left position-relative">
                      <div className="box-content">
                        <span className='prod-name'>Select Product</span>
                      </div>
                      <div className="search-box-container-one">
                        <Form className="top-0 start-0 bottom-0 end-0 d-flex align-items-center justify-content-end">
                          <Form.Control
                            type="search"
                            placeholder="Search Product"
                            className="tz-template-search-input"
                            aria-label="Search"
                            value={productSearchQuery}
                            onChange={this.handleProductSearch }
                          />
                           <i className="bi bi-search tz-tempelate-search-icon"></i>
                        </Form>
                        <div className="products-container">
                          {displayProducts && displayProducts.map((product) => (
                            <div key={product.productId} className="product-item">
                              <input
                                type="checkbox"
                                id={`productCheckbox_${product.productId}`}
                                checked={selectedProductIds.includes(product.productId)}
                                onChange={() => this.handleProductSelect(product.productId)}
                              />
                              <img
                                src={product.thumbnail.fileUrl}
                                alt={product.title}
                                className="thumbnail-round"
                                width={30}
                                height={15}
                              />
                              <div className='text-container'>
                                <div className="product-info custom-font-12">
                                  <div className="product-title">{product.title}</div>
                                  <div className="product-serial">{product.productId}</div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
        {showModal && (
          <Modal show={showModal} onHide={this.handleModalClose} centered >
          <Modal.Header closeButton>
            <Modal.Title>Catalog Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <Form>
          <Form.Group controlId="catalogTitle">
          <Form.Label>Catalog Title</Form.Label>
          <FormControl
            type="text"
            value={flipbookName}
            onChange={this.handleFlipbookNameChange}
            placeholder="Enter flipbook name"
          />
        </Form.Group>
          </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={this.handleCreate}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
        )}

                <Row className="justify-content-end mt-4">
                  <Col className="text-right">
                    <Button variant="primary" onClick={this.handleCreateCancel} className="mr-2 flipbook-button"> Cancel</Button>
                    <Button variant="primary" onClick={this.handleModalOpen} >Create</Button>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Container>
      </div>
    );
  }
}
export default withParams(Portraitblue);
