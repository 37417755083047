import React, { Component ,useState , useEffect} from 'react';
import './sales.css';
import Logo from "../assests/public/banner.png";
import Logobluesofa from "../assests/public/cover_page-01_720.png";
import Logoyellowsofa from "../assests/public/cover_page-02_720.png";
import { Button , Modal ,Form , ButtonGroup , Dropdown , Col, Card} from 'react-bootstrap';
import { getAllflipbookTemplates, updateflipbookStatus, getProductsByIdForflipbook,getFlipbookByFlipbookIdentifier,downloadProductqrcode, createFlipbook ,deleteCustomerTemplate ,updateFlipbookShareCount,downloadTemplateqrcode , getProductById} from "../services/ProductsService";
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import HTMLFlipBook from 'react-pageflip';
import Loader from '../context/loader';
import LoadingImage from  "../assests/public/Loading-03-03.png";
import TreziLogo from  "../assests/public/Group 10 (2).png";
const SalesCatalog = () => {
  const [showModal, setShowModal] = useState(false);
  const [shareLink, setShareLink] = useState('');
  const [imagesFromBackend, setImagesFromBackend] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [flipbooksPerPage] = useState(6);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [selectedFlipbook, setSelectedFlipbook] = useState(null);
  const [flipbookData, setFlipbookData] = useState(null);
  const [flipbookFieldsData, setFlipbookFieldsData] = useState([]);
  const [productData, setProductData] = useState([]);
  const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
  const [isSinglePageMode, setIsSinglePageMode] = useState(true);
  const getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const updatedFlipbooks = await getAllflipbookTemplates();
        console.log(updatedFlipbooks.data);
        if (Array.isArray(updatedFlipbooks.data)) {
          setImagesFromBackend(updatedFlipbooks.data);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.error('Error fetching updated flipbook data:', error);
      }
    };
    fetchData();
    document.title = 'E-Catalog';
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsSinglePageMode(window.innerWidth <= 1760); // Adjust the screen width threshold as needed
    };

    // Listen to window resize events
    window.addEventListener('resize', handleResize);

    // Initial check for page mode based on screen size
    handleResize();

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleViewButtonClick = async (flipbook) => {
    const organizationIdentifier = getCookie('orgIdentifier');
    try {
      setLoading(true);
      setSelectedFlipbook(flipbook);
      const response = await getFlipbookByFlipbookIdentifier(flipbook.flipBookIdentifier);
      setFlipbookData(response.data);
      console.log(response.data);
      if (response.data && response.data.flipBookFields) {
        setFlipbookFieldsData(response.data.flipBookFields);
        const productIds = response.data.productIds || [];
        const productDataArray = await Promise.all(
          productIds.map(async (productId) => {
            try {
              const productResponse = await getProductsByIdForflipbook(productId);
              return productResponse.data;
            } catch (error) {
              console.error('Error fetching product data:', error);
              Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: `Error fetching product data for productId: ${productId}`,
              });
              return null;
            }
          })
        );
        const filteredProductData = productDataArray.filter((data) => data !== null && data !== undefined);
        const updatedProductData = await Promise.all(filteredProductData.map(async (product) => {
          const url = window.location.origin;
          const linkInput = product?.shareLink;
          const linkWithoutPrefix = linkInput.replace(/.*\/v1\/products\//, '');
          const newLink = `${url}/products/${organizationIdentifier}/${linkWithoutPrefix}`;
          const qrcodeResponse = await downloadProductqrcode(newLink);
          if (qrcodeResponse && qrcodeResponse.data && qrcodeResponse.data.qrCodeBytes) {
            return {
              ...product,
              qrcodeResponse: qrcodeResponse.data.qrCodeBytes
            };
          } else {
            return product;
          }
        }));
        setProductData(updatedProductData);
      }
      setLoading(false);
      setShowPreviewModal(true);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching flipbook data:', error);
    }
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage);
  };
  const closeModal = () => {
    setShowModal(false);
  };
  const handleShareButtonClick = (template) => {
    const url = window.location.origin;
    const flipbookIdentifier = template.flipBookIdentifier;
    const templateIdentifier = template.templateIdentifier;
    const newLink = `${url}/${templateIdentifier}/${flipbookIdentifier}`;
    setShareLink(newLink);
    setShowModal(true);
  };

  const copyLink = async (flipBookIdentifier) => {
    // const { template } = this.state;
    try {
      await updateFlipbookShareCount(flipBookIdentifier);
      const linkInput = document.getElementById("link-input");
      linkInput?.select();
      document.execCommand("copy");
      Swal.fire({
        title: 'Success!',
        text: 'Link copied to clipboard!',
        icon: 'success',
        timer: 2000,
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to copy the link.',
        icon: 'error',
      });
    }
  };
  const downloadQRCode = async (flipBookIdentifier) => {
    try {
      const templateresponse = await getFlipbookByFlipbookIdentifier(flipBookIdentifier);
      const url = window.location.origin;
      const linkInput = templateresponse.data;
      const Templateidentifier = linkInput.templateIdentifier;
      const newLink = `${url}/${Templateidentifier}/${flipBookIdentifier}`;
      const response = await downloadTemplateqrcode(newLink);

      if (response) {
        let fileName = 'qrcode.png'; // Default filename
        const blobUrl = "data:image/png;base64," + response.data.qrCodeBytes;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();

        // Clean up by revoking the object URL and removing the anchor element
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);

        Swal.fire({
          title: 'Success!',
          text: 'QR downloaded successfully.',
          icon: 'success',
          timer: 2000, // Optional: Automatically close after 2 seconds
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to download the QR.',
          icon: 'error',
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to download the QR.',
        icon: 'error',
      });
    }
  };
  const handleDropdownToggle = (index) => {
    setOpenDropdownIndex(prevState => prevState === index ? null : index);
  };


  const renderContent = () => {
    if (!Array.isArray(imagesFromBackend)) {
      return <div>No images available</div>;
    } else {
      const indexOfLastFlipbook = currentPage * flipbooksPerPage;
      const indexOfFirstFlipbook = indexOfLastFlipbook - flipbooksPerPage;
      const currentFlipbooks = imagesFromBackend.slice(indexOfFirstFlipbook, indexOfLastFlipbook);
      return (
        <div className="your-ecatalog-container">
          <div className="image-grid">
            {currentFlipbooks.map((template, index) => (
              <div className="template-container" key={index}>
                <img
                  src={template.previewImageUrl}
                  alt={`Template ${index}`}
                  className={`template-${template.templateIdentifier === 'furniture2' || template.templateIdentifier === 'landscape_blue' ? 'image2' : 'image1'}`}
                />
                <div className={`actions-icons mt-2 ${template.templateIdentifier === 'furniture1' || template.templateIdentifier === 'portrait_blue' ? 'flipbook-text2' : 'flipbook-text1'}`}>
                  {template.name}
                </div>
                <div className="icon">
                  <div className="actions-icons mt-1">
                  </div>
                  <div className="image-actions">
                    <div className='mt-4 buttons-on-hover'>
                      <Button className="tzz-delete-button" onClick={() => handleViewButtonClick(template)}>
                        <i className="bi bi-eye-fill custom-font-16"></i> View
                      </Button>
                      <Button className="tzz-share-button mt-3" onClick={() => handleShareButtonClick(template)}>
                        <i className="bi bi-share-fill custom-font-16"></i> Share
                      </Button>
                    </div>
                  </div>
                </div>
                {showModal && (
                      <Modal show={showModal} onHide={closeModal} centered className="fade modal show" key={template.templateIdentifier}>
                        <Modal.Header closeButton>
                          <Modal.Title style={{ fontSize: '14px', color: '#000000' }}>Share Link</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                          <ButtonGroup>
                            <Form.Control id="link-input" type="text" value={shareLink} readOnly className="mr-2" />
                            <div className="d-flex align-items-center">
                              <Button onClick={() => copyLink(template.flipBookIdentifier)}>
                                <i className="bi bi-files"></i> Copy
                              </Button>
                              <Button onClick={(e) => downloadQRCode(template.flipBookIdentifier)}>
                                <i className="bi bi-download"></i> Download QR
                              </Button>
                            </div>
                          </ButtonGroup>
                        </Modal.Body>
                      </Modal>
                    )}
              </div>
            ))}
          </div>
        
          {imagesFromBackend.length > flipbooksPerPage && (
            <div className="pagination">
              <ReactPaginate
                pageCount={Math.ceil(imagesFromBackend.length / flipbooksPerPage)}
                pageRangeDisplayed={5}
                marginPagesDisplayed={1}
                onPageChange={(selectedPage) => handlePageChange(selectedPage.selected + 1)}
                containerClassName="pagination"
                activeClassName="pagination-item-active"
              />
            </div>
          )}
        </div>
      );
    }
  };

  const groupedProducts = [];
  for (let i = 0; i < productData.length; i += 2) {
      groupedProducts.push(productData.slice(i, i + 2));
  }

  return (
    <div className="catalog-container">
      <div className="banner">
        <img src={Logo} alt="Banner Logo" className="banner-image" />
        <h2 className="banner-heading">E-Catalog by Trezi</h2>
      </div>
      <div className="options-container mt-3">
        <div className={`sales-option ${selectedOption === 'your' ? 'selected' : ''}`}>
          <div className='custom-font-16'>Your E-Catalog</div>
          {loading && <Loader />}
        </div>
      </div>
      <div className="responsive-container">
        <div> {renderContent()}</div>
      </div>
      <Modal show={showPreviewModal} centered   
      dialogClassName={ flipbookData && flipbookData.templateIdentifier === 'furniture1' || flipbookData && flipbookData.templateIdentifier === 'portrait_blue' ? "custom-modal-dialog" : "tz-landscape-custom-modal-dialog" }
       onHide={() => setShowPreviewModal(false)}>
        <Modal.Header closeButton style={{ border: "none" }}> 
          <Modal.Title>{flipbookFieldsData[3]?.value}</Modal.Title>
        </Modal.Header>
        <Modal.Body closeButton>
        { flipbookData && flipbookData.templateIdentifier === 'furniture1' ? (
  <>
    {productData && productData.length === 0 ? (
      <div className="Loading-image-container">
        <img src={LoadingImage} alt="Loading" className="animated-logo" />
      </div>
    ) : (
      <HTMLFlipBook
      width={600}
      height={850}
      minWidth={650}
      maxWidth={700}
      minHeight={600}
      maxHeight={900}
      drawShadow={true}
      flippingTime={600}
      useMouseEvents={true}
      pageMode="double"
      mobileScrollSupport={true}
      style={{ marginTop: '0px' , marginLeft :"35px" }}
    >
      <div className="demoPage hard">
</div>
<div className="demoPage" >      
<div className='full-size-container' 
style={{  backgroundImage: `url("${flipbookFieldsData[1]?.value}")`, // Use the imported image as background
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',}}>
{/* <div className="logo-container">
                <img src={flipbookData.brandLogoUrl} alt="Logo" />
            </div> */}
</div>
</div>

{ productData && productData.map((product, index) => (
<div className="demoPage" key={index}>
  <div className="row-container">
    <div className="sec-category-name">
      {flipbookFieldsData[0]?.value}
    </div>
    <div className="logo-container">
      <img className='flipbook-Logo' src={flipbookData.brandLogoUrl} alt="Logo" />
    </div>
  </div>
        <div className="">
            <div className='product-info'>
                <Col className="tz-product-details-frame">
                    {/* Second Column */}
                    <Card
                        className="tz-product-view-card mt-4"
                        style={{
                            border: "1px solid #C7CFDE",
                            backgroundColor: "#FFFFFF",
                        }}
                    >
                        <div className="tz-showcase-frame">
                            <img src={product.thumbnailUrl} className='thumb-image' alt="Product Thumbnail" />
                        </div>
                    </Card>
                </Col>
                <div className='upper-spacing '>
                    <span className="title-data">{product.title}</span>
                </div>
            </div>
          
            <div className='row responsive-cont mt-4'>
                <div className='col-md-6 ' >
                    <div className='flipbook-cont1'>
                    <div >
                            <span className="label">Product Tzn:</span>
                            <span className="data">{product.productTzn}</span>
                        </div>
                        <div >
                            <span className="label">Brand:</span>
                            <span className="data">{product.brand}</span>
                        </div>
                        <div>
                            <span className="label">Model Number:</span>
                            <span className="data">{product.modelNumber}</span>
                        </div>
                        <div>
                            <span className="label">Model Name:</span>
                            <span className="data">{product.modelName}</span>
                        </div>
                        <div>
                            <span className="label">SKU:</span>
                            <span className="data">{product.sku}</span>
                        </div>
                    </div>
                </div>
                <div className='col-md-6 flipbook-cont2 ' >
                    <div className="image-text-container">
                        <img className="qr-code-image" src={`data:image/png;base64,${product.qrcodeResponse}`} alt="Base64 Image" />
                        <span className="scan-text">
                            Scan this for more details
                        </span>
                    </div>
                </div>
            </div>
        </div>
    
        <div className="footer mb-2">
                                Powered by Trezi
                              <div><img class="sales-trezi-logo" src={TreziLogo} alt="Trezi Logo" ></img>
                                </div>  
                            </div>
</div>
))}

<div className="demoPage" >      
<div className='full-size-container' 
style={{  backgroundImage: `url("${flipbookFieldsData[2]?.value}")`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',}}>
{/* <div className="logo-container">
                <img src={flipbookData.brandLogoUrl} alt="Logo" />
            </div> */}
</div>
</div>
    </HTMLFlipBook>
    )}
  </>
) :  flipbookData && flipbookData.templateIdentifier === 'portrait_blue' ? (
  <>
  {productData && productData.length === 0 ? (
    <div className="Loading-image-container">
      <img src={LoadingImage} alt="Loading" className="animated-logo" />
    </div>
  ) : (
    <HTMLFlipBook
    width={600}
    height={850}
    minWidth={650}
    maxWidth={700}
    minHeight={600}
    maxHeight={900}
    drawShadow={true}
    flippingTime={600}
    useMouseEvents={true}
    pageMode="double"
    mobileScrollSupport={true}
    style={{ marginTop: '0px' , marginLeft :"35px" }}
  >
      <div className="demoPage" >  
      </div>
    <div className="demoPage" >      
      <div className='full-size-container' 
     style={{  backgroundImage: `url("${flipbookFieldsData[1]?.value}")`, // Use the imported image as background
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',}}>
  {/* <div className="logo-container">
                      <img src={flipbookData.brandLogoUrl} alt="Logo" />
                  </div> */}
      </div>
  </div>
  {groupedProducts && groupedProducts.map((productPair, index) => (
                                    <div className="demoPage" key={index} style={{ width: "75%" }}>
                                        <div className="row-container">
                                            <div className="sec-category-name">
                                                {flipbookFieldsData[0]?.value}
                                            </div>
                                            <div className="logo-container">
                                                <img className=''  style={{ width: "60%" }} src={flipbookData.brandLogoUrl} alt="Logo" />
                                            </div>
                                        </div>
                                        <div className="row mt-3">
    {productPair && productPair.map((product, idx) => (
        <div key={idx} className={`sales-product-info ${productPair.length === 1 ? 'single-product' : ''}`}>
            <div className='col-md-6'>
                <Card
                    className="mt-3"
                    style={{
                        border: "1px solid #C7CFDE",
                        backgroundColor: "#FFFFFF",
                    }}
                >
                    <div className="card-thumbnail-container">
                        <img src={product.thumbnailUrl} className='card-thumbnail'  alt="Product Thumbnail" />
                    </div>
                </Card>
            </div>

            <div className='col-md-6'>
                <div className=''>
                    <div className='mt-2'>
                        <span className="title-data">{product.title}</span>
                    </div>
                    <div className='flipbook-cont1 mt-3'>
                        <div>
                            <span className="label">Brand:</span>
                            <span className="data">{product.brand}</span>
                        </div>
                        <div>
                            <span className="label">Model Number:</span>
                            <span className="data">{product.modelNumber}</span>
                        </div>
                        <div>
                            <span className="label">Model Name:</span>
                            <span className="data">{product.modelName}</span>
                        </div>
                        <div>
                            <span className="label">SKU:</span>
                            <span className="data">{product.sku}</span>
                        </div>
                        <div className="image-text-container">
                        <img className="qr-code-image" src={`data:image/png;base64,${product.qrcodeResponse}`} alt="Base64 Image" />
                        <span className="scan-text">
                            Scan this for more details
                        </span>
                    </div>
                    </div>
                </div>
               
            </div>
        </div>
    ))}
</div>

                                        
                                        <div className="footer mb-2">
                                            Powered by Trezi
                                          <div><img class="sales-trezi-logo" src={TreziLogo} alt="Trezi Logo" ></img>
                                            </div>  
                                        </div>
                                    </div>
                                ))}
  
  
  <div className="demoPage" >      
      <div className='full-size-container' 
     style={{  backgroundImage: `url("${flipbookFieldsData[2]?.value}")`, // Use the imported image as background
     backgroundSize: 'cover',
     backgroundRepeat: 'no-repeat',}}>
      </div>
  </div>
  </HTMLFlipBook>
  )}
</>
): flipbookData && flipbookData.templateIdentifier === 'landscape_blue' ? (
  <>
    {productData && productData.length === 0 ? (
      <div className="Loading-image-container">
        <img src={LoadingImage} alt="Loading" className="animated-logo" />
      </div>
    ) : (

     <HTMLFlipBook
     width={110}
  height={80}
  size="stretch"
  minWidth={700}
  maxWidth={800}
  minHeight={500}
  maxHeight={700}
  drawShadow={true}
  flippingTime={600}
     useMouseEvents={true}
     pageMode="double"
     style={{ marginTop: '0px' }}
   >
     {/* Hard page at the start */}
     <div className="demoPage hard">
     </div>
     <div className="demoPage" >      
       <div className='full-size-container' 
      style={{  backgroundImage: `url("${flipbookFieldsData[1]?.value}")`, // Use the imported image as background
         backgroundSize: 'cover',
         backgroundRepeat: 'no-repeat',}}>
   {/* <div className="logo-container">
                       <img src={flipbookData.brandLogoUrl} alt="Logo" />
                   </div> */}
       </div>
   </div>
   
   {groupedProducts && groupedProducts.map((productPair, index) => (
                                    <div className="demoPage" key={index} style={{ width: "75%" }}>
                                        <div className="row-container">
                                            <div className="sec-category-name">
                                                {flipbookFieldsData[0]?.value}
                                            </div>
                                            <div className="logo-container">
                                                <img className=''  style={{ width: "60%" }} src={flipbookData.brandLogoUrl} alt="Logo" />
                                            </div>
                                        </div>
                                        <div className="row">
    {productPair && productPair.map((product, idx) => (
        <div key={idx} className={`sales-product-info ${productPair.length === 1 ? 'single-product' : ''}`}>
              <div className='row'>
    <div className='col-md-4'>
        <Card className="flipbook2-tz-product-view-card" style={{ border: "1px solid #C7CFDE", backgroundColor: "#FFFFFF" }}>
            <div className="flipbook-tz-showcase-frame ">
                <img src={product.thumbnailUrl} className='flipbook2-thumb-image' alt="Product Thumbnail" />
            </div>
        </Card>
    </div>
    <div className='col-md-4'>
        <div className='flipbook-cont1'>
        <div className='flipbook-upper-spacing'>
                            <span className="title-data">{product.title}</span>
                        </div>
                        <div className="custom-font-10">{product.productTzn}</div>
            <div>
                <span className="label mt-1">Brand:</span>
                <span className="data custom-font-12">{product.brand}</span>
            </div>
            <div>
                <span className="label">Model Number:</span>
                <span className="data">{product.modelNumber}</span>
            </div>
            <div>
                <span className="label">Model Name:</span>
                <span className="data ">{product.modelName}</span>
            </div>
            <div>
                <span className="label">SKU:</span>
                <span className="data">{product.modelName}</span>
            </div>
        </div>
      
    </div>
    <div className='col-md-4'>
        <div className="image-text-container">
            <img className="qr-code-image" src={`data:image/png;base64,${product.qrcodeResponse}`} alt="Base64 Image" />       
        </div>
        <div className="scan-text centered-content ">
                Scan this for more details
            </div>
    </div>
</div>

        </div>
    ))}
</div>

                                        
                                        <div className="footer mb-2">
                                            Powered by Trezi
                                          <div><img class="sales-trezi-logo" src={TreziLogo} alt="Trezi Logo" ></img>
                                            </div>  
                                        </div>
                                    </div>
                                ))}
   <div className="demoPage" >      
       <div className='full-size-container' 
      style={{  backgroundImage: `url("${flipbookFieldsData[2]?.value}")`, // Use the imported image as background
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',}}>
       </div>
   </div>
   </HTMLFlipBook>
    )}
  </>
): (
  <>
    {productData && productData.length === 0 ? (
      <div className="Loading-image-container">
        <img src={LoadingImage} alt="Loading" className="animated-logo" />
      </div>
    ) : (
      <HTMLFlipBook
      width={150}
      height={120}
      size="stretch"
      minWidth={150}
      maxWidth={145}
      minHeight={500}
      maxHeight={700}
      drawShadow={true}
      flippingTime={600}
      useMouseEvents={true}
      pageMode="single"
      style={{ marginTop: '0px' }}
    >
      {/* Hard page at the start */}
      <div className="demoPage hard">
      </div>
      <div className="demoPage" >      
        <div className='full-size-container' 
       style={{  backgroundImage: `url("${flipbookFieldsData[1]?.value}")`, // Use the imported image as background
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',}}>
    {/* <div className="logo-container">
                        <img src={flipbookData.brandLogoUrl} alt="Logo" />
                    </div> */}
        </div>
    </div>
      {productData.map((product, index) => (
        <div className="demoPage" key={index}>
          <div className="sec-row-container">
            <div className="landscape-category-name">
              {flipbookFieldsData[0]?.value}
            </div>
            <div className="logo-container">
              <img className='flipbook-Logo'   src={flipbookData.brandLogoUrl} alt="Logo" />
            </div>
          </div>
          <div className='row'>
        <div className='col-md-8'>
            <Card className="flipbook2-tz-product-view-card" style={{ border: "1px solid #C7CFDE", backgroundColor: "#FFFFFF" }}>
                <div className="flipbook-tz-showcase-frame ">
                    <img src={product.thumbnailUrl} className='flipbook2-thumb-image' alt="Product Thumbnail" />
                </div>
            </Card>
        </div>
        <div className='col-md-4'>
            <div className='flipbook-cont1'>
            <div className='flipbook-upper-spacing'>
                                <span className="title-data">{product.title}</span>
                            </div>
                            <div className="custom-font-10">{product.productTzn}</div>
                <div>
                    <span className="label mt-3">Brand:</span>
                    <span className="data custom-font-12">{product.brand}</span>
                </div>
                <div>
                    <span className="label">Model Number:</span>
                    <span className="data">{product.modelNumber}</span>
                </div>
                <div>
                    <span className="label">Model Name:</span>
                    <span className="data ">{product.modelName}</span>
                </div>
                <div>
                    <span className="label">SKU:</span>
                    <span className="data">{product.modelName}</span>
                </div>
            </div>
            <div className="image-text-container">
                <img className="qr-code-image" src={`data:image/png;base64,${product.qrcodeResponse}`} alt="Base64 Image" />
                <span className="scan-text">
                    Scan this for more details
                </span>
            </div>
        </div>
    </div>
    
    <div className="footer mb-2">
                                            Powered by Trezi
                                          <div><img class="sales-trezi-logo" src={TreziLogo} alt="Trezi Logo" ></img>
                                            </div>  
                                        </div>
        </div>
      ))}
    
    <div className="demoPage" >      
        <div className='full-size-container' 
       style={{  backgroundImage: `url("${flipbookFieldsData[2]?.value}")`, // Use the imported image as background
       backgroundSize: 'cover',
       backgroundRepeat: 'no-repeat',}}>
        </div>
    </div>
    </HTMLFlipBook>


    )}
  </>
)}
   
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default SalesCatalog; 