import React from "react";
import {
  Table,
  Stack,
  Form,
  Dropdown,
  Card,
  Row,
  Col,
  Button,
  Container,
  Modal,
  Pagination,
  ButtonGroup
} from "react-bootstrap";
import "../css/ProductTable.css";
import "../css/ProductImage.css";
import {downloadProductqrcode, deleteProduct, ProductListOnthebaseOfcategories,deleteProductBatch, getProductById, getProducts, getShareCount, increaseShareCount, increaseViewCount, updateStatus, updateStatusBatch } from "../services/ProductsService";
import { useNavigate } from "react-router-dom";
import ModalComponent from "./Modal/Modal";
import { EditStateContext, ModalBody, ModalFooter } from "./ProductDetailsHeader";
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import ProductCatalog from '../pages/ProductCatalog.jsx';
export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class ProductTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      filteredData: [],
      productData: [],
      sortColumn: "status",
      sortDirection: "asc",
      showDeleteModal: false,
      showShareModal: false,
      showEditModal: false,
      count: null,
      currentPage: 1, // Current page number
      itemsPerPage: 12,// Number of items per page
      totalPages: 1,
      selectAllChecked: false,
      isUpdated: false,
      shareLink: "",
      statusUpdates: {},
      CategoryProduct: [],
      MetaData: {}
    };
  }

  componentDidMount() {
    const currentPage = 1;
    this.fetchProducts(currentPage);
    this.filterData();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.searchQuery !== this.props.searchQuery ||
      this.state.isUpdated !== prevState.isUpdated ||
      prevState.productData !== this.state.productData
    ) {
      this.filterData();
    }
    if (prevState.isUpdated !== this.state.isUpdated) {
      this.state.productData = [];
    }
  }

  // Get all products
  async fetchProducts(currentPage) {
    // const { data, currentPage, itemsPerPage } = this.state;
    console.log(currentPage)
    debugger;
    try {
      const products = await getProducts(currentPage);
      console.log(products)
      this.setState(
        {
          data: products.data.products,
          totalPages : products.data.meta.totalPages,
          productData: [],
        },
        () => {
          this.filterData();
        }
      );
    } catch (err) {
      console.log(err , "error")
      // if(err.response.status === 401){
      //   Swal.fire({
      //     icon: 'Unauthorised',
      //     title: 'Error fetching Products',
      //     text: 'Error fetching Products'
      //   });
      //   window.location.href = '/'; // Redirect to the login page
      // }else {
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Error fetching Products',
      //     text: 'Error fetching Products'
      //   });
      // }
      
    }
  }
  async downloadQRCode(id) {
    const organizationIdentifier = this.getCookie('orgIdentifier');
  debugger;
    try {
      const product = await getProductById(id);
      const url = window.location.origin;
      const linkInput = product?.shareLink;
    const linkWithoutPrefix = linkInput.replace(/.*\/v1\/products\//, '');
    const newLink = `${url}/products/${organizationIdentifier}/${linkWithoutPrefix}`;
      const newLink1 = "https://showcasedemo.trezi.com/products/public/TZNSOSO1121211692708420773";
  
      const response = await downloadProductqrcode(newLink);
  
      if (response) {

        let fileName = 'qrcode.png'; // Default filename
        
        const blobUrl ="data:image/png;base64,"+response.data.qrCodeBytes;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = fileName;
        
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
        Swal.fire({
          title: 'Success!',
          text: 'Product downloaded successfully.',
          icon: 'success',
          timer: 2000, // Optional: Automatically close after 2 seconds
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to download the product.',
          icon: 'error',
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to download the product.',
        icon: 'error',
      });
    }
  }
  // Filtered products while searching
  filterData = () => {
    debugger
    const { searchQuery } = this.props;
    const { data, currentPage, itemsPerPage } = this.state;
    let filteredData = data;
    if (!searchQuery) {
      this.setState({ filteredData: data });
      return;
    } else {
      const filteredData = data && data.filter(
        (item) =>
          item.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.productId
            .toString()
            .toLowerCase()
            .includes(searchQuery.toLowerCase().trim())
      );
      // Calculate indexes for pagination
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;

      // Update filteredData based on current page and itemsPerPage
      const currentDataPagination = filteredData.slice(indexOfFirstItem, indexOfLastItem);
      this.setState({ filteredData: currentDataPagination });
    }
  };

  handlePaginationClick = async (pageNumber) => {
    console.log(pageNumber)
    const { selectedCategories,handleApply} = this.props;
    const { itemsPerPage } = this.state;
    this.setState({ currentPage: pageNumber  });
    if(selectedCategories && selectedCategories.length >0){
      console.log(this.props);
   this.props.handleApply(pageNumber)
    }else{
      this.fetchProducts(pageNumber);
    }
    localStorage.setItem('currentPage', pageNumber);
  };
 
  handleSelectAllCheck = (e) => {
    const checked = e.target.checked;
    this.setState((prevState) => {
      const updatedData = prevState.filteredData.map((item) => ({
        ...item,
        selected: checked,
      }));

      const count = prevState.filteredData.length;
      const show = checked;
      const selectAllChecked = checked;

      const productData = [...prevState.productData]; // Preserve existing productData

      if (checked) {
        const selectedIds = updatedData.map((item) => item.productId);

        Promise.all(selectedIds.map(async (id) => await getProductById(id)))
          .then((selectedProductData) => {
            selectedProductData = selectedProductData.filter(Boolean); // Remove null or undefined values

            const updatedProductData = productData.concat(selectedProductData); // Merge existing and new productData

            const selectedIds = updatedData.map((item) => item.productId);

            this.setState({
              data: updatedData,
              filteredData: updatedData,
              count: count,
              show: show,
              selectAllChecked: selectAllChecked,
              productId: selectedIds,
              productIds: selectedIds,
              productData: updatedProductData, // Set the updated productData
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const deselectedIds = updatedData
          .filter((item) => !item.selected)
          .map((item) => item.productId);

        const updatedProductData = productData.filter(
          (item) => !deselectedIds.includes(item.productId)
        );

        const selectedIds = updatedData.map((item) => item.productId);

        this.setState({
          data: updatedData,
          filteredData: updatedData,
          count: count,
          show: show,
          selectAllChecked: selectAllChecked,
          productId: selectedIds,
          productIds: selectedIds,
          productData: updatedProductData, // Set the updated productData
        });
      }
    });
  };

  // select particular checkbox
  handleCheckboxChange = (id) => async (e) => {
    debugger;
    const checked = e.target.checked;
    if (checked) {
      try {
        const productData = await getProductById(id);
        // const url = window.location.origin;
        // const linkInput = productData?.shareLink;
        // const newLink = linkInput?.replace(/.*\/v1/, `${url}`);
        const organizationIdentifier = this.getCookie('orgIdentifier');
        const url = window.location.origin;
      const linkInput = productData?.shareLink;
    const linkWithoutPrefix = linkInput.replace(/.*\/v1\/products\//, '');
    const newLink = `${url}/products/${organizationIdentifier}/${linkWithoutPrefix}`;
        this.setState((prevState) => {
          return {
            productData: [...prevState.productData, productData],
            shareLink: newLink,
          };
        });
      } catch (err) {
        console.log(err);
      }
    } else {
      this.setState((prevState) => {
        const updatedProductData = prevState.productData.filter(
          (item) => item.productId !== id
        );
        return {
          productData: updatedProductData,
        };
      });
    }

    this.setState((prevState) => {
      const updatedData = prevState.filteredData.map((item) =>
        item.productId === id
          ? { ...item, selected: checked, show: checked }
          : item
      );
      const count = updatedData.filter((item) => item.selected === true).length;
      const show = count > 0;

      if (!checked) {
        const selectedIds = prevState.productIds.filter(
          (productId) => productId !== id
        );
        const selectAllChecked = updatedData.every((item) => item.selected);

        return {
          data: updatedData,
          filteredData: updatedData,
          count: count,
          show: show,
          productId: selectedIds,
          productIds: selectedIds,
          selectAllChecked: selectAllChecked,
        };
      }

      const selectedIds = updatedData
        .filter((item) => item.selected === true)
        .map((item) => item.productId);
      const selectAllChecked = updatedData.every((item) => item.selected);

      return {
        data: updatedData,
        filteredData: updatedData,
        count: count,
        show: show,
        productId: selectedIds,
        productIds: selectedIds,
        selectAllChecked: selectAllChecked,
      };
    });
  };

  // Sort data according to selected column
  handleSort = (column) => {
    const { filteredData, sortColumn, sortDirection } = this.state;

    // Toggle sort direction if the same column is clicked
    const newSortDirection =
      column === sortColumn && sortDirection === "asc" ? "desc" : "asc";

    const sortedData = filteredData.slice().sort((a, b) => {
      if (column === "created on") {
        if (a.createdAt < b.createdAt)
          return newSortDirection === "asc" ? -1 : 1;
        if (a.createdAt > b.createdAt)
          return newSortDirection === "asc" ? 1 : -1;
      } else if (column === "status") {
        const statusA = a.status.toLowerCase();
        const statusB = b.status.toLowerCase();

        if (statusA < statusB) return newSortDirection === "asc" ? -1 : 1;
        if (statusA > statusB) return newSortDirection === "asc" ? 1 : -1;
      } else {
        if (a.updatedAt < b.updatedAt)
          return newSortDirection === "asc" ? -1 : 1;
        if (a.updatedAt > b.updatedAt)
          return newSortDirection === "asc" ? 1 : -1;
      }
      return 0;
    });

    this.setState({
      data: sortedData,
      filteredData: sortedData,
      sortColumn: column,
      sortDirection: newSortDirection,
    });
  };
  getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
   for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };
  handleItemClick = (productId, status) => {
    debugger;
    const organizationIdentifier = this.getCookie('orgIdentifier');
    increaseViewCount(productId, status);
    this.props.navigate(`/products/${organizationIdentifier}/${productId}`);
  };

  // Status change
  handleStatusChange = async (id, selectedStatus, productIds, status) => {
    if (id !== null && selectedStatus !== null) {
      const { code, label } = selectedStatus; // Destructure the code and label from the selectedStatus object
      await updateStatus(id, selectedStatus).then((response) => {
        if (response.status === 417) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "Can't update status",
          });
          return;
        }
        else if (response.status === 200) {
          this.setState(
            (prevState) => {
              const updatedData = prevState.data.map((item) =>
                item.productId === id
                  ? { ...item, status: { code, label } }
                  : item
              );
              const updatedFilteredData = prevState.filteredData.map((item) =>
                item.productId === id
                  ? { ...item, status: { code, label } }
                  : item
              );
              const updatedProductData = prevState.productData.map(
                (item, index) =>
                  index === 0 ? { ...item, status: { code, label } } : item
              );
              return {
                data: updatedData,
                filteredData: updatedFilteredData,
                productData: updatedProductData,
              };
            },
            () => {
              this.fetchProducts();
              this.filterData();
              this.setState({ show: false, selectAllChecked: false });
            }
          );
        }
      });
    } else if (productIds !== null && status !== null) {
      // const { code, label } = status; // Destructure the code and label from the status object
      const statusBatch = productIds?.map((productId, index) => {
        return { productId, status };
      });

      await updateStatusBatch(statusBatch).then((response) => {
        debugger;
        if (response === 400) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: "One or more products status could not be updated...",
          });
          return;
        }
        else if (response === 200) {
          debugger;
          // this.filterData();
          // this.fetchProducts();
          this.setState(
            (prevState) => {
              const updatedData = prevState.data.map((item) => {
                const updatedStatus = statusBatch.find((batchItem) => batchItem.productId === item.productId);
                if (updatedStatus) {
                  return { ...item, status: updatedStatus.status };
                }
                return item;
              });
      
              const updatedFilteredData = prevState.filteredData.map((item) => {
                const updatedStatus = statusBatch.find((batchItem) => batchItem.productId === item.productId);
                if (updatedStatus) {
                  return { ...item, status: updatedStatus.status };
                }
                return item;
              });
      
              const updatedProductData = prevState.productData.map((item) => {
                const updatedStatus = statusBatch.find((batchItem) => batchItem.productId === item.productId);
                if (updatedStatus) {
                  return { ...item, status: updatedStatus.status };
                }
                return item;
              });
      
              return {
                data: updatedData,
                filteredData: updatedFilteredData,
                productData: updatedProductData,
              };
            },
            () => {
              this.fetchProducts();
              this.filterData();
              this.setState({ show: false, selectAllChecked: false });
              this.setState({ filteredData: [] });
            }
          );
        }
      });
    }
  };



  // Deleting a product or batch of products
  handleDeleteProduct = async (productIds) => {
    debugger;
    if (productIds.length > 1) {
      try {
        const response = await deleteProductBatch(productIds);
        if (response.status === 200) {
          this.fetchProducts();
          this.setState({ filteredData: [],show : false  });
          this.closeDeleteModal();
          Swal.fire('Product Deleted', 'Selected products have been deleted.', 'success');     
        } else {
          throw new Error("Failed to delete product");
        }
      } catch (err) {
        console.log(err);
        Swal.fire('Error', 'Failed to delete products.', 'error');
      }
    } else if (productIds.length === 1) {
      try {
        const response = await deleteProduct(productIds);
        if (response === "Product deleted.") {
          this.fetchProducts();
          this.setState({ filteredData: [] , count :null , checked :false ,show : false });
          this.closeDeleteModal();
          Swal.fire('Product Deleted', 'The product has been deleted.', 'success');
        } else {
          throw new Error("Failed to delete product");
        }
      } catch (err) {
        console.log(err);
        Swal.fire('Error', 'Failed to delete the product.', 'error');
      }
    }
  };

  // For sharing link of product by copying.
  copyLink = async (productId, code, label) => {
    try {
      await increaseShareCount(productId, code, label).then(() =>
        getShareCount(productId, code, label).then((response) => {
          this.setState({ shareCount: response });
          this.props.setShareCount(this.state.shareCount);
        })
      );

      const linkInput = document.getElementById("link-input");
      console.log("Link input", linkInput)
      linkInput?.select();
      document.execCommand("copy");
    } catch (error) {
      console.log(error);
    }
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber }, () => {
      this.filterData(); // Update filteredData based on the new page
    });
  };

  openDeleteModal = () => {
    debugger;
    this.setState({ showDeleteModal: true });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteModal: false });
  };

  openShareModal = () => {
    debugger;
    this.setState({ showShareModal: true });
  };

  closeShareModal = () => {
    this.setState({ showShareModal: false });
  };

  openEditModal = () => {
    debugger;
    this.setState((prevState) => ({ ...prevState, showEditModal: !prevState.showEditModal }));
  };

  closeEditModal = () => {
    this.setState({ showEditModal: false });
  };

  productListUpdate = () => {
    this.setState(prevState => ({ ...prevState, isUpdated: !prevState.isUpdated }));
    this.fetchProducts();
    this.setState({ show: false });
  };

  isProductUpdate = () => {
    this.setState(prevState => ({ ...prevState, isUpdated: !prevState.isUpdated }));
  };
 
  render() {
    const {
      sortColumn,
      selectAllChecked,
      productData,
      sortDirection,
      showDeleteModal,
      showShareModal,
      count,
      show,
      productId,
      productIds,
      filteredData,
      currentPage,
      itemsPerPage,
      shareLink,totalPages ,data ,Ca
    } = this.state;
    const { view ,filteredProducts, MetaData , handleApply } = this.props;
    console.log(this.props);
    const getRole = localStorage.getItem('role');
   console.log( filteredProducts ,"filteredProducts" ,totalPages ,MetaData, );
    const TimestampInfo = (time) => {
      if (time === null || time === 0) {
        return "-";
      }
      const timestamp = time;
      const datetimeObj = new Date(timestamp);

      const month = datetimeObj.toLocaleString("default", { month: "short" });
      const date = datetimeObj.getDate();
      const day = datetimeObj.toLocaleString("en-US", { weekday: "long" });
      const year = datetimeObj.getFullYear();

      return (
        <div>
          <span className="tz-month custom-font-12">
            {month} {date}, {year}
          </span>{" "}
          <br />
          <span className="tz-day custom-font-12">{day}</span>
        </div>
      );
    };

    // Calculate the indexes of the items to display on the current page
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    // const pageNumbers = [];
    // for (let i = 1; i <= Math.ceil(filteredData.length / itemsPerPage); i++) {
    //   debugger;
    //   pageNumbers.push(i);
    // }
    const indexOfLastAttribute = currentPage * itemsPerPage;
    const indexOfFirstAttribute = indexOfLastAttribute - itemsPerPage;
    let currentDataPaginition;

   if (filteredProducts && filteredProducts.length > 0 ) {
      currentDataPaginition = filteredProducts;
    } else {
      currentDataPaginition = filteredData;
    }
  
console.log(currentDataPaginition);
    return (
      <Stack className="table-product-container">
        {show && (
          <div
            className="select-checkbox"
            style={{
              display: "flex",
              justifyContent: "space-between",
              maxWidth: "100%",
              border: "1px solid #2B91FF",
              borderRadius: "4px",
              height: "50px",
              padding: "12px 12px",
              marginBottom: "18px",
              backgroundColor: "#E5F0FB",
            }}
          >
            <div
              style={{
                color: "#6A6D74",
                fontWeight: "400",
                fontStyle: "normal",
                fontSize: "14px",
              }}
            >
              {count} {count > 1 ? "Products" : "Product"} in this page{" "}
              {count > 1 ? "are" : "is"} selected
            </div>
            <div
              style={{
                display: "flex",
                gap: "40px",
              }}
            >
              {count === 1 ? (
                <>
                  {(getRole === "Owner" || getRole === "Catalog_Manager")  && (
                    <>
                      <div style={{ display: "inline-flex" }}>
                        <span
                          className={
                            productData[0]?.status.code === "PUBLISHED"
                              ? "red-dot"
                              : "green-dot"
                          }
                          style={{ marginTop: "5px" }}
                        ></span>
                        <span
                          className="custom-font-12"
                          style={{
                            cursor: "pointer",
                            paddingLeft: "10px",
                            paddingTop: "1px",
                          }}
                          onClick={() =>
                            this.handleStatusChange(
                              productId,
                              productData[0]?.status.label === "Published"
                                ? "Unpublished"
                                : "Published",
                              null,
                              null
                            )
                          }
                        >
                          {productData[0]?.status.label === "Published"
                            ? "Unpublish"
                            : "Publish"}
                        </span>
                      </div>
                    </>
                  )}
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={this.openShareModal}
                  >
                    <i
                      className="bi bi-share-fill"
                      style={{ color: "#0070DC" }}
                    ></i>
                    <span
                      style={{
                        marginLeft: "8px",
                      }}
                      className="custom-font-12"
                    >
                      Share
                    </span>
                  </div>
                  {(getRole === "Owner" || getRole === "Catalog_Manager") && (
                    <>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={this.openEditModal}
                      >
                        <i className="bi bi-pencil-square"></i>
                        <span
                          style={{
                            marginLeft: "8px",
                          }}
                          className="custom-font-12"
                        >
                          Edit
                        </span>
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={this.openDeleteModal}
                      >
                        <i className="bi bi-trash3"></i>
                        <span
                          style={{
                            marginLeft: "8px",
                          }}
                          className="custom-font-12"
                        >
                          Delete
                        </span>
                      </div>
                    </>
                  )}

                  {/* Share Modal */}
                  <Modal
                    show={showShareModal}
                    onHide={this.closeShareModal}
                    className="tz-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{ fontSize: "14px", color: "#000000" }}
                      >
                        To share, copy the link below
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <ButtonGroup>
                        <Form.Control
                          id="link-input"
                          type="text"
                          value={shareLink}
                          readOnly
                          className="mr-2"
                        />
                            <div className="d-flex align-items-center">
                            <Button
                          onClick={() =>
                            this.copyLink(
                              productData[0].productId,
                              productData[0].status.code,
                              productData[0].status.label
                            )
                          }
                          className="mr-2"
                        >
                             <i className="bi bi-files"></i> Copy
                        </Button>
                        <Button onClick={() => this.downloadQRCode(productData[0].productId,)}>  <i className="bi bi-download"></i> Download QR</Button>
                            </div>
                       
                      </ButtonGroup>
                    </Modal.Body>
                  </Modal>

                  {/* Edit modal */}
                  <EditStateContext
                    productData={productData[0]}
                    showModal={this.state.showEditModal}
                    handleModal={this.closeEditModal}
                    isProductUpdate={this.isProductUpdate}
                    isProductListUpdated={this.productListUpdate}
                  >
                    <ModalComponent
                      showModal={this.state.showEditModal}
                      handleModal={this.closeEditModal}
                      title={"Edit Product Details"}
                      body={<ModalBody productData={productData[0]} />}
                      footer={<ModalFooter handleModal={this.closeEditModal} />}
                    />
                  </EditStateContext>

                  {/* Delete Modal */}
                  <Modal
                    show={showDeleteModal}
                    onHide={this.closeDeleteModal}
                    className="tz-delete-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{
                          fontSize: "14px",
                          color: "#000000",
                          textAlign: "center",
                        }}
                      >
                        Are you sure you want to delete {productData[0]?.title}?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Button
                        onClick={() => this.handleDeleteProduct(productIds)}
                      >
                        Confirm
                      </Button>
                      <Button onClick={this.closeDeleteModal}>Cancel</Button>
                    </Modal.Body>
                  </Modal>
                </>
              ) : (
                <>
                  <div style={{ display: "inline-flex", cursor: "pointer" }}>
                    <span
                      style={{ marginTop: "7px" }}
                      className="green-dot"
                    ></span>
                    <span
                      style={{
                        paddingLeft: "10px",
                        paddingTop: "2px",
                      }}
                      className="custom-font-12"
                      onClick={() =>
                        this.handleStatusChange(
                          null,
                          null,
                          productIds,
                          "Published"
                        )
                      }
                    >
                      Publish All
                    </span>
                  </div>
                  <div style={{ display: "inline-flex", cursor: "pointer" }}>
                    <span
                      style={{ marginTop: "7px" }}
                      className="red-dot"
                    ></span>
                    <span
                      style={{
                        paddingLeft: "10px",
                        paddingTop: "2px",
                      }}
                      className="custom-font-12"
                      onClick={() =>
                        this.handleStatusChange(
                          null,
                          null,
                          productIds,
                          "Unpublished"
                        )
                      }
                    >
                      Unpublish All
                    </span>
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={this.openDeleteModal}
                  >
                    <i className="bi bi-trash3"></i>
                    <span
                      style={{
                        marginLeft: "8px",
                      }}
                      className="custom-font-12"
                    >
                      Delete All
                    </span>
                  </div>
                  <ProductCatalog    
                  pageNumber={currentPage} // Pass the current page number as a prop
                  onPageChange={(selectedPage) => this.handlePaginationClick(selectedPage.selected + 1)}
   />
                  {/* Delete Modal */}
                  <Modal
                    show={showDeleteModal}
                    onHide={this.closeDeleteModal}
                    className="tz-delete-modal"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title
                        style={{
                          fontSize: "14px",
                          color: "#000000",
                          textAlign: "center",
                        }}
                      >
                        Are you sure you want to delete {count}{" "}
                        {count > 1 ? "items" : "item"}?
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Button
                        onClick={() => this.handleDeleteProduct(productIds)}
                      >
                        Confirm
                      </Button>
                      <Button onClick={this.closeDeleteModal}>Cancel</Button>
                    </Modal.Body>
                  </Modal>
                </>
              )}
            </div>
          </div>
        )}
        <Table hover borderless className="product-table">
          {/* Change according to selected view */}
          {view === "List" ? (
            <thead className="list-view-table-head">
              <tr>
                <th className="first-product-column-th">
                  {/* To select all checkbox items on one click in list view */}
                  {(getRole === "Owner" || getRole === "Catalog_Manager")  && (
                    <Form.Check
                      type="checkbox"
                      className="product-checkbox"
                      checked={selectAllChecked ?? false}
                      onChange={this.handleSelectAllCheck}
                    />
                  )}
                </th>
                <th className="second-product-column-th">
                  Title & Tzn Number
                </th>
                <th className="third-product-column-th ">
                  <div
                    className="d-flex sort-column align-items-center"
                    onClick={() => this.handleSort("status")}
                  >
                    <span className="">Status</span>
                    <div className="sort-icons">
                      <button
                        className={`sort-button ${sortColumn === "status" &&
                          sortDirection === "asc" &&
                          "active"
                          }`}
                      >
                        <span className="bi bi-caret-up-fill"></span>
                        <span className="bi bi-caret-down-fill"></span>
                      </button>
                    </div>
                  </div>
                </th>
                <th className="fourth-product-column-th">
                  <div
                    className="d-flex sort-column align-items-center"
                    onClick={() => this.handleSort("created on")}
                  >
                    <span className="">Created on</span>
                    <div className="sort-icons">
                      <button
                        className={`sort-button ${sortColumn === "created on" &&
                          sortDirection === "asc" &&
                          "active"
                          }`}
                      >
                        <span className="bi bi-caret-up-fill"></span>
                        <span className="bi bi-caret-down-fill"></span>
                      </button>
                    </div>
                  </div>
                </th>
                <th className="fifth-product-column-th">
                  <div
                    className="d-flex sort-column align-items-center"
                    onClick={() => this.handleSort("modified on")}
                  >
                    <span className="">Modified on</span>
                    <div className="sort-icons">
                      <button
                        className={`sort-button ${sortColumn === "modified on" &&
                          sortDirection === "asc" &&
                          "active"
                          }`}
                      >
                        <span className="bi bi-caret-up-fill"></span>
                        <span className="bi bi-caret-down-fill"></span>
                      </button>
                    </div>
                  </div>
                </th>
              </tr>
            </thead>
          ) : (
            <thead className="grid-view-table-head"></thead>
          )}
          {view === "List" ? (
            <tbody className="table-body">
              {currentDataPaginition && currentDataPaginition?.map((item, index) => (
                <tr key={index} className="tz-table-body-row">
                  <td className="first-product-column w-30">
                    {/* To select a particular checkbox item in list view */}
                    <Form.Check
                      className="product-checkbox"
                      type="checkbox"
                      checked={item.selected ?? false}
                      onChange={this.handleCheckboxChange(
                        item.productId,
                        item.status
                      )}
                    />
                  </td>
                  <td
                    className="second-product-column"
                    onClick={() =>
                      this.handleItemClick(
                        item.productId,
                        item.status,
                      )
                    }
                  >
                    <div className="name-container">
                       {item.thumbnail && (
    <img
      src={item.thumbnail.fileUrl}
      alt={item.thumbnail.fileName}
      className="avatar"
    />
  )}
                      <span>
                        <div className="product-title custom-font-12">
                          {item?.title}
                        </div>
                        <div className="product-serial custom-font-12">
                          {item.productId}
                        </div>
                      </span>
                    </div>
                  </td>
                  <td className="third-product-column">
                    {/* Dropdown to change the status of a product */}
                    <Dropdown
                      className="align-left"
                      onSelect={(status) =>
                        this.handleStatusChange(
                          item.productId,
                          status,
                          null,
                          null
                        )
                      }
                    >
                      <Dropdown.Toggle
                        className={
                          item.status === 'Published'
                            ? 'product-publish published'
                            : item.status === 'Unpublished'
                              ? 'product-unpublish unpublished'
                              : item.status === 'Baking in progress'
                                ? 'product-baking baking'
                                : item.status === 'Uploaded'
                                  ? 'product-uploaded uploaded'
                                  : 'product-unpublish unpublished'
                        }
                      >
                        {item.status === "Published"
                          ? "Published"
                          : item.status === "Unpublished"
                            ? "Unpublished"
                            : item.status === "Baking in progress"
                              ? "Baking In Progress"
                              : item.status === "Uploaded"
                                ? "Uploaded"
                                : "Uploaded"} {/* Default to "Unpublished" */}
                      </Dropdown.Toggle>


                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="Published"
                          className="product-item-publish"
                          active={item.status === "Published"}
                        >
                          <p className="green-dot"></p>
                          <p className="custom-font-12">Published</p>
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="Unpublished"
                          className="product-item-unpublish"
                          active={item.status === "Unpublished"}
                        >
                          <p className="red-dot"></p>
                          <p className="custom-font-12">Unpublished</p>
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="Baking in progress"
                          className="product-item-baking-in-progress"
                          active={item.status === "Baking in progress"}
                        >
                          <p className="pink-dot"></p>
                          <p className="custom-txt custom-font-12"> Baking In Progress</p>
                        </Dropdown.Item>
                        <Dropdown.Item
                          eventKey="Uploaded"
                          className="product-item-uploaded"
                          active={item.status === "Uploaded"}
                        >
                          <p className="yellow-dot"></p>
                          <p className="custom-font-12">Uploaded</p>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>

                  </td>
                  <td className="fourth-product-column">
                    {TimestampInfo(item.createdAt)}
                  </td>
                  <td className="fifth-product-column">
                    {TimestampInfo(item.updatedAt) }
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <tbody className="grid-view-table-body">
              <Container fluid>
                <Row sm={2} md={2} lg={3} xl={3} className="p-0">
                  {currentDataPaginition && currentDataPaginition ?.map((item) => (
                    <Col key={item.productId} className="mb-4">
                      <Card className="grid-view-product-card">
                        <Card.Body className="d-flex flex-column justify-content-between">
                          <div className="d-flex justify-content-between align-items-center">
                            {/* Checkbox to select a particular product in grid view */}
                            <Form.Check
                              className="product-checkbox"
                              type="checkbox"
                              checked={item.selected ?? false}
                              onChange={this.handleCheckboxChange(
                                item.productId,
                                item.status
                              )}
                            />
                          </div>
                          <div
                            className="product-grid-view-image-container mt-3"
                            onClick={() => this.handleItemClick(item.productId)}
                          >
                            <Card.Img
                              variant="top"
                              src={item.thumbnail.fileUrl}
                              alt={item.thumbnail.fileName}
                            />
                          </div>
                          <div className="mt-2 align-center">
                            <span
                              className="product-title custom-font-14"
                              onClick={() =>
                                this.handleItemClick(item.productId)
                              }
                            >
                              {item.title}
                            </span>
                            <br />
                            <span
                              className="product-serial custom-font-12"
                              onClick={() =>
                                this.handleItemClick(item.productId)
                              }
                            >
                              {item.productId}
                            </span>
                            {/* Dropdown to change the status of a product */}
                            <Dropdown
                              className="align-left"
                              onSelect={(status) =>
                                this.handleStatusChange(
                                  item.productId,
                                  status, // Use code property here
                                  null,
                                  null
                                )
                              }
                            >
                              <Dropdown.Toggle
                                className={
                                  item.status === 'Published'
                                    ? 'product-publish published'
                                    : item.status === 'Unpublished'
                                      ? 'product-unpublish unpublished'
                                      : item.status === 'Baking in progress'
                                        ? 'product-baking baking'
                                        : item.status === 'Uploaded'
                                          ? 'product-uploaded uploaded'
                                          : 'product-unpublish unpublished'
                                }
                              >
                                {/* Use code property here */}
                                {item.status === "Published"
                                  ? "Published"
                                  : item.status === "Unpublished"
                                    ? "Unpublished"
                                    : item.status === "Baking in progress"
                                      ? "Baking In Progress"
                                      : item.status === "Uploaded"
                                        ? "Uploaded"
                                        : "Unpublished"} {/* Default to "Unpublished" */}
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  eventKey="Published"
                                  className="product-item-publish"
                                  active={item.status.code === "Published"}
                                >
                                  <p className="green-dot"></p>
                                  <p className="custom-font-12">Published</p>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey="Unpublished"
                                  className="product-item-unpublish"
                                  active={item.status.code === "Unpublished"}
                                >
                                  <p className="red-dot"></p>
                                  <p className="custom-font-12">Unpublished</p>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey="Baking in progress"
                                  className="product-item-baking-in-progress"
                                  active={item.status.code === "Baking in progress"}
                                >
                                  <p className="pink-dot"></p>
                                  <p className="tz-custom-font-12">Baking In Progress</p>
                                </Dropdown.Item>
                                <Dropdown.Item
                                  eventKey="Uploaded"
                                  className="product-item-uploaded"
                                  active={item.status.code === "Uploaded"}
                                >
                                  <p className="yellow-dot"></p>
                                  <p className="custom-font-12">Uploaded</p>
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
                </Row>
              </Container>
            </tbody>
          )}
        </Table>

        <div className="pagination">
          <ReactPaginate
            pageCount={MetaData && MetaData.totalPages ? MetaData.totalPages : totalPages}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            onPageChange={(selectedPage) => this.handlePaginationClick(selectedPage.selected + 1)}
            containerClassName="pagination"
            activeClassName="pagination-item-active"
          />
        </div>

        
      </Stack>
    );
  }
}
export default withNavigation(ProductTable);
