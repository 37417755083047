import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Col, Container } from "react-bootstrap";
import {
    Grid,
    TextField,
    FormControl,
    Select,
    MenuItem,
    InputLabel,
    Checkbox,
    FormControlLabel,
    Button,
    Typography
} from '@material-ui/core';
import { useUserAuth } from "../context/UserAuthContext";
import logolink2 from "../assests/public/trezi-logo-full.png"
import errormsg from "../assests/public/errormsg.png"
import "./common.css";


const CancelPage = () => {
  let navigate = useNavigate();


  useEffect(() => {
      // This code will be executed once the component mounts
      console.log('Component mounted!');

      // You can return a function to execute when the component unmounts
      return () => {
          console.log('Component unmounted!');
      };
  }, []);

  const handleSubmit = async () => {
    navigate('/signupboard');
    console.log("aaa")
   };

   useEffect(() => {
    document.title = 'Cancel Page'; // Set the desired page title
  }, []);
  return (
      <>
          {/* {error && <Alert variant="danger">{error}</Alert>} */}
          <div className="box-8-container">
   <div className="success-container">
      <div className="logo-container">
        <img src={logolink2} alt="Logo" className="logo" />
      </div>
      <div className="content-container">
      <img src={errormsg} alt="Logo" width={330} />
        {/* <h1 className="success-heading">Success!</h1> */}
        {/* <p className="success-message">Your submission was successful.</p> */}
        {/* <Button className="login-button mt-4" onClick={handleSubmit} variant="contained" color="primary">Sign Up</Button> */}
      </div>
    </div>
    </div>
      </>
  );
};

export default CancelPage;

