import React from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Image,
  Dropdown,
  Button,
  Tooltip,
  OverlayTrigger,
  Modal,
  Form,
  ButtonGroup,
} from "react-bootstrap";
import { getProductById, getShareCount, getViewCount, increaseShareCount, getCartValue,updateStatus, downloadProductqrcode ,AddToCartItems} from "../services/ProductsService";
import { useParams } from "react-router-dom";
import "../css/ProductDetails.css";
import "../css/ProductCatalog.css";
import ProductDetailsHeader from "../components/ProductDetailsHeader";
import AccordionComponent from "../components/Accordion/Accordion";
import AccordionHeader from "../components/Accordion/AccordionHeader";
import AccordionBody from "../components/Accordion/AccordionBody";
import Swal from 'sweetalert2';
import { copy } from "../assests/public/Copy icon.png";
import VectarybrokenUrl from '../assests/public/Error (1).png';

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class ProductDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      openAccordions: [],
      frameImage: null,
      image: null,
      clicked: false,
      showModal: false,
      link: "",
      viewCount: null,
      shareCount: null,
      isUpdated: false,
      shareLink: "",
      imageLoaded: false,
      selectedColor: null,
      cartData :{},
      displayedColor: null,
    };
  }
  getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };
  componentDidMount() {
    this.fetchProductById();
    this.fetchAllCartItems();
    
    document.title = "Product Details";
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.params.productId !== this.props.params.productId ||
      this.state.isUpdated !== prevState.isUpdated
    ) {
      this.fetchProductById();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.params.productId !== this.props.params.productId ||
      nextState.viewCount !== this.state.viewCount ||
      nextState.shareCount !== this.state.shareCount ||
      nextState.showModal !== this.state.showModal ||
      nextState.data?.status !== this.state.data?.status ||
      nextState.isUpdated !== this.state.isUpdated ||
      nextState.image !== this.state.image
    ) {
      return true;
    }
    return false;
  }
  async fetchAllCartItems() {
    try {
      const logs = await getCartValue(); 
      this.setState({
        CartData: logs.data,
      });
      this.props.updatedCartData(logs.data);
      console.log(logs ,"sadasdlogdata")
    } catch (err) {
      console.log(err);
    }
  }
  async fetchProductById() {
    debugger;
    let scope = this;
    const { productId } = scope.props.params;
    // const organizationIdentifier = scope.getCookie('organizationIdentifier');
    const organizationIdentifier = scope.getCookie('orgIdentifier');
    try {
      const product = await getProductById(productId);
      this.forceUpdate();
      const url = window.location.origin;
      const linkInput = product?.shareLink;
      // const newLink = linkInput?.replace(/.*\/v1/, `${url}`);
      const linkWithoutPrefix = linkInput.replace(/.*\/v1\/products\//, '');
      const newLink = `${url}/products/${organizationIdentifier}/${linkWithoutPrefix}`;
      
      scope.setState({
        data: product,
        shareLink: newLink,
        // frameImage : product.bakedImageUrl,
      });
      this.handleGetShareCount(product.status);
      this.handleGetViewCount(product.status);
      if (product.bakedImageUrl !== null) {
        fetch(product.bakedImageUrl)
          .then((response) => {
            if (response.status === 200) {
              const encodedURL = encodeURI(response.url);
              scope.setState({
                frameImage: encodedURL,
              });
              console.log('frameImage3:', scope.state.frameImage);
            } else {
              scope.setState({
                frameImage: VectarybrokenUrl, // Default image for 404 responses
              });
            }
            console.log('frameImage1:', scope.state.frameImage);
            console.log('frameImage2:', scope.state.frameImage);
            scope.handleGetShareCount(product.status);
            scope.handleGetViewCount(product.status);
            scope.forceUpdate();
          })
          .catch((error) => {
            console.error('Error checking image:', error);
          });
      }
      /*console.log('frameImage2:', scope.state.frameImage);
      scope.handleGetShareCount(product.status.code, product.status.label);
      scope.handleGetViewCount(product.status.code, product.status.label);
      scope.forceUpdate();*/
    } catch (err) {
      console.log(err);
    }
  }
 

  async downloadQRCode(e) {
    const { productId } = this.props.params;
    const organizationIdentifier = this.getCookie('orgIdentifier');
    debugger;
    try {
      const product = await getProductById(productId);
      const url = window.location.origin;
      const linkInput = product?.shareLink;
      // const newLink = linkInput?.replace(/.*\/v1/, `${url}`);
      const linkWithoutPrefix = linkInput.replace(/.*\/v1\/products\//, '');
      const newLink = `${url}/products/${organizationIdentifier}/${linkWithoutPrefix}`;
      const newLink1 = "https://showcasedemo.trezi.com/products/public/TZNSOSO1121211692708420773";

      const response = await downloadProductqrcode(newLink);

      if (response) {

        let fileName = 'qrcode.png'; // Default filename

        const blobUrl = "data:image/png;base64," + response.data.qrCodeBytes;
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = blobUrl;
        a.download = fileName;

        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
        Swal.fire({
          title: 'Success!',
          text: 'Product downloaded successfully.',
          icon: 'success',
          timer: 2000, // Optional: Automatically close after 2 seconds
        });
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to download the product.',
          icon: 'error',
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        title: 'Error!',
        text: 'Failed to download the product.',
        icon: 'error',
      });
    }
  }

  anotherFunctionalAPI = (extractedToken) => {
    // Use the token in your API call here
    console.log('Token:', extractedToken);
  };
  handleStatusChange = (id, selectedStatus) => {
    debugger;
    const status = selectedStatus.code;
    updateStatus(id, selectedStatus).then((response) => {
      if (response.status === 417) {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: "Can't update status",
        });
        return;
      }
      else if (response.status === 200) {
        this.setState((prevState) => ({
          data: {
            ...prevState.data,
            status: selectedStatus, // Update the status property
          },
        }));
      }
    });
  };

  handleGetViewCount = (status) => {
    const { productId } = this.props.params;
    getViewCount(productId, status).then((response) => {
      this.setState({ viewCount: response });
    })
  };

  // Get share count for product
  handleGetShareCount = (status) => {
    const { productId } = this.props.params;
    getShareCount(productId , status).then((response) => {
      this.setState({ shareCount: response });
    })
  };

  toggleAccordion = (eventKey) => {
    const openAccordions = [...this.state.openAccordions];
    const index = openAccordions.indexOf(eventKey);

    if (index > -1) {
      openAccordions.splice(index, 1);
    } else {
      openAccordions.push(eventKey);
    }

    this.setState({
      openAccordions: openAccordions,
    });
  };

  handleFrameImage = (file) => {
    debugger;
    if (file.isDefault === true) {
      const bakedImageUrl = this.state.data?.bakedImageUrl;
      if (bakedImageUrl !== null) {
        fetch(bakedImageUrl)
          .then((response) => {
            if (response.status === 200) {
              const encodedURL = encodeURI(response.url);
              this.setState({
                frameImage: encodedURL,
                image :encodedURL
              });
              console.log('frameImage3:', this.state.frameImage);
            } else {
              this.setState({
                frameImage: VectarybrokenUrl,
                image :VectarybrokenUrl // Default image for 404 responses
              });
            }
          })
          .catch((error) => {
            console.error('Error checking image:', error);
          });
      }else{
        this.setState({ image: file.fileUrl });
      }
    } else {
      this.setState({ image: file.fileUrl, frameImage: null });
    }
    console.log('frameImage:', this.state.frameImage);
  };
  handleColorChange = (colorValue) => {
    debugger;
    console.log('Selected Color:', colorValue);
    //setSelectedColor(colorValue);
    this.setState({ selectedColor: colorValue }, () => {
      console.log('Selected Color:', this.state.selectedColor);
    });
    this.forceUpdate();
  };
  

  openModal = () => {
    const { data } = this.state;
    this.setState({ showModal: true });
  };

  closeModal = () => {
    this.setState({ showModal: false });
  };

  // For sharing link of product by copying.
  copyLink = async (status) => {
    const { productId } = this.props.params;
    debugger;
    try {
      await increaseShareCount(productId, status).then(() =>
        this.handleGetShareCount(status)
      );

      const linkInput = document.getElementById("link-input");
      linkInput?.select();
      document.execCommand("copy");
    } catch (error) {
      console.log(error);
    }
  };

  isProductUpdate = () => {
    this.setState(prevState => ({ ...prevState, isUpdated: !prevState.isUpdated }));
  }

  productListUpdate = () => {
    this.setState(prevState => ({ ...prevState, isUpdated: !prevState.isUpdated }));
  }
  
  handleAddToCart = async () => {
    const { selectedColor, data } = this.state;
    const colorAttribute = data.additionalAttributes.find(attribute => attribute.clusterName === 'Color');
    try {
      debugger;
      if (!selectedColor && colorAttribute) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Please choose a color before adding the product to cart',
        });
        return;
      } else if (selectedColor === 'N/A') {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'Item not available for sale',
        });
        return;
      }
      let colorToSend = selectedColor !== "" ? selectedColor : null;
      const response = await AddToCartItems(data.productId, colorToSend);
      if (response.status === 200) {
        this.setState(prevState => {
        const updatedCartData = { ...prevState.CartData, itemCount: prevState.CartData.itemCount + 1 };
        return { CartData: updatedCartData };
      }, () => {
        this.fetchAllCartItems();
      });
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Product added to cart successfully',
        });
        // this.fetchAllCartItems();
        return;
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
      if (error.response.status === 417) {
        Swal.fire({
          icon: 'warning',
          title: 'Warning',
          text: 'For adding the product in cart ,first change the status in Published.',
        });
        return;
      }else{
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while adding to cart',
        });  
        return;
      }
    }
  };
  
  render() {
    const { data, openAccordions, frameImage, image, showModal,  shareCount, shareLink,product, viewCount } = this.state;
    const getRole = (localStorage.getItem("role"));

    const TimestampInfo = (time) => {
      const timestamp = time;
      const datetimeObj = new Date(timestamp);

      const month = datetimeObj.toLocaleString("default", { month: "short" });
      const date = datetimeObj.getDate();
      const year = datetimeObj.getFullYear();

      const hours = datetimeObj.getHours();
      const minutes = datetimeObj.getMinutes();

      const formattedHours = hours % 12 || 12;
      const amPm = hours >= 12 ? "PM" : "AM";
      const formattedTime = `${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${amPm}`;

      return (
        <>
          <span className="tz-month custom-font-12">
            {date} {month}, {year}, {formattedTime}
          </span>
        </>
      );
    };

    return (
      <>
        {console.log("role", getRole)}
  

        <div className="tz-product-catalog-container">
          <ProductDetailsHeader
            productData={this.state.data}
            isProductUpdate={this.isProductUpdate}
            isProductListUpdated={this.productListUpdate}
            getRole={getRole}
          />
          
          <Container fluid className="tz-product-details-container">
            <Row className="tz-product-details-row lg-3">
              <>
                <Col
                  style={{ paddingLeft: "0", paddingRight: "2px" }}
                  className="small-image-container"
                >
                  {data?.thumbnails.map((file, index) => (
                    <Card
                      key={index}
                      style={{
                        minWidth: "100px",
                        minHeight: "100px",
                        maxWidth: "116px",
                        maxHeight :"116px",
                        marginBottom: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => this.handleFrameImage(file)}
                    >
                      <Card.Body style={{
                        display :"flex" ,
                        justifyContent :"center" ,
                        alignItems :"center" ,
                        height :"100%"
                      }}>
                        <Image
                          src={file.fileUrl}
                          alt={file.fileName}
                          style={{
                       width :"80px" ,
                       height :"80px",
                           objectFit :"contain",
                          }}
                        />
                      </Card.Body>
                    </Card>
                  ))}
                </Col>
                <Col className="tz-product-details-frame">
                  {/* Second Column */}
                  <Card
                    className="tz-product-view-card"
                    style={{
                      border: "1px solid #C7CFDE",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    <div className="tz-showcase-frame">
                      {this.state.frameImage !== null ? (
                        <iframe
                          title="Vactary 3D viewer"
                          src={this.state.frameImage}
                          width="100%"
                          height="100%"
                        ></iframe>
                      ) : image !== null ? (
                        <img src={image} alt="Product" height="100%" />
                      ) : (
                        data?.thumbnails[0] && (
                          <img
                            src={data?.thumbnails[0].fileUrl}
                            alt={data?.thumbnails[0].fileName}
                            width="100%"
                            height="100%"

                          />
                        )
                      )}
                    </div>
                  </Card>
                </Col>
                <Col className="tz-product-details" md="auto">
                  {/* Third Column */}
                  
                  <Card className="tz-product-details-card">
                    <div>
                      <div className="d-flex align-items-center justify-content-between"
                        style={{
                          fontWeight: "700",
                          fontSize: "20px",
                          color: "#13161B",
                        }}
                      >
                        <div> {data?.title}</div>
                        <div className="d-flex align-items-center ">
          <div style={{ marginRight: "15px" }}>
            <span>
              <i
                className="bi bi-eye-fill custom-font-16"
                style={{ color: "#8599BA" }}
              ></i>{" "}
            </span>
            <span
              style={{ marginLeft: "6px" }}
              className="custom-font-12"
            >
              {viewCount}
            </span>
          </div>

          <div>
            <span>
              <i
                className="bi bi-share-fill custom-font-16"
                style={{ color: "#8599BA" }}
              ></i>{" "}
            </span>
            <span
              style={{ marginLeft: "6px" }}
              className="custom-font-12"
            >
              {shareCount}
            </span>
          </div>
        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
        <div>
          <OverlayTrigger
            placement="bottom-start"
            overlay={
              <Tooltip className="tz-tooltip">TZN Number</Tooltip>
            }
          >
            <span
              style={{
                color: "#6A6D74",
                fontWeight: "400",
                fontStyle: "normal",
              }}
              className="custom-font-12"
            >
              {/* {data?.productId}{" "} */}
            </span>
          </OverlayTrigger>
          {/* <span style={{ color: "#6A6D74" }}>{"| "}</span> */}
          <OverlayTrigger
            placement="bottom-end"
            overlay={
              <Tooltip className="tz-tooltip">
                Product Category
              </Tooltip>
            }
          >
            <span
              style={{
                color: "#6A6D74",
                fontWeight: "400",
                fontStyle: "normal",
              }}
              className="custom-font-12"
            >
              {data?.categoryName}
            </span>
          </OverlayTrigger>
        </div>

      </div>

                      {(getRole === "Owner" || getRole === "Catalog_Manager")  && (
                        <>
                          <div
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span  
                               style={{
                                fontWeight: "700",
                                fontSize: "20px",
                                color: "#13161B",
                              }}>  {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR' }).format(data?.price)}</span>
               <div>(incl. all taxes)</div>
                          </div>

                          <div>
                            
  {data?.additionalAttributes.some(attr => attr.clusterName === 'Color') && (
    <div style={{ marginTop: "15px" }}>
      <div style={{ display: "flex", alignItems: "center", fontWeight: "18px" }}>
        <span>
          <i className="bi bi-palette custom-font-16" style={{ color: "#8599BA" }}></i>{" "}
        </span>
        Color Variant
      </div>
      {/* <div>Color Variant {this.state.selectedColor}</div> */}
      <Dropdown onSelect={this.handleColorChange}>
      <Dropdown.Toggle variant="light" className="custom-font-12">
      {this.state.selectedColor ? `${this.state.selectedColor}` : 'Select Color'}
</Dropdown.Toggle>
<Dropdown.Menu>
  {data?.additionalAttributes
    .filter((colorInfo) => colorInfo.clusterName === 'Color' && colorInfo.value)
    .map((colorInfo, index) => (
      <Dropdown.Item key={index} eventKey={colorInfo.value}>
        <div className="color-box" style={colorInfo.value ? { backgroundColor: colorInfo.value } : { display: 'none' }}>
        {colorInfo.value ? (
            <span className="color-text">{colorInfo.value}</span>
          ) : (
            <span className="color-text">N/A</span>
          )}
        </div>
      </Dropdown.Item>
    ))}
</Dropdown.Menu>


</Dropdown>

    </div>
  )}
</div>

                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <span>
                                <i
                                  className="bi bi-file-earmark-fill"
                                  style={{ color: "#8599BA" }}
                                ></i>{" "}
                              </span>
                              <span
                                style={{ marginLeft: "10px" }}
                                className="custom-font-12"
                              >
                                Created on : {TimestampInfo(data?.createdAt)}
                              </span>
                            </div>
                            <div
                              style={{
                                marginTop: "5px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <span>
                                <i
                                  className="bi bi-arrow-clockwise custom-font-16"
                                  style={{ color: "#8599BA" }}
                                ></i>{" "}
                              </span>
                              <span
                                style={{ marginLeft: "10px" }}
                                className="custom-font-12"
                              >
                                Modified on : {TimestampInfo(data?.updatedAt)}
                              </span>
                            </div>
                            <div
  style={{
    marginTop: "5px",
    display: "flex",
    alignItems: "center",
  }}
>
  <Button style={{ width: "100%", backgroundColor: "black", color: "white" }}     onClick={this.handleAddToCart}>
    Add to Cart
  </Button>
                          </div>
                        </>
                      )}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "17px",
                        }}
                      >
                        {(getRole === "Owner" || getRole === "Catalog_Manager")  && (
                          <>
                            <span>
                              <Dropdown
                                className="align-left mt-4"
                                onSelect={(status) =>
                                  this.handleStatusChange(data?.productId, status, null,
                                    null)
                                }
                              >
                                {data?.status !== "BAKING IN PROGRESS" ? (
                                  <>
                                    <Dropdown.Toggle
                                      className={
                                        data?.status === "PUBLISHED" || data?.status === "Uploaded"
                                          ? "product-publish"
                                          : "product-unpublish"
                                      }
                                    >
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          gap: "3px",
                                          alignItems: "center",
                                        }}
                                      >
                                        <p
                                          style={{
                                            marginLeft: "5px",
                                            color: "#000000",
                                            fontWeight: "400",
                                            fontStyle: "normal",
                                            marginTop: "3px",
                                          }}
                                          className="custom-font-12"
                                        >
                                          {data?.status === "Published"
                                            ? "Published"
                                            : data?.status === "Unpublished"
                                              ? "Unpublished"
                                              : data?.status === "Baking in progress"
                                                ? "Baking In Progress"
                                                : data?.status === "Uploaded"
                                                  ? "Uploaded"
                                                  : "Unpublished"}
                                        </p>
                                      </div>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        eventKey="Published"
                                        className="product-item-publish"
                                        active={data?.status === "PUBLISHED"}
                                      >
                                        <p className="green-dot"></p>
                                        <p className="custom-font-12">Published</p>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey="Unpublished"
                                        className="product-item-unpublish"
                                        active={data?.status === "UNPUBLISHED"}
                                      >
                                        <p className="red-dot"></p>
                                        <p className="custom-font-12">Unpublished</p>
                                      </Dropdown.Item>
                                      {/* Additional status options */}
                                      <Dropdown.Item
                                        eventKey="Uploaded"
                                        className="tz-product-item-uploaded"
                                        active={data?.status === "UPLOADED"}
                                      >
                                        <p className="yellow-dot"></p>
                                        <p className="custom-font-12">Uploaded</p>
                                      </Dropdown.Item>
                                      <Dropdown.Item
                                        eventKey="Baking in progress"
                                        className="product-item-baking-in-progress"
                                        active={data?.status === "Baking in progress"}
                                      >
                                        <p className="pink-dot"></p>
                                        <p className="custom-heading custom-font-12">Baking In Progress</p>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>

                                  </>
                                ) : (
                                  <Button className="baking-progress">Baking In Progress</Button>
                                )}
                              </Dropdown>
                            </span>
                          </>
                        )}
                        <div
                          className={
                            (getRole === "Owner" || getRole === "Catalog_Manager") 
                              ? "share-button-container"
                              : "salesrole-share-button"
                          }
                        >
                          <Button
                            className="tz-product-details-btn mt-4"
                            onClick={this.openModal}
                          >
                            <div>
                              <span>
                                <i
                                  className="bi bi-share-fill"
                                  style={{ color: "#0070DC" }}
                                ></i>
                              </span>
                              <span
                                style={{
                                  marginLeft: "8px",
                                }}
                                className="custom-font-12"
                              >
                                Share
                              </span>
                            </div>
                          </Button>

                          <Modal
                            show={showModal}
                            onHide={this.closeModal}
                            className="tz-modal"
                          >
                            <Modal.Header closeButton>
                              <Modal.Title
                                style={{ fontSize: "14px", color: "#000000" }}
                              >
                                To share, copy the link below
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <ButtonGroup>
                                <Form.Control
                                  id="link-input"
                                  type="text"
                                  value={shareLink}
                                  readOnly
                                  className="mr-2"
                                />
                                <div className="d-flex align-items-center">
                                  <Button
                                    onClick={() =>
                                      this.copyLink(data?.status)
                                    }
                                  >
                                    <i className="bi bi-files"></i> Copy
                                  </Button>
                                  <div style={{ width: "10px" }}></div> {/* Add space */}
                                  <Button onClick={(e) => this.downloadQRCode(e)}>
                                    <i className="bi bi-download"></i> Download QR
                                  </Button>
                                </div>
                              </ButtonGroup>
                            </Modal.Body>

                          </Modal>
                        </div>
                      </div>
                 
                      <div className="tz-accordion">
                        {/* Product attributes panel */}
                        <AccordionComponent
                          accordionClass={"align-left"}
                          bodyClass={"tz-app"}
                          eventKey={"1"}
                          onClick={() => this.toggleAccordion("0")}
                          header={
                            <AccordionHeader
                              openAccordions={openAccordions}
                              text={"Product Attributes"}
                            />
                          }
                          body={<AccordionBody text={"Product Attributes"} data={data} />}
                        />

                        {/* Product description panel */}
                        <AccordionComponent
                          accordionClass={"align-left mt-3"}
                          bodyClass={"tz-app"}
                          eventKey={"1"}
                          onClick={() => this.toggleAccordion("1")}
                          header={
                            <AccordionHeader
                              openAccordions={openAccordions}
                              text={"Product Description"}
                            />
                          }
                          body={
                            <AccordionBody text={"Product Description"} data={data} />
                          }
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </>
            </Row>
          </Container>

        </div>
      </>
    );
  }
}
export default withParams(ProductDetails);

