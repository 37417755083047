import React, { useState, useEffect } from 'react';
import axios from 'axios';
import DashboardIndex from './DashboardIndex';
import * as XLSX from 'xlsx';

const produrl = 'https://showcaseapi.trezi.com/dashboards/';

const WeeklyUsers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set loading to true

  useEffect(() => {
    const fetchData = async () => {
      try {
        const endpoint = produrl + 'api/v1/data/weekly/users';
        const response = await axios.get(endpoint);
        console.log(response.data);
        setData(response.data);
      } catch (error) {
        setError('Error making API call');
        console.error('Error making API call:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData(); // Call fetchData function when component mounts
  }, []); // Empty dependency array ensures useEffect runs only once

  const exportToExcel = () => {
    if (data.length === 0) return; // Do nothing if there's no data

    // const columns = Object.keys(data[0]);
    const dateRanges = [...new Set(data.map(item => `${item.startDate} to ${item.endDate}`))];
    const products = [...new Set(data.map(item => item.product))];
    
    const headers = ['Week', ...products.map(product => `${product} Count`)];
    const worksheetData = [
      headers,
      ...dateRanges.map(dateRange => {
        const [startDate, endDate] = dateRange.split(' to ');
        const dateData = data.filter(item => item.startDate === startDate && item.endDate === endDate);
        const countsByProduct = products.map(product => {
          const productData = dateData.find(item => item.product === product);
          return productData ? productData.count : 0;
        });
        return [dateRange, ...countsByProduct];
      })
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Weekly Active Users');
    XLSX.writeFile(workbook, 'Weekly_Active_Users.xlsx');
  };

  const renderTable = () => {
    if (loading) return <p>Loading...</p>; // Show loading indicator

    if (error) return <p>{error}</p>; // Show error message if API call fails

    if (data.length === 0) return null; // Render nothing if data is empty

    let dateRanges, dataByDateRange, products;

    // const columns = Object.keys(data[0]);

    dateRanges = [...new Set(data.map(item => `${item.startDate} to ${item.endDate}`))];
    products = [...new Set(data.map(item => item.product))];
    dataByDateRange = dateRanges.map(dateRange => {
      const [startDate, endDate] = dateRange.split(' to ');
      const dateData = data.filter(item => item.startDate === startDate && item.endDate === endDate);
      const countsByProduct = products.map(product => {
        const productData = dateData.find(item => item.product === product);
        return productData ? productData.count : 0;
      });
      return { dateRange, countsByProduct };
    });

    return (
    
      <div>
    
        <h2 id="heading">Weekly Active Users</h2>
        <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
        <div className='other_table_container'>
  <table className="result-table">
    <thead>
      <tr>
        <th>Week</th>
        {products.map((product, index) => (
          <th key={index}>{product} Count</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {dataByDateRange
        .sort((a, b) => {
          // Extract start dates for sorting
          const startDateA = new Date(a.dateRange.split(' to ')[0]);
          const startDateB = new Date(b.dateRange.split(' to ')[0]);
          return startDateB - startDateA; // Sort in descending order by the start date
        })
        .map((item, index) => {
          // Reverse the date range format
          const [startDate, endDate] = item.dateRange.split(' to ');
          const reversedDateRange = `${endDate} to ${startDate}`;

          return (
            <tr key={index}>
              <td>{reversedDateRange}</td> {/* Display the reversed date range */}
              {item.countsByProduct.map((count, idx) => (
                <td key={idx}>{count}</td>
              ))}
            </tr>
          );
        })}
    </tbody>
  </table>
</div>


      </div>
    );
  };

  return (
    <>
    <DashboardIndex/>
<div className='home-container'>
  <div className='options-container'>
    {/* No button required as data is fetched automatically */}
  </div>
  <div className="data-container">
    {renderTable()}
  </div>
</div>
</>
  );
};

export default WeeklyUsers;
