import React, { Component, createContext, createRef } from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import "../css/ProductDetailsHeader.css";
import Modal from './Modal/Modal'
import { updateProduct, getProductByIdforCustomer ,updateProductforthumbnails} from "../services/ProductsService";
import arrowRight from '../assests/arrow-right.svg';
import {
  Col,
  Container,
  Row,
  Card,
  Dropdown,
} from "react-bootstrap";
import { FaUpload } from "react-icons/fa";
import Swal from 'sweetalert2';

export const EditState = createContext()

export class EditStateContext extends Component {
  constructor(props) {
    super(props);
    const { productData } = this.props;
    this.localStateRef = createRef([]);
    this.localStateRef.current = []
    this.state = {
      productId: productData?.productId,
      title: productData?.title,
      sku: productData?.sku,
      brand: productData?.brand,
      modelNumber: productData?.modelNumber,
      modelName: productData?.modelName,
      description: productData?.description,
      additionalAttributes: productData?.additionalAttributes,
      thumbnails : productData?.thumbnails,
      price : productData?.price,
      thumbnailFiles: [null, null, null , null]  , 
    };
    this.handleTextureFileChange = this.handleTextureFileChange.bind(this);
  }
  componentDidMount() {
    this.updateStateIfNeeded();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.productData?.productId !== this.props.productData?.productId || prevProps.showModal !== this.props.showModal) {
      this.updateStateIfNeeded();
    }
  }


  updateStateIfNeeded() {
    debugger;
    const { productData } = this.props;
    if (productData?.productId !== null) {
      this.setState(s => ({
        ...s,
        productId: productData?.productId,
        title: productData?.title,
        sku: productData?.sku,
        brand: productData?.brand,
        modelNumber: productData?.modelNumber,
        modelName: productData?.modelName,
        description: productData?.description,
        additionalAttributes: productData?.additionalAttributes,
        thumbnails : productData?.thumbnails,
          price : productData?.price,

      }));
    }
  }
//   updateStateIfNeeded() {
//     const { productData } = this.props;
//     if (productData?.productId !== null) {
//         this.setState({ ...productData });
//     }
// }

  handleChange = (e, parentIndex) => {
    debugger;
    if (parentIndex !== undefined && parentIndex !== null) { // for handling additional attributes state
      this.setState(s => ({
        ...s, additionalAttributes: s.additionalAttributes.map((item, index) =>
          index === parentIndex ? { ...item, ["value"]: e.target.value } : item),
      }))
      return
    }
    this.setState({ [e.target.name]: e.target.value });
  };
  handleTextureFileChange = (e, index, file) => {
    debugger;
    if (index !== undefined && index !== null) {
      if (e.target.value.trim() === '') {
        // If the input field is empty, set the value to an empty string
        this.setState((s) => ({
          ...s,
          additionalAttributes: s.additionalAttributes.map((item, idx) =>
            idx === index ? { ...item, ["value"]: '' } : item
          ),
        }));
      } else {
        this.setState((s) => ({
          ...s,
          additionalAttributes: s.additionalAttributes.map((item, idx) =>
            idx === index ? { ...item, ["value"]: e.target.files[0].name } : item
          ),
        }));
      }
      this.setState({ textureFile: file });
      return;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    debugger;
    const { productId, title, sku, brand, modelNumber, modelName, description, additionalAttributes,price, thumbnails,textureFile, thumbnailFiles } = this.state;
    const inputElement = document.querySelector('input[name="title"]');
    if (inputElement.validity.valid === false) {
      return inputElement.reportValidity();
    }
    const formData = new FormData();
    formData.append("title", title);
    formData.append("brand", brand);
    formData.append("modelNumber", modelNumber);
    formData.append("SKU", sku);
    formData.append("modelName", modelName);
    formData.append("description", description);
    formData.append("additionalAttributes", JSON.stringify(additionalAttributes));
    thumbnailFiles.forEach((file, index) => {
      if (file) {
        formData.append(`thumbnail${index}`, file);
      }
    });
    formData.append("productId", productId);
    formData.append("price", price);
   
  if (textureFile !== undefined) {
    formData.append("textureFile", textureFile);
  } else {
    formData.append("textureFile", "");
  }
    updateProductforthumbnails(formData)
      .then((res) => {
        Swal.fire({
          icon: 'success',
          title: 'Success',
          text: 'Product details updated successfully!',
        }).then(() => {
          this.forceUpdate();
          this.props.isProductUpdate();
          this.props.isProductListUpdated();
          this.props.handleModal();
        });
          return;
      })
      .catch((e) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Error while updating product details!',
        });
        console.error(e);
      });
  };


  updateThumbnailFile = (index, file) => {
   debugger;
    this.setState((prevState) => {
      const thumbnailFiles = [...prevState.thumbnailFiles];
      thumbnailFiles[index] = file; // Update the thumbnailFiles array at the specified index with the new file
      return { thumbnailFiles };
    });
  };
  
  render() {

    const { children } = this.props;
    const { productId, title, sku, brand, modelNumber, modelName, description, additionalAttributes, price, thumbnails,thumbnailFiles} = this.state;
    const contextValue = {
      productId,
      title,
      sku,
      brand,
      modelNumber,
      modelName,
      description,
      additionalAttributes,
      thumbnails,
      thumbnailFiles,
      price,
      handleChange: this.handleChange,
      handleSubmit: this.handleSubmit,
      handleTextureFileChange : this.handleTextureFileChange ,
      updateThumbnailFile: this.updateThumbnailFile,
    };
    return <EditState.Provider value={contextValue}>{children}</EditState.Provider>;
  }
}

export default class ProductDetailsHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
  }
 
  handleModal = () => {
    debugger;
    this.setState(p => ({ ...p, showModal: !p.showModal }))
  }

  render() {
    const { getRole } = this.props;

    return (
      <>
        <EditStateContext
          productData={this.props.productData}
          isProductUpdate={this.props.isProductUpdate}
          isProductListUpdated={this.props.isProductListUpdated}
          showModal={this.state.showModal}
          handleModal={this.handleModal}
        >
          <Stack
            direction="horizontal"
            gap={3}
            className="list-dashboard-header"
          >
            <div className="custom-font-20 product-catalog">
              Product Details
            </div>
            {(getRole === "Owner" || getRole === "Catalog_Manager")  && (
              <div>
                <Button
                 onClick={this.handleModal} 
                // onClick={() => this.handleEditSingleUpload(this.props.productData?.productId)}
                className="tz-primary-btn">
                  Edit
                </Button>
              </div>
            )}

          </Stack>
          <Modal
          className ="tz-custom-modal"
            showModal={this.state.showModal}
            handleModal={this.handleModal}
            title={"Edit Product Details"}
            body={<ModalBody productData={this.props.productData} />}
            footer={<ModalFooter handleModal={this.handleModal} />}
          />
        </EditStateContext>
        
      </>
    );
  }
}

export class ModalBody extends Component {
  static contextType = EditState;
  
  constructor(props) {
    super(props);
    this.state = {
      selected: 0,
      selectedCluster: null,
      thumbnailFiles: [null, null, null , null],
    };
  }
  
  handleImageUpload = (event, index) => {
    const file = event.target.files[0];
    if (file) {
        const image = new Image();
        image.src = URL.createObjectURL(file);
        image.onload = () => {
            const naturalWidth = image.naturalWidth;
            const naturalHeight = image.naturalHeight;
            // width >=747 && height >= 747
            if (naturalWidth >= 747 && naturalHeight >= 747) {
              this.context.updateThumbnailFile(index, file);
          }
           else {
                Swal.fire({
                    icon: 'error',
                    title: 'Image Size Error',
                    text: `Image size must be at least ${747}x${747} pixels.`,
                });
                event.target.value = '';
            }
        };
    }
};

  handleItem = (arg) => {
    this.setState(s => ({ ...s, selected: arg }))
  }
  handleClusterClick = (clusterName) => {
    this.setState((prevState) => ({
      selectedCluster: prevState.selectedCluster === clusterName ? null : clusterName,
    }));
  };
  render() {
    const { title, sku, brand, modelNumber, modelName, description, price,handleChange,handleTextureFileChange, handleSubmit, additionalAttributes ,thumbnails ,thumbnailFiles} = this.context
    const extractNumbersFromUrl = (url) => {
      // Check if the url is null or undefined
      if (!url) {
          return null;
      }
      const match = url.match(/_(\d+|mandatory)_/);
      return match ? match[1] : null;
  };

    const matchedThumbnails = [null, null, null, null];

    if (thumbnails && thumbnails.length > 0) {
      thumbnails.forEach((thumbnail) => {
          if (thumbnail) {
              const extractedValue = extractNumbersFromUrl(thumbnail.fileUrl);
              const index = extractedValue === 'mandatory' ? 1 : parseInt(extractedValue);
              matchedThumbnails[index] = thumbnail;
          }
      });
  }
  

    return (
      <>
      <Container fluid>
      <Row >
      <Col md={2} >
        <form onSubmit={handleSubmit}>
        <div>
                  {[1, 2, 3 , 4].map((index) => {
                    const thumbnail = matchedThumbnails[index];
                    const imageSrc = thumbnailFiles[index] ? URL.createObjectURL(thumbnailFiles[index]) : (thumbnail ? thumbnail.fileUrl : null);
                    return (
                      <Card key={index} className="custom-image-card" style={{ width: "100%", height: "150px" }}>
                        <div className='file-select-center'>
                          {imageSrc ? (
                            <img
                              src={imageSrc}
                              alt={`Thumbnail ${index}`}
                              style={{ maxWidth : "100%", maxHeight: "75%", padding: "10px" }}
                            />
                          ) : (
                            <label htmlFor={`image${index}File`} className="file-upload-label">
                              <FaUpload size={30} className="upload-icon" />
                              <span>Upload Image {index}</span>
                            </label>
                          )}
                          <input
                            type="file"
                            id={`image${index}File`}
                            accept=".jpg,.png,.svg"
                            onChange={(event) => this.handleImageUpload(event, index)}
                            style={{ width: "100%", maxWidth: "200px" }}
                          />
                        </div>
                      </Card>
                    );
                  })}
                </div>
        </form>
        </Col>
        <Col md={10} >
        <form onSubmit={handleSubmit}>
          <div>
          <div style={{ marginBottom: '1rem' }} className="tz-modal-section title">
            <p>Product</p>
            <div className="tz-modal-section-content">
              <p>Product Title</p>
              <input required name='title' onChange={handleChange} type='text' bled placeholder="Product title"  value={title} />
            </div>
          </div>

          <div className="tz-modal-section title">
            <p>Product Attributes</p>
            <div className="tz-modal-menu-selection">
              <div className="tz-modal-menu">
                <p className="tz-modal-menu-title">Cluster</p>
                <p onClick={() => this.handleItem(0)} className={this.state.selected === 0 ? "tz-modal-menu-selected" : ''}>Mandatory Specifications <img src={arrowRight} alt="arrow right" /></p>
                {additionalAttributes?.length > 0 && <p onClick={() => this.handleItem(1)} className={this.state.selected === 1 ? "tz-modal-menu-selected" : ''} > Feature <img src={arrowRight} alt="arrow right" /></p>}
              </div>
              <div className="tz-modal-menu-item-content">
                <p className="uniq">Attributes With Value</p>
                {
                  this.state.selected === 0 ?
                    <section>
                      <div>
                        <p>Title</p>
                        <input required name='title'  onChange={handleChange} type='text'  value={title} />
                      </div>

                      <div>
                        <p>SKU</p>
                        <input name='sku'  onChange={handleChange} type='text'  value={sku} />
                      </div>

                      <div>
                        <p>Brand</p>
                        <input name='brand'  onChange={handleChange} type='text'  value={brand} />
                      </div>
                      <div>
                        <p>Price</p>
                        <input name='price'  onChange={handleChange} type='text'  value={price} />
                      </div>
                      <div>
                        <p>Model Number</p>
                        <input name='modelNumber'  onChange={handleChange} type='text'  value={modelNumber} />
                      </div>

                      <div>
                        <p>Model Name</p>
                        <input name='modelName'  onChange={handleChange} type='text'  value={modelName} />
                      </div>
                    </section>
                    :
                    <AdditionalAttr />
                }

              </div>
            </div>
          </div>

          <div className="tz-modal-section title" >
            <p>Product Description</p> 
            <div>
              <p>
                <textarea className="tz-modal-padded-textbox tz-modal-no-border-textbox"   name='description' onChange={handleChange} type='text'>{description}</textarea>
              </p>
            </div>
          </div>
          </div>
          
        </form>
        </Col>
      </Row>
      </Container>
      </>
    );
  }
  }

export class ModalFooter extends Component {
  static contextType = EditState;
  render() {
    const { handleModal } = this.props;
    const { handleSubmit } = this.context;

    return (
      <>
        <Button variant="secondary" className="tz-primary-btn reverse" onClick={handleModal}>
          Cancel
        </Button>

        <Button type='submit' variant="secondary" className="tz-primary-btn" onClick={handleSubmit}>
          Save Changes
        </Button>
      </>
    );
  }
}

class AdditionalAttr extends Component {
  static contextType = EditState;
  render() {
    debugger;
    const { additionalAttributes, handleChange ,handleTextureFileChange } = this.context;

    return (
      <>
            <section>
          {additionalAttributes?.length > 0 &&
            additionalAttributes?.map((attr, index) => (
              <div key={index}>
            {attr.clusterName === "Color" ? (
  <>
    <>
      <p>{attr.name}</p>
      <input
        type="text"
        name={attr.name}
        value={attr.value || ""}
        onChange={(e) => handleChange(e, index)}
         className="tz-color-preview"
        style={{ backgroundColor: attr.value || "transparent" , color :"gray" }}
      />
    </>
  </>
  ) : attr.clusterName === "Texture" ? (
    // Add the condition for "Texture"
    attr.name === "Texture Name" ? (
      <>
      <p>{attr.name}</p>
      <input
        type="text"
        name={attr.name}
        value={attr.value || ""}
        onChange={(e) => handleChange(e, index)}
        className="attribute-input"
        placeholder={`${attr.name}`}
      />
    </>
    ) : (
      <>
        <>
      <p>{attr.name}</p>
      <input
          type="file"
          name={attr.name}
          className="attribute-input"
          accept=".jpg, .png, .svg"
          onChange={(e) => handleTextureFileChange(e,index , e.target.files[0])}
        />
    </>
      </>
    )
) : (
  < >
    <p>{attr.name}</p>
    <input
      name={attr.name}
      onChange={(e) => handleChange(e, index)}
      type="text"
      value={attr.value || ""}
    />
  </>
)}

              </div>
            ))}
        </section>
      </>
    );
  }
}