import React, { Component } from 'react';
import "./Productattribute.css";
import "./ProductCluster.css";
import { Form, Button } from "react-bootstrap";
import Swal from 'sweetalert2';
import { Grid } from '@material-ui/core';
import Loader from "../../context/loader";
import Banner from "../../assests/public/banner1.jpg";
import Refresh from "../../assests/public/refresh.png";
import { AddGenAi} from "../../services/ProductsService";
class GenerateAi extends Component {
  constructor(props) {
    super(props);
    const roleData = localStorage.getItem('role') || {};

    const { clientId, email } = roleData;
    this.state = {
      generatedImage: null,
      searchQuery: '',
      isLoading: false,
      clientId: clientId || '',
      userId: email || '',
      acknowledgementChecked: true,
    };
  }
  trackSearchQuery = async (searchQuery) => {
  const treziAccessToken = localStorage.getItem('treziAccessToken');
  
  if (!treziAccessToken) {
    console.error('No treziAccessToken found in localStorage.');
    return;
  }

  try {
    // const response = await fetch(`https://lensservice.trezi.com/treziservice/api/v1/capturesearchdata?searchedData=${searchQuery}`, {
       const response = await fetch(`http://125.63.103.147:8080/treziservice-uus/api/v1/capturesearchdata?searchedData=${searchQuery}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${treziAccessToken}`,
      },
    });

    if (response.ok) {
      console.log('Search query tracked successfully.');
      this.generateImage();
    } else {
      console.error('Error tracking search query:', response.status, response.statusText);
    }
  } catch (error) {
    console.error('Error tracking search query:', error.message);
  }
};

  generateImage = async () => {
    const { searchQuery , acknowledgementChecked   } = this.state;
    const {  clientId, userId } = this.state;
    const form = new FormData();
    form.append('prompt', searchQuery);
    if (!acknowledgementChecked) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please acknowledge the disclaimer before generating the image.',
      });
      return;
    }
          if(searchQuery === ""){
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Input field cannot be empty.',
              });
              return;
          }
    try {
      debugger;
        this.showLoader();

      const response = await fetch('https://clipdrop-api.co/text-to-image/v1', {
        method: 'POST',
        headers: {
          'x-api-key': '87e78f7b2daba208bdd9101d826f9509f3dedf9c5a4bb9a12944d819c20be943227753a8118a3217768a91f33d4661de',
        },
        body: form,
      });

      if (response.status === 200) {
        const buffer = await response.arrayBuffer();
        const generatedImage = new Uint8Array(buffer);
        const base64Image = this.convertToBase64(generatedImage);
        this.hitAddGenAi(userId,clientId);
        this.setState({ generatedImage: base64Image });
      } else {
        throw new Error('Image generation failed.');
      }
    } catch (error) {
      console.error('Error generating image:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while generating the image.',
      });
    }finally {
        this.hideLoader();
      }
  };
  componentDidMount() {
    // Fetch all attributes when the component mounts
    document.title = 'Generate-AI-Image'; 
  }
  hitAddGenAi = async ( userId,clientId) => {
    try {
      debugger;
      const response = await AddGenAi( userId, clientId);
      if (response.status === 200) {
console.log("sfwsfaf");
return;
      } else {
        throw new Error('AddGenAi API call failed.');
      }
    } catch (error) {
      console.error('Error calling AddGenAi API:', error);
    return;
    }
  };
  convertToBase64 = (arrayBuffer) => {
    const bytes = new Uint8Array(arrayBuffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  };

  handleSearchQueryChange = (event) => {
    this.setState({ searchQuery: event.target.value });
  };

  showLoader = () => {
    this.setState({ isLoading: true });
  };

  hideLoader = () => {
    this.setState({ isLoading: false });
  };
  handleAcknowledgementChange = (event) => {
    this.setState({ acknowledgementChecked: event.target.checked });
  };


  refreshImage = () => {
    const { searchQuery } = this.state;
    if (searchQuery.trim() !== '') {
      this.generateImage();
    }
  };
clearText=()=>{
    this.setState({ searchQuery: '' });
}
  render() {
    const { generatedImage, searchQuery ,isLoading , acknowledgementChecked } = this.state;
  
    return (
      <div className="big-container custom-small-size-styles">
        <div className='tz-heading'>Generate AI Image</div>
        <div className="Gen-table-container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={9}>
              <Form> 
              <Form.Label >Input Your Command here.......</Form.Label>
                <Form.Group controlId="imageGeneration">
                  <Form.Control
                    type="text"
                    placeholder="Enter text"
                    value={searchQuery}
                    onChange={this.handleSearchQueryChange}
                  />
                </Form.Group>
              </Form>
            </Grid>
            <Grid item xs={12} sm={2}>
              <div>
                <Button className='clear-gen-tz-button'  onClick={(e) =>
                      this.trackSearchQuery(searchQuery) } >Generate Image</Button>
              </div>
            </Grid>
            <Grid item xs={12} sm={1}>
            <div >
            <Button  className='gen-tz-button reverse btn btn-secondary'   onClick={this.clearText}>Clear</Button>
          </div>
            </Grid>
          </Grid>
          
          <div className="gen-cluster-container mt-4">
            <div>
            <img src={Refresh} alt="Default Image" width={12} onClick={this.refreshImage}/>
            </div>
            {isLoading ? (
              <div className="loader-container">
                <Loader animation="border" variant="primary" />
              </div>
            ) : (
              generatedImage ? (
                <div className="generated-image">
                  <img src={`data:image/jpeg;base64,${generatedImage}`} width={600} height={500} alt="Generated Image" />
                </div>
              ) : (
                <div className="default-image">
                  <img src={Banner} alt="Default Image" width={600} height={500}/>
                </div>
              )
            )}
          </div>
          
          <div className="acknowledgement-checkbox">
            <input
              type="checkbox"
              checked={acknowledgementChecked}
              onChange={this.handleAcknowledgementChange}
            />
            <label className='ml-1'>
            I acknowledge that the results produced by 'Generate AI Image' through artificial intelligence will be used in accordance with applicable laws and regulations.
            </label>
          </div>
        </div>
      </div>
    );
  }
}

export default GenerateAi;
