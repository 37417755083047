import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { submitBrandLogo , submitProfilePicture, submitShortcode ,getBrandLogo,getProfilePicture, getShortcode} from "../services/ProductsService";
import "./financeportal.css";
import {
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { Form , Button } from "react-bootstrap";
import Avatar from '@material-ui/core/Avatar';
import Swal from 'sweetalert2';
import axios from "axios";
import { baseUrlUser } from "../utils/common";
import {
  Stack
} from "react-bootstrap";
import defaultImage from "../assests/public/Upload File (2).png"

const urlUser = baseUrlUser;

const FinanceProfile = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [position, setPosition] = useState("");
  const [org, setOrg] = useState("");
  const [pinCode, setPincode] = useState("");
  const [error, setError] = useState("");
  const [profileData, setProfileData] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [role, setRole] = useState("");
  const [shortcode,setShortcode] =useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [selectedBrandLogo, setSelectedBrandLogo] = useState(null);
  const [brandLogoData, setBrandLogoData] = useState(null);
  const [profilePictureData, setProfilePictureData] = useState({});
  const [shortcodeData, setShortcodeData] = useState({});

  useEffect(() => {
    document.title = 'My Profile'; // Set the desired page title
  }, []);

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }

    return "";
  };
  const handleProfilePictureChange = (e) => {
    const pictureFile = e.target.files[0];
    setProfilePicture(pictureFile);
  };
  useEffect(() => {
    const fetchProfileData = async () => {
      debugger;
      try {
        const jwt = getCookie('treziAccessToken'); // Retrieve JWT token from cookies
        console.log(jwt);
        if (!jwt) {
          console.error('JWT token not found in cookies');
          return;
        }
  
        const response = await fetch( `${urlUser}users/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`,
          },
        });
  
        if (response.ok) {
          const data = await response.json();
          setProfileData(data);
          console.log(data);
        }  else if(response.status === 401){
          Swal.fire({
            title: 'Unauthorized!',
            text: 'Jwt token Expired. Please Login again.',
            icon: 'warning',
            showConfirmButton: false,
            timer: 2000 // 2 seconds
          }).then(() => {
            window.location.href = "/";
          });
        } else {
          throw new Error('Request failed with status ' + response.status);
        }
      } catch (error) {
      if(error.response.status === 401){

      }else{
        
      }
      }
    };
  
    fetchProfileData();
  }, []);
  
  const handleBrandLogoChange = (e) => {
    const logoFile = e.target.files[0];
    if (!logoFile) {
      return;
    }
     const image = new Image();
    image.src = URL.createObjectURL(logoFile);
    image.onload = () => {
      const minWidth = 100;
      const maxWidth = 230;
      const minHeight = 40;
      const maxHeight = 60;
     if (
        image.width >= minWidth &&
        image.width <= maxWidth &&
        image.height >= minHeight &&
        image.height <= maxHeight
      ) {
        setSelectedBrandLogo(logoFile);
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Invalid Brand Logo Dimensions',
          text: 'Brand logo dimensions must be between 154x40 and 230x60 pixels.',
        });
        setSelectedBrandLogo(null);
      }
    };
  };
  
  
  const handleBrandLogoClick = () => {
    document.getElementById('brandLogoInput').click();
  };
  const handleSaveDetails = async () => {
    try {
      debugger;
      const actions = [];
      if (profilePicture ) {
        const profilePictureResponse = await submitProfilePicture(profilePicture);
        if (profilePictureResponse.status === 200) {
          actions.push('Profile Picture Uploaded Successfully');
          setShortcode(shortcode); 
        }
      }
     if (selectedBrandLogo) {
        const brandLogoResponse = await submitBrandLogo(selectedBrandLogo);
        if (brandLogoResponse.status === 200) {
          actions.push('Brand Logo Uploaded Successfully');
          setSelectedBrandLogo("");
          setShortcode(shortcode); 
        }
      }
     if (!shortcode) {
      if (profileData && profileData.orgIdentifier) {
        setShortcode(shortcode); 
      } else {
        actions.push('Shortcode is required'); // Add an error message
      }
      } else {
        const shortcodeResponse = await submitShortcode(shortcode);
        if (shortcodeResponse.status === 200) {
          actions.push('Shortcode Submitted Successfully');
          setShortcode(shortcode); 
        } 
      }
  
      if (actions.length > 0) {
        const organizationIdentifier = getCookie('orgIdentifier');
        if (shortcode !== organizationIdentifier) {
          // Update the cookie with the new shortcode
          document.cookie = `organizationIdentifier=${shortcode}`;
        }
        Swal.fire({
          icon: actions.every(action => action.includes('Successfully')) ? 'success' : 'error',
          title: actions.join('\n'),
          showConfirmButton: false,
          timer: 1500, // Close the alert after 1.5 seconds
        });
        fetchDataFromAPIs();
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while submitting data',
      });
    }
  };
  const fetchDataFromAPIs = async () => {
    try {
      debugger;
      const brandLogoResponse = await getBrandLogo();
      if (brandLogoResponse) {
        const brandLogoData =  brandLogoResponse.data;
        const brandLogoUrl = brandLogoResponse.data.fileUrl;
        if (brandLogoUrl) {
          setBrandLogoData(brandLogoUrl); // Set the URL if available
        } else {
          setBrandLogoData(null); // Set to null if URL is not available
        }
      }
      console.log('All data fetched successfully');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }; 
  const fetchDataFromAPIshortcode = async () => {
    try {
      debugger;
      const shortcodeResponse = await getShortcode();
      if (shortcodeResponse.status === 200) {
        const shortcodeData = shortcodeResponse.data.organizationIdentifier;
        // const shortcodeData = shortcodeResponse.data;
        console.log(shortcodeData,"shortcodeData")
        setShortcode(shortcodeData);
      }
      console.log('All data fetched successfully');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }; 
  const fetchDataProfilepicture = async () => {
    try {
      debugger;
      const profilePictureResponse = await getProfilePicture();
      if (profilePictureResponse) {
        const profilePictureData =  profilePictureResponse.data;
   console.log(profilePictureData,"profilePictureData")
   setProfilePictureData(profilePictureData.fileUrl);
      }
      console.log('All data fetched successfully');
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  useEffect(() => {
    fetchDataFromAPIs();
    fetchDataFromAPIshortcode();
    fetchDataProfilepicture();
  }, []);
  useEffect(() => {
    const { email, firstName, lastName, phone, city, addressLine1, addressLine2, pinCode, state, country,org ,position , role , shortcode} = profileData;
    setFirstName(firstName);
    setLastName(lastName);
    setEmail(email);
    setPhone(phone);
    setCity(city);
    setAddressLine1(addressLine1);
    setAddressLine2(addressLine2);
    setPincode(pinCode);
    setState(state);
    setCountry(country);
    setPosition(position);
    setOrg(org);
    setShortcode(shortcode)
  }, [profileData]);


  const handlePincodeChange = (e) => {
    const pinCode = e.target.value;
    const sanitizedPincode = pinCode.replace(/\D/g, "");
    setPincode(sanitizedPincode);
    debugger;
    fetch(`https://api.postalpincode.in/pincode/${sanitizedPincode}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        if (data && data.length > 0) {
          const { Name, State, Country,Block } = data[0].PostOffice[0];
          setCity(Block);
          setState(State);
          setCountry(Country);
        } else {
          console.log("No data found for the given pincode.");
        }
      })
      .catch((error) => {
        console.log("An error occurred:", error.message);
      });
  };
  const validatePincode = () => {
    if (pinCode.length !== 6) {
    //   return "Pincode must be exactly 6 digits.";
    }
    return "";
  };

  const updateUserProfile = async () => {
    try {
      const jwt = getCookie('treziAccessToken'); // Retrieve JWT token from cookies
      console.log(jwt);
      debugger;
      if (!jwt) {
        console.error('JWT token not found in cookies');
        return;
      }
     const userData = {
        // email: email,
        firstName: firstName,
        lastName: lastName,
        // role: "Owner",
        phone: phone,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        country: country,
        pinCode: pinCode,
        state: state,
        position :position
      };
  
      const response = await fetch( `${urlUser}users/profile`, {
        method: 'PUT',
        headers: {
          // 'Cookie': `jwt=${jwt}`,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(userData),
      });
  
      if (response.ok) {
        // Handle success, display a success message to the user
        console.log('Profile updated successfully');
      } else {
        throw new Error('Request failed with status ' + response.status);
      }
    } catch (error) {
      console.log(error);
      // Handle the error, display an appropriate message to the user
    }
  };
  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");
    setPhone(sanitizedPhoneNumber);
  };

  const validatePhoneNumber = () => {
    if (phone.length !== 10) {
      return;
    }
    return "";
  };

  useEffect(() => {
    if (!isEditMode) {
      updateUserProfile();
    }
  }, [isEditMode]);

  const isAdmin = profileData.position === "Catalog_Manager";
  return (
    <div className="tz-product-catalog-container">
       <div className="">{/* Center the text */}
        {/* <Typography style={{ fontSize: "16px", fontWeight: "bold" }} component="h1" variant="h5">
          "Hello, {firstName}"
        </Typography> */}
        <Stack
            direction="horizontal"
            gap={3}
            className="list-dashboard-header"
          >
            <div className="custom-font-20 product-catalog">
            {`Hello, ${firstName}`}
            </div>
            </Stack>
    
    </div>
    <div className="box3">
    <div className="row">
      <div className="col-12">
      <Typography style={{ fontSize: "16px", fontWeight: "bold"}} component="h1" variant="h5">
          My Profile
          </Typography>
        <div className="mt-2">
        {isAdmin ? (
                // Display the profile picture without edit options for admin
                <Avatar
                  src={profilePicture ? URL.createObjectURL(profilePicture) : profilePictureData}
                  alt="Profile Picture"
                  style={{ width: '80px', height: '80px', marginRight: '20px' }}
                />
              ) : (
                // Display the profile picture with edit option for non-admin
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleProfilePictureChange}
                    style={{ display: 'none' }}
                    id="profilePictureInput"
                  />
                  <label htmlFor="profilePictureInput">
                    <Avatar
                      src={profilePicture ? URL.createObjectURL(profilePicture) : profilePictureData}
                      alt="Profile Picture"
                      style={{ width: '80px', height: '80px', marginRight: '20px', cursor: 'pointer' }}
                    />
                  </label>
                </div>
              )}

          <div className="line mt-2"></div>
        <div style={{ marginTop: "10px" }}>
          <Typography style={{ fontSize: "16px", fontWeight: "bold"}} component="h1" variant="h5">
  Brand Info
</Typography>
<Grid container spacing={2}>
  <Grid item xs={12} sm={6}>
  <label style={{ color :"gray"}}>Brand Logo(File size 230*60 pixels)</label>
    {isAdmin ? (
      // Display the brand logo without edit options for admin
      <div
        style={{
          height: '70px',
          border: '1px solid #ccc',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
         
        }}
      >
       {brandLogoData ? (
    // Display the brand logo if brandLogoData is not null
    <img
      src={brandLogoData}
      alt="Brand Logo"
      style={{ maxWidth: '100%', maxHeight: '100%', marginRight: '20px' }}
    />
  ) : (
      <img
    src={defaultImage}
    alt="Brand Logo"
    style={{ maxWidth: '100%', maxHeight: '50%', marginRight: '20px'}}
  />
  )}
      </div>
    ) : (
      // Display the brand logo with edit option for non-admin
      <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
        <div
          style={{
            width: "40vw", // Adjust the width as needed
            height: '70px',
            border: '1px solid #ccc',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {selectedBrandLogo ? (
            // Show a preview of the selected image
            <img
              src={URL.createObjectURL(selectedBrandLogo)}
              alt="Brand Logo"
              style={{ maxWidth: '100%', maxHeight: '100%', marginRight: '20px', cursor: 'pointer' }}
              onClick={handleBrandLogoClick}
            />
          ) : (
            <div
  style={{
    height: '70px',
    // border: '1px solid #ccc',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor :"pointer"
  }}
>
  {brandLogoData ? (
    // Display the brand logo if brandLogoData is not null
    <img
      src={brandLogoData}
      alt="Brand Logo"
      style={{ maxWidth: '100%', maxHeight: '100%', marginRight: '20px',  cursor :"pointer" }}
        onClick={handleBrandLogoClick}
    />
  ) : (
      <img
    src={defaultImage}
    alt="Brand Logo"
    style={{ maxWidth: '100%', maxHeight: '50%', marginRight: '20px' ,  cursor :"pointer"}}
    onClick={handleBrandLogoClick}
  />
  )}
</div>
          )}
          <input
            type="file"
            accept="image/*"
            onChange={handleBrandLogoChange}
            style={{ display: 'none' ,  cursor :"pointer"}}
            id="brandLogoInput"
          />
        </div>
      </div>
    )}
  </Grid>

                  <Grid item xs={12} sm={6}>
  {/* Shortcode input field */}
  <label style={{ color :"gray"}}>Shortcode(Upto 8 characters)</label>
  <div className="row" style={{ display: '', alignItems: 'center' }}>
        <div className="col-md-10"  >
          <input
          className="user-inputbox"
            type="text"
            id="shortCode"
            value={shortcode}
      onChange={(e) => {
        if (!isAdmin) {
          const inputValue = e.target.value.slice(0, 8);
          setShortcode(inputValue);
        }
      }}
      disabled={isAdmin}
          />
        </div>
       <div className="col-md-2">
       {!isAdmin && (
      <Button
      className="tz-save-button"
        variant="primary"
        color="primary"
        onClick={handleSaveDetails}
      >
        Save
      </Button>
    )}
       </div>
      </div>
</Grid>

                </Grid>
        </div>
        <div className="line mt-2"></div>
        <div className="mt-2" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
  <Typography style={{ fontSize: "16px", fontWeight: "bold"}} component="h1" variant="h5">
    Basic Info
  </Typography>
  <Button
   variant="primary"
   color="primary"
    onClick={() => setIsEditMode(!isEditMode)}
  >
    {isEditMode ? "Save Info" : "Edit Info"}
  </Button>
</div>
 <form >

                <Grid container spacing={2} >
                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink>First Name*</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      autoFocus
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      size="small"
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink>Last Name*</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      size="small"
                      disabled={!isEditMode}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink>Email Address*</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      size="small"
                      disabled
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink>Phone*</InputLabel>
                    <TextField
                      type="tel"
                      fullWidth
                      variant="outlined"
                      value={phone}
                       onChange={handlePhoneChange}
                      size="small"
                      inputProps={{
                        maxLength: 10,
                      }}
                      disabled={!isEditMode}
                    //  error={phone.length !== 10}
                    //  helperText={validatePhoneNumber()}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} >
                    <InputLabel shrink >Position</InputLabel>
                    <TextField fullWidth
                      value={position}
                      onChange={(e) => setPosition(e.target.value)}
                      variant="outlined"
                      size="small"
                      disabled={!isEditMode} />
                  </Grid>
                  <Grid item xs={12} sm={6} >
                    <InputLabel shrink >Organization</InputLabel>
                    <TextField fullWidth
                      value={org}
                      onChange={(e) => setOrg(e.target.value)}
                      variant="outlined"
                      size="small"  disabled={!isEditMode}/>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink>City</InputLabel>
                    <FormControl fullWidth variant="outlined">
                      <TextField
                        value={city} // Set the value of the TextField to the city state variable
                        variant="outlined"
                        style={{ height: '45px' }}
                        size="small"
                        disabled
                      />
                    </FormControl>
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink>Pincode</InputLabel>
                    <TextField
                      fullWidth
                      variant="outlined"
                      value={pinCode}
                       onChange={handlePincodeChange}
                      size="small"
                      inputProps={{
                        maxLength: 6,
                      }}
                      disabled={!isEditMode}
                    //  error={pincode.length !== 6}
                    //  helperText={validatePincode()}
                    />
                  </Grid>


                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink>State</InputLabel>
                    <TextField
                      fullWidth
                      value={state}
                      variant="outlined"
                      size="small"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <InputLabel shrink>Country</InputLabel>
                    <TextField
                      fullWidth
                      value={country}
                      variant="outlined"
                      size="small"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <InputLabel shrink >Address Line 1*</InputLabel>
                    <TextField fullWidth
                      value={addressLine1}
                      onChange={(e) => setAddressLine1(e.target.value)}
                      variant="outlined"
                      size="small"
                      disabled={!isEditMode} />
                  </Grid>
                  <Grid item xs={12} >
                    <InputLabel shrink >Address Line 2*</InputLabel>
                    <TextField fullWidth
                      value={addressLine2}
                      onChange={(e) => setAddressLine2(e.target.value)}
                      variant="outlined"
                      size="small"  disabled={!isEditMode}/>
                  </Grid>

                </Grid>
                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
              {/* <Button variant="contained" color="primary" style={{ marginRight: "10px" }}>
                Save Changes
              </Button>
              <Button variant="contained" color="default">
                Cancel
              </Button> */}
            </div>
              </form>
</div>
            </div>

          </div>
        </div>
   </div>
  );
};


export default FinanceProfile;