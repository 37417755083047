import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import LogoLink from "../assests/public/login-bg.png";
import logolink2 from "../assests/public/trezi_logo.png"
import "./loginstyle.css";
import { Row, Col, Navbar } from "react-bootstrap";
import { styled } from '@mui/material/styles';
import { Container, Box, Typography, TextField, Button, FormControl, InputAdornment, IconButton ,InputLabel ,Grid } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { baseUrlUser } from "../utils/common";
import { getCartValue,} from "../services/ProductsService";

const url = baseUrlUser;
const getCookieValue = (name) => {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(`${name}=`)) {
      // If it does, return the substring after the name
      return cookie.substring(name.length + 1);
    }
  }
  return null;
};


const Login = ({ checkAuthentication } ) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const fetchAllCartItems = async () => {
    try {
      const logs = await getCartValue();
      // Update state or perform any other necessary actions with the cart data
      console.log(logs, "sadasdlogdata");
      this.props.updatedCartData(logs.data);
    } catch (err) {
      console.log(err);
    }
  };
  const navigate = useNavigate();
  useEffect(() => {
    document.title = 'Login Page'; // Set the desired page title
  }, []);

  useEffect(() => {
    const fetchData = async () => {
    };
    fetchData();
  }, []);
 
  const handleSubmit = async (e) => {
    debugger;
    e.preventDefault();
    setError("");
  debugger;
    try {
      const response = await fetch(`${url}users/login/product`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Product' :'Showcase'
        },
        credentials: 'include',
        body: JSON.stringify({
          email: email.toLowerCase() ,
          password: password,
        })
      });
  
    
      if (!response.ok) {
        if (response.status === 400) {
          setError("Invalid Login");
        } else if (response.status === 403) {
          setError("User is currently inactive.");
        } else if (response.status === 404) { // Handle the case for unregistered email
          setError("Sorry, we couldn't find an account with that email address.");
        } else {
          throw new Error("Please check login details again");
        }
        return;
      }
  
      const data = await response.json();
      const treziAccessToken = data.treziAccessToken; // Assuming the JWT token is returned as 'jwt' in the response 
      const orgIdentifier = data.orgIdentifier; // Assuming the JWT token is returned as 'jwt' in the response 
      const role = data.role; 
      if (!treziAccessToken) {
        setError("Invalid Login and Password");
        return;
      }
      const organizationIdentifier = getCookieValue('orgIdentifier');
      localStorage.setItem('email', email);
      localStorage.setItem('treziAccessToken', treziAccessToken);
      localStorage.setItem('organizationIdentifier', organizationIdentifier);
      localStorage.setItem('role', role);
      document.cookie = `treziAccessToken=${treziAccessToken}; expires=${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString()}; path=/`;
      document.cookie = `orgIdentifier=${orgIdentifier}; expires=${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString()}; path=/`;
      document.cookie = `role=${role}; expires=${new Date(Date.now() + 7 * 24 * 60 * 60 * 1000).toUTCString()}; path=/`;
      if (data.role === "Finance_Manager") {
        checkAuthentication();
        navigate("/financeportal");
        return;
      } else if (data.role === "Owner" &&   data.isSubscriptionActive === false ) {
        navigate("/planbox");
      } else if (data.role === "Owner" && data.isSubscriptionActive === true) {
        checkAuthentication();
        navigate('/dashboard');
        console.log("login");
       }else if (data.role === "Catalog_Manager" && data.isSubscriptionActive === true ){
        checkAuthentication();
      navigate('/dashboard');
        } else if (data.role === "Catalog_Manager" && data.isSubscriptionActive === false ){
          setError(" “User not active. Please contact your owner.");
         return;
          }else if ( data.role === "Owner" && data.isSubscriptionActive === false ) {
        navigate("/paymentUnverified");
      } else if (data.role === "Customer_Success_Manager") {
        checkAuthentication();
        navigate("/CustomerSuccessTable");
      }else if (data.role === "Sales" && data.isSubscriptionActive === true  ) {
        checkAuthentication();
        navigate("/sales-product-catalog");
        return;
      }else if (data.role === "Sales" && data.isSubscriptionActive === false  ) {
        setError("User Not active ,Please Contact Your Owner.");
        return;
      } else if (data.role === "Dashboard" ){
        checkAuthentication();
        navigate("/trezi-dashboard");   
       }else {
        setError("Check Login Details");
        return;
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Invalid email or password");
      return;
    }
  };
  
  const toggleShowPassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  return (
    <div className="box8">
      <Grid container >
        <Grid item>
          <Box className="box mt-0 mb-0" p={4}>
          <div >
            <img src={logolink2} alt="Logo" style={{ width: "100px" }}  />
                    </div>
            <Typography variant="h5" className="mt-2" component="h1" gutterBottom>
              Please, Login to continue
            </Typography>
            {error && <span className="text-danger mt-2">- {error}</span>}
            <Box component="form" onSubmit={handleSubmit} mt={3}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                <InputLabel  shrink>Email Address*</InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      required
                      autoFocus
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                <InputLabel  shrink>Password*</InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      required
                      value={password}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={toggleShowPassword}>
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Typography variant="body2" align="start" className="mt-2 custom-link">
                <Link to="/forgetPassword" className="text-reset">
                  Forgot Password?
                </Link>
              </Typography>
              <Button className="mt-4" style={{ height: "55px", textTransform: "none" }} type="submit" variant="contained" fullWidth>
                Login Securely
              </Button>
              <Typography variant="subtitle2" align="start" mt={5} color="textSecondary">
                Don't have an account? <Link to="/signupboard">Sign Up</Link>
              </Typography>
              <Box mt={5}>
                <Typography variant="body2" align="start" className="mt-3 custom-link-anchor">
                  By logging into Trezi and its offerings, you agree to our <a href="https://trezi.com/terms-of-use/">Terms & Conditions</a> and <a href="https://trezi.com/privacy-policy/">Privacy Policy</a>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default Login;
