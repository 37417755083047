import React, { Component, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import "./PreviewModal.css";
import Logobluesofa from "../../assests/public/cover_page-01_720.png";
import Logoyellowsofa from "../../assests/public/cover_page-02_720.png";
import { useParams } from "react-router-dom";
import Flipbook from './Flipbook';

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

const PreviewModal = ({ show, onHide, image }) => {
debugger;
  return (
    <Modal show={show} onHide={onHide} centered dialogClassName="preview-modal" >
      <Modal.Header closeButton>
        <Modal.Title>FURNITURE TEMPLATE</Modal.Title>
      </Modal.Header>
      <Modal.Body className='preview-modal-body'>
        <div className="row ">
          <div className="col-md-12">
          <div className="flipbook-container">
                <Flipbook selectedImage={image} />
              </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PreviewModal;
