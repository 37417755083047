import React, { Component } from 'react';
import "./Productattribute.css";
import { Nav, Form, Dropdown, Button, Modal,Table } from "react-bootstrap";
import { Grid } from '@material-ui/core';
import { Edit as EditIcon, Delete as DeleteIcon, ArrowDownward } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Select from 'react-select';
import { Pagination } from "react-bootstrap";
import { baseUrl } from '../../utils/common';
import { createAttribute , getAllAttributes , getSingleAttribute ,updateProductAttribute ,deleteAttribute } from "../../services/ProductsService";
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate'; 
const url = baseUrl;
class ProductAttributePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attributes: [
      ],
      showModal: false,
      sortDirection: 'asc', // Initial sorting direction
      sortedBy: null,
      searchQuery: '',
      currentPage: 1,
      itemsPerPage: 9,
      name: '', // Attribute name
      variableType: '',
      label :'',
      isEdit: false, 
      filteredAttributes: [], 
    };
  }
  handleSearch = (event) => {
    const searchQuery = event.target.value;
    this.setState((prevState) => ({
      searchQuery,
      // Filter all attributes based on the search query and store in the state
      filteredAttributes: prevState.attributes.filter((attribute) =>
        attribute.name.toLowerCase().includes(searchQuery.toLowerCase())
      ),
      currentPage: 1, // Reset currentPage to the first page when performing a search
    }));
  };
  
  componentDidMount() {
    // Fetch all attributes when the component mounts
    this.fetchAllAttributes();
    document.title = 'Product Attribute'; 
  }

  fetchAllAttributes = () => {
    debugger;
    getAllAttributes()
      .then((data) => {
        if (data.response && data.response.status === 404) {
          console.log("Error 404: Customer categories not found.");
          this.setState({ clusters: [] });
        } else {
        // Update the state with the retrieved attributes
        this.setState({ attributes: data });
        }
      })
      .catch((error) => {
        if (error.message === 'Request failed with status code 409') {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Attribute creation failed. Attribute already exists.',
          });
        }
          else if(error.response.status === 401){
            Swal.fire({
              title: 'Unauthorized!',
              text: 'Jwt token Expired. Please Login again.',
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000 // 2 seconds
            }).then(() => {
              window.location.href = "/";
            });
          } else {
            throw new Error('Request failed with status ' + error.response.status);
          }
      });
  };

  handleEdit = async (label) => {
    debugger;
    try {
      console.log('Edit attribute with id:', label);
      const attribute = await getSingleAttribute(label);
      console.log('Attribute data:', attribute);
  
      // Check if clusters.clusterNames is empty
      const isClustersEmpty = attribute.clusters && attribute.clusters.clusterNames.length === 0;
  
      // Update the state with the fetched attribute data and set isEdit to true
      this.setState({
        showModal: true,
        name: attribute.name,
        oldName: attribute.name, // Store the old attribute name in the state
        variableType: attribute.variableType,
        editingAttributeId: attribute.label,
        isEdit: !isClustersEmpty,
        handleUpdateAttribute: this.handleUpdateAttribute,
      });
    } catch (error) {
      console.error('Error retrieving attribute:', error);
      // Handle the error condition if needed
    }
  };
  

  handleUpdateAttribute = async () => {
    const { name, oldName, editingAttributeId, variableType } = this.state;
    const oldLabel = name.replace(/\s+/g, '_').toLowerCase(); // Replace spaces with hyphen 
    try {
      if (!name || !variableType) {
        throw new Error('Please fill in all the fields.');
      }
      await updateProductAttribute(
        oldName, // Pass the old attribute name
        name, // Pass the new attribute name
        editingAttributeId,
        oldLabel,
        variableType
      );
      Swal.fire({
        icon: 'success',
        title: 'Attribute Updated',
        text: 'The attribute has been updated successfully.',
      });
      this.fetchAllAttributes();
      this.handleCloseModal(); // Close the modal
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'An error occurred while updating the attribute.',
      });
    }
  };
  

  handleDelete = (label) => {
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: 'This action cannot be undone. Are you sure you want to delete the attribute?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        deleteAttribute(label)
          .then((data) => {
            if (data.status === 200) {
              // Handle the successful delete, such as updating the attributes state or showing a success message
              console.log('Attribute deleted:', data);
              const updatedAttributes = this.state.attributes.filter(attribute => attribute.label !== label);
              this.setState({ attributes: updatedAttributes }, () => {
                if (this.state.currentPage > 1 && updatedAttributes.length <= ((this.state.currentPage - 1) * this.state.itemsPerPage)) {
                  this.handlePaginationClick(this.state.currentPage - 1);
                }
              });
              Swal.fire({
                icon: 'success',
                title: 'Attribute Deleted',
                text: 'The attribute has been deleted successfully.',
              });
              this.fetchAllAttributes(); // Refetch attributes after deletion
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Default Color Texture attribute cannot delete.',
              });
            }
          })
          .catch((error) => {
            // Handle errors
            console.error('Error deleting attribute:', error);
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'An error occurred while deleting the attribute.',
            });
          });
      }
    });
  };
  
  
  
  handleSaveAttribute = () => {
    debugger;
    const { name, variableType } = this.state; // Get the attribute details from the component state
    const enteredValue = name.replace(/\s+/g, '_').toLowerCase(); // Replace spaces with hyphen
    if (!name || !variableType) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Please fill in all the fields.',
      });
      return;
    }
    const attributeData = {
      name: name,
      label: enteredValue,
      variableType: variableType,
    };
    createAttribute(attributeData)
      .then((data) => {
        // Handle the successful response
        console.log('Attribute created:', data);
        this.setState({ 
          showModal: false,
          name: '', // Reset the attribute name
        variableType: '',  });
        Swal.fire({
          icon: 'success',
          title: 'Attribute Created Successfully',
          text: 'The attribute data has been submitted successfully.',
        });
        this.fetchAllAttributes();
      })
      .catch((error) => {
        if (error.message === 'Request failed with status code 409') {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Attribute creation failed. Attribute already exists.',
          });
        }
          else if(error.response.status === 401){
            Swal.fire({
              title: 'Unauthorized!',
              text: 'Jwt token Expired. Please Login again.',
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000 // 2 seconds
            }).then(() => {
              window.location.href = "/";
            });
          } else {
            throw new Error('Request failed with status ' + error.response.status);
          }
      });
  };

  handleCreateModal = () => {
    this.setState({ showModal: true });
  };

  handleCloseModal = () => {
    this.setState({
      showModal: false,
      name: '',
      label: '',
      variableType: '',
      isEdit :'',
      searchQuery :'',
    });
  };

  handleSort = (columnName) => {
    const { attributes, sortDirection } = this.state;
    const sortedAttributes = [...attributes].sort((a, b) => {
      if (columnName === 'variableType') {
        // Access the variableType property and compare its string value
        const aType = a[columnName];
        const bType = b[columnName];
        if (aType < bType) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (aType > bType) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      } else {
        // For other columns, use the existing sorting logic
        if (a[columnName] < b[columnName]) {
          return sortDirection === 'asc' ? -1 : 1;
        }
        if (a[columnName] > b[columnName]) {
          return sortDirection === 'asc' ? 1 : -1;
        }
        return 0;
      }
    });
  
    // Update the state with the sorted attributes and toggle the sort direction
    this.setState({
      attributes: sortedAttributes,
      sortDirection: sortDirection === 'asc' ? 'desc' : 'asc',
      sortedBy: columnName,
    });
  };
  
  
  handlePaginationClick = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    const { attributes, showModal, sortedBy, sortDirection, searchQuery, currentPage, itemsPerPage, } = this.state;
    const filteredAttributes = attributes.filter((attribute) =>
    attribute.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate the index of the last and first attribute based on currentPage
  const indexOfLastAttribute = currentPage * itemsPerPage;
  const indexOfFirstAttribute = indexOfLastAttribute - itemsPerPage;
  const currentAttributes = filteredAttributes.slice(indexOfFirstAttribute, indexOfLastAttribute);
    
  const  options = [
      {
        variableType: 'String',
        label: (
          <div>
            Text
            <div className="option-description">Input a text value</div>
          </div>
        ),
      },
      {
        variableType: 'Boolean',
        label: (
          <div>
            Boolean
            <div className="option-description">A value that has two possible values - Yes or No</div>
          </div>
        ),
      },
      {
        variableType: 'Integer',
        label: (
          <div>
            Integer
            <div className="option-description">Numeric value with no decimals </div>
          </div>
        ),
      },
      {
        variableType: 'Decimal',
        label: (
          <div>
            Decimal values
            <div className="option-description">A numerical value with support for decimals </div>
          </div>
        ),
      },
      {
        variableType: 'Date',
        label: (
          <div>
            Date
            <div className="option-description">Input a date value </div>
          </div>
        ),
      },
    ];
  
    return (
      <div className="big-container custom-small-size-styles ">
        <div className='tz-heading '>Product Attributes</div>
        <div className="attribute-table-container">
          <Grid container spacing={3}>
            <Grid item xs={12} sm={10}>
              <Form className="d-flex tz-attr-textbox-with-icon i tz-attr-search-textbox-container">
                <Form.Control
                  type="search"
                  placeholder="Search Attributes"
                  className="tz-textbox"
                  aria-label="Search"
                  value={this.state.searchQuery} // bind the searchQuery value to the input value
                  onChange={this.handleSearch}
                />
                <i className="bi bi-search"></i>
              </Form>
            </Grid>
            <Grid item xs={12} sm={2} >
              <div >
                <Button className='tz-button' style={{width :"140px"}} onClick={this.handleCreateModal} >Create New</Button>
              </div>
            </Grid>
          </Grid>
    
          <div className="product-attribute-table">
          <Table hover borderless  className="product-table">
            <thead className='table-head' >
              <tr>
                <th onClick={() => this.handleSort('name')} style={{ cursor: 'pointer' }}>
                  Attribute Name
                  {sortedBy === 'name' && (
                    <span className="sort-icon">
                      {sortDirection === 'asc' ? (
                        <KeyboardArrowDownIcon style={{ height: "0.8em" }} />
                      ) : (
                        <KeyboardArrowDownIcon style={{ height: "0.8em" }} />
                      )}
                    </span>
                  )}
                  {sortedBy !== 'name' && (
                    <span className="sort-icon">
                      <KeyboardArrowDownIcon
                        style={{ height: "0.8em" }}
                      />
                    </span>
                  )}
                </th>
                <th onClick={() => this.handleSort('variableType')} style={{ cursor: 'pointer' }}>
                  Attribute Type
                  {sortedBy === 'variableType' && (
                    <span className="sort-icon">
                      {sortDirection === 'asc' ? (
                        <KeyboardArrowDownIcon style={{ height: "0.8em" }} />
                      ) : (
                        <KeyboardArrowDownIcon style={{ height: "0.8em" }} />
                      )}
                    </span>
                  )}
                  {sortedBy !== 'variableType' && (
                    <span className="sort-icon">
                      <KeyboardArrowDownIcon
                        style={{ height: "0.8em" }}
                      />
                    </span>
                  )}
                </th>
                <th>Clusters</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody>
              {currentAttributes.map((attribute) => (
                <tr key={attribute.id}>
                  <td>{attribute.name}</td>
                  <td>{attribute.variableType}</td>
                  <td>
   { attribute.clusterNames && attribute.clusterNames.length > 0 ? attribute.clusterNames.map((name, index) => (
    <span key={index}>
      {name}
      {index !== attribute.clusterNames.length - 1 && ', '}
    </span>
  )) : '-'}
</td>
         <td>
                  {/* Edit icon */}
                  {attribute.clusterNames && attribute.clusterNames.length === 0 ? (
                    <EditIcon className="edit-icon"
                      onClick={() => this.handleEdit(attribute.label)}
                      // style={{ marginRight: 18, cursor: 'pointer' }}
                    >
                      <EditIcon />
                    </EditIcon>
                  ) : (
                    <EditIcon disabled >
                      <EditIcon />
                    </EditIcon>
                  )}
                </td>
                 
                  <td>
                  {/* Edit icon */}
                  {attribute.clusterNames && attribute.clusterNames.length === 0 ? (
                    <DeleteIcon className="edit-icon"
                      onClick={() => this.handleDelete(attribute.label)}
                      // style={{ marginRight: 18, cursor: 'pointer' }}
                    >
                      <DeleteIcon />
                    </DeleteIcon>
                  ) : (
                    <DeleteIcon disabled >
                      <DeleteIcon />
                    </DeleteIcon>
                  )}
                </td>
                </tr>
              ))}
            </tbody>
          </Table>
            </div>
          <div className="pagination">
            {/* <Pagination className="mt-0 custom-pagination">
              {Array.from(Array(Math.ceil(filteredAttributes.length / itemsPerPage)), (e, i) => (
                <Pagination.Item
                  key={i + 1}
                  active={i + 1 === currentPage}
                  onClick={() => this.handlePaginationClick(i + 1)}
                >
                  {i + 1}
                </Pagination.Item>
              ))}
            </Pagination> */}
          </div>
          <div className="pagination">
        <ReactPaginate
          pageCount={Math.ceil(filteredAttributes.length / itemsPerPage)}
          pageRangeDisplayed={5}
          marginPagesDisplayed={1}
          onPageChange={(selectedPage) => this.handlePaginationClick(selectedPage.selected + 1)}
          containerClassName="pagination"
          activeClassName="pagination-item-active"
        />
      </div>
        </div>

        {/* Modal codestarts from here !!!! */}
        {showModal && (
          <Modal show={showModal} onHide={this.handleCloseModal}  centered={true}
          backdrop="static"
          keyboard={false}
          dialogClassName="tz-my-modal"
          attribute={attributes} >
            <Modal.Header closeButton>
              <Modal.Title>{this.state.isEdit  ? 'Edit Attribute' : 'Create Attribute'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form >
              <Form.Group controlId="attributeType">
                <Form.Label className="tz-form-label mt-3">Select Attribute Type</Form.Label>
                <div className="tz-select-formcontrol select">
                <Select
  type="select"
  onChange={(selectedOption) => this.setState({ variableType: selectedOption.variableType })}
  value={this.state.variableType && options.find(option => option.variableType === this.state.variableType)}
  options={options} // Use the defined options array
  isSearchable={false}
  styles={{
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999, // Ensure the menu appears on top of other components
    }),
    menu: (base) => ({
      ...base,
      borderRadius: 0, // Customize the menu border radius, if needed
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? 'lightgray' : 'white', // Customize the background color of the selected option
      color: state.isFocused ? 'black' : 'inherit', // Customize the text color of the selected option
      cursor: 'pointer', // Show pointer cursor on hover
    }),
    control: (provided) => ({
      ...provided,
      minHeight: '50px',
      backgroundColor: 'white',
      borderRadius: '4px',
      boxShadow: 'none',
    }),
  }}
  fullWidth
/>
                </div>
              </Form.Group>

              <label></label>
              <Form.Group controlId="attributeName">
                <Form.Label className="tz-form-label mt-3" >Attribute Name</Form.Label>
                <Form.Control
                  className="tz-atr-form-control"
                  type="text"
                  placeholder="Enter attribute name"
                  value={this.state.name}
                  onChange={(event) => this.setState({ name: event.target.value })}
                />
              </Form.Group>
              {/* <Form.Group controlId="attributeLabel">
                <Form.Label className="tz-form-label mt-3">Attribute Label</Form.Label>
                <Form.Control className="tz-atr-form-control"
                  type="text"
                  value={this.state.name}
                  onChange={(event) => this.setState({ name: event.target.value })}
                  placeholder="Attribute Label" fullWidth />
              </Form.Group> */}
            </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className='tz-primary-btn reverse btn btn-secondary'  onClick={this.handleCloseModal}>
                Close
              </Button>
              <Button variant="primary" className="tz-primary-btn" onClick={this.state.isEdit  ? this.handleUpdateAttribute : this.handleSaveAttribute}>
                {this.state.isEdit ?  'Update' : 'Create'}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

      </div>
    );

  }
}

export default ProductAttributePage;
