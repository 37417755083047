import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import DashboardIndex from './DashboardIndex';

function LensSignedUPUsers() {
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      let allUsers = [];
      let page = 0;
      let totalPages = 1;
      // Fetch all pages
      while (page < totalPages) {
        const response = await axios.get(`https://showcaseapi.trezi.com/uus/api/v1/users/signup/list?product=Lens&page=${page}&pageSize=100`);
        allUsers = allUsers.concat(response.data.users);
        totalPages = response.data.meta.totalPages;
        page++;
      }
      allUsers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      setData(allUsers);
      setDisplayedData(allUsers.slice(currentPage * 10, (currentPage + 1) * 10));
      setMeta({
        totalPages: Math.ceil(allUsers.length / 40),
        hasPreviousPage: currentPage > 0,
        hasNextPage: currentPage  * 40 < allUsers.length
      });
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < meta.totalPages) {
      setCurrentPage(newPage);
      setDisplayedData(data.slice(newPage * 40, (newPage + 1) * 40));
    }
  };
  const exportToExcel = () => {
    const headers = ['Client ID', 'First Name', 'Last Name', 'Organization', 'Position', 'Phone', 'Address', 'Country', 'Subscription Date'];
    const worksheetData = [
      headers,
      ...data.map(user => [
        user.clientId || '',
        user.firstName || '',
        user.lastName || '',
        user.org || '',
        user.position || '',
        user.phone || '',
        `${user.addressLine1 || ''}${user.addressLine2 ? `, ${user.addressLine2}` : ''}, ${user.city || ''} ${user.state || ''} ${user.pinCode || ''}`,
        user.country || '',
        user.createdAt ? new Date(user.createdAt).toLocaleString() : ''
      ])
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Lens Signed Up Users');
    XLSX.writeFile(workbook, 'Lens_Signed_Up_Users.xlsx');
  };
  return (
    <div>
         <DashboardIndex/>
      <div className="App">
        {loading && <p>Loading data...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && (
          <div>
            <div id="pagination">
              <h2 id="heading" >Lens Signed Up Users</h2>
              <div id="button">
                <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
              </div>
      <div id="pagination_controls">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
    Previous
  </button>
  <span> Page {currentPage + 1} of {meta.totalPages} </span>
  <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === meta.totalPages - 1}>
    Next
  </button>
</div>
            </div>
            <div className="table_container">
              <table className="result-table">
                <thead>
                  <tr>
                    <th className="client-id">Client ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Organization</th>
                    <th>Position</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Country</th>
                    <th>Subscription Date</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedData.map((user) => (
                    <tr key={user.clientId}>
                      <td>{user.clientId || ''}</td>
                      <td>{user.firstName || ''}</td>
                      <td>{user.lastName || ''}</td>
                      <td>{user.org || ''}</td>
                      <td>{user.position || ''}</td>
                      <td>{user.phone || ''}</td>
                      <td>
                        {`${user.addressLine1 || ''}${user.addressLine2 ? `, ${user.addressLine2}` : ''}, ${user.city || ''} ${user.state || ''} ${user.pinCode || ''}`}
                      </td>
                      <td>{user.country || ''}</td>
                      <td>{user.createdAt ? new Date(user.createdAt).toLocaleString() : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
export default LensSignedUPUsers;