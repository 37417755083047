import React, { useRef, useEffect, useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';
import './OverLay.css';
import IconHeading from '../../assests/public/Frame 1 (4).png';
import defaultBackgroundImage from '../../assests/public/Frame 1 (5).png';
import defaultBackgroundImage2 from '../../assests/public/Group 3290 (2).png';
import downloadLogo from '../../assests/public/Group 3284.png';
import { Button } from 'react-bootstrap';
function OverLay() {
  const canvasContainerRef = useRef(null);
  const canvasRef = useRef(null);
  const [image1, setImage1] = useState(new Image());
  const [image2, setImage2] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [overlaySettings, setOverlaySettings] = useState({
    size: 100,
    rotation: 0,
    transparency: 1,
    offsetX: 0,
    offsetY: 0,
    flipHorizontal: 1,
  });
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    if (!image1 || !image1.complete) {
      const defaultImg = new Image();
      defaultImg.onload = () => {
        canvas.width = defaultImg.width;
        canvas.height = defaultImg.height;
        ctx.drawImage(defaultImg, 0, 0, canvas.width, canvas.height);
      };
      defaultImg.src = defaultBackgroundImage;
    } else {
      const aspectRatio = image1.height / image1.width;
      canvas.width = canvasRef.current?.clientWidth || 950;
      canvas.height = canvas.width * aspectRatio;
      ctx.drawImage(image1, 0, 0, canvas.width, canvas.height);
    }
    image1.src = defaultBackgroundImage;
  }, []);
  
  

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
  
// Inside drawImages function
const drawImages = () => {
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  if (image1 && image1.complete) {
    // Calculate aspect ratio of image1
    const aspectRatio = image1.height / image1.width;
    canvas.width = canvasRef.current?.clientWidth;
    canvas.height = canvas.width * aspectRatio;
    ctx.drawImage(image1, 0, 0, canvas.width, canvas.height);
  }

  if (image2 && image2.complete) {
    const {
      size,
      rotation,
      transparency,
      offsetX,
      offsetY,
      flipHorizontal,
    } = overlaySettings;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    ctx.save();
    ctx.globalAlpha = transparency;
    ctx.translate(centerX + offsetX, centerY + offsetY);
    ctx.rotate((rotation * Math.PI) / 180);
    // Apply scale to the overlay image
    ctx.scale(size / 100, size / 100);
    if (Math.abs(flipHorizontal) > 0) {
      ctx.scale(flipHorizontal, 1);
    }
    // Draw the overlay image
    ctx.drawImage(image2, -image2.width/2, -image2.height / 2);
    ctx.restore();
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    const pixels = imageData.data;
    console.log('Pixel values:', pixels);
  }
};
drawImages();
  }, [image1, image2, overlaySettings]);
  
  
  useEffect(() => {
    document.title = 'Fusion Canva';
  }, []);
  const handleImageUpload = (event, setImage) => {
  const file = event.target.files[0];

  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      const img = new Image();
      img.onload = () => {
        setImage(img);
        const canvas = canvasRef.current;
        canvas.width = img.width;
        canvas.height = img.height;

        // Redraw the canvas with the uploaded image
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
      img.src = reader.result;
    };
    reader.readAsDataURL(file);
  } else {
    // If no image is selected, set the default background image
    setImage(null);
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const defaultImg = new Image();
    defaultImg.onload = () => {
      canvas.width = defaultImg.width;
      canvas.height = defaultImg.height;
      ctx.drawImage(defaultImg, 0, 0, canvas.width, canvas.height);
    };
    defaultImg.src = defaultBackgroundImage;
  }
};
  const handleAPIrequest = (event, setImage) => {
    const file = event.target.files[0];

    if (file) {
      const form = new FormData();
      form.append('image_file', file);

      fetch('https://clipdrop-api.co/remove-background/v1', {
        method: 'POST',
        headers: {
          'x-api-key': '87e78f7b2daba208bdd9101d826f9509f3dedf9c5a4bb9a12944d819c20be943227753a8118a3217768a91f33d4661de',
        },
        body: form,
      })
        .then(response => response.arrayBuffer())
        .then(buffer => {
          console.log("BLoobbb", buffer);
          const blob = new Blob([buffer], { type: 'image/png' });

          const reader = new FileReader();
          reader.onload = () => {
            const img = new Image();
            img.onload = () => {
              setImage(img);
              // setLoading(false); 
            };
            img.src = URL.createObjectURL(blob);
          };
          reader.readAsDataURL(file);
        })
        .catch(error => {
          console.error("Error fetching API:", error);
          // setLoading(false);
        });
    }
  };
  const handleOverlaySettingsChange = (key, value) => {
    console.log(value); // Check the value in the console
    setOverlaySettings((prevSettings) => ({
      ...prevSettings,
      [key]: value,
    }));
  };
  // const generatePreviewImage = () => {
  //   const canvas = canvasRef.current;
  //   const previewCanvas = document.createElement('canvas');
  //   const previewCtx = previewCanvas.getContext('2d');
  //   previewCanvas.width = canvas.width;
  //   previewCanvas.height = canvas.height;
  //   if (image1) {
  //     previewCtx.drawImage(image1, 0, 0, canvas.width, canvas.height);
  //   }
  //   if (image2 && image2.complete) {
  //     const { size, rotation, transparency, offsetX, offsetY } = overlaySettings;
  //     const centerX = canvas.width / 2;
  //     const centerY = canvas.height / 2;
  //     const aspectRatio = image1.height / image1.width;
  //     const adjustedSize = size * aspectRatio;
  //     previewCtx.save();
  //     previewCtx.globalAlpha = transparency;
  //     previewCtx.translate(centerX + offsetX, centerY + offsetY);
  //     previewCtx.rotate((rotation * Math.PI) / 180);
  //     previewCtx.drawImage(image2, -size / 2, -size / 2, size, size);
  //     previewCtx.restore();
  //   }
  //   setPreviewImage(previewCanvas.toDataURL());
  // };

  const generatePreviewImage = () => {
    const canvas = canvasRef.current;
    const previewCanvas = document.createElement('canvas');
    const previewCtx = previewCanvas.getContext('2d');
    previewCanvas.width = canvas.width;
    previewCanvas.height = canvas.height;
  
    const drawOverlayOnPreview = () => {
      if (image2 && image2.complete) {
        const { flipHorizontal, size, rotation, transparency, offsetX, offsetY } = overlaySettings;
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        previewCtx.save();
        previewCtx.globalAlpha = transparency;
        previewCtx.translate(centerX + offsetX, centerY + offsetY);
        // Apply scale to the overlay image
        previewCtx.scale(size / 100, size / 100);
        if (Math.abs(flipHorizontal) > 0) {
          previewCtx.scale(flipHorizontal, 1);
        }
        previewCtx.rotate((rotation * Math.PI) / 180);
        previewCtx.drawImage(image2, -image2.width/2, -image2.height / 2);
        previewCtx.restore();
      }
    };
  
    if (image1) {
      previewCtx.drawImage(image1, 0, 0, canvas.width, canvas.height);
    }
  
    drawOverlayOnPreview();
  
    setPreviewImage(previewCanvas.toDataURL());
  };
  
  

  const handleFlipHorizontal = () => {
    const newFlipValue = overlaySettings.flipHorizontal === 1 ? -1 : 1;
    handleOverlaySettingsChange('flipHorizontal', newFlipValue);
  };
  const handleMouseDown = (e) => {
    e.preventDefault(); // Prevent default behavior
    setIsDragging(true);
    setDragStart({
      x: e.clientX - overlaySettings.offsetX,
      y: e.clientY - overlaySettings.offsetY,
    });
  };
  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const offsetX = e.clientX - dragStart.x;
    const offsetY = e.clientY - dragStart.y;
    setOverlaySettings((prevSettings) => ({
      ...prevSettings,
      offsetX,
      offsetY,
    }));
  };
  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const handlePreviewImage = () => {
    generatePreviewImage();
  };
  const handleDownloadImage = () => {
    const link = document.createElement('a');
    link.href = previewImage;
    link.download = 'overlay_preview.png'; // Adjust the filename as needed
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className='OverLay-box'>
      <div className="OverLay">
        <div className='heading-image'>
          <img src={IconHeading} alt="overlay-app" />
        </div>
      </div>

      <div className='overlay-ecatalog-container'>
        <div className="OverLay-container">
          <div className='mt-4 mb-4'>
          <div id="canvasContainer" className="mt-3" ref={canvasContainerRef}>
  <canvas
    ref={canvasRef}
    width={canvasRef.current?.clientWidth || 950}
    height={canvasRef.current?.clientHeight || 560}
    style={{ border: '1px dashed gray', width: '100%', height: '100%' }}
    onMouseDown={handleMouseDown}
    onMouseMove={handleMouseMove}
    onMouseUp={handleMouseUp}
  ></canvas>
</div>

          </div>
        </div>
        <div className='overlay-template-container'>
          <div className='file-upload-container'>
            <label htmlFor="fileInput1" className="overlay-custom-file-upload">
              Choose Image
            </label>
            <input
              type="file"
              id="fileInput1"
              accept="image/*"
              onChange={(e) => handleImageUpload(e, setImage1)}
              style={{ display: 'none' }}
            />
          </div>
          <div className="file-upload-container">
            <label htmlFor="fileInput" className="overlay-custom-file-upload">
              Add Overlay Image
            </label>
            <input
              type="file"
              id="fileInput"
              accept="image/*"
              onChange={(e) => handleAPIrequest(e, setImage2)}
              style={{ display: 'none' }}
            />
          </div>
          <div className='file-upload-container'>
            <div className='Transparency'>
              <label>Blend Percentage</label>
              <RangeSlider
                value={overlaySettings.transparency}
                onChange={(e) => {
                  console.log('Slider Value:', e.target.value);
                  handleOverlaySettingsChange('transparency', +e.target.value);
                }}
                step={0.01}
                min={0}
                max={1}
              />
              <div >
                <div>
                  <label>Size</label>
                  <RangeSlider
                    value={overlaySettings.size}
                    onChange={(e) => handleOverlaySettingsChange('size', +e.target.value)}
                    min={0}
                    max={200}
                    style={{ width: '100%' }}
                  />
                </div>
                <div>
                  <label>Rotation</label>
                  <RangeSlider
                    value={overlaySettings.rotation}
                    onChange={(e) =>
                      handleOverlaySettingsChange('rotation', +e.target.value)
                    }
                    min={0}
                    max={360}
                    style={{ width: '100%' }}
                  />
                </div>
                
              </div>
            </div>
          </div>
          <div className="file-upload-container">
            <Button
              className='overLay-preview-button'
              onClick={handleFlipHorizontal}
            >
            {overlaySettings.flipHorizontal === 1 ? 'Flip' : 'Flip'}
            </Button>
          </div>
          <div className="file-upload-container">
            <Button
              className='overLay-preview-button'
              onClick={handlePreviewImage}
            >
              Preview Image
            </Button>
          </div>

        </div>
      </div>

      <div className='preview-overlay-template-container'>
        <div className='download-button-container' style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div className='overlay-download-icon' onClick={handleDownloadImage}>
            <img src={downloadLogo} alt="downloadLogo" width={30} />
          </div>
        </div>
        {!previewImage && (
          <div className='tz-preview-overlay-template-container' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: "120px" }}>
            <img
              src={defaultBackgroundImage2}
              alt='Default Background'
            />
          </div>
        )}
        {previewImage && (
          <div className='tz-preview-overlay-template-container'>
            <img
              src={previewImage}
              alt='Preview'
              style={{ height: 'auto', maxWidth: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }}
            />
          </div>
        )}

      </div>
    </div>
  );
}
export default OverLay;