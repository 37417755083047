import React, { Component, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import "./PreviewModal.css";
import "./PreviewModalTemp.css";
import { useParams } from "react-router-dom";
import Flipbook from './Flipbook';
import FlipbookcreateTemp from './FlipbookcreateTemp';

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

const PreviewModalCreatedTemp = ({ showNewModal, onHide, template  }) => {
    debugger;
  return (
    <Modal show={showNewModal} onHide={onHide} centered dialogClassName="template-preview-modal">
      <Modal.Header closeButton>
        <Modal.Title>FURNITURE TEMPLATE</Modal.Title>
      </Modal.Header>
      <Modal.Body className='preview-modal-body'>
        <div className="row ">
          <div className="col-md-12">
            <FlipbookcreateTemp  Selectedtemplate="furniture2" />
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default PreviewModalCreatedTemp;
