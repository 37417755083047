import React, { useEffect , useState} from 'react';
import $ from 'jquery';
import 'turn.js';
import './Flipbook.css';
import { getAllpublishedCategories,getTemplateforTemplatepreview } from "../../services/ProductsService";
const Flipbook = ({ selectedImage }) => {
  const [fetchedImages, setFetchedImages] = useState([]);

  const initFlipbook = () => {
    if ($.fn.turn) {
      console.log('turn function is available');
      $('#flipbook').turn({
        autoCenter: true
      });
    } else {
      console.log('turn function is not available, retrying in 100ms...');
      setTimeout(initFlipbook, 10000); // Retry after 100ms if the turn function is not available
    }
  };
  const getTemplateByIdentifier = async () => {
    try {
      const template = await getTemplateforTemplatepreview(selectedImage);
      const retrievedTemplateData = template.data;
      setFetchedImages(retrievedTemplateData);
    } catch (error) {
      console.error('Error fetching template:', error);
    }
  };

  useEffect(() => {
    if ($('#flipbook').length) {
      $(document).ready(() => {
        console.log('Initiating flipbook...', $.fn);
        initFlipbook();
        getTemplateByIdentifier();
      });
    } else {
      console.error('The element with ID "flipbook" was not found.');
    }
  }, [selectedImage]); // Run this effect only once on component mount
  const getFlipbookWidth = () => {
    if (selectedImage === 'furniture1' || selectedImage === 'portrait_blue') {
      return '60%'; // Set width to 50%
    } else if (selectedImage === 'furniture2' || selectedImage === 'landscape_blue') {
      return '100%'; // Set width to 80%
    }
  };
  return (
    <div className="flipbook-wrapper">
      <div id="flipbook" style={{ width: getFlipbookWidth() }}>
        <div className='hard'>
          <img src={fetchedImages.frontPageImageUrl} alt='Front Page Image' />
        </div>
        <div className='page'>
          <img src={fetchedImages.productPageLeftImageUrl} alt='Product Page Left Image' />
        </div>
        <div className='page'>
          <img src={fetchedImages.productPageRightImageUrl} alt='Product Page Right Image' />
        </div>
        <div className='hard'>
          <img src={fetchedImages.backPageImageUrl} alt='Back Page Image' />
        </div>
      </div>
    </div>
  );
};

export default Flipbook;
