import React, { Component } from "react";
import { Route, Routes, Navigate , useNavigate , Link} from "react-router-dom";
import ProductDetails from "../pages/ProductDetails";
import ProductCatalog from "../pages/ProductCatalog";
import Payment from "../components/Payment";
import SignupBoard from "../PurchasePlans/signupboard";
import ForgotPassword from "../components/forgetPassword";
import AddUser from "../UserManagement/AddUser";
import Navbar from "../UserManagement/Navbar";
import Navbarplanbox from "../common/navbarplanbox";
import UserList from "../UserManagement/UserList";
import Sidebar from "../UserManagement/sidebar";
import Plan from "../common/plan";
import PlanBox from "../common/planBox";
import ThankYouPage from "../common/thankyoupage";
import FinancePortal from "../financePortal/financeportal";
import Dashboard from "../UserManagement/dashboard";
import Mysubscription from "../UserManagement/Mysubscription";
import SuccessPage from "../VerificationPage/successpage";
import CancelPage from "../VerificationPage/cancelpage";
import Navbarfinbox from "../financePortal/navbarfinbox";
import Header from "../components/Header/Header";
import { fetchRole } from "../services/ProductsService";
import Login from "../components/Login";
import PaymentUnverified from "../PaymentPendingPage/PaymentUnverified";
import ProductDetailsView from "../pages/ProductDetailsView";
import ResetPassword from "../components/resetpassword.js";
import ProductAttribute from "../components/Header/ProductAtribute"
import ProductCluster from "../components/Header/ProductCluster"
import GenerateAi from "../components/Header/GenerateAi"
import ProductCategory from "../components/Header/ProductCategory";
import ProductSingleUpload from "../SingleUpload/productSingleUpload";
import CustomerSuccessTable from "../CustomerSuccessPortal/CustomerSuccessTable.jsx";
import SingleUploadcustomer from "../CustomerSuccessPortal/SingleUploadcustomer.jsx";
import CustomerHeader from "../CustomerSuccessPortal/CustomerHeader.jsx";
import EditSingleUpload from "../CustomerSuccessPortal/EditSingleUplad";
import CategoryApproval from "../CustomerSuccessPortal/CategoryApproval";
import BulkUpload from "../BulkUploadfolder/BulkUpload";
import BulkUploadforCsm from "../CustomerSuccessPortal/BulkUploadforCsm.jsx";
import EditSingleProduct from "../SingleUpload/EditSingleProduct.jsx";
import SalesUserHeader from "../SalesUser/SalesUserHeader";
import SalesProductTable from "../SalesUser/SalesProductTable";
import SalesProductCatalog from "../SalesUser/SalesProductCatalogue";
import InventoryLog from "../components/Header/InventoryLog";
import Flipbook from "../components/E-catalog/Flipbook.jsx";
import Catalog from "../components/E-catalog/Catalog.jsx";
import TemplatePage from "../components/E-catalog/TemplatePage.jsx";
import BluesofaTemplate from "../components/E-catalog/BluesofaTemplate.jsx";
import FlipbookcreateTemp from "../components/E-catalog/FlipbookcreateTemp.jsx";
import FlipbookVertical from "../components/E-catalog/FlipbookVertical.jsx";
import OrderManagement from "../components/Header/OrderManagement.jsx";
import Cart from "../components/Header/Cart.jsx";
import OverLay from "../components/Header/OverLay.jsx";
import SalesCatalog from "../SalesUser/SalesCatalog.jsx";
import BlueFlipbookDouble from "../components/E-catalog/BlueFlipbookDouble.jsx";
import Portraitblue from "../components/E-catalog/Portraitblue.jsx";
import FlipbookLSDouble from "../components/E-catalog/FlipbookLSDouble.jsx";
import FlipbookHorDouble from "../components/E-catalog/FlipbookHorDouble.jsx";
import VendorDetail from "../financePortal/VendorDetail.js";
import PaymentManagement from "../financePortal/PaymentManagement.jsx";
import Loader from "../context/loader.js";
import CustomerProductDetailPage from "../CustomerSuccessPortal/CustomerProductDetailPage.jsx";
import { getCartValue,} from "../services/ProductsService";
import CreditPointUsage from "../financePortal/CreditPointUsage.jsx";
import DashboardPortal from "../DashboardPortal/DashboardPortal";
import DetailedDailyUsers from "../DashboardPortal/DetailedDailyActiveUser.js";
import ShowcaseSKU from "../DashboardPortal/ShowcaseSKU.js";
import FlipbookUsed from "../DashboardPortal/ShowcaseFlipbookUsed.js";
import DailyUsers from "../DashboardPortal/DailyActiveUser.js";
import WeeklyUsers from "../DashboardPortal/WeeklyActiveUser.js";
import MonthlyUsers from "../DashboardPortal/MonthlyActiveUser.js";
import ShowcaseSignedUPUsers from "../DashboardPortal/ShowcaseSignedUpUser.js";
import LensSignedUPUsers from "../DashboardPortal/LensSignedUpUser.js";
import ConceptAI from "../DashboardPortal/ConceptAI.js";
import ClientDetails from "../DashboardPortal/Clientdetails.js";
import ClientsOverview from "../DashboardPortal/ClientOverview.js";
import FinanceProfile from "../financePortal/FinanceProfile.js";
export default class AllRoutes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shareCount: "",
      searchQuery: "",
      Data: [ ], // Your customer data here 
      isAuthenticated: false,
      userRole: null,
      isLoading: true,
      cartData: [],
      CurrentPageNumber :"",
    };
    
  }
  setSearchQuery = (query) => {
    this.setState({ searchQuery: query });
  };
  async componentDidMount() {
    await this.fetchAllCartItems();
  }

  fetchAllCartItems = async () => {
    try {
      const logs = await getCartValue();
      this.setState({
        CartData: logs.data,
      });
      console.log(logs, "sadasdlogdata");
    } catch (err) {
      console.log(err);
    }
  };

  updateCartData = (newCartData) => {
    debugger;
    this.setState({ cartData: newCartData });
  };

  componentDidMount() {
      this.checkAuthentication();
  }
  
  checkAuthentication = () => {
    debugger;
    const isAuthenticated = !!localStorage.getItem('treziAccessToken') ;
    this.setState({ isAuthenticated, isLoading: false });
    console.log( isAuthenticated ,"isAuthenticated")
  };
  


  handleShareCount = (count) => {
    debugger;
    this.setState({ shareCount: count });
    this.props.handleShareCount(count);
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };
  

  render() {
    const { searchQuery , Data , isLoading,  cartData,isAuthenticated,CartData, userRole } = this.state;
  console.log(isAuthenticated ,"isAuthenticated" , isLoading , cartData , CartData);
  
  if (isLoading) {
    // You may render a loading spinner or placeholder while authentication is being checked
    return <div>Loading...</div>;
  } 

    return (
      <>
        {/* Routes without Header or different header */}
        <Routes>
          <Route path="/" element={<Login checkAuthentication={this.checkAuthentication}  />} />
          <Route exact path="/payment" element={<Payment />} />
          <Route exact path="/signupboard" element={<SignupBoard />} />
          <Route exact path="/forgetPassword" element={<ForgotPassword />} />
          <Route
            path="/resetPassword/:token"
            element={
              <>
                <ResetPassword />
              </>
            }
          />
          <Route exact path="/navbarplanbox" element={<Navbarplanbox />} />
          <Route exact path="/navbarfinbox" element={<Navbarfinbox />} />
          <Route exact path="/plan" element={<Plan />} />
          <Route exact path="/planbox" element={<PlanBox />} />
          <Route exact path="/thankyoupage" element={<ThankYouPage />} />

          <Route exact path="/financeportal" 
            element={
              isAuthenticated === true ? (
                <>
           <FinancePortal />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/paymentPortal" 
            element={
              isAuthenticated === true ? (
                <>
           <PaymentManagement />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
                 <Route exact path="/Vendor-Details" 
            element={
              isAuthenticated === true ? (
                <>
          <VendorDetail />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
            
          <Route exact path="/clinetUsage" 
            element={
              isAuthenticated === true ? (
                <>
           <CreditPointUsage /> 
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
          <Route exact path="/successpage" element={<SuccessPage />} />
          <Route exact path="/cancelpage" element={<CancelPage />} />
          <Route
            exact
            path="/products/:organizationIdentifier/public/:productId"
            element={
                <>
               <ProductDetailsView />
                </>
            }
            // element={<ProductDetailsView />}
          />
        </Routes>
        <Routes>
          <Route
            exact
            path="/adduser"
            element={
              isAuthenticated ? (
                <>
              <Header setSearchQuery={this.handleSearch}  cartData={cartData } />
                <AddUser />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
            <Route
            exact
            path="/financeProfile"
            element={
              isAuthenticated ? (
                <>
              <Navbarfinbox   />
                <FinanceProfile />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
            <Route exact path="/trezi-dashboard" 
            element={
              isAuthenticated === true ? (
                <>
           <DashboardPortal />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/detailed-daily-active-users" 
            element={
              isAuthenticated === true ? (
                <>
           <DetailedDailyUsers />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/concept-ai" 
            element={
              isAuthenticated === true ? (
                <>
           <ConceptAI />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/showcase-SKU" 
            element={
              isAuthenticated === true ? (
                <>
           <ShowcaseSKU />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/flipbook-used" 
            element={
              isAuthenticated === true ? (
                <>
           <FlipbookUsed />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/daily-active-users" 
            element={
              isAuthenticated === true ? (
                <>
           <DailyUsers />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/weekly-active-users" 
            element={
              isAuthenticated === true ? (
                <>
           <WeeklyUsers />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/monthly-active-users" 
            element={
              isAuthenticated === true ? (
                <>
           <MonthlyUsers />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/lens-signedup" 
            element={
              isAuthenticated === true ? (
                <>
           <LensSignedUPUsers />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
               <Route exact path="/showcase-signedup" 
            element={
              isAuthenticated === true ? (
                <>
           <ShowcaseSignedUPUsers />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
          <Route
            exact
            path="/navbar"
            element={
              isAuthenticated ? (
                <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData } />
                <Navbar />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
          <Route
            exact
            path="/product-attribute"
            element={
              isAuthenticated ? (
                <>
               <Header setSearchQuery={this.handleSearch}  cartData={cartData } />
                <ProductAttribute />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
            <Route
            exact
            path="/generate-ai-image"
            element={
              isAuthenticated ? (
                <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <GenerateAi />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
              <Route
            exact
            path="/product-category"
            element={
              isAuthenticated ? (
                <>
                 <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <ProductCategory />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
             <Route
            exact
            path="/inventory"
            element={
              isAuthenticated ? (
                <>
                    <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <InventoryLog />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          
          />
            <Route
            exact
            path="/overlay-image"
            element={
              isAuthenticated ? (
                <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <OverLay />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
           <Route
            exact
            path="/cart-items"
            element={
              isAuthenticated ? (
                <>
                  <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <Cart updatedCartData={this.updateCartData}  />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
             <Route
            exact
            path="/order-management"
            element={
              isAuthenticated ? (
                <>
                   <Header setSearchQuery={this.handleSearch} cartData={cartData } />
                <OrderManagement />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
              <Route
            exact
            path="/product-clusters"
            element={
              isAuthenticated ? (
                <>
            <Header setSearchQuery={this.handleSearch}  cartData={cartData } />
                <ProductCluster />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
             <Route
            exact
            path="/userlist"
            element={
              isAuthenticated ? (
                <>
                   <Header setSearchQuery={this.handleSearch}  cartData={cartData } />
                <UserList />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
          <Route
            exact
            path="/sidebar"
            element={
              <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <Sidebar />
              </>
            }
          />
          {/* <Route
            exact
            path="/dashboard"
            element={
              <>
                <Header setSearchQuery={this.handleSearch} />
                <Dashboard />
              </>
            }
          /> */}
          
     <Route
  exact
  path="/dashboard"
  element={
    isAuthenticated  ? (
      <>
        <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
        <Dashboard />
        {console.log("Dashboard rendered for authenticated user")}
      </>
    ) : (
      <>
        {console.log("User not authenticated, redirecting to login page")}
        <Navigate to="/"   replace  state={{ reload: true }}  />
      </>
    )
  }
/>


          <Route
            exact
            path="/mysubscription"
            element={
              <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <Mysubscription />
              </>
            }
          />
          <Route
            exact
            path="/paymentUnverified"
            element={
              <>
                <PaymentUnverified />
              </>
            }
          />
          <Route
            path="/product-catalog"
            element={
              isAuthenticated ? (
                <>
                  <Header setSearchQuery={this.handleSearch} cartData={cartData } />
                  <ProductCatalog
                  searchQuery={searchQuery}
                  setShareCount={this.handleShareCount}
                />
                </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
            <Route
            path="/e-catalog"
            element={
              isAuthenticated ? (
                <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData } />
                <Catalog
                searchQuery={searchQuery}
                setShareCount={this.handleShareCount}      />
              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
       <Route
          path="/templatePage/:image" component={TemplatePage}
          element={
              <>
                  <Header setSearchQuery={this.handleSearch} cartData={cartData } />
                <TemplatePage
                  searchQuery={searchQuery}
                  setShareCount={this.handleShareCount}
                />
              </>     }
          />
              <Route
          path="/TemplatePage/furniture1" component={TemplatePage}
            element={
              <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <BluesofaTemplate
                  searchQuery={searchQuery}
                  setShareCount={this.handleShareCount}
                />
              </>
            }
          />
           <Route
          path="/TemplatePage/landscape_blue" component={TemplatePage}
            element={
              <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <BlueFlipbookDouble
                  searchQuery={searchQuery}
                  setShareCount={this.handleShareCount}
                />
              </>
            }
          />
             <Route
          path="/TemplatePage/portrait_blue" component={TemplatePage}
            element={
              <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <Portraitblue
                  searchQuery={searchQuery}
                  setShareCount={this.handleShareCount}
                />
              </>
            }
          />
            <Route
          path="/furniture2/:flipBookIdentifier" component={TemplatePage}
            element={
              <>
                <FlipbookcreateTemp
                  searchQuery={searchQuery}
                  setShareCount={this.handleShareCount}
                />
              </>
            }
          />
             <Route
          path="/landscape_blue/:flipBookIdentifier" component={TemplatePage}
            element={
              <>
                <FlipbookHorDouble
                  searchQuery={searchQuery}
                  setShareCount={this.handleShareCount}
                />
              </>
            }
          />
             <Route
          path="/portrait_blue/:flipBookIdentifier" component={TemplatePage}
            element={
              <>
                <FlipbookLSDouble
                  searchQuery={searchQuery}
                  setShareCount={this.handleShareCount}
                />
              </>
            }
          />
              <Route
          path="/furniture1/:flipBookIdentifier" 
            element={
              <>
                <FlipbookVertical
                  searchQuery={searchQuery}
                  setShareCount={this.handleShareCount}
                />
              </>
            }
          />
            <Route
            path="/sales-product-catalog"
            element={
              
              isAuthenticated ? (
                <>
                <SalesUserHeader setSearchQuery={this.handleSearch} />
               <SalesProductCatalog
                 searchQuery={searchQuery}
                 setShareCount={this.handleShareCount}      />
               </>
                  ) : (
                    <Navigate to="/"  replace  state={{ reload: true }} />
                  )
            }
          />

          <Route
            path="/products/:orgIdentifier/:productId"
            element={
              isAuthenticated ? (
                 <>
                <Header setSearchQuery={this.handleSearch}   cartData={cartData }  />
                <ProductDetails  
                  shareCount={this.state.shareCount}   
                  updatedCartData={this.updateCartData} 
                        />
              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
               <Route
            path="/sales-products/:orgIdentifier/:productId"
            element={
              <>
                <SalesUserHeader setSearchQuery={this.handleSearch} />
                <ProductDetails
                  shareCount={this.state.shareCount}
                />
              </>
            }
          />
           <Route
            path="/sales-e-catalog"
            element={         
              isAuthenticated ? (
            <>
                <SalesUserHeader   setSearchQuery={this.handleSearch} />
                <SalesCatalog        shareCount={this.state.shareCount}/>
              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
            <Route
            path="/productSingleUpload"
            element={
              <>
                <Header setSearchQuery={this.handleSearch}  cartData={cartData } />
                <ProductSingleUpload
                  shareCount={this.state.shareCount}
                />
              </>
            }
          />
           <Route
            path="/EditSingleProduct/:productId"
            element={
              <>
                <Header setSearchQuery={this.handleSearch}  />
                <EditSingleProduct
                  shareCount={this.state.shareCount}
                />
              </>
            }
          />
           <Route
            path="/CustomerSuccessTable"
            element={
              isAuthenticated ? (
                <>
                <CustomerHeader setSearchQuery={this.handleSearch}  />
                <CustomerSuccessTable searchQuery={searchQuery} />
                       </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
             <Route
            path="/CategoryApproval"
            element={
              isAuthenticated ? (
                <>
                <CustomerHeader setSearchQuery={this.handleSearch} />
                <CategoryApproval searchQuery={searchQuery} />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />

<Route
            path="/CustomerProducts/:orgIdentifier/:productId"
            element={
              isAuthenticated ? (
                 <>
                <CustomerHeader setSearchQuery={this.handleSearch} />
                <CustomerProductDetailPage         />
              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
           <Route
            path="/SingleUploadcustomer"
            element={
              isAuthenticated ? (
                <>
             <CustomerHeader setSearchQuery={this.handleSearch} />
                <SingleUploadcustomer
                  shareCount={this.state.shareCount}
                />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            }
          />
             <Route
            path="/EditSingleUpload/:productId"
            element={
              <>
                <CustomerHeader setSearchQuery={this.handleSearch} />
                <EditSingleUpload
                  shareCount={this.state.shareCount}
                />
              </>
            }
          />
           <Route
            path="/flipbook"
            element={
              <>
                {/* <Header setSearchQuery={this.handleSearch} /> */}
                <Flipbook
                  // shareCount={this.state.shareCount}
                />
              </>
            }
          />
            <Route
            path="/bulk-upload"
            element={
              <>
                <Header setSearchQuery={this.handleSearch} cartData={cartData }  />
                <BulkUpload
                  shareCount={this.state.shareCount}
                />
              </>
            }
          />
           <Route
            path="/bulk-upload-for-csm/:bulkUploadClientId"
            element={
              <>
                <CustomerHeader setSearchQuery={this.handleSearch}  />
                <BulkUploadforCsm
                  shareCount={this.state.shareCount}
                />
              </>
            }
          />
                         <Route exact path="/client-details" 
            element={
              isAuthenticated === true ? (
                <>
           <ClientDetails />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
              <Route exact path="/client-overview" 
            element={
              isAuthenticated === true ? (
                <>
           <ClientsOverview />
                              </>
              ) : (
                <Navigate to="/"  replace  state={{ reload: true }} />
              )
            } />
        </Routes>
      </>
    );
  }
}