import React, { Component } from 'react';
import { Table, Form, Dropdown, Stack, Pagination, Modal, Button , Tooltip ,OverlayTrigger} from 'react-bootstrap'; // Import required components from react-bootstrap
import './CustomerSuccessTable.css';
import { getAllCustomerSuccessCategories, downloadProductforcsm, getClientCsmCategories ,getColorexists, getColor ,AddColorforAndroid , AddColorFileforIos} from "../services/ProductsService";
import ReactPaginate from 'react-paginate';
import EditIcon from "../assests/public/edit (1).png"
import Swal from 'sweetalert2';
import Loader from "../context/loader.js";
import SuccessModal from "../CustomerSuccessPortal/SuccessModal.jsx";
import Upload from "../../src/assests/public/after download  template_Upload.png"
import GreyUpload from "../../src/assests/public/1 (2).jpg"
import GreyUpload2 from "../../src/assests/public/2.jpg"
import Upload1 from "../../src/assests/public/Group 3163.png"
import Upload2 from "../../src/assests/public//Group 3322.png"
import RejectionModal from './RejectionModal.jsx';
import { useNavigate } from "react-router-dom";

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class CustomerSuccessTable extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      Data: [],
      isAnyCheckboxSelected: false,
      sortColumn: '', // Provide initial value for sorting
      sortDirection: 'asc', // Provide initial value for sorting direction
      currentPage: 1, // Provide initial value for the current page
      itemsPerPage: 10, // You can set the number of items displayed per page
      indexOfFirstItem: 0,
      indexOfLastItem: 10,
      pageNumbers: [],
      filteredData: [],
      isDownloadOptionsActive: false,
      isLoading: false,
      isBulkUploadModalOpen: false,
      bulkUploadClientId: '',
      error: "",
      is3DUploadModalOpen: false,
      selectedFile: null,
      selectedFiles: [],
      buttonText: 'Next',
      isIOS: false,
      successMessage: '',
      showSuccessModal: false,
      showRejectModal: false,
      rejectMessage: ''
    };
  }
  componentDidMount() {
    document.title = 'Customer Success';
    this.fetchAllcustomerSuccessCategories();
    this.filterData()
  }
  getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
   for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };
  componentDidUpdate(prevProps, prevState) {
    debugger;
    if (
      prevProps.searchQuery !== this.props.searchQuery ||
      this.state.isUpdated !== prevState.isUpdated ||
      prevState.productData !== this.state.productData
    ) {
      this.filterData();
    }
    if (prevState.isUpdated !== this.state.isUpdated) {
      this.setState({ productData: [] });
    }
  }
  handleSingleUploadClick = () => {
    window.location.href = "/SingleUploadcustomer";
  };
  handleBulkUploadModalOpen = () => {
    this.setState({ isBulkUploadModalOpen: true });
  };
  showSuccessModal = (message) => {
    this.setState({
      successMessage: message,
      showSuccessModal: true,
    });
  };
  closeSuccessModal = () => {
    this.setState({
      successMessage: '',
      showSuccessModal: false,
    });
  };
  showRejectModal = (rejectMessage) => {
    debugger;
    this.setState({
      showRejectModal: true,
      RejectMessage: rejectMessage,
    });
  };
  handleCloseRejectModal = () => {
    this.setState({
      showRejectModal: false,
      RejectMessage: '',
    });
  };
  handleBulkUploadModalClose = () => {
    this.setState({ isBulkUploadModalOpen: false, bulkUploadClientId: '', error: "", });
  };

  handleBulkUploadClientIdChange = (event) => {
    this.setState({ bulkUploadClientId: event.target.value });
  };
  handleClose3DUploadModal = () => {
    this.setState({ is3DUploadModalOpen: false });
  };

  handleEditForm = () => {
    debugger;
    const { filteredData } = this.state;
    const selectedProduct = filteredData.find((item) => item.selected);
    if (selectedProduct) {
      window.location.href = `/EditSingleUpload/${selectedProduct.productId}`;
    }
  };
  handleOpen3DUploadModal = async () => {
    try {
      debugger;
      const { filteredData } = this.state;
      const selectedProduct = filteredData.find((item) => item.selected);
      if (selectedProduct) {
        const colorExistsResponse = await getColorexists(selectedProduct.productId);
        console.log(colorExistsResponse, "API Response");
        if (colorExistsResponse.status === 200) {
          console.log("Color exists!");
        const existingColorsResponse = await getColor(selectedProduct.productId);
        console.log(existingColorsResponse, "Existing Colors Response");
        const existingColors = existingColorsResponse.data;
        console.log("Existing Colors:", existingColors);
        this.setState({
          existingColors: existingColorsResponse.data,
          selectedColor: null, // Reset selectedColor when opening the modal
          selectedFiles: [],
          buttonText :"Next",
          is3DUploadModalOpen :true
        });
        }else if (colorExistsResponse.response.status === 417) {
          this.showRejectModal('Colors does not exist for this product.');
          return;
        }
      }
      return;
    } catch (error) {
      if(error.response.status === 417){
        this.showRejectModal('Something went wrong.');
      }else{
        this.showRejectModal('Something went wrong.');
      }
    }
  };
  
  handleDownloadOption = async (fileType) => {
    debugger;
    const { filteredData } = this.state;
    const selectedProduct = filteredData.find((item) => item.selected);

    if (selectedProduct) {
      const { productId } = selectedProduct;
      this.setState({ isLoading: true });

      try {
        await downloadProductforcsm(productId, fileType);

        Swal.fire({
          icon: 'success',
          title: 'Download Successful',
          text: `The ${fileType} file for product ID ${productId} has been downloaded and opened.`,
        });

        const updatedData = this.state.filteredData.map((item) => ({
          ...item,
          selected: false,
        }));

        this.setState({
          filteredData: updatedData,
          isAnyCheckboxSelected: false,
          isDownloadOptionsActive: false,
          isLoading: false,
        });
        this.fetchAllcustomerSuccessCategories();
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Download Failed',
          text: `Failed to download the ${fileType} file for product ID ${productId}. Please try again.`,
        });
        this.setState({ isLoading: false });
      }
    }
  };
  handleItemClick = (productId, status) => {
    debugger;

    const organizationIdentifier = this.getCookie('orgIdentifier');
    console.log('Organization Identifier:', organizationIdentifier);
    this.props.navigate(`/CustomerProducts/${organizationIdentifier}/${productId}`);
};

  filterData = () => {
    const { searchQuery } = this.props;
    const { Data } = this.state;
    if (!searchQuery) {
      this.setState({ filteredData: Data });
      return;
    } else {
      const filteredData = Data.filter(
        (item) =>
          item.title.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
          item.productId.toString().startsWith(searchQuery.toUpperCase().trim()) ||
          item.status.toString().startsWith(searchQuery.toLowerCase().trim()) ||
          item.clientId.toString().startsWith(searchQuery.toLowerCase().trim()) ||
          item.modelName.toString().startsWith(searchQuery.toLowerCase().trim()) ||
          item.categoryName.toString().startsWith(searchQuery.toLowerCase().trim()) ||
          item.createdAt.toString().startsWith(searchQuery.toLowerCase().trim()) ||
          item.brand.toString().startsWith(searchQuery.toLowerCase().trim())
      );

      this.setState({ filteredData: filteredData });
    }
  };
  handle3DUploadSubmit = async() => {
    debugger;
    const { filteredData , existingColors ,selectedFiles} = this.state;
    const selectedProduct = filteredData.find((item) => item.selected);
      // Filter out colors that have associated files
      const nonEmptyColors = existingColors.filter(color => color.value !== '');
      console.log(nonEmptyColors.map(color => color.value));
      const arData = {
        productId: selectedProduct.productId,
        colors: selectedFiles.reduce((acc, color) => {
          acc[color.colorName.toLowerCase().replace(/\s/g, '')] = color.file;
          return acc;
        }, {}),
      };
      
      
      console.log(arData.colors, "dhgad");
      
    try {
    const response =  await AddColorforAndroid(selectedProduct.productId, arData.colors);
if(response.status ===200){
  this.setState({
    is3DUploadModalOpen: true,
    selectedFile: null, // Reset selectedFile when opening the modal
    iosImageSource: 'GreyUpload2',
    buttonText: 'Save', // Update button text to 'Save' for iOS
    // existingColors: [],
    selectedFiles: [],
  });
}
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Something went wrong.',
        text: `Please try again.`,
      });
      console.log('Error saving data to AR service:', error);
    }
  };
  handle3DiosSubmit = async() => {
    const { filteredData , existingColors ,selectedFiles} = this.state;
    const selectedProduct = filteredData.find((item) => item.selected);
    const arData = {
      productId: selectedProduct.productId,
      colors: selectedFiles.reduce((acc, color) => {
        acc[color.colorName.toLowerCase().replace(/\s/g, '')] = color.file;
        return acc;
      }, {}),
    };
    try {
    const response =  await AddColorFileforIos(selectedProduct.productId, arData.colors);
if(response.status ===200){
  this.setState({
    is3DUploadModalOpen: false,
    selectedFile: null, // Reset selectedFile when opening the modal
    iosImageSource: 'GreyUpload2',
    selectedFiles: [],
  });
  this.showSuccessModal('All the 3D files uploaded successfully.');
}
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Download Failed',
        text: `Please try again.`,
      });
      console.log('Error saving data to AR service:', error);
    }
  };
  handlePageChange = (selectedPage) => {
    debugger;
    this.setState({ currentPage: selectedPage });
  };

  handleSort = (columnName) => {
    const { sortColumn, sortDirection, Data, filteredData } = this.state;
    const newSortDirection = sortColumn === columnName ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      // Perform sorting based on the column name and sort direction
      if (columnName === 'status') {
        return newSortDirection === 'asc' ? a.status.localeCompare(b.status) : b.status.localeCompare(a.status);
      } else if (columnName === 'created on') {
        return newSortDirection === 'asc' ? new Date(a.createdAt) - new Date(b.createdAt) : new Date(b.createdAt) - new Date(a.createdAt);
      } else if (columnName === 'Product Title') {
        return newSortDirection === 'asc' ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
      } else if (columnName === 'clientId') {
        return newSortDirection === 'asc' ? a.clientId.localeCompare(b.clientId) : b.clientId.localeCompare(a.clientId);
      } else if (columnName === 'categoryName') {
        return newSortDirection === 'asc' ? a.categoryName.localeCompare(b.categoryName) : b.categoryName.localeCompare(a.categoryName);
      } else if (columnName === 'brand') {
        return newSortDirection === 'asc' ? a.brand.localeCompare(b.brand) : b.brand.localeCompare(a.brand);
      } else if (columnName === 'modelName') {
        return newSortDirection === 'asc' ? a.modelName.localeCompare(b.modelName) : b.modelName.localeCompare(a.modelName);
      } else if (columnName === 'productId') {
        return newSortDirection === 'asc' ? a.productId.localeCompare(b.productId) : b.productId.localeCompare(a.productId);
      }
      return 0;
    });

    this.setState({ filteredData: sortedData, sortColumn: columnName, sortDirection: newSortDirection });
  };


  handleCheckboxChange = (productId, status) => {
    const updatedData = this.state.filteredData.map((item) => {
      if (item.productId === productId) {
        return {
          ...item,
          selected: !item.selected,
        };
      }
      // Unselect other items
      return {
        ...item,
        selected: false,
      };
    });

    // Check if the clicked item is now selected
    const isAnyCheckboxSelected = updatedData.some((item) => item.selected);
    if (isAnyCheckboxSelected && updatedData.filter((item) => item.selected).length > 1) {
      Swal.fire("Oops!", "You can only select one item at a time.", "warning");
    } else {
      this.setState({
        filteredData: updatedData,
        isAnyCheckboxSelected,
        isDownloadOptionsActive: isAnyCheckboxSelected, // Enable the dropdown if any checkbox is selected
      });
    }
  };

  async fetchAllcustomerSuccessCategories() {
    debugger;
    try {
      const products = await getAllCustomerSuccessCategories();

      this.setState(
        {
          Data: products,
          filteredData: products
        },
      );
    } catch (err) {
      if(err.response.status === 401){
        Swal.fire({
          icon: 'Unauthorised',
          title: 'Error fetching Products',
          text: 'Error fetching Products'
        });
        window.location.href = '/'; // Redirect to the login page
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Error fetching Products',
          text: 'Error fetching Products'
        });
      }
      console.log(err);
    }
  }
  handleBulkUploadStart = () => {
    debugger;
    const { bulkUploadClientId } = this.state;
    if (!bulkUploadClientId) {
      this.setState({
        error: "Client ID cannot be blank. Please enter a valid client ID.",
      });
      return;
    }
    this.setState({ error: "" });
    getClientCsmCategories(bulkUploadClientId)
      .then((response) => {
        if (response.status === 200) {
          window.location.href = `/bulk-upload-for-csm/${bulkUploadClientId}`;
          this.handleBulkUploadModalClose();
        } else if (response.response.data === "Customer categories not found.") {
          this.setState({
            error: "Customer categories not found.",
          });
        } else if (response.response.data === "Invalid clientId.") {
          this.setState({
            error: "Invalid clientId.",
          });
        }
      })
      .catch((error) => {
        console.error('Error retrieving clusters:', error);
        this.setState({
          error: "Error retrieving data from the server. Please try again later.",
        });
      });
  };
  handleFileChange = (e, index, colorValue) => {
    debugger;
    const file = e.target.files[0]; // Assuming only one file is selected
    const { selectedFiles } = this.state;
  
    if (file === undefined) {
      this.setState({ selectedFiles });
      return; // Exit the function since no file is selected
    }
  
    selectedFiles[index] = { file, colorName: colorValue }; // Store the new file and associated color name at the corresponding index
    this.setState({ selectedFiles });
  };
  
  

  
  render() {
    const { sortColumn, sortDirection, Data, isAnyCheckboxSelected,selectedFiles, selectedFile ,selectedColor ,is3DUploadModalOpen, isLoading, filteredData, isDownloadOptionsActive,existingColors, isBulkUploadModalOpen, bulkUploadClientId } = this.state;
    const {
      currentPage,
      itemsPerPage,
    } = this.state;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(Data.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }
    const displayedData = filteredData.slice(indexOfFirstItem, indexOfLastItem);
    const tooltip = (
      <Tooltip id="tooltip">Add color variant of GLB and USDZ 3D files from a gallery of options for view in AR.</Tooltip>
    );

    return (
      <div>
        <Stack className='table-customer-product-container '>
          <div className="upload-heading">
            Customer Success Portal
          </div>
          <div className="csm-button-container">
            {isLoading && (
              <Loader />
            )}
            <div className="upload-button" >
              <Dropdown >
                <Dropdown.Toggle >
                  Upload
                </Dropdown.Toggle>

                <Dropdown.Menu className='tz-dropdown-menu'>
                  <Dropdown.Item
                    onClick={this.handleSingleUploadClick}
                  // active={viewOption === "List"}
                  >
                    Single Upload
                  </Dropdown.Item>
                  <Dropdown.Item onClick={this.handleBulkUploadModalOpen}
                  >
                    Bulk Upload
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <Button className="button" onClick={this.handleEditForm} disabled={!isAnyCheckboxSelected} >
              <img src={EditIcon} alt="Edit" width={10} height={10} /> {/* Use the EditIcon image */}
              Edit
            </Button>
            <OverlayTrigger
        placement="bottom-start"
        overlay={tooltip}
      >
        <span>
          <Button
            className="button"
            onClick={this.handleOpen3DUploadModal}
            disabled={!isAnyCheckboxSelected} 
          >
          Add Color Variant 3D Files
          </Button>
        </span>
      </OverlayTrigger>
            <Dropdown>
              <Dropdown.Toggle variant="light" style={{ border: "1px solid grey" }} disabled={!isDownloadOptionsActive}>
                Download Files
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.handleDownloadOption("Info")} >Product Info file</Dropdown.Item>
                <Dropdown.Item onClick={() => this.handleDownloadOption("Texture")} >Texture file</Dropdown.Item>
                <Dropdown.Item onClick={() => this.handleDownloadOption("Raw")} >raw file</Dropdown.Item>
                <Dropdown.Item onClick={() => this.handleDownloadOption("Lens")}   >fbx file</Dropdown.Item>
                <Dropdown.Item onClick={() => this.handleDownloadOption("Viewer")} >glb file</Dropdown.Item>
                <Dropdown.Item onClick={() => this.handleDownloadOption("All")} >Download all</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className='table-customer-body'>
            <Table hover borderless className="product-customer-table ">
              <thead className="list-view-table-head">
                <tr>
                  <th className="first-product-column-th">
                    <span className="custom-font-15 product-checkbox">Select</span>
                  </th>
                  {/* CustomerId */}
                  <th className="fifth-product-column-th custom-font-10">
                    <div
                      className="d-flex sort-column align-items-center"
                      onClick={() => this.handleSort('clientId')}
                    >
                      <span className="custom-font-15">Customer ID</span>
                      <div className="tz-sort-icons">
                        <button
                          className={`sort-button ${sortColumn === 'clientId' &&
                            sortDirection === 'asc' &&
                            'active'
                            }`}
                        >
                          <span className="bi bi-caret-up-fill"></span>
                          <span className="bi bi-caret-down-fill"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  {/* Title & Tzn Number */}
                  <th className="second-product-column-th custom-font-10">
                    <div
                      className="d-flex sort-column align-items-center"
                      onClick={() => this.handleSort('productId')}
                    >
                      <span className="custom-font-15">Product ID</span>
                      <div className="tz-sort-icons">
                        <button
                          className={`sort-button ${sortColumn === 'productId' &&
                            sortDirection === 'asc' &&
                            'active'
                            }`}
                        >
                          <span className="bi bi-caret-up-fill"></span>
                          <span className="bi bi-caret-down-fill"></span>
                        </button>
                      </div>
                    </div>
                  </th>

                  {/* Product Tittle */}
                  <th className="fifth-product-column-th custom-font-10">
                    <div
                      className="d-flex sort-column align-items-center"
                      onClick={() => this.handleSort('Product Title')}
                    >
                      <span className="custom-font-15">Product Title</span>
                      <div className="tz-sort-icons">
                        <button
                          className={`sort-button ${sortColumn === 'Product Title' &&
                            sortDirection === 'asc' &&
                            'active'
                            }`}
                        >
                          <span className="bi bi-caret-up-fill"></span>
                          <span className="bi bi-caret-down-fill"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  <th className="fifth-product-column-th custom-font-10">
                    <div
                      className="d-flex sort-column align-items-center"
                      onClick={() => this.handleSort('categoryName')}
                    >
                      <span className="custom-font-15">Product Category</span>
                      <div className="tz-sort-icons">
                        <button
                          className={`sort-button ${sortColumn === 'categoryName' &&
                            sortDirection === 'asc' &&
                            'active'
                            }`}
                        >
                          <span className="bi bi-caret-up-fill"></span>
                          <span className="bi bi-caret-down-fill"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  {/* Created on */}
                  <th className="fourth-product-column-th custom-font-10">
                    <div
                      className="d-flex sort-column align-items-center"
                      onClick={() => this.handleSort('created on')}
                    >
                      <span className="custom-font-15">Created on</span>
                      <div className="tz-sort-icons">
                        <button
                          className={`sort-button ${sortColumn === 'created on' &&
                            sortDirection === 'asc' &&
                            'active'
                            }`}
                        >
                          <span className="bi bi-caret-up-fill"></span>
                          <span className="bi bi-caret-down-fill"></span>
                        </button>
                      </div>
                    </div>
                  </th>

                  <th className="fifth-product-column-th custom-font-15">
                    <div
                      className="d-flex sort-column align-items-center"
                      onClick={() => this.handleSort('brand')}
                    >
                      <span className="custom-font-15">Brand Name</span>
                      <div className="tz-sort-icons">
                        <button
                          className={`sort-button ${sortColumn === 'brand' &&
                            sortDirection === 'asc' &&
                            'active'
                            }`}
                        >
                          <span className="bi bi-caret-up-fill"></span>
                          <span className="bi bi-caret-down-fill"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  {/* Model Name*/}
                  <th className="fifth-product-column-th custom-font-15">
                    <div
                      className="d-flex sort-column align-items-center"
                      onClick={() => this.handleSort('modelName')}
                    >
                      <span className="custom-font-15">Model Name</span>
                      <div className="tz-sort-icons">
                        <button
                          className={`sort-button ${sortColumn === 'modelName' &&
                            sortDirection === 'asc' &&
                            'active'
                            }`}
                        >
                          <span className="bi bi-caret-up-fill"></span>
                          <span className="bi bi-caret-down-fill"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                  {/* Status */}
                  <th className="third-product-column-th custom-font-15">
                    <div
                      className="d-flex sort-column align-items-center"
                      onClick={() => this.handleSort('status')}
                    >
                      <span className="custom-font-15">Status</span>
                      <div className="tz-sort-icons">
                        <button
                          className={`sort-button ${sortColumn === 'status' &&
                            sortDirection === 'asc' &&
                            'active'
                            }`}
                        >
                          <span className="bi bi-caret-up-fill"></span>
                          <span className="bi bi-caret-down-fill"></span>
                        </button>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>

              {/* Table body */}
              <tbody className=""   >
                {displayedData?.map((item, index) => (
                  <tr key={index} className="tz-table-body-row">
                    <td className="first-product-column w-30">
                      <Form.Check
                        className="product-checkbox"
                        type="checkbox"
                        checked={item.selected ?? false}
                        onChange={() =>
                          this.handleCheckboxChange(item.productId, item.status)
                        }
                      />
                    </td>
                    <td className="fifth-product-column"    
                       onClick={() =>
                          this.handleItemClick(item.productId, item.status)
                        }  >
                      {item.clientId}
                    </td>
                    <td
                      className="second-product-column "
                      onClick={() =>
                        this.handleItemClick(item.productId, item.status)
                      } 
                    >
                      <div className="name-container" >
                        <span>
                          <div className="product-serial custom-font-12">
                            {item.productId}
                          </div>
                        </span>
                      </div>
                    </td>
                    <td className="fifth-product-column"  
                     onClick={() =>
                          this.handleItemClick(item.productId, item.status)
                        } >
                      {item.title}
                    </td>
                    <td className="fifth-product-column">
                      {item.categoryName}
                    </td>
                    <td className="fourth-product-column">
                      {item.createdAt ? new Date(item.createdAt).toISOString().split("T")[0] : ""}
                    </td>
                    <td className="fifth-product-column">
                      {item.brand}
                    </td>
                    <td className="fifth-product-column">
                      {item.modelName}
                    </td>
                    {/* Dropdown for changing status */}
                    <td className="third-product-column">
                      <Dropdown
                        className="align-left"
                      >
                        <Button
                          className={
                            item.status === 'Published'
                              ? 'product-publish published'
                              : item.status === 'Unpublished'
                                ? 'product-unpublish unpublished'
                                : item.status === 'Baking in progress'
                                  ? 'product-baking baking'
                                  : item.status === 'Uploaded'
                                    ? 'product-uploaded uploaded'
                                    : 'product-unpublish unpublished'
                          }
                          disabled
                        >
                          {item.status === 'Published'
                            ? 'Published'
                            : item.status === 'Unpublished'
                              ? 'Unpublished'
                              : item.status === 'Baking in progress'
                                ? 'Baking In Progress'
                                : item.status === 'Uploaded'
                                  ? 'Uploaded'
                                  : 'Unpublished'}{' '}
                          {/* Default to "Unpublished" */}
                        </Button>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Stack>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
          <ReactPaginate
            pageCount={Math.ceil(Data.length / itemsPerPage)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            onPageChange={(selectedPage) => this.handlePageChange(selectedPage.selected + 1)}
            containerClassName="pagination"
            activeClassName="pagination-item-active"
          />
        </div>

        <Modal show={isBulkUploadModalOpen} centered onHide={this.handleBulkUploadModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Enter Client ID for Bulk Upload</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="bulkUploadClientId">
                <Form.Label>Client ID</Form.Label>
                <div className='mt-1 mb-2'>
                  {this.state.error && (
                    <div className="error-message" style={{ color: 'red' }}>
                      {this.state.error}
                    </div>
                  )}
                </div>
                <Form.Control
                  type="text"
                  placeholder="Enter Client ID"
                  value={bulkUploadClientId}
                  onChange={this.handleBulkUploadClientIdChange}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleBulkUploadModalClose}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.handleBulkUploadStart}>
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={is3DUploadModalOpen} centered onHide={this.handleClose3DUploadModal}>
  <Modal.Header >
    <Modal.Title  className="subtitle-2" >
    Add Color Variant 3D Files
      <div className="subtitle">Add color variant of GLB and USDZ 3D files from a gallery of options for view in AR.</div>
    </Modal.Title>
  </Modal.Header>
  <div className="custom-img-container">
  <img src={this.state.buttonText === 'Next' ? GreyUpload : GreyUpload2} alt="upload" className="custom-img" />
</div>
  <Modal.Body>
    <div>
    {existingColors && existingColors.length > 0 &&
  existingColors
    .filter(color => color.value) // Filter out colors with empty values
    .map((color, index) => (
      <div key={index}>
        <div className="color-container">
          <div className="color-info">
            <div className="color-name">{color.name}</div>
          </div>
          <div className="color-inputs">
            <div className="color-box" style={{ backgroundColor: color.value }}></div>
            <div className="color-value">{color.value}</div>
          </div>
        </div>
        <div className="mt-2">
          <label  htmlFor={`fileInput_${index}`} className="csm-custom-file-input">
          <img src={Upload1} alt="upload" width={20} />
          <span className='overlay-space'>
  {this.state.buttonText === 'Next'
    ? 'Attach GLB file here'
    : 'Attach usdz file here'}
</span>
          </label>
          <label htmlFor={`fileInput_${index}`}>
            <span className='overlay-space'>
              {selectedFiles[index]
                ? `${selectedFiles[index].file.name}`
                : this.state.buttonText === 'Next'
                  ? 'No file choosen'
                  : 'No file choosen'}
            </span>
          </label>
          <input
            type="file"
            id={`fileInput_${index}`}
            accept=".glb, .usdz , .USDZ ,.GLB"
            className="hidden-input"
            onChange={(e) => this.handleFileChange(e, index ,color.name)}
          />
        </div>
      </div>
    ))
}
    </div>
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" className='cancel-button' onClick={this.handleClose3DUploadModal}>
      Cancel
    </Button>
    <Button variant="primary" onClick={this.state.buttonText === 'Save' ? this.handle3DiosSubmit : this.handle3DUploadSubmit}>
  {this.state.buttonText}
</Button>
  </Modal.Footer>
</Modal>
<SuccessModal
        show={this.state.showSuccessModal}
        onClose={this.closeSuccessModal}
        message={this.state.successMessage}
      />
      <RejectionModal show={this.state.showRejectModal} 
      onClose={this.handleCloseRejectModal} 
      message={this.state.RejectMessage} />
      </div>

    );
  }
}
export default withNavigation(CustomerSuccessTable);
