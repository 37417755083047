import React, { useState, useEffect } from 'react';
import { getClientforpoints } from '../services/ProductsService';
import DashboardIndex from './DashboardIndex';
import * as XLSX from 'xlsx';
import Dropdown from 'react-bootstrap/Dropdown';

const ClientsOverview = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortKey, setSortKey] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getClientforpoints();
        console.log('Full API response:', result);
        console.log('Data received:', result.data);

        if (Array.isArray(result.data)) {
          setData(result.data);
        } else if (typeof result.data === 'object' && result.data !== null) {
          setData([result.data]);
        } else {
          setError('Received data is not in expected format.');
        }
      } catch (error) {
        console.error('Error fetching client points:', error);
        setError('Failed to fetch data.');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const exportToExcel = () => {
    const headers = ['Client ID', 'Total Points', 'Used Points', 'Balanced Points'];
    const worksheetData = [
      headers,
      ...data.map(item => [item.clientId, item.totalPoints, item.usedPoints, item.balancePoints])
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Detailed Daily Users');
    XLSX.writeFile(workbook, 'Detailed_Daily_Users.xlsx');
  };

  const handleSortChange = (key) => {
    if (sortKey === key) {
      setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      setSortKey(key);
      setSortOrder('asc');
    }
  };

  const getSortedData = () => {
    if (!sortKey) return data;
  
    const sortedData = [...data].sort((a, b) => {
      // If sorting by a number
      if (typeof a[sortKey] === 'number' && typeof b[sortKey] === 'number') {
        return sortOrder === 'asc' ? a[sortKey] - b[sortKey] : b[sortKey] - a[sortKey];
      }
  
      // If sorting by string (future proof if you add string sorting)
      if (typeof a[sortKey] === 'string' && typeof b[sortKey] === 'string') {
        return sortOrder === 'asc' ? a[sortKey].localeCompare(b[sortKey]) : b[sortKey].localeCompare(a[sortKey]);
      }
  
      // Default return if sortKey type is not handled
      return 0;
    });
  
    return sortedData;
  };
  

  const renderTable = () => {
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (data.length === 0) return <p>No data available.</p>;

    return (
      <div>
        <h2 id="heading">Clients Overview</h2>
        <div id="button">
          <button id="exportbutton" onClick={exportToExcel} disabled={loading}>
            {loading ? 'Loading...' : 'Export to Excel'}
          </button>

          <Dropdown>
            <Dropdown.Toggle variant="tz-primary" id="dropdown-basic">
              Sort by
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => handleSortChange('totalPoints')}>
                Total Points ({sortOrder === 'asc' && sortKey === 'totalPoints' ? 'Descending' : 'Ascending'})
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSortChange('balancePoints')}>
                Balance Points ({sortOrder === 'asc' && sortKey === 'balancePoints' ? 'Descending' : 'Ascending'})
              </Dropdown.Item>
              <Dropdown.Item onClick={() => handleSortChange('usedPoints')}>
                Used Points ({sortOrder === 'asc' && sortKey === 'usedPoints' ? 'Descending' : 'Ascending'})
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

        <div className="other_table_container">
          <table className="result-table">
            <thead>
              <tr>
                <th>Client Id</th>
                <th>Total Points</th>
                <th>Used Points</th>
                <th>Balanced Points</th>
              </tr>
            </thead>
            <tbody>
              {getSortedData().map((entry, index) => (
                <tr key={index}>
                  <td>{entry.clientId}</td>
                  <td>{entry.totalPoints}</td>
                  <td>{entry.usedPoints}</td>
                  <td>{entry.balancePoints}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
      <DashboardIndex />
      <div className="home-container">
        <div className="options-container">
          {/* No button required as data is fetched automatically */}
        </div>
        <div className="data-container">
          {renderTable()}
        </div>
      </div>
    </>
  );
};

export default ClientsOverview;
