import React, { useEffect , useState } from 'react';
import $ from 'jquery';
import {
  Col,
  Container,
  Row,
  Card,
} from "react-bootstrap";
import HTMLFlipBook from 'react-pageflip';
import './NewFlipbook.css'; // Import the new CSS file for the new flipbook
import {getFlipbookByFlipbookIdentifier ,getProductsByIdForflipbook ,downloadProductqrcode} from "../../services/ProductsService";
import { useParams } from 'react-router-dom';
import LoadingImage from  "../../assests/public/Loading-03-03.png";
import html2pdf from 'html2pdf.js';
import TreziLogo from  "../../assests/public/Group 10 (2).png";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
function withParams(Component) {
  return (props) => {
    const params = useParams(); // Fetch the route parameters using React Router's useParams hook
    return <Component {...props} params={params} />;
  };
}

const FlipbookHorDouble = ( { params }) => {
    const [flipbookData, setFlipbookData] = useState({});
    const [flipbookFieldsData, setFlipbookFieldsData] = useState([]);
    const [flipbookTemplateIdentifier, setFlipbookTemplateIdentifier] = useState([]);
    const [productData, setProductData] = useState([]);
    const [newLink, setNewLink] = useState("");
    const { flipBookIdentifier  } = params;
    console.log('Flipbook Identifier from route:', flipBookIdentifier);
    const getCookie = (name) => {
      const cookieString = document.cookie;
      const cookies = cookieString.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    };
  const initFlipbook = () => {
    if ($.fn.turn) {
      $('#new-flipbook').turn({
        autoCenter: true,
      });
    } else {
      setTimeout(initFlipbook, 100); // Retry after 100ms if the turn function is not available
    }
  };

  useEffect(() => {
    const element = $('#new-flipbook');
    if (element.length) {
      $(document).ready(() => {
        initFlipbook();
      });
    } else {
      console.error('The element with ID "new-flipbook" was not found.');
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      console.log('Flipbook Identifier from route:', flipBookIdentifier);
      const organizationIdentifier = getCookie('orgIdentifier');
      try {
          const response = await getFlipbookByFlipbookIdentifier(flipBookIdentifier);
          setFlipbookData(response.data);
          if (response.data && response.data.flipBookFields) {
            setFlipbookFieldsData(response.data.flipBookFields);
            setFlipbookTemplateIdentifier(response.data.templateIdentifier);
            const productIds = response.data.productIds || [];
            const productDataArray = await Promise.all(
              productIds.map(async (productId) => {
                try {
                  const productResponse = await getProductsByIdForflipbook(productId);
                  return productResponse.data; // Adjust this based on your API response structure
                } catch (error) {
                  console.error('Error fetching product data:', error);
                  return null;
                }
              })
            );
            console.log('Product Data Array:', productDataArray);
            const filteredProductData = productDataArray.filter((data) => data !== null && data !== undefined);
            console.log('Filtered Product Data:', filteredProductData);           
            const updatedProductData = await Promise.all(filteredProductData.map(async (product) => {
              const url = window.location.origin;
              const linkInput = product?.shareLink;
              const linkWithoutPrefix = linkInput.replace(/.*\/v1\/products\//, '');
              const newLink = `${url}/products/${organizationIdentifier}/${linkWithoutPrefix}`;
              setNewLink(newLink);
              const qrcodeResponse = await downloadProductqrcode(newLink);
              
              if (qrcodeResponse && qrcodeResponse.data && qrcodeResponse.data.qrCodeBytes) {
                return {
                  ...product,
                  qrcodeResponse: qrcodeResponse.data.qrCodeBytes,
                  newLink
                };
              } else {
                return product;
              }
            }));     
            setProductData(updatedProductData);
            console.log('Updated Product Data:', updatedProductData); 
          }
      } catch (error) {
        console.error('Error fetching flipbook data:', error);
      }
    };
  
    fetchData();
    console.log(flipbookData);
    console.log(flipbookFieldsData);
    console.log(productData);
  }, [flipBookIdentifier]);

  useEffect(() => {
    console.log('Updated flipbookData:', flipbookData);
    console.log('Updated flipbookFieldsData:', flipbookFieldsData);
    console.log('Updated templateFieldsData:', flipbookTemplateIdentifier);
    document.title = 'Flipbook';
  }, [flipbookData, flipbookFieldsData,flipbookTemplateIdentifier]);

  const generatePageContent = (product, categoryName, brandLogoBase64, FlipbookName, isFirstPage ,isLastPage) => {
    let headerContent = '';
    if (isFirstPage) {
        headerContent = `
        <div class="row-container mt-3">
            <div class="down-category-name">${categoryName}</div>
            <div classname="down-logo-container">
                <img class="flipbook-logo" src="${brandLogoBase64}" alt="brandlogo">
            </div>
        </div>
        `;
        
    }

    let poweredByTrezi = '';
    if (isLastPage) {
        poweredByTrezi = `
        <div class="powered-by-trezi mt-3">
            <div  class="Powered" >
            Powered by 
            </div>
            <img class="sales-trezi-logo" src="${TreziLogo}" alt="Trezi Logo" >   
            </div>
        `;
    }
    const productDetails = flipbookData &&  flipbookData.attributes
    .map((attribute) => {
      if (product.hasOwnProperty(attribute)) {
        return `
          <div key="${attribute}" class="mt-1">
            <span class="label">${attribute.charAt(0).toUpperCase() + attribute.slice(1)}:</span>
            <span class="data">${product[attribute]}</span>
          </div>
        `;
      }
      return ''; // Ignore attributes not present in flipbookData.attributes
    })
    .join(''); // Join the generated HTML elements into a single string

    return `
        <div>
            ${headerContent}
        </div>
        <div class="row mt-4">
            <div class="col-md-4">
            <div class="horz-tz-showcase-frame">
            <img class="flipbook-image" src="${product.thumbnailBase64}" alt="Thumbnail">
        </div>
            </div>
            <div class="col-md-4 upper-spacing">
                <div class="flipbook-cont1">
                    <div>
                        <span class="title-data">${product.title}</span>
                    </div>
                    <div class="">${product.productTzn}</div>
                    ${productDetails}
                </div>
                </div>
                <div class="col-md-4">
                <div class="qr-code-container">
                <img src="data:image/png;base64,${product.qrcodeResponse}" alt="QR Code" class="qr-code-image mt-2">
                <div class="scan-text" >Scan this for more details</div>
            </div>
            </div>
            </div>
            <div >
            ${poweredByTrezi}
            </div>
    `;
};
const handleDownload = async () => {
  const flipbookContainer = document.createElement('div');
  const brandLogoBase64 = await getImageDataURL(flipbookData.brandLogoUrl);
  const categoryName = flipbookFieldsData[0].value;
  const FlipbookName = flipbookFieldsData[3].value;
  let isFirstPage = true;
  const options = {
    pagebreak: { mode: 'avoid-all' }, // Set pagebreak mode
    jsPDF: {
      format: [215.9, 279.4], // Set page format to A4 (landscape)
      orientation: "landscape", // Set orientation to landscape
    }
  };
  try {
      // Convert image URLs to base64 and adjust size
      const frontCoverImageDataURL = await getImageDataURL(flipbookFieldsData.find(field => field.name === 'frontCoverImage').value, 'small');
      const backCoverImageDataURL = await getImageDataURL(flipbookFieldsData.find(field => field.name === 'backCoverImage').value, 'small');
      const productImageDataURLs = await Promise.all(productData.map(async (product) => {
          const thumbnailBase64 = await getImageDataURL(product.thumbnailUrl);
          return {
              ...product,
              thumbnailBase64
          };
      }));

      // Append the front cover image to flipbook container
      const frontCoverImageElement = document.createElement('img');
      frontCoverImageElement.src = frontCoverImageDataURL;
      frontCoverImageElement.style.width = "100vw"; // Set width to cover the entire viewport width
      frontCoverImageElement.style.height = "auto"; 
      frontCoverImageElement.style.maxWidth = "100%";
      frontCoverImageElement.style.objectFit = "cover";
      flipbookContainer.appendChild(frontCoverImageElement);

      // Append product pages to flipbook container
      productImageDataURLs.forEach((product, index) => {
        const isLastPage = index === productData.length - 1;
          const pageContent = generatePageContent(product, categoryName, brandLogoBase64, FlipbookName, isFirstPage ,isLastPage);
          const pageContainer = document.createElement('div');
          pageContainer.innerHTML = pageContent;
          flipbookContainer.appendChild(pageContainer);
          isFirstPage = false;
       
          // Add a page break before appending the back cover page
          if (index === productData.length - 1) {
              const pageBreak = document.createElement('div');
              pageBreak.style.pageBreakAfter = 'always';
              flipbookContainer.appendChild(pageBreak);
          }
      });

      // Append the back cover image to flipbook container
      const backCoverImageElement = document.createElement('img');
      backCoverImageElement.src = backCoverImageDataURL;
      backCoverImageElement.style.maxWidth = "100%";
      backCoverImageElement.style.width = "100vw"; // Set width to cover the entire viewport width
      backCoverImageElement.style.height = "auto"; 
      flipbookContainer.appendChild(backCoverImageElement);

      // Generate PDF from the flipbook container
      await html2pdf().set(options).from(flipbookContainer).save();
  } catch (error) {
      console.error('Error generating PDF:', error);
  }
};
  

const getImageDataURL = async (url, size) => {
  try {
      const response = await fetch(url);
      if (!response.ok) {
          throw new Error(`Failed to fetch image at ${url}`);
      }
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
      });
  } catch (error) {
      console.error('Error fetching image:', error);
      throw error;
  }
};
const groupedProducts = [];
for (let i = 0; i < productData.length; i += 2) {
    groupedProducts.push(productData.slice(i, i + 2));
}
  return productData.length == 0 ? (<div class="Loading-image-container" >
    <img src={LoadingImage} alt="Logo" class="animated-logo"  />
    </div>) : (
      <div>
        <div className='mt-4'>
<button onClick={handleDownload}>Download Flipbook as PDF</button>
</div>
<div className="Land-center-container">
            <div className="tz-flipbook-container">
            <HTMLFlipBook
 width={120}
 height={80}
 size="stretch"
 minWidth={700}
 maxWidth={800}
 minHeight={500}
 maxHeight={700}
 drawShadow={true}
 flippingTime={600}
 useMouseEvents={true}
 pageMode="double"
 usePortrait = {true}
 style={{ marginTop: '20px' }}
 mobileScrollSupport = {true}  
>
  {/* Hard page at the start */}
  <div className="demoPage hard">
  </div>
  <div className="demoPage" >      
    <div className='full-size-container' 
   style={{  backgroundImage: `url("${flipbookFieldsData[1]?.value}")`, // Use the imported image as background
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',}}>
{/* <div className="logo-container">
                    <img src={flipbookData.brandLogoUrl} alt="Logo" />
                </div> */}
    </div>
</div>
 
   {groupedProducts && groupedProducts.map((productPair, index) => (
                                    <div className="demoPage" key={index} style={{ width: "75%" }}>
                                        <div className="row-container">
                                            <div className="sec-category-name">
                                                {flipbookFieldsData[0]?.value}
                                            </div>
                                            <div className="logo-container">
                                                <img className=''  style={{ width: "60%" }} src={flipbookData.brandLogoUrl} alt="Logo" />
                                            </div>
                                        </div>
                                        <div className="row">
    {productPair && productPair.map((product, idx) => (
        <div key={idx} className={`sales-product-info ${productPair.length === 1 ? 'single-product' : ''}`}>
              <div className='row'>
    <div className='col-md-4'>
        <Card className="flipbook2-tz-product-view-card" style={{ border: "1px solid #C7CFDE", backgroundColor: "#FFFFFF" }}>
            <div className="flipbook-tz-showcase-frame ">
                <img src={product.thumbnailUrl} className='flipbook2-thumb-image' alt="Product Thumbnail" />
            </div>
        </Card>
    </div>
    <div className='col-md-4'>
        <div className='flipbook-cont1'>
        <div className='flipbook-upper-spacing'>
        <div className="custom-font-10"> 
        <OverlayTrigger
      placement="top"
      overlay={
        <Tooltip id="tooltip" >
Click here
        </Tooltip>
      }
    >
      <a
        href={product.newLink}
        target="_blank"
        rel="noopener noreferrer"
        className="title-link"
      >
        <span className="title-data">{product.title}</span>
      </a>
    </OverlayTrigger></div>      
                        </div>
            {/* <div>
                <span className="label mt-1">Brand:</span>
                <span className="data custom-font-12">{product.brand}</span>
            </div>
            <div>
                <span className="label">Model Number:</span>
                <span className="data">{product.modelNumber}</span>
            </div>
            <div>
                <span className="label">Model Name:</span>
                <span className="data ">{product.modelName}</span>
            </div>
            <div>
                <span className="label">SKU:</span>
                <span className="data">{product.modelName}</span>
            </div> */}
         { flipbookData && flipbookData.attributes.map((attribute) => (
      product.hasOwnProperty(attribute) && (
        <div key={attribute} className="product-detail mt-1">
          <span className="label">{attribute.charAt(0).toUpperCase() + attribute.slice(1)}:</span>
          <span className="data">{product[attribute]}</span>
        </div>
      )
    ))}
        </div>
      
    </div>
    <div className='col-md-4'>
        <div className="image-text-container">
            <img className="qr-code-image" src={`data:image/png;base64,${product.qrcodeResponse}`} alt="Base64 Image" />       
        </div>
        <div className="scan-text centered-content ">
                Scan this for more details
            </div>
    </div>
</div>

        </div>
    ))}
</div>

                                        
                                        <div className="footer mb-1">
                                            Powered by Trezi
                                          <div><img class="sales-trezi-logo" src={TreziLogo} alt="Trezi Logo" ></img>
                                            </div>  
                                        </div>
                                    </div>
                                ))}
<div className="demoPage" >      
    <div className='full-size-container' 
   style={{  backgroundImage: `url("${flipbookFieldsData[2]?.value}")`, // Use the imported image as background
   backgroundSize: 'cover',
   backgroundRepeat: 'no-repeat',}}>
    </div>
</div>
</HTMLFlipBook>
                </div>
            </div>

      </div>
);
  
};
export default withParams(FlipbookHorDouble);
