import { Component } from "react";

export default class AccordionHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { openAccordions, text } = this.props;
    return (
      <>
        <i
          className={`bi bi-caret-${
            openAccordions.includes(text === "Product Attributes" ? "0" : "1")
              ? "down"
              : "right"
          }-fill`}
          style={{
            color: "#8599BA",
            marginRight: "16px",
            width: "14px",
            height: "14px",
          }}
        ></i>
        <span
          style={{ marginLeft: "-10px", width: "420px" }}
          className="custom-font-12"
        >
          {text}
        </span>
      </>
    );
  }
}
