import React, { useState, useEffect } from 'react';
import { useNavigate  } from 'react-router-dom';
import "./ThankYouPage.css";
import creditcard from "../assests/public/credit-card__2_.png"
import { baseUrlUser } from '../utils/common';

const urlPayment = baseUrlUser;
const ThankYouPage = () => {
  const navigate = useNavigate();
  const [paymentData, setPaymentData] = useState(null);

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }

    return "";
  };
  useEffect(() => {
    document.title = 'Payment Success'; // Set the desired page title
  }, []);
  useEffect(() => {
    debugger;
    // Retrieve the paymentId from localStorage
    const paymentId = localStorage.getItem('paymentId');
    const jwt = getCookie('jwt');
  
    if (!jwt) {
      console.log('JWT token not found. Returning...');
      return; // Exit the function if the JWT token is not found
    }
  
    if (paymentId) {
      fetch(`${urlPayment}payments/getpayment?paymentId=${paymentId}`, {
        method: 'GET',
        headers: {
          'Cookie': `jwt=${jwt}`,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          setPaymentData(data);
          console.log(data);
        })
        .catch(error => {
          console.log('Failed to fetch payment data:', error);
        });
    }
  }, []);
  
  const handleLogout = async (e) => {
    e.preventDefault();
    const jwt = getCookie('jwt');
  
    if (!jwt) {
      console.error('JWT token or email not found in cookies');
      // Handle the error, display an appropriate message to the user, or redirect to the login page
      return;
    }
  
    try {
      const response = await fetch(`${urlPayment}users/signout`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Cookie': `jwt=${jwt}`,
          'Authorization': `Bearer ${jwt}`
        }
      });
  
      if (response.ok) {
        document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        navigate("/");
        console.log('User logged out successfully.');
        // Perform any additional actions after logout
      } else {
        // Logout failed
        console.error('User logout failed.');
      }
    } catch (error) {
      console.error('An error occurred during user logout:', error);
    }
  };
  
  const handleDownloadReceipt = () => {
    // Logic to download the receipt
    // Add your implementation here
  };
  const formatTransactionDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Adjust the formatting options as needed
  };


  const handleLogin = () => {
    handleLogout();
    navigate("/"); // Replace "/login" with the actual route/path of your login page
  };

  return (
    <div className="thank-you-page">
          <div className="box-container">
      <div className="thank-you-page">
        <img src={creditcard} alt="Trezi Logo" className="logo" />
        <h1>Thank You!</h1>
        {paymentData ? (
          <div>
            <p>Your payment has been received successfully.</p>
            <ul>
            <li className='mt-2'>Payment Name:{paymentData.name}</li>
            <li className='mt-2'>Payment Details:{paymentData.plan}</li>
              <li className='mt-2'>Payment ID: {paymentData.transactionId}</li>
              <li className='mt-2'>Transaction Date: {formatTransactionDate(paymentData.transactionDate)}</li>
              {/* Add more payment details as needed */}
            </ul>
            <div className="button-container">
              <button className="login-button" onClick={handleLogin}>Login Securely</button>
            </div>
          </div>
        ) : (
          <h5>Loading payment data...</h5>
        )}
      </div>
    </div>
      </div>

  );
};

export default ThankYouPage;
