import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import './Modal.css';

class ModalComponent extends Component {
  render() {
    const { showModal, title, body, handleModal, footer } = this.props;

    return (
      <Modal show={showModal} onHide={handleModal}  dialogClassName="custom-modal"  centered >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='tz-modal-body'>{body}</Modal.Body>
        <Modal.Footer>{footer}</Modal.Footer>
      </Modal>
    );
  }
}

export default ModalComponent;