import React from "react";
import { Nav, Form, Dropdown , Badge} from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { getCartValue} from "../../services/ProductsService";
import Logo from "../../assets/trezi-logo.png";
import UserImage from "../../assets/UserImage.png";
import OrderImage from "../../assests/public/Vector (3).png";
import Swal from 'sweetalert2';
import category from "../../assets/category.svg";
import cluster from "../../assets/cluster.svg";
import attribute from "../../assets/attribute.svg";
import inventory from "../../assets/inventory.svg";
import fusion from "../../assests/public/Group 3225 (1).png";
import generativeai from "../../assests/public/creative (1).png";
import help from "../../assets/help.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import logoutIcon from "../../assests/public/icon_logout.png";
import profileIcon from "../../assests/public/icon_profile.png";
import settingIcon from "../../assests/public/settings.png";
import subscriptionIcon from "../../assests/public/icon_My subscription.png";
import { baseUrlUser } from "../../utils/common";
import { ListItemIcon } from "@material-ui/core";
// Header CSS
import "./Header.css";

const urlUser = baseUrlUser;

function getCookie(name) {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
}

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

function withNavigation(Component) {
  return (props) => <Component {...props} navigate={useNavigate()} />;
}

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventKey: "",
      CartData: props.cartData || {},
    };
  }

  componentDidMount() {
    this.updateActiveTab();
    this.fetchAllCartItems();

  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.updateActiveTab();
    }
    if (prevProps.cartData !== this.props.cartData) {
      // Update the state with the new cartData
      this.setState({
        CartData: this.props.cartData,
      });
    }
  }

  // continuouslyFetchCartItems = () => {
  //   this.fetchInterval = setInterval(() => {
  //     this.fetchAllCartItems();
  //   }, 5000);
  // };
  componentWillUnmount() {
    // clearInterval(this.fetchInterval);
  }
  async fetchAllCartItems() {
    try {
      const logs = await getCartValue();
      this.setState({
        CartData: logs.data,
      });
    } catch (err) {
      console.log(err);
    }
  }
  
  updateActiveTab() {
    const { pathname } = window.location;
    const { productId } = this.props.params;
    const eventKey =
      pathname === "/product-catalog" || pathname === `/products/${productId}`
        ? "2"
        : pathname === "/dashboard"
        ? "1"
        : pathname === "/e-catalog" ||  pathname === "/tempelatePage"
        ? "3"
        : "";
    this.setState({ eventKey });
  }

  // On search, redirect to product catalog and then search.
  handleSearch = (event) => {
    const query = event.target.value;
    this.props.setSearchQuery(query);

    if (window.location.href !== `${window.location.origin}/userlist`) {
      // Navigate to the Product Catalog tab while searching for products
      const query = event.target.value;
      this.props.setSearchQuery(query);
      this.setState({ eventKey: "2" });
      this.props.navigate("/product-catalog");
    } else {
      this.setState({ eventKey: "1" });
      // this.props.navigate("/userlist"); 
    }
  };
  handleLogout = async (e) => {
    e.preventDefault();
    const { navigate } = this.props;
    const treziAccessToken = getCookie("treziAccessToken");
     if (!treziAccessToken) {
      console.error("JWT token or email not found in cookies"); 
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'JWT token or email not found in cookies',
      });
      return;
    }
  
    try {
      const response = await fetch(`${urlUser}users/logout`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Cookie: `treziAccessToken=${treziAccessToken}`,
          Authorization: `Bearer ${treziAccessToken}`,
        },
      });
  
      if (response.ok) {
        document.cookie = "trezi-access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "orgIdentifier=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "treziAccessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem("role");
        localStorage.removeItem("treziAccessToken");
        localStorage.removeItem("organizationIdentifier");
        navigate("/");
        console.log("User logged out successfully.");
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Logged Out',
        //   text: 'User logged out successfully.',
        // });
      } else {
        // Logout failed
        console.error("User logout failed");
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'User logout failed , JWT Token expired.',
        });
        window.location.href = "/";
      }
    } catch (error) {
      console.error("An error occurred during user logout:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred during user logout',
      });
    }
  };
  handleClick = (event) => {
    const { navigate } = this.props;
    const option = event.target.getAttribute("data-option");
  
    // Deselect both tabs by setting eventKey to an empty string
    this.setState({ eventKey: "" });
  
    if (option === "inventory") {
      navigate("/inventory");
    }
    if (option === "order-management") {
      navigate("/order-management");
    }
    if (option === "product-attributes") {
      navigate("/product-attribute");
    }
    if (option === "product-clusters") {
      navigate("/product-clusters");
    }
    if (option === "Product-categories") {
      navigate("/product-category");
    }
    if (option === "generate-AI") {
      navigate("/generate-ai-image");
    }
    if (option === "overlay-img") {
      navigate("/overlay-image");
    }
  
    // Close the off-canvas menu
    const toggleElement = document.querySelector(".tz-toggle-left-menu");
    if (toggleElement) {
      toggleElement.click();
    }
  };
  
  
  
  render() {
    const { eventKey  } = this.state;
    const {  CartData } = this.state;
    console.log(CartData);
    return (
      <>
        {[false].map((expand, index) => (
          <React.Fragment key={index}>
            <Navbar
              className="tz-navbar"
              sticky="top"
              key={`navbar-${expand}-${index}`}
              bg="dark"
              variant="dark"
              expand={expand}
            >
              <Container fluid className="tz-toggle">
                <div className="tz-header-left-section">
                  <Navbar.Toggle
                    aria-controls={`offcanvasNavbar-expand-${expand}`}
                    className="tz-toggle-left-menu"
                  />
                  <Navbar.Brand href="/dashboard" className="tz-logo-container">
                    <img src={Logo} alt="Logo" />
                    <span>Trezi</span>
                  </Navbar.Brand>
                </div>

                <Form className="d-flex tz-textbox-with-icon tz-search-textbox-container" onSubmit={(e) => {
  e.preventDefault(); 
}}>
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="tz-textbox tz-textbox-dark"
                    aria-label="Search"
                    onChange={this.handleSearch}
                  />
                  <i className="bi bi-search"></i>
                </Form>
                < div className="tz-header-right-section d-flex align-items-center " >
                <div className="position-relative">
                <Link to="/cart-items">
            <img
              src={OrderImage}
              alt="Order"
              width={30}
              height={30}
              style={{ marginRight: '10px' }}
            />
               <Badge bg="primary" className="cart-badge">
               {CartData && CartData.itemCount}
    </Badge>
          </Link>
  </div>
                  < div href="/dashboard" >
                  <Dropdown >
                  <Dropdown.Toggle className="tz-user-dropdown">
                    <img src={UserImage} alt="User" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="tz-user-menu">
                    <Dropdown.Item className="myprofile-button" navigate ="/adduser">
                    <Link to="/adduser" className="dropdown-link">
      <ListItemIcon>
        <img src={profileIcon} alt="Logout" width={20} height={20} />
        <span></span>
        <span className="myprofile-text">My Profile</span>
      </ListItemIcon>
    </Link>
                    </Dropdown.Item>
                    <Dropdown.Item  className="user-button"  navigate ="/userlist" >
                    <Link to="/userlist" className="dropdown-link">
                      <ListItemIcon>
                        <img
                          src={settingIcon}
                          alt="User"
                          width={20}
                          height={20}
                        />{" "}
                        <span></span><span className="user-text">User Management</span>
                      </ListItemIcon>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="subscription-button"  navigate ="/mysubscription" >
                    <Link to="/mysubscription" className="dropdown-link">
                      <ListItemIcon>
                        <img
                          src={subscriptionIcon}
                          alt="Subscription"
                          width={20}
                          height={20}
                        />{" "}  <span></span><span className="subscription-text"> My Subscription  </span>
                      </ListItemIcon>
                      </Link>
                    </Dropdown.Item>
                    <Dropdown.Item className="logout-button"  onClick={this.handleLogout} href="#">
                      <ListItemIcon>
                        <img
                          src={logoutIcon}
                          alt="Logout"
                          width={20}
                          height={20}
                        />{" "}
                   <span></span>  <span className="logout-text">Logout</span>
                      </ListItemIcon>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
                  </div>
  </div>
                

                <Navbar.Offcanvas
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="start"
                >
                  <Offcanvas.Body className="tz-toggle-body">
                    <div data-option="inventory" onClick={this.handleClick}>
                      <img src={inventory} alt="Inventory log" />{" "}
                      <span  data-option="inventory">Inventory Log</span> 
                    </div>
                    <div data-option="order-management" onClick={this.handleClick}>
                      <img src={OrderImage} width={15} alt="order-management" />{" "}
                      <span  data-option="order-management">Order Management</span> 
                    </div>
                    <div data-option="product-attributes" onClick={this.handleClick}>
                      <img src={attribute} alt="Product Attributes" />{" "}
                      <span  data-option="product-attributes">Product Attributes</span>
                    </div>
                    <div data-option="product-clusters" onClick={this.handleClick}>
                      <img src={cluster} alt="product-clusters" />{" "}
                      <span data-option="product-clusters">Product Clusters</span>
                    </div>
                    <div   data-option= "Product-categories" onClick={this.handleClick} >
                      <img src={category} alt="Product-categories" />{" "}
                      <span   data-option= "Product-categories" >Product Categories</span>
                    </div>
                    <div data-option="generate-AI"  onClick={this.handleClick}>
                      <img    style={{width :"15px"}}  src={generativeai} alt="Generate-AI" />{" "}
                      <span data-option="generate-AI">Generate AI Image</span>
                    </div>
                    <div data-option="overlay-img"  onClick={this.handleClick}>
                      <img className="fusion-design" src={fusion} alt="overlay-img"  />{" "}
                      <span data-option="overlay-img">Fusion Canvas</span>
                    </div>
                    <div data-option="help"  onClick={this.handleClick}>
                      <img src={help} alt="Help and Support"  />{" "}
                      <span data-option="help">Help & Support</span>
                    </div>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
            <Navbar
              key={`navbar-header-${expand}-${index}`}
              bg="dark"
              variant="dark"
              className="tz-header-navbar"
            >
              <Container fluid>
                <Nav
                  className="d-flex justify-content-center tab tz-header-tabs"
                  key={`${expand}-${index}`}
                  fill
                  variant="tabs"
                  activeKey={eventKey}
                  onSelect={(selectedTab) =>
                    this.setState({ eventKey: selectedTab })
                  }
                >
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      eventKey="1"
                      active={eventKey === "1"}
                      to="/dashboard"
                    >
                      Home
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      eventKey="2"
                      active={eventKey === "2"}
                      to="/product-catalog"
                    >
                      Product Listing
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      eventKey="3"
                      active={eventKey === "3"}
                      to="/e-catalog"
                    >
                 E-Catalog by Trezi
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Container>
            </Navbar>
          </React.Fragment>
        ))}
      </>
    );
  }
}
export default withNavigation(withParams(Header));
