import "./App.css";
import { Component } from "react";
import AllRoutes from "./routes/AllRoutes";
import { Route,} from "react-router-dom";
export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  
  render() {
    return (
      <div className="tz-app">
         <AllRoutes />
      </div>
    );
  }
}
