import React from "react";
import {
  Col,
  Container,
  Row,
  Card,
  Image,
  Tooltip,
  OverlayTrigger,
  Navbar,
  Stack
} from "react-bootstrap";
import { viewProductByPublicId ,getProductById,getProductByIdforPublic} from "../services/ProductsService";
import { useParams } from "react-router-dom";
import "../css/ProductDetails.css";
import "../css/ProductCatalog.css";
import AccordionComponent from "../components/Accordion/Accordion";
import AccordionHeader from "../components/Accordion/AccordionHeader";
import AccordionBody from "../components/Accordion/AccordionBody";
import Logo from "../assets/trezi-logo.png";
import VectarybrokenUrl from '../assests/public/Error (1).png';
import Swal from 'sweetalert2';
function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

class ProductDetailsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      openAccordions: [],
      frameImage: null,
      image: null,
      clicked: false,
      showModal: false,
      link: "",
      viewCount: null,
      shareCount: null,
      isUpdated: false,
    };
  }

  componentDidMount() {
    this.ViewProductById();
    document.title = "Product Details";
  }
  
  getCookie = (name) => {
    const cookieString = document.cookie;
    const cookies = cookieString.split(';');
   for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + '=')) {
        return cookie.substring(name.length + 1);
      }
    }
    return null;
  };
  async ViewProductById() {
    try {
      const { productId } = this.props.params;
      const organizationIdentifier = this.getCookie('organizationIdentifier');
      const product = await viewProductByPublicId(productId);
  
      if (product.status === 200) {
        this.setState((prevState) => ({
          data: product.data,
        }));
        console.log(product, "data")
//       const metaTitle = document.querySelector('meta[property="og:title"]');
//       const metaDescritption = document.querySelector('meta[property="og:description"]');
//         const metaImage = document.querySelector('meta[property="og:image"]');
// console.log(metaTitle, "metaTitle")
// console.log('Product Title:', product.data.title);
// console.log('Product Description:', product.data.description);
// console.log('Product Image URL:', product.data.thumbnails[0].fileUrl);
//         metaTitle.setAttribute('content', product.data.title);
//         metaDescritption.setAttribute('content', product.data.description);
//         metaImage.setAttribute('content', product.data.thumbnails[0].fileUrl);
        let image = product.data.bakedImageUrl;
        if (image !== null) {
          try {
            const response = await fetch(image);
   console.log(response , "response");
            if (response.status === 200) {
              const encodedURL = encodeURI(response.url);
              this.setState((prevState) => ({
                frameImage: encodedURL,
              }));
              console.log('frameImage:', this.state.frameImage);
            } else {
              this.setState((prevState) => ({
                frameImage: VectarybrokenUrl, // Default image for 404 responses
              }));
              console.log('frameImage:', this.state.frameImage);
            }
            this.handleGetShareCount(product.status, product.status);
            this.handleGetViewCount(product.status, product.status);
            this.forceUpdate();
          } catch (error) {
            console.error('Error checking image:', error);
          }
        }
      } else {
        // Handle the case when the product is not in a published state
        Swal.fire({
          icon: 'error',
          title: 'Product Error',
          text: 'Product is not available in a published state.',
        });
      }
    } catch (err) {
      console.error('Error:', err);
    }
  }
  

  toggleAccordion = (eventKey) => {
    const openAccordions = [...this.state.openAccordions];
    const index = openAccordions.indexOf(eventKey);

    if (index > -1) {
      openAccordions.splice(index, 1);
    } else {
      openAccordions.push(eventKey);
    }

    this.setState({
      openAccordions: openAccordions,
    });
  };
  handleFrameImage = (file) => {
    debugger;
    if (file.isDefault === true) {
      const bakedImageUrl = this.state.data?.bakedImageUrl;
      if (bakedImageUrl !== null) {
        fetch(bakedImageUrl)
          .then((response) => {
            if (response.status === 200) {
              const encodedURL = encodeURI(response.url);
              this.setState({
                frameImage: encodedURL,
                image :encodedURL
              });
              console.log('frameImage3:', this.state.frameImage);
            } else {
              this.setState({
                frameImage: VectarybrokenUrl,
                image :VectarybrokenUrl // Default image for 404 responses
              });
            }
          })
          .catch((error) => {
            console.error('Error checking image:', error);
          });
      }else{
        this.setState({ image: file.fileUrl });
      }
      // this.setState({ image: null });
    } else {
      this.setState({ image: file.fileUrl });
      this.setState({ frameImage: null});
    }
    console.log('frameImage:', this.state.frameImage);
  };
  

  render() {
    const { data, openAccordions, frameImage, image, shareCount, viewCount } =
      this.state;

    const TimestampInfo = (time) => {
      const timestamp = time;
      const datetimeObj = new Date(timestamp);

      const month = datetimeObj.toLocaleString("default", { month: "short" });
      const date = datetimeObj.getDate();
      const year = datetimeObj.getFullYear();

      const hours = datetimeObj.getHours();
      const minutes = datetimeObj.getMinutes();

      const formattedHours = hours % 12 || 12;
      const amPm = hours >= 12 ? "PM" : "AM";
      const formattedTime = `${formattedHours}:${minutes
        .toString()
        .padStart(2, "0")} ${amPm}`;

      return (
        <>
          <span className="tz-month custom-font-12">
            {date} {month}, {year}, {formattedTime}
          </span>
        </>
      );
    };

    return (
      <>
        <>
          {[false].map((expand, index) => (
            <React.Fragment key={index}>
              <Navbar
                className="tz-navbar"
                sticky="top"
                key={`navbar-${expand}-${index}`}
                bg="dark"
                variant="dark"
                expand={expand}
              >
                <Container
                  fluid
                  className="tz-toggle"
                  style={{ height: "120px" }}
                >
                  <div className="tz-header-left-section mx-auto d-flex align-items-center">
                    <Navbar.Brand
                      // href="/dashboard"
                      className="d-flex align-items-center"
                      style={{ marginTop: "0px" }}
                    >
               {data?.brandLogo?.fileUrl ? (
          <img
            src={data.brandLogo.fileUrl}
            alt="Brand Logo"
            style={{     minWidth: "130px",
            minHeight: "30px",
            maxWidth: "230px",
          maxHeight :"60px"}}
          />
        ) : null}
                    </Navbar.Brand>
                  </div>
                </Container>
              </Navbar>
            </React.Fragment>
          ))}
        </>
        <div className="tz-product-catalog-container">
        <Stack
            direction="horizontal"
            gap={3}
            className="list-dashboard-header"
          >
            <div className="custom-font-20 product-catalog">
              Product Details
            </div>
            </Stack>
          <Container fluid className="tz-product-details-container">
            <Row className="tz-product-details-row lg-3">
              <>
                <Col
                  style={{ paddingLeft: "0", paddingRight: "2px" }}
                  className="small-image-container"
                >
                  { data?.thumbnails.map((file, index) => (
                    <Card
                      key={index}
                      style={{
                        minWidth: "100px",
                        minHeight: "100px",
                        maxWidth: "116px",
                        marginBottom: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => this.handleFrameImage(file)}
                    >
                      <Card.Body>
                        <Image
                          src={file.fileUrl}
                          alt={file.fileName}
                          style={{
                            width: "80px",
                            height: "80px",
                          }}
                        />
                      </Card.Body>
                    </Card>
                  ))}
                </Col>
                <Col className="tz-product-details-frame">
                  {/* Second Column */}
                  <Card
                    className="tz-product-view-card"
                    style={{
                      border: "1px solid #C7CFDE",
                      backgroundColor: "#FFFFFF",
                    }}
                  >
                    {/* <div className="tz-showcase-frame">
                      {frameImage !== null ? (
                        <iframe
                          title="Vactary 3D viewer"
                          src={frameImage}
                          width="100%"
                          height="100%"
                        ></iframe>
                      ) : image !== null ? (
                        <img src={image} alt="Product" height="100%" />
                      ) : (
                        ""
                      )}
                    </div> */}
                    <div className="tz-showcase-frame">
                      {this.state.frameImage !== null ? (
                        <iframe
                          title="Vactary 3D viewer"
                          src={this.state.frameImage}
                          width="100%"
                          height="100%"
                        ></iframe>
                      ) : image !== null ? (
                        <img src={image} alt="Product" height="100%" />
                      ) : (
                        data?.thumbnails[0] && (
                          <img
                            src={data?.thumbnails[0].fileUrl}
                            alt={data?.thumbnails[0].fileName}
                            height="100%"
                          />
                        )
                      )}
                    </div>
                  </Card>
                </Col>
                <Col className="tz-product-details" md="auto">
                  {/* Third Column */}
                  <Card className="tz-product-details-card">
                    <div>
                      <div
                        style={{
                          fontWeight: "700",
                          fontSize: "20px",
                          color: "#13161B",
                        }}
                      >
                        {data?.title}
                      </div>
                      <div>
                        <OverlayTrigger
                          placement="bottom-start"
                          overlay={
                            <Tooltip className="tz-tooltip">TZN Number</Tooltip>
                          }
                        >
                          <span
                            style={{
                              color: "#6A6D74",
                              fontWeight: "400",
                              fontStyle: "normal",
                            }}
                            className="custom-font-12"
                          >
                            {/* {data?.productId}{" "} */}
                          </span>
                        </OverlayTrigger>
                        {/* <span style={{ color: "#6A6D74" }}>{"| "}</span> */}
                        <OverlayTrigger
                          placement="bottom-end"
                          overlay={
                            <Tooltip className="tz-tooltip">
                              Product Category
                            </Tooltip>
                          }
                        >
                          <span
                            style={{
                              color: "#6A6D74",
                              fontWeight: "400",
                              fontStyle: "normal",
                            }}
                            className="custom-font-12"
                          >
                            {data?.categoryName}
                          </span>
                        </OverlayTrigger>
                      </div>

                      {/* Show status if status is present */}
                      {data?.status ? (
                        <div
                          style={{
                            marginTop: "15px",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <span>
                            <i
                              className="bi bi-eye-fill custom-font-16"
                              style={{ color: "#8599BA" }}
                            ></i>{" "}
                          </span>
                          <span
                            style={{ marginLeft: "10px" }}
                            className="custom-font-12"
                          >
                            {viewCount}
                          </span>

                          <span style={{ marginLeft: "20px" }}>
                            <i
                              className="bi bi-share-fill custom-font-16"
                              style={{ color: "#8599BA" }}
                            ></i>{" "}
                          </span>
                          <span
                            style={{ marginLeft: "10px" }}
                            className="custom-font-12"
                          >
                            {shareCount}
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}

                      <div style={{ marginTop: "15px" }}>
                        {/* Show creation date if present */}
                        {data?.createdAt ? (
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <span>
                              <i
                                className="bi bi-file-earmark-fill"
                                style={{ color: "#8599BA" }}
                              ></i>{" "}
                            </span>
                            <span
                              style={{ marginLeft: "10px" }}
                              className="custom-font-12"
                            >
                              Created on : {TimestampInfo(data?.createdAt)}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* Show modified date if present*/}
                        {data?.updatedAt ? (
                          <div
                            style={{
                              marginTop: "5px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <span>
                              <i
                                className="bi bi-arrow-clockwise custom-font-16"
                                style={{ color: "#8599BA" }}
                              ></i>{" "}
                            </span>
                            <span
                              style={{ marginLeft: "10px" }}
                              className="custom-font-12"
                            >
                              Modified on : {TimestampInfo(data?.updatedAt)}
                            </span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>

                      <div className="tz-accordion">
                        {/* Product attributes panel */}
                        <AccordionComponent
                          accordionClass={"align-left"}
                          bodyClass={"tz-app"}
                          eventKey={"1"}
                          onClick={() => this.toggleAccordion("0")}
                          header={
                            <AccordionHeader
                              openAccordions={openAccordions}
                              text={"Product Attributes"}
                            />
                          }
                          body={
                            <AccordionBody
                              text={"Product Attributes"}
                              data={data}
                            />
                          }
                        />

                        {/* Product description panel */}
                        <AccordionComponent
                          accordionClass={"align-left mt-3"}
                          bodyClass={"tz-app"}
                          eventKey={"1"}
                          onClick={() => this.toggleAccordion("1")}
                          header={
                            <AccordionHeader
                              openAccordions={openAccordions}
                              text={"Product Description"}
                            />
                          }
                          body={
                            <AccordionBody
                              text={"Product Description"}
                              data={data}
                            />
                          }
                        />
                      </div>
                    </div>
                  </Card>
                </Col>
              </>
            </Row>
          </Container>
        </div>
        <div className="powered-by-container" style={{ position: 'fixed', bottom: '0', left: '0', right: '0', background: '#000', color: '#fff', textAlign: 'center', padding: '10px 0' }}>
  <div className="powered-by">
    Powered By Trezi
  </div>
</div>
      </>
    );
  }
}
export default withParams(ProductDetailsView);
