import { Modal } from "react-bootstrap";
import React, { useState,useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  Grid,InputLabel
} from "@mui/material";
import logolink2 from "../assests/public/trezi_logo.png";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { baseUrlUser } from "../utils/common";
const urlUser = baseUrlUser;


const ForgotPassword = ({ show, onClose }) => {
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const[error , setError ] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if(email !== confirmEmail){
      setError("Enter correct Email");
      return;
    }
    try {
      const response = await fetch(
       `${urlUser}users/forgot/password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );
  
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success!",
          text: "Forgot password link sent successfully on the registered email address.",
        });
        navigate("/");
        console.log("Forgot link sent successfully");
      } else {
        setError("This email address doesn't exist.");
      }
    } catch (error) {
      console.error(
        "An error occurred while sending the password reset request:",
        error
      );
      // Handle the error case as per your requirements
    }
  };
  useEffect(() => {
    document.title = 'Forget Password'; // Set the desired page title
  }, []);
  const handleCancel = (e) => {
    e.preventDefault();
    navigate("/");
  };

  return (
    <div className="box8">
    <Grid container>
      <Grid item>
      <Box className="box mt-0 mb-0" p={4}>
      <div >
            <img src={logolink2} alt="Logo" style={{ width: "100px" }}  />
                    </div>
          <Typography
            className="mt-4"
            style={{ fontSize: "22px" }}
            component="h1"
            variant="h5"
          >
            Forgot your Password?
          </Typography>
          <Typography
            textAlign="left"
            className="mt-4"
            variant="subtitle2"
            component="div"
            color="textSecondary"
          >
            Enter your registered email address to retrieve your password
          </Typography>
          <div className="forgot-form-container">
          {error && <span className="text-danger mt-2">- {error}</span>}
          <form component="form" onSubmit={handleSubmit} sx={{ mt: 5 }}>
          <Grid container spacing={2}>
            <Grid className="mt-2" item xs={12}>
              <InputLabel shrink>Email Address*</InputLabel>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  required
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel shrink>Confirm Email Address*</InputLabel>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  required
                  value={confirmEmail}
                  onChange={(e) => setConfirmEmail(e.target.value)}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Button
            className="mt-4"
            style={{ height: "55px", textTransform: "none" }}
            type="submit"
            variant="contained"
            fullWidth
          >
            Send Link
          </Button>
        </form>
            <Button
              className=""
              style={{ height: "55px", textTransform: "none" }}
              type="submit"
              onClick={handleCancel}
              fullWidth
            >
              <Link to="/" className="text-reset">
                Cancel
              </Link>
            </Button>
          </div>
          <Box mt={3}>
            <div className="mt-3 text-start small-text custom-link-anchor">
              By logging into Trezi and its services you are agreeing to our{" "}
              <a href="https://trezi.com/terms-of-use/">Terms & Conditions</a> and <a href="https://trezi.com/privacy-policy/">Privacy Policy</a>
            </div>
          </Box>
        </Box>
      </Grid>
    </Grid>
  </div>

  );
};
export default ForgotPassword;
