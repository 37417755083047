import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import DashboardIndex from './DashboardIndex';

function ShowcaseSignedUPUsers() {
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [meta, setMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      let allUsers = [];
      let page = 0;
      let totalPages = 1;

      // Fetch all pages
      while (page < totalPages) {
        // const response = await axios.get(`https://showcaseapi.trezi.com/uus/api/v1/users/signup/list?product=Showcase&page=${page}&pageSize=50`);
        const response = await axios.get(`http://125.63.103.147:8080/uus/api/v1/users/signup/list?product=Showcase&page=${page}&pageSize=50`);
        allUsers = allUsers.concat(response.data.users);
        totalPages = response.data.meta.totalPages;
        page++;
      }

      // Sort combined data by 'createdAt' in descending order
      allUsers.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      setData(allUsers);
      setMeta({ ...meta, totalPages });
      setDisplayedData(allUsers.slice(currentPage * 50, (currentPage + 1) * 50)); // Adjust slice size based on your pagination needs
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    // Update displayed data whenever currentPage or data changes
    setDisplayedData(data.slice(currentPage * 50, (currentPage + 1) * 50)); // Adjust slice size based on your pagination needs
  }, [currentPage, data]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const exportToExcel = () => {
    if (data.length === 0) return; // Do nothing if there's no data

    const headers = ['Client ID', 'First Name', 'Last Name', 'Organization', 'Position', 'Phone', 'Address', 'Country', 'Subscription Date'];
    const worksheetData = [
      headers,
      ...data.map(user => [
        user.clientId || '',
        user.firstName || '',
        user.lastName || '',
        user.org || '',
        user.position || '',
        user.phone || '',
        `${user.addressLine1 || ''}${user.addressLine2 ? `, ${user.addressLine2}` : ''}, ${user.city || ''} ${user.state || ''} ${user.pinCode || ''}`,
        user.country || '',
        user.createdAt ? new Date(user.createdAt).toLocaleString() : ''
      ])
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Showcase Signed Up Users');
    XLSX.writeFile(workbook, 'Showcase_Signed_Up_Users.xlsx');
  };

  return (
    <div>
   <DashboardIndex/>
      <div className="App">
        {loading && <p>Loading data...</p>}
        {error && <p>Error: {error}</p>}
        {!loading && !error && (
          <div id="containerfortable">
            <div id="pagination">
              <h2 id="heading">Showcase Signed Up Users</h2>
              <div id="button">
                <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
              </div>
              <div id="pagination_controls">
                <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 0}>
                  Previous
                </button>
                <span> Page {currentPage + 1} of {Math.ceil(data.length / 50)} </span>
                <button onClick={() => handlePageChange(currentPage + 1)} disabled={(currentPage + 1) * 50 >= data.length}>
                  Next
                </button>
              </div>
            </div>
            <div className='table_container'>
              <table className="result-table">
                <thead>
                  <tr>
                    <th className='client-id'>Client ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Organization</th>
                    <th>Position</th>
                    <th>Phone</th>
                    <th>Address</th>
                    <th>Country</th>
                    <th>Subscription Date</th>
                  </tr>
                </thead>
                <tbody>
                  {displayedData.map((user) => (
                    <tr key={user.clientId}>
                      <td>{user.clientId || ''}</td>
                      <td>{user.firstName || ''}</td>
                      <td>{user.lastName || ''}</td>
                      <td>{user.org || ''}</td>
                      <td>{user.position || ''}</td>
                      <td>{user.phone || ''}</td>
                      <td>
                        {`${user.addressLine1 || ''}${user.addressLine2 ? `, ${user.addressLine2}` : ''}, ${user.city || ''} ${user.state || ''} ${user.pinCode || ''}`}
                      </td>
                      <td>{user.country || ''}</td>
                      <td>{user.createdAt ? new Date(user.createdAt).toLocaleString() : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default ShowcaseSignedUPUsers;

