import React, { Component } from 'react';
import './Catalog.css';
import Logo from "../../assests/public/banner.png";
import Logobluesofa from "../../assests/public/cover_page-01_720.png";
import Logoyellowsofa from "../../assests/public/cover_page-02_720.png";
import { Button , Modal ,Form , ButtonGroup , Dropdown} from 'react-bootstrap';
import PreviewModal from './PreviewModal'; 
import { getAllflipbookTemplates,getAllTemplates, updateflipbookStatus,getFlipbookByFlipbookIdentifier, createFlipbook ,deleteCustomerTemplate ,updateFlipbookShareCount,downloadTemplateqrcode , getProductById} from "../../services/ProductsService";
import Swal from 'sweetalert2';
import ReactPaginate from 'react-paginate';
import PreviewModalCreatedTemp from './PreviewModalCreatedTemp';
import Loader from '../../context/loader';
class Catalog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: 'create', // Set default selected option here
            showPreviewModal: false,
            imagesFromBackend: [],
            showModal: false,
            template: null,
            shareLink: '',
            showNewModal: false,
            selectedTemplate: null,
            loading: false ,
            currentPage: 1, // State to manage current page
            flipbooksPerPage: 6,
            openDropdownIndex: null 
        };
    }
    openModal = (template) => {
      debugger;
      if (template.status === "Published") {
          this.setState({ showModal: true, template });
      } else {
          // Show a SweetAlert indicating that the status needs to be changed to "published"
          Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Please change the status to "Published".',
          });
      }
  };
  
      handleStatusChange = async (flipBookIdentifier, status) => {
        try {
          // Perform the API call to update the status
          const updatedFlipbook = await updateflipbookStatus(flipBookIdentifier, status);
          const responseData = updatedFlipbook.data;
      
          if (updatedFlipbook.status === 200) {
            // Fetch updated flipbook data after status change
            await this.fetchUpdatedFlipbookData();
          } else {
            console.error('Failed to update flipbook status.');
          }
        } catch (error) {
          console.error('Error updating flipbook status:', error);
        }
      };
      
      async fetchUpdatedFlipbookData() {
        try {
          debugger;
          const updatedFlipbooks = await getAllflipbookTemplates();
          if (Array.isArray(updatedFlipbooks.data)) {
            this.setState({
              imagesFromBackend: updatedFlipbooks.data,
              loading: false,
              // currentPage: 1,
            });
          }
        } catch (error) {
          this.setState({ loading: false });
          alert("Error!", `Failed to fetch updated flipbook data: ${error.message}`, "error");
        }
      }

      async componentDidMount() {
        await this.fetchAllTemplates();
        document.title = 'E-Catalog';
      }
      async fetchAllTemplates() {
        try {
          debugger;
          const allTemplates = await getAllTemplates();
          console.log('All Templates:', allTemplates);
          this.setState({
            allTemplates: allTemplates.data // Assuming the fetched templates are stored in `data` property
          });
        } catch (error) {
          if(error.response.status === 401){
            Swal.fire({
              title: 'Unauthorized!',
              text: 'Jwt token Expired. Please Login again.',
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000 // 2 seconds
            }).then(() => {
              window.location.href = "/";
            });
          }else{
            Swal.fire({
              title: 'Error!',
              text: 'Something went wrong while fetching templates. Please try again later.',
              icon: 'error',
              confirmButtonText: 'Ok'
            });
            console.error('Error fetching templates:', error);
          }
        }
      }
      
      closeModal = () => {
        this.setState({ showModal: false });
      };
      handleShareButtonClick = (template) => {
        debugger;
        const url = window.location.origin;
        const flipbookIdentifier = template.flipBookIdentifier;
        const templateIdentifier = template.templateIdentifier;
        const newLink = `${url}/${templateIdentifier}/${flipbookIdentifier}`;
        this.setState({
          shareLink : newLink,
        })
        this.openModal(template);
      };
      copyLink = async (flipBookIdentifier) => {
        const { template } = this.state;
        debugger;
        try {
            await updateFlipbookShareCount(flipBookIdentifier);
          const linkInput = document.getElementById("link-input");
          linkInput?.select();
          document.execCommand("copy");
          // alert('Link copied to clipboard!');
        } catch (error) {
          console.log(error);
        }
      };
      async downloadQRCode(flipBookIdentifier) {
        debugger;
        try {
                const templateresponse = await getFlipbookByFlipbookIdentifier(flipBookIdentifier);
                const url = window.location.origin;
                const linkInput = templateresponse.data;
                const Templateidentifier = linkInput.templateIdentifier
                // const newLink = linkInput?.replace(/.*\/v1/, `${url}`);
                // const linkWithoutPrefix = linkInput.replace(/.*\/v1\/products\//, '');
                const newLink = `${url}/${Templateidentifier}/${flipBookIdentifier}`;
          const response = await downloadTemplateqrcode(newLink);
    
          if (response) {
    
            let fileName = 'qrcode.png'; // Default filename
    
            const blobUrl = "data:image/png;base64," + response.data.qrCodeBytes;
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = blobUrl;
            a.download = fileName;
    
            document.body.appendChild(a);
            a.click();
    
            // Clean up by revoking the object URL and removing the anchor element
            window.URL.revokeObjectURL(blobUrl);
            document.body.removeChild(a);
            Swal.fire({
              title: 'Success!',
              text: 'QR downloaded successfully.',
              icon: 'success',
              timer: 2000, // Optional: Automatically close after 2 seconds
            });
          } else {
            Swal.fire({
              title: 'Error!',
              text: 'Failed to download the QR.',
              icon: 'error',
            });
          }
        } catch (err) {
          console.error(err);
          Swal.fire({
            title: 'Error!',
            text: 'Failed to download the QR.',
            icon: 'error',
          });
        }
      }
   handleOptionClick = async (option) => {
        debugger;
        this.setState({ selectedOption: option , loading: true});
            try {
                const images = await getAllflipbookTemplates(); 
                const imageData =images.data
                this.setState({
                    selectedOption: option,
                    imagesFromBackend: imageData, 
                    loading: false ,
                    currentPage: 1,
                });
                console.log('imagesFromBackend', imageData);
            } catch (error) {
                this.setState({ loading: false });
                console.error('Error fetching images:', error);
            }
            this.setState({ selectedOption: option ,  loading: false });
        
    };

    
    handlePreviewButtonClick = (image) => {
     debugger;
        this.setState({ showPreviewModal: true, selectedImage: image });
      };
      

  handlePreviewModalClose = () => {
  debugger;
    this.setState({ showPreviewModal: false });
  };
  handlePreviewTemplateButtonClick = (template) => {
    debugger;
    this.setState({ showNewModal: true, selectedTemplate: template });
  };

  handleNewModalClose = () => {
    this.setState({ showNewModal: false });
  };
  handleUseTemplateClick =(image) =>{
    debugger;
    if(image === "furniture2"){
        window.location.href = `/templatePage/${image}`;
    }else if(image === "furniture1"){
        window.location.href = '/TemplatePage/furniture1';
    }else if(image === "landscape_blue"){
      window.location.href = '/TemplatePage/landscape_blue';
  }else if(image === "portrait_blue"){
    window.location.href = '/TemplatePage/portrait_blue';
  }else{
    window.location.href = `/templatePage/${image}`;
  }
  }
 handleDeleteButtonClick = async (flipbookidentifier) => {
    debugger;
    const { currentPage, flipbooksPerPage } = this.state;
    const confirmation = await Swal.fire({
        title: 'Are you sure?',
        text: 'You are about to delete this flipbook.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
    });
    if (confirmation.isConfirmed) {
        try {
            const deleted = await deleteCustomerTemplate(flipbookidentifier);
            if (deleted.status === 200) {
                Swal.fire({
                    title: 'Deleted!',
                    text: 'The flipbook has been deleted.',
                    icon: 'success',
                });
                const updatedTemplates = await getAllflipbookTemplates();
                const remainingFlipbooks = updatedTemplates.data.length;
                const newCurrentPage =
                    remainingFlipbooks <= flipbooksPerPage
                        ? 1 // If there are fewer flipbooks than the number per page, go back to the first page
                        : currentPage;

                this.setState({
                    imagesFromBackend: updatedTemplates.data,
                    currentPage: newCurrentPage,
                });
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: 'Failed to delete the flipbook.',
                    icon: 'error',
                });
            }
        } catch (error) {
            // Swal.fire({
            //     title: 'Error!',
            //     text: 'An error occurred while deleting the flipbook.',
            //     icon: 'error',
            // });
            this.setState({
              imagesFromBackend: [],
              currentPage: 1,
          });
            console.log( 'An error occurred while deleting the flipbook.' , error)
        }
    } else {
        Swal.fire('Cancelled', 'The deletion process was cancelled', 'info');
    }

}

handleDropdownToggle = (index) => {
  this.setState((prevState) => ({
    openDropdownIndex: prevState.openDropdownIndex === index ? null : index
  }));
};
    renderContent = () => {
        const { selectedOption ,imagesFromBackend ,allTemplates, showModal,shareLink , showNewModal , currentPage, flipbooksPerPage} = this.state;
        const { openDropdownIndex } = this.state;
        if (selectedOption === 'create') {
            return (
                <div>
                    <div className='custom-font-20' style={{ fontWeight: "bold" }}>Start With 4 Eye-catching Templates</div>
                    <div className='your-ecatalog-container'>
                      <div className='image-grid'>
                      {allTemplates && allTemplates.map((template, index) => (
    <div className="first-template-container">
        {/* <img   src={URL.createObjectURL(template.previewImageUrl)} alt="Blue Sofa" className="image" /> */}
        <img src={template.previewImageUrl} alt={`Template ${index}`}   className={`template-${template.templateIdentifier === 'furniture2' || template.templateIdentifier === 'landscape_blue' ? 'imagesofa2' : 'imagesofa1'} mt-4`}/>
        <div className="image-info">
            <span>
                <i className="bi bi-eye-fill custom-font-16" style={{ color: "#8599BA", pointerEvents: "none" }}></i>{" "}
            </span>{template.viewCount}
        </div>
        <div className="overlay">
        <div className="buttons">
                <div>
                <Button className="preview-button"  onClick={() => this.handlePreviewButtonClick(template.templateIdentifier)}  >    <span>
                <i className="bi bi-eye-fill custom-font-16" style={{ color: "#8599BA", pointerEvents: "none" }}></i>{" "}
                </span>Preview</Button>
                </div>
                <Button className="use-template-button" onClick={() => this.handleUseTemplateClick(template.templateIdentifier)}>
                            Use Template
                        </Button>
            </div>
        </div>
    </div>
                ))}
                      </div>
    
</div>
                </div>
            );
        } else if (selectedOption === 'your') {
            if (!Array.isArray(imagesFromBackend)) {
                return <div>No images available</div>;
              }else{
                const indexOfLastFlipbook = currentPage * flipbooksPerPage;
                const indexOfFirstFlipbook = indexOfLastFlipbook - flipbooksPerPage;
                const currentFlipbooks = imagesFromBackend.slice(indexOfFirstFlipbook, indexOfLastFlipbook);
                return (
                    <div className="your-ecatalog-container">
                      <div className="image-grid">
                        {currentFlipbooks.map((template, index) => (
                          <div key={index} className="template-container">
                              <div className="dropdown-menu-trigger">
      {template.status === 'Published' ? (
        <div
          onClick={() => this.handleStatusChange(template.flipBookIdentifier, 'Unpublished')}
        >
          Unpublish
        </div>
      ) : (
        <div
          onClick={() => this.handleStatusChange(template.flipBookIdentifier, 'Published')}
        >
          Publish
        </div>
      )}
    </div>
    <img
    src={template.previewImageUrl}
    alt={`Template ${index}`}
    className={`template-${template.templateIdentifier === 'furniture2'  || template.templateIdentifier === 'landscape_blue' ? 'image2' : 'image1'}`}
/>

{/* <div className={`actions-icons mt-2 ${template.templateIdentifier === 'furniture1' ? 'flipbook-text2' : 'flipbook-text1'}`}>

</div> */}
<div className={`actions-icons mt-2 ${template.templateIdentifier === 'furniture1' || template.templateIdentifier === 'portrait_blue' ? 'flipbook-text2' : 'flipbook-text1'}`}>
  {template.name}
</div>


                            <div className="icon">
                            <div className="actions-icons mt-1">
              {/* Eye icon */}
              <span className=''>
                <i className="bi bi-eye-fill temp-custom-font-16"></i>
                {template.viewCount}
              </span>
              {/* Share icon */}
              <span className='spacing'>
                <i className="bi bi-share-fill temp-custom-font-16"></i>
                {template.shareCount}
              </span>
              <span className="right-published">
          {/* Show status based on template.status */}
          <span className="dot-and-status">
            <i className={`bi bi-dot-fill ${template.status === 'Published' ? 'green-dot' : 'red-dot'}`}></i>
            <span className="status-text">{template.status}</span>
          </span>
        </span>
              </div>
              <div className="image-actions">
              {/* Preview button */}
              {/* <Button className="tzz-preview-button" onClick={() => this.handlePreviewTemplateButtonClick(template)}>
              <i className="bi bi-eye-fill custom-font-16"></i> Preview
              </Button> */}
    <div className='mt-4 buttons-on-hover'>
    <Button className="tzz-share-button mt-3" onClick={() => this.handleShareButtonClick(template)}>
              <i className="bi bi-share-fill custom-font-16"></i>  Share
              </Button>
              <Button className="tzz-delete-button" onClick={() => this.handleDeleteButtonClick(template.flipBookIdentifier)}>
              <i className="bi bi-trash-fill custom-font-16"></i> Delete
              </Button>
              
      </div>

              {showModal && (
          <Modal show={showModal} onHide={this.closeModal}  centered class="fade modal show" >
            <Modal.Header closeButton>
              <Modal.Title style={{ fontSize: '14px', color: '#000000' }}>Share Link</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ButtonGroup>
                <Form.Control id="link-input" type="text" value={shareLink} readOnly className="mr-2" />
                <div className="d-flex align-items-center">
                  <Button   onClick={() =>
                                      this.copyLink(template.flipBookIdentifier)
                                    }>
                    <i className="bi bi-files"></i> Copy
                  </Button>
                  <div> 
                                  <Button onClick={(e) => this.downloadQRCode(template.flipBookIdentifier)}>
                                    <i className="bi bi-download"></i> Download QR
                                  </Button>
                                </div>
                </div>
              </ButtonGroup>
            </Modal.Body>
          </Modal>
        )}
            </div>

                                </div>
                          </div>
                        ))}
                      </div>
                      {imagesFromBackend.length > flipbooksPerPage && (
                        <div className="pagination">
                            <ReactPaginate
                                pageCount={Math.ceil(imagesFromBackend.length / flipbooksPerPage)}
                                pageRangeDisplayed={5}
                                marginPagesDisplayed={1}
                                onPageChange={(selectedPage) => this.handlePageChange(selectedPage.selected + 1)}
                                containerClassName="pagination"
                                activeClassName="pagination-item-active"
                            />
                        </div>
                    )}
                    </div>
                  );
              }
              
        }
    };
  //    handlePageChange = (selectedPage) => {
  //     this.setState({ currentPage: selectedPage });
  // };
  handlePageChange = (selectedPage) => {
    debugger;
    this.setState({ currentPage: selectedPage });
  };
  
    render() {
        const { selectedOption ,showPreviewModal , showNewModal ,selectedTemplate ,loading } = this.state;
    
        return (
            <div className="catalog-container">
                <div className="banner">
                    <img src={Logo} alt="Banner Logo" className="banner-image" />
                    <h2 className="banner-heading">E-catalog by Trezi</h2>
                </div>
                <div className="options-container mt-3">
                    <div
                        className={`option ${selectedOption === 'create' ? 'selected' : ''} border`}
                        onClick={() => this.handleOptionClick('create')}
                    >
                        <div className='custom-font-16 '>Create E-catalog</div>
                    </div>
                    <div
                        className={`option ${selectedOption === 'your' ? 'selected' : ''}`}
                        onClick={() => this.handleOptionClick('your')}
                    >
                        <div className='custom-font-16'>Your E-catalog</div>

                        {loading && <Loader />}
                    </div>
                </div>
                <div className="responsive-container">
                    {/* <div>Content related to the selected option: {selectedOption}</div> */}
                    <div> {this.renderContent()}</div>
                    {showNewModal && selectedTemplate && (
            <PreviewModalCreatedTemp
              showNewModal={showNewModal}
              onHide={this.handleNewModalClose}
              template={selectedTemplate}
            />
          )}
                </div>
                <PreviewModal show={showPreviewModal} onHide={this.handlePreviewModalClose} image={this.state.selectedImage} />

            </div>
        );
    }
}

export default Catalog;
