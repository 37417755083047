import React, { useState, useEffect } from "react";
import "./plan.css";
import { Button } from "react-bootstrap";
import { FaCheck } from "react-icons/fa";
import { RazorpayButton } from 'razorpay';

function Plan(props) {
    const {
        title,
        price,
        features,
        variant,
        inclusions,
        heading,
        taxes,
        handleClick,
        handleonClick,
        commonline,
        catalog_manager,
        sales_person_license_count
    } = props;

    return (
        <div className="plan">
            <h3 className="plan-title">{title}</h3>
            <h3 className="plan-price">{price}</h3>
            <h5 className="plan-taxes">{taxes}</h5>    
            <ul className="plan-features">
                {features &&
                    features.map((feature, index) => (
                        <li key={index} style={{ display: "flex" }}>
                            {feature}
                        </li>
                    ))}
            </ul>
            <hr className={`mt-1 mb-1 plan-line-${props.variant}`} />
            <h5 className="plan-heading">{heading}</h5>
           
            <ul className="plan-features">
                {inclusions &&
                    inclusions.map((inclusion, index) => (
                        <li key={index} style={{ display: "flex" }}>
                            {inclusion}
                        </li>
                    ))}
            </ul>

          <div className="plan-button-container">
  {/* <Button className="plan-button" onClick={handleClick} variant={variant}>
    Subscribe
  </Button>   */}
  <Button className="purchase-button" onClick={handleonClick} variant={variant}>
    Purchase
  </Button>
</div>

        </div>
    );
}

export default Plan;
