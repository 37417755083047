import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import React from "react";
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  FormControl,
  InputAdornment,
  IconButton,
  Checkbox,
  Grid,
  InputLabel,
} from "@mui/material";
import { useParams, useHistory, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Swal from 'sweetalert2';
import { baseUrlUser } from "../utils/common";
import logolink2 from "../assests/public/trezi_logo.png";

const url =baseUrlUser;
const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [resetError, setResetError] = useState("");
  const { token } = useParams();
  const navigate = useNavigate();

  const isPasswordValid = () => {
    return (
      password.length >= 6 &&
      /[0-9]/.test(password) &&
      /[!@#$%^&*(),.?":{}|<>]/.test(password) &&
      /[a-z]/.test(password) &&
      /[A-Z]/.test(password)
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setResetError("");

    if (password !== confirmPassword) {
      setResetError("Passwords do not match");
      return;
    }

    if (!isPasswordValid()) {
      setResetError("Invalid password. Please follow the password requirements.");
      return;
    }

    try {
      const response = await fetch(`${url}users/reset/password/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ password }),
      });
 
      if (response.ok) {
        Swal.fire({
          icon: 'success',
          title: 'Password Reset Successful',
          text: 'Your password has been reset successfully.',
        }).then(() => {
          navigate('/');
        });
      } else {
        setResetError("Reset password is not successful");
      }
    } catch (error) {
      // Handle any network or other errors
      // ...
      console.log("Invalid Password")
    }
  };
  

  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  useEffect(() => {
    document.title = 'Reset Password'; // Set the desired page title
  }, []);
  const handleCancel = (e) => {
    e.preventDefault();
    navigate('/');
  };

  return (
    <div className="box8">
      <Grid className="forgot-container">
        <div
          className="box mt-0 mb-0"
          style={{
            flexDirection: "column",
            display: "flex",
            height: "100%",
            padding: "32px",
          }}
        >   
          <img src={logolink2} alt="Logo" style={{width:"100px"}}/>
          <Typography
            className=""
            style={{ fontSize: "22px" }}
            component="h1"
            variant="h5"
          >
            Reset Your Password
          </Typography>
          <div className="forgot-form-container">
          {resetError && (
                <Typography color="error" variant="body2">
                  {resetError}
                </Typography>
              )}

              <div className="mt-1">
              {!isPasswordValid() && (
  <Typography  variant="body2">
    <div className="list-manage">
                <li>
                  Password must contain a number, capital letter, small letter
                  and special character.
                </li>
                <li>Password must be at least 6 characters long.</li>
                </div>
   
  
  </Typography>
)}
              </div>
            <Box onSubmit={handleSubmit} sx={{ mt: 0 }}>
              <Grid container spacing={2}>
                <Grid className="mt-2" item xs={12}>
                  <InputLabel shrink>Enter New Password*</InputLabel>
                  <FormControl sx={{ mb: 0 }} fullWidth>
                    <TextField
                      className="mt-0"
                      variant="outlined"
                      autoFocus
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel shrink>Confirm Password*</InputLabel>
                  <FormControl fullWidth>
                    <TextField
                      variant="outlined"
                      required
                      value={confirmPassword}
                      type={showPassword ? "text" : "password"}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={toggleShowPassword}>
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            
              <Button
                className="mt-4"
                style={{ height: "55px", textTransform: "none" }}
                type="submit"
                variant="contained"
                fullWidth
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
            <Button
              className=""
              style={{ height: "55px", textTransform: "none" }}
              type="submit"
              onClick={handleCancel}
              fullWidth
            >
              Cancel
            </Button>
          </div>
        </div>
      </Grid>
    </div>
  );
};

export default ResetPassword; 
