import React, { Component } from "react";
import "../css/ProductCatalog.css";
import { Button, Dropdown, Stack } from "react-bootstrap";
import ProductTable from "../components/ProductTable";
import ProductSingleUpload from "../SingleUpload/productSingleUpload";
import SalesProductTable from "./SalesProductTable";

export default class SalesProductCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewOption: "List", // Default selected option
      shareCount: "",
    };
  }
  componentDidMount() {
    document.title = "Product-Catalogue"; // Set the page title dynamically
  }
  handleShareCount = (count) => {
    this.setState({ shareCount: count });
  };

  handleViewOptionChange = (option) => {
    this.setState({ viewOption: option });
  };

  render() {
    const { viewOption } = this.state;
    const { searchQuery } = this.props;

    return (
      <>
        <div className="tz-product-catalog-container">
          <Stack
            direction="horizontal"
            gap={3}
            className={`${
              viewOption === "List"
                ? "list-dashboard-header"
                : "grid-dashboard-header"
            }`}
          >
            <div className="custom-font-20 product-catalog">
              Product Catalog
            </div>
            <div className="ms-auto tz-list-dropdown">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  View By: {viewOption}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => this.handleViewOptionChange("List")}
                    active={viewOption === "List"}
                  >
                    View By: List
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => this.handleViewOptionChange("Grid")}
                    active={viewOption === "Grid"}
                  >
                    View By: Grid
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div >
             
            </div>
            {/* <div>
              <Button className="tz-primary-btn">Bulk Upload</Button>
            </div> */}
            
          </Stack>
          <SalesProductTable
            view={viewOption}
            searchQuery={searchQuery}
            setShareCount={this.handleShareCount}
          />
        </div>
      </>
    );
  }
}
