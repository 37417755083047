import React, { Component } from "react";
import logolink2 from "../assests/public/landing_page__1_.jpg";
import logolink from "../assests/public/icons__info.png";
import Header from "../components/Header/Header";
import { getRole } from "../services/ProductsService";
import Navbarplanbox from "../common/navbarplanbox";

class PaymentUnverified extends Component {
  render() {
    return (
      <>
        <div className="box5">
          {/* Render the Navbarplanbox component */}
          <Navbarplanbox />
          <div className="p-4 box3">
            <div className="row">
              <div className="col-12">
                <img
                  src={logolink2} 
                  alt="Logo"
                  className="image-with-border"
                />
              </div>
            </div>
          </div>
          {/* Orange color line with message */}
          <div
            style={{
              backgroundColor: "orange",
              color: "white",
              padding: "10px",
              textAlign: "center",    
            }}
          >
         <img
                  src={logolink} 
                  alt="Logo"
                  height={20}
                  width={20}
                />  <span style={{ marginLeft: "20px" }}> Waiting for account activation. You will be able to handle your profile only.</span>
          </div>
        </div>
        {/* CSS overlay to disable interaction */}
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            zIndex: 9999,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            pointerEvents: "none", // Disable pointer events
          }}
        ></div>
      </>
    );
  }
}

export default PaymentUnverified;
