import React, { Component } from 'react'
import { Accordion, Card } from 'react-bootstrap';

export default class AccordionComponent extends Component {
  render() {
    const { accordionClass, bodyClass, eventKey, onClick, header, body } =
      this.props;
    return (
      <Accordion className={accordionClass}>
        <Card>
          <Accordion.Item eventKey={eventKey}>
            <Accordion.Header onClick={onClick}>{header}</Accordion.Header>
            <Accordion.Body className={bodyClass}>{body}</Accordion.Body>
          </Accordion.Item>
        </Card>
      </Accordion>
    );
  }
}
