import "./financeportal.css";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Box } from '@material-ui/core';
import React, { useState, useEffect } from "react";
import {  Modal, Form , Dropdown , DropdownToggle , DropdownMenu ,Accordion, Card,} from 'react-bootstrap';
import Navbarfinbox from "../financePortal/navbarfinbox";
import ReactPaginate from 'react-paginate';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { baseUrlUser} from "../utils/common";
import { getAllVendorData ,getAllVendorServiceId ,getAllVendorTransictionbyVendorId ,downloadVendorExcel, deleteVendorbyId,CreateVendorData , getAllVendorDatabyvendorId ,updateVendorData} from "../services/ProductsService";
import { Typography } from "antd";
import logolink  from "../assests/public/Group 3415 (1).svg";
import Delete  from "../assests/public/Delete.svg";
import Edit  from "../assests/public/Group (5).svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import "../css/ProductTable.css";
import "../css/ProductImage.css";
import add  from "../assests/public/ADD.svg";
import minus  from "../assests/public/MINUS.svg";
const urlPayment = baseUrlUser;
const VendorDetail = () => {
  const [VendorData, setVendorData] = useState([]);
  const [VendorbyVendorId, setVendorDataByVendorId] = useState([]);
  const [show, setShow] = useState(false);
  const [vendorName, setVendorName] = useState('');
  const [vendorEmail, setVendorEmail] = useState('');
  const [vendorURL, setVendorURL] = useState('');
  const [vendorPhone, setVendorPhone] = useState('');
  const [vendorAddress, setVendorAddress] = useState('');
  const [vendorCity, setVendorCity] = useState('');
  const [vendorState, setVendorState] = useState('');
  const [vendorZip, setVendorZip] = useState('');
  const [description, setDescription] = useState('');
  const [gstNumber, setGstNumber] = useState('');
  const [editVendor, setEditVendor] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [vendorToDelete, setVendorToDelete] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [activeView, setActiveView] = useState("myVendors");
  const [VendorUsageData, setVendorUsageData] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [loading, setLoading] = useState(false); // Declare loading state
  const [error, setError] = useState(false); // Declare error state
  const [vendorServiceData, setVendorServiceData] = useState([]);
  const [loadingVendors, setLoadingVendors] = useState({});

  const handleClose = () => { 
    resetFormFields();
     setShow(false); 
     setErrorMessage("");
    };

  const handleShow = () => {
    if (!editVendor) {
      resetFormFields();
    }
    setShow(true);
    
  };

  const handlePhoneChange = (e) => {
    const vendorPhone = e.target.value;
    // Remove any non-digit characters from the input
    const sanitizedPhoneNumber = vendorPhone.replace(/\D/g, '').slice(0, 10);
    setVendorPhone(sanitizedPhoneNumber);
  };
  const fetchVendorUsage = async (vendorId , vendorServiceId) => {
    try {
      debugger;
      const response = await getAllVendorTransictionbyVendorId(vendorId, vendorServiceId);
      if (response.status === 200) {
        console.log(response.data ,"sdfdsfs")
        setVendorUsageData(prevState => ({
          ...prevState,
          [vendorId]: response.data
        }));
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: response.data || 'Failed to fetch vendor usage data.',
        });
      }
    } catch (error) {
      console.error('Error fetching vendor usage data:', error);
    }
  };

  const validatePhoneNumber = () => {
    if (vendorPhone.length !== 10) {
      return ;
    }
    return "";
  };
  const resetFormFields = () => {
    setVendorName('');
    setVendorEmail('');
    setVendorURL('');
    setVendorPhone('');
    setVendorAddress('');
    setVendorCity('');
    setVendorState('');
    setVendorZip('');
    setDescription('');
    setGstNumber('');
    setErrorMessage("");
  };

  const handleCreateVendor = async () => {
    let errors = [];

  if (!vendorName) errors.push('Vendor Name');
  if (!vendorEmail) errors.push('Email');
  if (!vendorURL) errors.push('URL');
  if (!vendorPhone) errors.push('Phone');

  if (errors.length > 0) {
    setErrorMessage(`Please fill in the following required fields: ${errors.join(', ')}.`);
    return;
  }
  
    const newVendor = {
      name: vendorName,
      emailId: vendorEmail,
      websiteUrl: vendorURL,
      phone: vendorPhone,
      vendorAddress,
      vendorCity,
      vendorState,
      vendorZip,
      description,
      gstNumber,
    };
  
    try {
      debugger;
      const response = await CreateVendorData(newVendor);
      if (response.status === 202) {
        Swal.fire({
          icon: 'success',
          title: 'Vendor Created',
          text: 'The vendor has been successfully created.',
        });
        fetchVendorData();
        handleClose();
        setErrorMessage('');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text:response.data.phone   ||  response.data.emailId  ||  response.data  ||  'Failed to create vendor.',
        });
      }
    } catch (error) {
      console.error('Error creating vendor:', error);
      const errorMessage = error.response?.data || 'An error occurred while creating the vendor.';
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: "An error occurred while creating the vendor.",
      });
    }
  };
  const handleViewChange = (view) => {
    setActiveView(view);
  };

  const handleUpdateVendor = async () => {
    let errors = [];

    if (!vendorName) errors.push('Vendor Name');
    if (!vendorEmail) errors.push('Email');
    if (!vendorURL) errors.push('URL');
    if (!vendorPhone) errors.push('Phone');
  
    if (errors.length > 0) {
      setErrorMessage(`Please fill in the following required fields: ${errors.join(', ')}.`);
      return;
    }
  
    const updatedVendor = {
      vendorId: editVendor.vendorId,
      name: vendorName,
      emailId: vendorEmail,
      websiteUrl: vendorURL,
      phone: vendorPhone,
      // vendorAddress,
      // vendorCity,
      // vendorState,
      // vendorZip,
      // description,
      // gstNumber,
    };

    try {
      const response = await updateVendorData(updatedVendor);
      if (response.status === 200) {
        
        Swal.fire({
          icon: 'success',
          title: 'Vendor Updated',
          text: 'The vendor has been successfully updated.',
        });
        fetchVendorData();
        handleClose();
        setErrorMessage('');
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data || 'Failed to create vendor.',
        });
        setErrorMessage('');
      }
    } catch (error) {
      console.error('Error updating vendor:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while updating the vendor.',
      });
      setErrorMessage('');
    }
  };

  const fetchVendorData = async () => {
    try {
      const response = await getAllVendorData();
      if (response.status === 200) {
        setVendorData(response.data.reverse())
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to fetch vendor data.',
        });
      }
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };
  const fetchVendorServiceId = async () => {
    try {
      const response = await getAllVendorServiceId();
      if (response.status === 200) {
        debugger;
        setVendorServiceData(response.data)
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to fetch vendor data.',
        });
      }
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };

  const fetchDatabyvendorId = async (vendorId) => {
    handleShow();
    try {
      const response = await getAllVendorDatabyvendorId(vendorId);
      if (response.status === 200) {
        const vendor = response.data;
        setVendorDataByVendorId(vendor);
        setEditVendor(vendor);
        setVendorName(vendor.name);
        setVendorEmail(vendor.emailId);
        setVendorURL(vendor.websiteUrl);
        setVendorPhone(vendor.phone);
  
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Failed to fetch vendor data.',
        });
      }
    } catch (error) {
      console.error('Error fetching vendor data:', error);
    }
  };
  const toggleAccordion = async (index, vendorId, vendorServiceId) => {
    if (activeIndex === index) {
      setActiveIndex(null); // Close the currently open accordion
      return;
    }
  
    setLoading(true);
    setError(false);
    
    // Fetch vendor usage data when the accordion is opened
    await fetchVendorUsage(vendorId, vendorServiceId);
    setLoading(false);
    setActiveIndex(index); // Open the selected accordion
  };
  

  useEffect(() => {
    fetchVendorData();
    fetchVendorServiceId();
  }, []);

  useEffect(() => {
    document.title = 'Vendor-Details';
  }, []);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected);
  };
  const handleEditClick = (vendor) => {
    debugger;
    fetchDatabyvendorId(vendor);
  };

  const handleDeleteVendor = async (vendorId) => {
    try {
      const response = await deleteVendorbyId(vendorId);
      if (response.status === 204) {
        Swal.fire({
          icon: 'success',
          title: 'Vendor Deleted',
          text: 'The vendor has been successfully deleted.'
        });
        fetchVendorData();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: response.data||'Failed to delete vendor.'
        });
      }
    } catch (error) {
      console.error('Error deleting vendor:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while deleting the vendor.'
      });
    }
  };

  const handleDeleteClick = (vendorId) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this vendor?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        // If confirmed, proceed with the deletion
        handleDeleteVendor(vendorId);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // If cancelled, show a message or simply do nothing
        Swal.fire({
          icon: 'info',
          title: 'Cancelled',
          text: 'The vendor was not deleted.',
        });
      }
    });
  };
  
  const handleDownloadVendorFile =async () => {
    debugger;
    try {
      const response = await downloadVendorExcel();
      if (!response.ok) {
        throw new Error('Failed to download Excel');
      }
      // Convert response to blob and create a download link
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'vendor_data.xlsx'; // Adjust filename as needed
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading Excel:', error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to download Excel file.',
      });
    }
  };
  const vendorsToDisplay = VendorData.slice(
    pageNumber * itemsPerPage,
    (pageNumber + 1) * itemsPerPage
  );

  return (
    <div className="box5">
      <Navbarfinbox />
      <div className="p-4 ">
        
      <div className="row">
      <div
          className={`col-1 mt-4 custom-font-20 clickable-div ${activeView === 'myVendors' ? 'active' : ''}`}
          onClick={() => handleViewChange("myVendors")}
        >
          My Vendors
        </div>
        <div
          className={`col-2 mt-4 custom-font-20 clickable-div ${activeView === 'vendorUsage' ? 'active' : ''}`}
          onClick={() => handleViewChange("vendorUsage")}
        >
          Vendor Point Usage
        </div>
        <div className="col-9 mt-4 text-right">
        {activeView !== 'vendorUsage' && (
    <button className="tz-primary-btn btn btn-secondary mr-2" onClick={handleShow}>
      Create Vendor
    </button>
  )}
        </div>
        </div>
        <div className ="mt-2 layout">
        {activeView === "myVendors" ? (
      <div>
          <TableContainer>
          <Table>
            <TableHead >
              <TableRow class="colorful-row">
                <TableCell>Vendor Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>URL</TableCell>
                <TableCell>Phone</TableCell>
                <TableCell>Total Points</TableCell>
                <TableCell>Used Points</TableCell>
                <TableCell>Balance Points</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vendorsToDisplay && vendorsToDisplay.map((item , index) => (
                <TableRow key={index} >
                  <TableCell>{item.name}</TableCell>
                  <TableCell>{item.emailId}</TableCell>
                  <TableCell>{item.websiteUrl}</TableCell>
                <TableCell>{item.phone}</TableCell>
                <TableCell>{item.totalPoints}</TableCell>
                <TableCell>{item.usedPoints}</TableCell>
                <TableCell>{item.balancePoints}</TableCell>
                <TableCell>
                      <Dropdown>          
                        <Dropdown.Toggle as="i"  id="dropdownMenuButton"    >
                          <img src={logolink} width={3} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item onClick={() => handleEditClick(item.vendorId)}><img  src={Edit} width={13} /> <span className="ml-2 typo"> Edit </span> </Dropdown.Item>
                          <Dropdown.Item onClick={() => handleDeleteClick(item.vendorId)}><img  src={Delete} width={13} /> <span className="ml-2 typo">Delete  </span></Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ReactPaginate
            pageCount={Math.ceil(VendorData.length / itemsPerPage)}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            onPageChange={handlePageClick}
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
        ) : (
          <div className="mt-2">
         <div className="accordion">
  {vendorServiceData && vendorServiceData.map((vendor, index) => (
    <div key={index} className="accordion-item">
      <h2 className="accordion-header" id={`heading-${vendor.vendorId}`}>
        <button
          className="accordion-button"
          type="button"
          onClick={() => toggleAccordion(index, vendor.vendorId, vendor.vendorServiceId)}
          aria-expanded={activeIndex === index}
          aria-controls={`collapse-${vendor.vendorId}`}
        >
          {activeIndex === index ? (
            <img src={minus} alt="Minus" />
          ) : (
            <img src={add} alt="Plus" />
          )}
          <span className="ml-2">
       {vendor.vendorName} ({vendor.serviceName})
            <span className="ml-4">
              <span>Total Points</span> <span>{vendor.totalPoints}</span>
              <span className="divider"></span>
              <span>Used Points</span> <span>{vendor.usedPoints}</span>
              <span className="divider"></span>
              <span>Balance Points</span> <span>{vendor.balancePoints}</span>
            </span>
          </span>
       
        </button>
      </h2>

      <div
        id={`collapse-${vendor.vendorId}`}
        className={`accordion-collapse collapse ${activeIndex === index ? 'show' : ''}`}
        aria-labelledby={`heading-${vendor.vendorId}`}
        data-bs-parent={`#accordion-${vendor.vendorId}`}
      >
        <div className="accordion-body">
          {VendorUsageData &&  VendorUsageData[vendor.vendorId] && activeIndex === index ? (
            <Card.Body>
              <div className="d-flex justify-content-between align-items-center mb-3">
                {/* Any additional content you may want to add */}
              </div>
              <div>
                {VendorUsageData[vendor.vendorId].length > 0 ? (
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Transaction Type</th>
                        <th>Transaction ID</th>
                        <th>Date & Time</th>
                        <th>Points</th>
                        <th>Client ID</th>
                      </tr>
                    </thead>
                    <tbody>
            {VendorUsageData[vendor.vendorId]
              .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date in descending order
              .map((transaction, i) => (
                <tr key={i}>
                  <td>{transaction.transactionType}</td>
                  <td>{transaction.transactionId}</td>
                  <td>{new Date(transaction.date).toLocaleString()}</td>
                  <td>{transaction.points}</td>
                  <td>{transaction.clientId}</td>
                </tr>
              ))}
          </tbody>
                  </table>
                ) : (
                  <p>No transactions found.</p>
                )}
              </div>
            </Card.Body>
          ) : null}
        </div>
      </div>
    </div>
  ))}
</div>

        </div>
      )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered dialogClassName="custom-modal">
        <Modal.Header closeButton>
          <Modal.Title>{editVendor ? 'Edit Vendor' : 'Create Vendor'}</Modal.Title>
        </Modal.Header>
        {errorMessage && (
                <div className="alert alert-danger" role="alert">
                    {errorMessage}
                </div>
            )}
        <Modal.Body>
          <Form>
            <div className="form-row">
              <Form.Group className="col-md-4 mb-3" controlId="formVendorName">
                <Typography>Vendor Name</Typography>
                <Form.Control
                  type="text"
                  value={vendorName}
                  onChange={(e) => setVendorName(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="col-md-4 mb-3" controlId="formVendorEmail">
                <Typography>Email</Typography>
                <Form.Control
                  type="email"
                  value={vendorEmail}
                  onChange={(e) => setVendorEmail(e.target.value)}
                />
              </Form.Group>
              <Form.Group className="col-md-4 mb-3" controlId="formVendorURL">
                <Typography>URL</Typography>
                <Form.Control
                  type="text"
                  value={vendorURL}
                  onChange={(e) => setVendorURL(e.target.value)}
                />
              </Form.Group>
            </div>
            <div className="form-row">
              <Form.Group className="col-md-12 mb-3" controlId="formVendorPhone">
                <Typography>Phone</Typography>
                <Form.Control
                  type="number"
                  value={vendorPhone}
                  onChange={handlePhoneChange}
                  maxLength={10}
                  onBlur={validatePhoneNumber}
                  helperText={validatePhoneNumber()}
                />
              </Form.Group>      
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="tz-primary-btn reverse" onClick={handleClose}>
           Cancel
          </Button>
          <Button variant="secondary" className="tz-primary-btn" onClick={editVendor ? handleUpdateVendor : handleCreateVendor}>
            {editVendor ? 'Update Vendor' : 'Create New'}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    
  );
};
export default VendorDetail;
