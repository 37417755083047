import React, { Component } from 'react';
import { Table, Form, Dropdown ,Stack ,Pagination, Button ,Modal} from 'react-bootstrap'; 
import './CustomerSuccessTable.css';
import './categoryApproval.css';
import { getAllApproveCustomerSuccessCategories , getAllRejectCustomerSuccessCategories ,rejectPendingCategory,getAllPendingCustomerSuccessCategories , approvePendingCategory} from "../services/ProductsService";
import ReactPaginate from 'react-paginate';
import Swal from 'sweetalert2';
class CategoryApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
    Data: [],
    isAnyCheckboxSelected: false,
      sortColumn: '', // Provide initial value for sorting
      sortDirection: 'asc', 
      currentPage: 1, 
      itemsPerPage: 10, 
      indexOfFirstItem: 0,
      indexOfLastItem: 10,
      pageNumbers: [],   
      showRejectModal: false, // Add this state to control the rejection modal
      rejectionReason: '',
      selectedItemName: '',
      currentStatusFilter: 'Pending', 
      selectedFilter: 'Pending Requests',
      filteredData: [],
      // searchQuery:'',
    };
    this.fetchAllApprovecustomerSuccessCategories = this.fetchAllApprovecustomerSuccessCategories.bind(this);
  this.fetchAllRejectcustomerSuccessCategories = this.fetchAllRejectcustomerSuccessCategories.bind(this);
  this.fetchAllPendingcustomerSuccessCategories = this.fetchAllPendingcustomerSuccessCategories.bind(this);
  }
  componentDidMount() {
    document.title = 'Customer Approval';
  this.fetchAllPendingcustomerSuccessCategories();
  }
  componentDidUpdate(prevProps, prevState) {
    debugger;
    if (
      prevProps.searchQuery !== this.props.searchQuery ||
      this.state.isUpdated !== prevState.isUpdated ||
      prevState.productData !== this.state.productData
    ) {
      this.filterData();
    }
    if (prevState.isUpdated !== this.state.isUpdated) {
      this.setState({ productData: [] });
    }
  }
  handleOpenRejectModal = (name) => {
    debugger;
    this.setState({ showRejectModal: true , selectedItemName: name });
  };
  
  handleCloseRejectModal = () => {
    this.setState({ showRejectModal: false, rejectionReason: '', selectedItemName: '' });
  }; 
  handleReject = async () => {
    debugger;
    const { selectedItemName, rejectionReason } = this.state;
    const label = selectedItemName.replace(/\s+/g, '_').toLowerCase(); 
    try {
      const res = await rejectPendingCategory(label, rejectionReason);
      if (res === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Category Rejected',
          text: 'The category has been rejected successfully.',
        });
        this.fetchAllPendingcustomerSuccessCategories();
        // this.fetchAllRejectcustomerSuccessCategories();
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while rejecting the category.',
        });
      }
    } catch (err) {
      console.error(err);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while rejecting the category.',
      });
    }
    this.handleCloseRejectModal();
  };
  
  
  handlePageChange = (selectedPage) => {
    this.setState({ currentPage: selectedPage });
  };
  
  handleSort = (columnName) => {
    const { sortColumn, sortDirection, Data,filteredData } = this.state;
    const newSortDirection = sortColumn === columnName ? (sortDirection === 'asc' ? 'desc' : 'asc') : 'asc';
    const sortedData = [...filteredData];
    sortedData.sort((a, b) => {
      // Perform sorting based on the column name and sort direction
      if (columnName === 'name') {
        return newSortDirection === 'asc' ? (a.name || '').localeCompare(b.name || '') : (b.name || '').localeCompare(a.name || '');
      } else if (columnName === 'parentCategoryName') {
        return newSortDirection === 'asc' ? (a.parentCategoryName || '').localeCompare(b.parentCategoryName || '') : (b.parentCategoryName || '').localeCompare(a.parentCategoryName || '');
      } else if (columnName === 'requestedUserEmail') {
        return newSortDirection === 'asc' ? (a.requestedUserEmail || '').localeCompare(b.requestedUserEmail || '') : (b.requestedUserEmail || '').localeCompare(a.requestedUserEmail || '');
      } else if (columnName === 'requestedOn') {
        return newSortDirection === 'asc' ? new Date(a.requestedOn || '') - new Date(b.requestedOn || '') : new Date(b.requestedOn || '') - new Date(a.requestedOn || '');
      } else if (columnName === 'requestedOrgName') {
        return newSortDirection === 'asc' ? (a.requestedOrgName || '').localeCompare(b.requestedOrgName || '') : (b.requestedOrgName || '').localeCompare(a.requestedOrgName || '');
      } else if (columnName === 'isPublished') {
        const aValue = a.isPublished === 'true';
        const bValue = b.isPublished === 'true';
        return newSortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      } 
      return 0; 
    });
  
    this.setState({ filteredData: sortedData, sortColumn: columnName, sortDirection: newSortDirection });
};
filterData = () => {
  const { searchQuery } = this.props;
  const { Data } = this.state;
  if (!searchQuery) {
    this.setState({ filteredData: Data });
    return;
  } else {
    const filteredData = Data.filter(
      (item) =>
        item.name.toLowerCase().startsWith(searchQuery.toLowerCase()) ||
        item.parentCategoryName.toString().startsWith(searchQuery.toLowerCase().trim()) ||
        item.requestedUserEmail.toString().startsWith(searchQuery.toLowerCase().trim())||
        item.requestedOrgName.toString().startsWith(searchQuery.toLowerCase().trim())||
        item.status.toString().startsWith(searchQuery.toLowerCase().trim())
    );

    this.setState({ filteredData: filteredData });
  }
};
  
  handleCategoryApprove = async (name) => {
    debugger;
    const label = name.replace(/\s+/g, '_').toLowerCase(); 
    try {
      const res = await approvePendingCategory(label);
      if (res === "Category already published.") {
        Swal.fire({
          icon: 'error',
          title: 'Category Already Published',
          text: 'This category has already been approved and published.',
        });
      } else {
        await Swal.fire({
          icon: 'success',
          title: 'Category Approved',
          text: 'The category has been approved and published successfully.',
         });
        this.fetchAllApprovecustomerSuccessCategories();
       }
    } catch (err) {
      console.error(err);
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while approving the category.',
      });
    }
  };

  async fetchAllApprovecustomerSuccessCategories() {
    try {
      debugger;
      const products = await getAllApproveCustomerSuccessCategories();
      if (products === "Approve categories not found.") {
        Swal.fire({
          icon: 'info',
          title: 'No Approve Categories',
          text: 'Approve categories not found.',
        });
        this.setState({
          Data: [],
          filteredData :[],
        });
      } else {
        this.setState({
          Data: products,
          currentPage: 1,
          filteredData :products,
        });
      }
    } catch (err) {
      if(err.response.status === 401){
        Swal.fire({
          icon: 'Unauthorised',
          title: 'Error fetching Products',
          text: 'Error fetching Products'
        });
        window.location.href = '/'; // Redirect to the login page
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Error fetching Products',
          text: 'Error fetching Products'
        });
      }
      console.log(err);
    }
  }
  
  async fetchAllRejectcustomerSuccessCategories() {
    try {
      const products = await getAllRejectCustomerSuccessCategories();
      if (products === "Rejected categories not found.") {
        Swal.fire({
          icon: 'info',
          title: 'No Rejected Categories',
          text: 'Rejected categories not found.',
        });
        this.setState({
          Data: [],
          filteredData :[],
        });
      } else {
        this.setState({
          Data: products,
          currentPage: 1,
          filteredData :products,
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  async fetchAllPendingcustomerSuccessCategories() {
    try {
      debugger;
      const products = await getAllPendingCustomerSuccessCategories();
      if (products === "Pending categories not found.") {
        Swal.fire({
          icon: 'info',
          title: 'No Pending Categories',
          text: 'There are no categories pending for approval.',
        });
        this.setState({
          Data: [],
          filteredData :[],
        });
      } else {
        this.setState({
          Data: products,
          currentPage: 1,
          filteredData :products
        });
      }
    } catch (err) {
      console.log(err);
    }
  }
  
  render() {
    const {currentStatusFilter, sortColumn, sortDirection, Data , filteredData ,isAnyCheckboxSelected} = this.state;
    const {
      currentPage,
      itemsPerPage,
    } = this.state;
      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(Data.length / itemsPerPage); i++) {
        pageNumbers.push(i);
      }
      const displayedData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    return (
      <div>
   <Stack className='table-customer-product-container '>
    <div className="upload-heading">
    {this.state.selectedFilter}
    </div>
    <div className="csm-button-container">
   <div className="upload-button" >
   <Dropdown>
        <Dropdown.Toggle>{this.state.selectedFilter}</Dropdown.Toggle>
        <Dropdown.Menu className='cat-tz-dropdown-menu'>
          <Dropdown.Item
            className='cat-tz-dropdown-item'
            onClick={() => {
              this.fetchAllPendingcustomerSuccessCategories();
              this.setState({ selectedFilter: 'Pending Requests' }); // Update selected filter
            }}
          >
            Pending Requests
          </Dropdown.Item>
          <Dropdown.Item
            className='cat-tz-dropdown-item'
            onClick={() => {
              this.fetchAllApprovecustomerSuccessCategories();
              this.setState({ selectedFilter: 'Approved' }); // Update selected filter
            }}
          >
            Approved
          </Dropdown.Item>
          <Dropdown.Item
            className='cat-tz-dropdown-item'
            onClick={() => {
              this.fetchAllRejectcustomerSuccessCategories();
              this.setState({ selectedFilter: 'Rejected' }); // Update selected filter
            }}
          >
            Rejected
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
   </div>
    </div>
        <div className='table-customer-body'>
        <Table hover borderless className="product-customer-table ">
          <thead className="list-view-table-head">
            <tr>
              {/* CustomerId */}
              <th className="fifth-product-column-th custom-font-10">
                <div
                  className="d-flex sort-column align-items-center"
                  onClick={() => this.handleSort('name')}
                >
                  <span className="custom-font-15">Category Name</span>
                  <div className="tz-sort-icons">
                    <button
                      className={`sort-button ${
                        sortColumn === 'name' &&
                        sortDirection === 'asc' &&
                        'active'
                      }`}
                    >
                      <span className="bi bi-caret-up-fill"></span>
                      <span className="bi bi-caret-down-fill"></span>
                    </button>
                  </div>
                </div>
              </th>
              {/* Title & Tzn Number */}
              <th className="second-product-column-th custom-font-10">
              <div
                  className="d-flex sort-column align-items-center"
                  onClick={() => this.handleSort('parentCategoryName')}
                >
                  <span className="custom-font-15">Parent Category</span>
                  <div className="tz-sort-icons">
                    <button
                      className={`sort-button ${
                        sortColumn === 'parentCategoryName' &&
                        sortDirection === 'asc' &&
                        'active'
                      }`}
                    >
                      <span className="bi bi-caret-up-fill"></span>
                      <span className="bi bi-caret-down-fill"></span>
                    </button>
                  </div>
                </div>
              </th>
               <th className="fifth-product-column-th custom-font-10">
                <div
                  className="d-flex sort-column align-items-center"
                  onClick={() => this.handleSort('requestedUserEmail')}
                >
                  <span className="custom-font-15">Requested BY</span>
                  <div className="tz-sort-icons">
                    <button
                      className={`sort-button ${
                        sortColumn === 'requestedUserEmail' &&
                        sortDirection === 'asc' &&
                        'active'
                      }`}
                    >
                      <span className="bi bi-caret-up-fill"></span>
                      <span className="bi bi-caret-down-fill"></span>
                    </button>
                  </div>
                </div>
              </th>
              <th className="fifth-product-column-th custom-font-10">
                <div
                  className="d-flex sort-column align-items-center"
                  onClick={() => this.handleSort('requestedOn')}
                >
                  <span className="custom-font-15">Requested On</span>
                  <div className="tz-sort-icons">
                    <button
                      className={`sort-button ${
                        sortColumn === 'requestedOn' &&
                        sortDirection === 'asc' &&
                        'active'
                      }`}
                    >
                      <span className="bi bi-caret-up-fill"></span>
                      <span className="bi bi-caret-down-fill"></span>
                    </button>
                  </div>
                </div>
              </th>
               <th className="fourth-product-column-th custom-font-10">
                <div
                  className="d-flex sort-column align-items-center"
                  onClick={() => this.handleSort('requestedOrgName')}
                >
                  <span className="custom-font-15">Organization Name</span>
                  <div className="tz-sort-icons">
                    <button
                      className={`sort-button ${
                        sortColumn === 'requestedOrgName' &&
                        sortDirection === 'asc' &&
                        'active'
                      }`}
                    >
                      <span className="bi bi-caret-up-fill"></span>
                      <span className="bi bi-caret-down-fill"></span>
                    </button>
                  </div>
                </div>
              </th> 
               {/* Status */}
               <th className="third-product-column-th custom-font-15">
                <div
                  className="d-flex sort-column align-items-center"
                  onClick={() => this.handleSort('isPublished')}
                >
                  <span className="custom-font-15">Status</span>
                  <div className="tz-sort-icons">
                    <button
                      className={`sort-button ${
                        sortColumn === 'isPublished' &&
                        sortDirection === 'asc' &&
                        'active'
                      }`}
                    >
                      <span className="bi bi-caret-up-fill"></span>
                      <span className="bi bi-caret-down-fill"></span>
                    </button>
                  </div>
                </div>
              </th>
              {this.state.selectedFilter === 'Rejected' && (
  <th className="fourth-product-column-th custom-font-10">
    <div className="d-flex align-items-center">
      <span className="custom-font-15">Rejection Reason</span>
    </div>
  </th>
)}
            </tr>
          </thead>

          {/* Table body */}
          <tbody className="">
          {displayedData && displayedData?.map((item ,index) => (
              <tr key={index} className="tz-table-body-row">
                
                {/* Title and Tzn Number */}
                <td className="fifth-product-column">
                    {item.name}
                  </td>
                <td
                  className="second-product-column"
                >
                  <div className="name-container">
                  
                        <span>
                          <div className="product-serial custom-font-12">
                            {item.parentCategoryName}
                          </div>
                        </span>
                  </div>
                </td>
                <td className="fifth-product-column">
                    {item.requestedUserEmail}
                  </td>
                  <td className="fourth-product-column">
  {item.requestedOn ? new Date(item.requestedOn).toISOString().split("T")[0] : ""}
</td>
<td className="third-product-column">
{item.requestedOrgName}
</td>

<td className="third-product-column">
  {this.state.selectedFilter === 'Pending Requests' ? (
    <Dropdown className="align-left">
      <Dropdown.Toggle
      className='pending-btn'
      >
        {item.status}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          eventKey="Approve"
          className="product-item-publish"
        >
          <p className="green-dot"></p>
          <p className="custom-font-12" onClick={() => this.handleCategoryApprove(item.name)}>Approve</p>
        </Dropdown.Item>
        <Dropdown.Item
          eventKey="Reject"
          className="product-item-unpublish"
        >
          <p className="red-dot"></p>
          <p className="custom-font-12" onClick={() => this.handleOpenRejectModal(item.name)}>Reject</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  ) : (
    item.status
  )}
</td>

{this.state.selectedFilter === 'Rejected' && (
      <td className="rejection-product-column">
        {item.rejectionReason}
      </td>
    )}
              </tr>
            ))}
          </tbody>
          <div>
          <Modal
           show={this.state.showRejectModal} 
          onHide={this.handleCloseRejectModal}
          centered
          >
  <Modal.Header closeButton>
    <Modal.Title>Reject Category</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <textarea
      rows="4"
      className="form-control"
      placeholder="Enter reason for rejection"
      value={this.state.rejectionReason}
      onChange={(e) => this.setState({ rejectionReason: e.target.value })}
    />
  </Modal.Body>
  <Modal.Footer>
    <Button variant="secondary" onClick={this.handleCloseRejectModal}>
      Close
    </Button>
    <Button variant="danger" onClick={this.handleReject}>
      Reject
    </Button>
  </Modal.Footer>
</Modal>

          </div>
        </Table>
        </div>
      </Stack>
      <div  style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
  <ReactPaginate
            pageCount={Math.ceil(Data.length / itemsPerPage)}
            pageRangeDisplayed={5}
            marginPagesDisplayed={1}
            onPageChange={(selectedPage) => this.handlePageChange(selectedPage.selected + 1)}
            containerClassName="pagination"
            activeClassName="pagination-item-active"
          />
</div>

      </div>
   
    );
  }
}
export default CategoryApproval;