import React from "react";
import { Button } from '@material-ui/core';
import logolink2 from "../assests/public/trezi-logo-full.png";
import successicon from "../assests/public/successicon-12.png";
import { Link, useNavigate ,U } from "react-router-dom";
import { useEffect } from "react";
import "./common.css";

const SuccessPage = () => {
  let navigate = useNavigate();

  const handleSubmit = async () => {
    navigate('/');
    console.log("aaa")
   };
   useEffect(() => {
    document.title = 'Success Page'; // Set the desired page title
  }, []);
  return (
    <div className="box-8-container">
   <div className="success-container">
      <div className="logo-container">
        <img src={logolink2} alt="Logo" className="logo" />
      </div>
      <div className="content-container">
        <img src={successicon} alt="Success Icon" className="success-icon" />
        {/* <h1 className="success-heading">Success!</h1> */}
        {/* <p className="success-message">Your submission was successful.</p> */}
        <Button className="login-button mt-4" variant="contained" onClick={handleSubmit} color="primary">Login Securely</Button>
      </div>
    </div>
    </div>
 
  );
};

export default SuccessPage;
