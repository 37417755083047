import React from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText , Box ,Button} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import addprosubscription from "../assests/public/add_product_specifications.png";
import usermanagement from "../assests/public/user_management.png";
import { Link, useNavigate } from "react-router-dom";
import "./sidebar.css";
import { Typography } from '@mui/material';

const drawerWidth = 235;

const useStyles = makeStyles((theme) => ({
  root:{
    display: 'flex',
    '&:hover': {
      backgroundColor:  "lightgrey",
      cursor: 'pointer',
    },
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  toolbar: theme.mixins.toolbar,
}));

function Sidebar() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        
        <div className={classes.toolbar} />
     
        <List className={classes.root}>
        <Link to="#" className="list-group-item list-group-item-action mt-2">
                <img className="image" src={usermanagement} alt="Logo" /> <span>User Management</span>
              </Link>
        </List>
        <List className={classes.root}>

        </List>
      </Drawer>

    </div>
  );
}

export default Sidebar;
