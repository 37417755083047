import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Plan from "./plan";
import "./plan.css";
import logolink2 from "../assests/public/trezi_logo-03.png";
import Loader from "../context/loader.js";
import ThankYouPage from "../common/thankyoupage.js";
import Navbarplanbox from './navbarplanbox';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { TextField, Grid, Box } from "@material-ui/core";
import { FaCheck } from "react-icons/fa";
import { baseUrlSubscribe, interceptor ,baseUrlUser ,baseUrlDiscount } from "../utils/common";


const url = baseUrlUser;
const urlSubscribe = baseUrlSubscribe;
const urlDiscount = baseUrlDiscount;
//for Gcp
 function PlanBox() {
  const [key, setKey] = useState(0);
  const [id, setId] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [plan1, setPlan1] = useState({});
  const [plan2, setPlan2] = useState({});
  const [plan3, setPlan3] = useState({});
  const [showThankYouPage, setShowThankYouPage] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [discount, setDiscount] = useState('');
  const [coupon, setCoupon] = useState('');
  const [finalPrice, setFinalPrice] = useState('');
  const [finalPriceWithGST, setFinalPriceWithGST] = useState('');
  const [purchaseFinalPrice, setPurchaseFinalPrice] = useState('');
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();
  const [selectedCoupon, setSelectedCoupon] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [featKeys, setFeatKeys] = useState([]);
  const [inclKeys, setInclKeys] = useState([]);
  const [modifiedAmount, setmodifiedAmount] = useState("");
  const [discountValue, setDiscountValue] = useState("");
  const [show, setShow] = useState(false);
  const [showAnotherModal, setShowAnotherModal] = useState(false);

  const handleCloseAnotherModal = () => {
    setShowAnotherModal(false);
    setFinalPriceWithGST(0);
    setDiscountValue(0);
  };

  const handleClose = () => {
    setShow(false);
    clearCoupon();
  };
  const handlepurchaseClose = () => {
    setShowAnotherModal(false);
    clearCoupon();
    setFinalPriceWithGST(0);
    setDiscountValue(0);
  };

  const handleShow = async (planTitle, planId) => {
    setShow(true);
    debugger;
    try {
      showLoader();
      const planData = await getPlans();
      const selectedPlan = planData.find((plan) => plan.item.name === planTitle && plan.id === planId);
      setSelectedPlan(selectedPlan);
      const dataplan = selectedPlan.notes;
      // const dataArray = Object.keys(dataplan).map(key => ({ name: key, value: dataplan[key] }));
      const planprice = selectedPlan.item.amount;
      const princeshow = planprice / 100;
      var modifiedAmount = `INR ${princeshow.toLocaleString('en-IN')}/ per Annum`;
      setmodifiedAmount(modifiedAmount);
      // Calculate GST amount
      const gstAmount = (princeshow * 0.10).toFixed(2); // Assuming GST is 18%

      // Calculate final price by adding GST amount to modified amount
      const finalPrice = princeshow + parseFloat(gstAmount);
      var modifiedfinalPriceAmount = `INR ${finalPrice.toLocaleString('en-IN')}/ per Annum`;
      setFinalPrice(modifiedfinalPriceAmount);
      const featKeys = [];
      const inclKeys = [];
      const indexesToRemove = [0, 3, 5, 7];

      for (const key in dataplan) {
        if (key.startsWith('feat_key')) {
          featKeys.push(dataplan[key]);
        }

        if (key.startsWith('incl_key')) {
          inclKeys.push(dataplan[key]);
        }
        inclKeys.sort();

      }
      const sortedInclKeys = inclKeys
        .filter((_, index) => !indexesToRemove.includes(index));

      console.log(sortedInclKeys);

      // Update the state with featKeys and inclKeys
      setFeatKeys(featKeys);
      setInclKeys(sortedInclKeys);
      console.log(inclKeys)
      hideLoader();
    } catch (error) {
      console.error('Error retrieving plan data:', error);
      // Handle error case here
      hideLoader();
    }
  };
  const handleanotherShow = async (planTitle, planId) => {
    setShowAnotherModal(true);
    debugger;
    try {
      showLoader();
      const planData = await getPlans();
      const selectedPlan = planData.find((plan) => plan.item.name === planTitle && plan.id === planId);
      setSelectedPlan(selectedPlan);
      const dataplan = selectedPlan.notes;
      const planprice = selectedPlan.item.amount;
      const princeshow = planprice / 100;
      var modifiedAmount = `INR ${princeshow.toLocaleString('en-IN')}/ per Annum`;
      setmodifiedAmount(modifiedAmount);
      const gstPercentage = 10; // Assuming GST is 10%
      const purchaseFinalPrice = princeshow + (princeshow * gstPercentage / 100);
      setPurchaseFinalPrice(purchaseFinalPrice);   
      const featKeys = [];
      const inclKeys = [];
      const indexesToRemove = [0, 3, 5, 7];
      for (const key in dataplan) {
        if (key.startsWith('feat_key')) {
          featKeys.push(dataplan[key]);
        }

        if (key.startsWith('incl_key')) {
          inclKeys.push(dataplan[key]);
        }
        inclKeys.sort();
      }
      const sortedInclKeys = inclKeys
        .filter((_, index) => !indexesToRemove.includes(index));

      console.log(sortedInclKeys);
      // Update the state with featKeys and inclKeys
      setFeatKeys(featKeys);
      setInclKeys(sortedInclKeys);
      hideLoader();
    } catch (error) {
      console.error('Error retrieving plan data:', error);
      // Handle error case here
      hideLoader();
    }
  };

  console.log(featKeys)
  useEffect(() => {
    showLoader();
    hideLoader();
  }, []);

  const clearCoupon = () => {
    setSelectedCoupon('');
    setErrorMessage('');
    setSuccessMessage('');
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }

    return "";
  };
  const getUser = async () => {
    try {
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        console.error('JWT token not found in cookies');
        return;
      }
      debugger;

      const response = await fetch(
        `${url}users/getme`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`,
            'Cookie': `jwt=${jwt}`,
          },
          method: 'GET',
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data, "data");
        setUserData(data);
        // Pre-fill the name, email, and phone fields in the modal
        setName(data.name);
        setEmail(data.email);
        setPhone(data.phone);
      } else {
        throw new Error('Failed to retrieve user data');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchCoupons = async () => {
    try {
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        setErrorMessage('JWT token not found in cookies');
        return;
      }
      const response = await fetch(`${urlDiscount}coupons/all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getCookie('jwt')}`,
          'Cookie': `jwt=${getCookie('jwt')}`,
        },
      });

      if (response.ok) {
        const coupons = await response.json();
        console.log(coupons.data);
        setCoupon(coupons.data);
        return coupons.data; // Return the fetched data
      } else {
        throw new Error('Failed to fetch coupons');
      }
    } catch (error) {
      console.error(error);
      throw error; // Rethrow the error to be handled in the calling function
    }
  };


  const handleApplyCoupon = async () => {
    try {
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        setErrorMessage('JWT token not found in cookies');
        return;
      }

      if (!selectedCoupon) {
        setErrorMessage('No coupon selected');
        return;
      }
      const coupons = await fetchCoupons();
      console.log(coupons);
      const response = await fetch(
        `${urlDiscount}coupons/apply?name=${selectedCoupon}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${jwt}`,
            'Cookie': `jwt=${jwt}`,
          },
        }
      );

      if (response.ok) {
        setErrorMessage('');
        const responseData = await response.json(); // Parse the response data as JSON
        const discountValue = responseData.data;
        setDiscountValue(discountValue);
        const planprice = selectedPlan.item.amount;
        const princeshow = planprice / 100;
        const finalPrice = princeshow - discountValue;
        const gstPercentage = 18; // Assuming GST is 10%
        const finalPriceWithGST = finalPrice + (finalPrice * gstPercentage / 100);
        setFinalPriceWithGST(finalPriceWithGST);
        console.log(finalPriceWithGST);
        setSuccessMessage('Coupon applied successfully');
      } else {
        setErrorMessage('Failed to apply coupon . Try with another coupon code.');
        setSuccessMessage('');
      }
    } catch (error) {
      console.error(error);
      setErrorMessage('Failed to apply coupon. Please try again.');
      setSuccessMessage('');
    }
  };

  const handleSubscribeSubmit = (planTitle, planId) => {
    debugger;
    handleClick(planTitle, planId);
    handleClose();
  };
  const handlePurchaseSubmit = (planTitle, planId) => {
    debugger;
    handleonClick(planTitle, planId)
    handleClose();
  };

  const handleonClick = (planTitle, planId) => {
    try {
      showLoader();
      let amount, description;
      debugger;
      switch (planId) {
        case "plan_MIhne4Cu8gsuB8": // Platinum Plan
          amount = 36000000;
          description = "Platinum Plan";
          break;
        case "plan_MIhn9yGfA8dE2C": // Gold Plan
          amount = 24000000;
          description = "Gold Plan";
          break;
        case "plan_MOhQZulZZv6Q2F": // Silver Plan
          amount = 8500000;
          description = "Silver Plan";
          break;
        default:
          throw new Error(`Invalid planId: ${planId}`);
      }
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        setErrorMessage('JWT token not found in cookies');
        return;
      }
      const finalAmount = finalPriceWithGST * 100;
      const currentDate = new Date();
      const endDate = new Date(
        currentDate.getFullYear() + 1,
        currentDate.getMonth(),
        currentDate.getDate()
      );
      const startDate = Math.floor(currentDate.getTime() / 1000); // Unix timestamp of the current date
      const endDateUnixTimestamp = Math.floor(endDate.getTime() / 1000); // Unix timestamp of the endDate
      const requestBody = {
        userId: email,
        planId: planId, // Use the actual planId here
        amount: finalAmount !== 0  ? finalAmount : (amount + (amount * 0.1)),
        currency: "INR",
        offerId: "example_offer",
        startDate: startDate,
        endDate: endDateUnixTimestamp
      };

      fetch(`${urlSubscribe}orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
          'Cookie': `jwt=${jwt}`,
        },
        body: JSON.stringify(requestBody),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('API Response:', data);
          const orderID = data.orderId; // Get the order ID from the API response
            const rzpOptions = {
            key: 'rzp_live_X0bMjd5BA7GVf9',
            amount: finalAmount !== 0 ? finalAmount : amount,
            currency: 'INR',
            name: planTitle,
            description: description,
            order_id: orderID,
            theme: {
              color: '#0000ff',
            },
            handler: function (response) {
              console.log('Payment success!', response);
              const razorpay_payment_id = response.razorpay_payment_id;
              // API call to capture payment
              fetchPayment(razorpay_payment_id , 'Purchase')

                .then((data) => {
                  console.log('API Response:', data);
                  navigate("/thankyoupage"); // Replace with your desired page or route
                })
                .catch((error) => {
                  console.error('Error capturing payment:', error);
                  // Handle the error appropriately (e.g., show an error message to the user)
                });
            },
            modal: {
              ondismiss: function () {
                hideLoader();
                console.log('Payment modal closed');
                // Handle the modal dismissal logic here
              },
            },
          };

          const razorpay = new window.Razorpay(rzpOptions);
          razorpay.open();
        })
        .catch((error) => {
          console.error('Error creating order:', error);
          // Handle the error appropriately (e.g., show an error message to the user)
        });
    } catch (error) {
      console.error('Error opening Razorpay payment modal:', error);
      // Handle the error appropriately (e.g., show an error message to the user)
    }
  };


  const getPlans = async () => {
    try {
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        setErrorMessage('JWT token not found in cookies');
        return;
      }
      const response = await fetch(`${urlSubscribe}fetchPlans`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
          'Cookie': `jwt=${jwt}`,
        },
        body: JSON.stringify({
          "platinumPlanId": "plan_MIhne4Cu8gsuB8",
           "silverPlanId": "plan_MOhQZulZZv6Q2F",
          "goldPlanId": "plan_MIhn9yGfA8dE2C",
      }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch plans');
      }

      const planData = await response.json();

      return planData;
    } catch (error) {
      console.error('Error creating plan:', error);
      throw error;
    }
  };

  const showLoader = () => {
    setIsLoading(true);
  }
  const hideLoader = () => {
    setIsLoading(false);
  };

  const displayPlans = async () => {
    try {
      showLoader();
      const planData = await getPlans();
      console.log(planData);
      // const [plan1, plan2, plan3] = planData;
      const [plan1] = planData.filter(plan => plan.id === 'plan_MIhne4Cu8gsuB8');
      const [plan2] = planData.filter(plan => plan.id === 'plan_MIhn9yGfA8dE2C');
      const [plan3] = planData.filter(plan => plan.id === 'plan_MOhQZulZZv6Q2F');

      console.log(plan1); // [1, 2, 3]
      console.log(plan2); // [4, 5, 6]
      console.log(plan3); // [7, 8, 9]
      const plantitle = plan1.item.name;
      const plantitle2 = plan2.item.name;
      const plantitle3 = plan3.item.name;
      const planprice = plan1.item.amount;
      const planprice2 = plan2.item.amount;
      const planprice3 = plan3.item.amount;
      const princeshow1 = planprice / 100;
      // var modifiedAmount = princeshow1.toLocaleString('en-IN');
      var modifiedAmount = `INR ${princeshow1.toLocaleString('en-IN')}/ per Annum`;
      const princeshow2 = planprice2 / 100;
      var modifiedAmount2 = `INR ${princeshow2.toLocaleString('en-IN')}/ per Annum`;
      const princeshow3 = planprice3 / 100;
      var modifiedAmount3 = `INR ${princeshow3.toLocaleString('en-IN')}/ per Annum`;
      const plan1Id = plan1.id;
      const plan2Id = plan2.id;
      const plan3Id = plan3.id;
      const dataPlan1 = plan1.notes;
      const dataPlan2 = plan2.notes;
      const dataPlan3 = plan3.notes;
      console.log(planprice);
      console.log(plantitle);
      const dataArray = Object.keys(dataPlan1).map(key => ({ name: key, value: dataPlan1[key] }));
      const dataArray2 = Object.keys(dataPlan2).map(key => ({ name: key, value: dataPlan2[key] }));
      const dataArray3 = Object.keys(dataPlan3).map(key => ({ name: key, value: dataPlan3[key] }));
      console.log(dataArray);
      console.log(dataArray2);
      console.log(dataArray3);
      const featKeys = dataArray.filter(item => item.name.includes("feat_key")).map(item => item.value);
      const inclKeys = dataArray
        .filter(item => item.name.includes("incl_key"))
        .sort((a, b) => {
          const inclKeyA = parseInt(a.name.match(/\d+/)[0]);
          const inclKeyB = parseInt(b.name.match(/\d+/)[0]);

          return inclKeyA - inclKeyB; // Sort based on the numeric value of incl_key
        })
        .map(item => item.value);

      inclKeys.splice(1, 4); // Remove elements at indices 1, 2, 3, and 4

      console.log(inclKeys);
      const inclKeys2 = dataArray2
        .filter(item => item.name.includes("incl_key"))
        .sort((a, b) => {
          const inclKeyA = parseInt(a.name.match(/\d+/)[0]);
          const inclKeyB = parseInt(b.name.match(/\d+/)[0]);

          return inclKeyA - inclKeyB; // Sort based on the numeric value of incl_key
        })
        .map(item => item.value);

      inclKeys2.splice(1, 4); // Remove elements at indices 1, 2, 3, and 4

      console.log(inclKeys2);

      // Similarly for inclKeys3
      const inclKeys3 = dataArray3
        .filter(item => item.name.includes("incl_key"))
        .sort((a, b) => {
          const inclKeyA = parseInt(a.name.match(/\d+/)[0]);
          const inclKeyB = parseInt(b.name.match(/\d+/)[0]);

          return inclKeyA - inclKeyB; // Sort based on the numeric value of incl_key
        })
        .map(item => item.value);

      inclKeys3.splice(1, 4); // Remove elements at indices 1, 2, 3, and 4

      console.log(inclKeys3);


      console.log(featKeys);
      const buttonVariant1 = "primary";
      const buttonVariant2 = "danger";
      const buttonVariant3 = "success";

      setPlan1({ ...plan1, featKeys: featKeys, buttonVariant1: buttonVariant1, plan1Id: plan1Id, inclKeys: inclKeys, modifiedAmount: modifiedAmount, plantitle: plantitle, heading: "Inclusions", taxes: "+ taxes as applicable at the time of invoicing" });
      setPlan2({ ...plan2, featKeys: featKeys, buttonVariant2: buttonVariant2, plan2Id: plan2Id, inclKeys2: inclKeys2, modifiedAmount2: modifiedAmount2, plantitle2: plantitle2, heading: "Inclusions", taxes: "+ taxes as applicable at the time of invoicing" });
      setPlan3({ ...plan3, featKeys: featKeys, plan3Id: plan3Id, buttonVariant3: buttonVariant3, inclKeys3: inclKeys3, modifiedAmount3: modifiedAmount3, plantitle3: plantitle3, heading: "Inclusions", taxes: "+ taxes as applicable at the time of invoicing" });
      // set state with the fetched plans
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
    finally {
      hideLoader(); // Hide the loading component after the operation is completed
    }
  };

  useEffect(() => {
    displayPlans(); // fetch and display the plans on component mount
  }, []);

  useEffect(() => {
    getUser();
  }, []);

  useEffect(() => {
    document.title = 'Payment Page'; // Set the desired page title
  }, []);
  const handleClick = async (planTitle, planId) => {
    debugger;
    showLoader();
    let amount, description;
    const userEmail = localStorage.getItem('email');
    switch (planId) {
      case "plan_MIhne4Cu8gsuB8": // Platinum Plan
        amount = 3600000;
        description = "Platinum Plan Subscription";
        break;
      case "plan_MIhn9yGfA8dE2C": // Gold Plan
        amount = 2400000;
        description = "Gold Plan Subscription";
        break;
      case "plan_MOhQZulZZv6Q2F": // Silver Plan
        amount = 850000;
        description = "Silver Plan Subscription";
        break;
      default:
        throw new Error(`Invalid planId: ${planId}`);
    }

    const planBody = {
      userId: userEmail,
      palnId: planId,
      subId: 'sub_50',
      lisenceKey: 'gdfgfvfgddgs',
      autoRenew: 'no',
      startDate: null,
      endDate: null,
      name: planTitle,
      amount: amount,
      currency: 'INR',
      status: 'active',
      total_count: 5,
      customer_notify: 1,

    };

    try {
      const jwt = getCookie('treziAccessToken'); // Retrieve the token cookie
      console.log(jwt);
      const response = await fetch(`${urlSubscribe}createSubscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
          'Cookie': `jwt=${jwt}`,
        },
        body: JSON.stringify(planBody),

      });
      console.log('Request Payload:', JSON.stringify(planBody));


      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }

      const data = await response.json();
      console.log('Subscription created:', data);
      const key = 'rzp_live_X0bMjd5BA7GVf9';
      const rzpOptions = {
        key, // Replace with your actual API key
        amount: planBody.amount,
        currency: planBody.currency,
        name: planBody.name,
        email: planBody.userId,
        description: description,
        subscription_id: data.id, // Replace with the appropriate field from the response data
        notes: {
          notes: "Razorpay Corporate Office"
        },
        handler: function (response) {
          console.log('Payment success!', response);
          const amount = planBody.amount;
          const razorpay_payment_id = response.razorpay_payment_id;
          // capturePayment(razorpay_payment_id, amount);
          fetchPayment(razorpay_payment_id ,'Subscription')
            .then(() => {
              navigate("/thankyoupage"); // Replace with your login screen URL or route
            })
            .catch((error) => {
              console.error('Error capturing payment:', error);
            });
        },
        theme: {
          color: '#0000ff',
        },
      };

      const razor = new window.Razorpay(rzpOptions);
      razor.on('payment.success', function (response) {
        const razorpay_payment_id = response.razorpay_payment_id;
        capturePayment(razorpay_payment_id);
      });
      razor.on("payment.cancel", function () {
        hideLoader(); // Hide the loader if payment is canceled
      });

      razor.open();
    } catch (error) {
      console.error('Error creating subscription:', error);
    }
    hideLoader();
  };


  const capturePayment = async (paymentData) => {
    try {
      debugger;
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        console.error('JWT token not found in cookies');
        return;
      }
      const requestBody = {
        paymentId: paymentData.id,
        orderId: paymentData.order_id,
        amount: paymentData.amount,
        currency: paymentData.currency
      };

      const response = await fetch(`${urlSubscribe}capturePayment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
          'Cookie': `jwt=${jwt}`,
        },
        body: JSON.stringify(requestBody)
      });

      if (response.ok) {
        const responseData = await response.json();
        console.log('Payment captured successfully');
        console.log(responseData);
      } else {
        const errorMessage = await response.text();
        console.error(`Failed to capture payment: ${response.status} - ${errorMessage}`);
      }
    } catch (error) {
      console.error('Error capturing payment:', error);
    }
  };

  const fetchPayment = async (razorpay_payment_id ,purchaseType) => {
    debugger;
    try {
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        console.error('JWT token not found in cookies');
        // Handle the error, display an appropriate message to the user, or redirect to the login page
        return;
      }

      // const apiUrl = `http://125.63.115.254:8080/subscriptionservice/api/v1/fetchPayment/pay_${razorpay_payment_id}`;
      const response = await fetch(`${urlSubscribe}fetchPayment/pay_${razorpay_payment_id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
          'Cookie': `jwt=${jwt}`,
        },
      });

      if (response.ok) {
        const paymentData = await response.json();
        console.log('Payment data:', paymentData);
        await addPayment(paymentData ,purchaseType);
        await capturePayment(paymentData);
        // Process the payment data or perform any other actions with it
      } else {
        console.error('Failed to fetch payment data');
        // Handle the error or display an error message
      }
    } catch (error) {
      console.error(error);
      // Handle errors here
    }
  };
  const showMessage = (message, type) => {
    // Implement your logic to display the message
    console.log(`Message: ${message}, Type: ${type}`);
  };

  const addPayment = async (paymentData ,purchaseType) => {
    try {
      debugger;
     const plandescription  = paymentData.description;
     const description = plandescription.split(' ')[0];
     const currentDate = new Date();
const year = currentDate.getFullYear();
const month = currentDate.getMonth() + 1; // Adding 1 because month is zero-based
const day = currentDate.getDate();
const hours = currentDate.getHours();
const minutes = currentDate.getMinutes();
const seconds = currentDate.getSeconds();
const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}, ${day} ${hours}:${minutes}:${seconds}`;
console.log(formattedDate); // Output: Current date in the format yyyy-mm-dd

      const planDetails = {
        email: email,
        name: name,
        product: "Showcase",
        plan: description,
        paymentId: paymentData.id,
        transactionDate: currentDate,
        amount: paymentData.amount/100,
        status: 0,
        purchaseType: purchaseType,
        orderId: paymentData.order_id,
        offerId: "23242353",
        discountValue : discountValue,
      };

      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        console.error('JWT token not found in cookies');
        // Handle the error, display an appropriate message to the user, or redirect to the login page
        return;
      }

      const response = await fetch(`${url}payments/addpayment`, {
        method: 'POST',
        headers: {
          'Cookie': `jwt=${jwt}`,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`, // Add the JWT token as a bearer token
        },
        body: JSON.stringify(planDetails),
      });
      if (response.ok) {
        const responseData = await response.json();
        // Pass responseData as a query parameter to the payment page
        localStorage.setItem('paymentId', planDetails.paymentId);
        navigate("/thankyoupage");
        hideLoader();
        showMessage('Payment added successfully', 'success');
      } else {
        console.log('Failed to add payment');
        hideLoader();
        showMessage('Failed to add payment', 'error');
      }
    } catch (error) {
      // Handle network error
      console.log('Network error occurred');
      // Perform any error handling here

      // Clear the loader and show error message
      hideLoader();
      showMessage('Network error occurred', 'error');
    }
  };


  return (
    <div>
      {isLoading ? <Loader /> : null}
      <div className="box8"  style={{display:"grid"}}>
        <Navbarplanbox />
        <div className="plain-main-container">
          <div className="plain-container">
            <div className="text">Select a Plan</div>
            <div className="row-planboard">
              <div className="col-board">
                <Plan
                  key={key}
                  planId={plan1.plan1Id}
                  title={plan1.plantitle}
                  price={plan1.modifiedAmount}
                  features={plan1.featKeys}
                  variant={plan1.buttonVariant1}
                  inclusions={plan1.inclKeys}
                  heading={plan1.heading}
                  taxes={plan1.taxes}
                  commonline={plan1.commonline}
                  // catalog_manager={plan1.catalog_manager1}
                  // sales_person_license_count={plan1.sales_person_license_count1}
                  // handleClick={handleShow}
                  handleClick={() => handleShow(plan1.plantitle, plan1.plan1Id, 'subscribe')}
                  handleonClick={() => handleanotherShow(plan1.plantitle, plan1.plan1Id, 'purchase')}
                />
              </div>
              <div className="col-board">
                <Plan
                  key={key + 1}
                  planId={plan2.plan2Id}
                  title={plan2.plantitle2}
                  price={plan2.modifiedAmount2}
                  features={plan2.featKeys}
                  variant={plan2.buttonVariant2}
                  inclusions={plan2.inclKeys2}
                  commonline={plan2.commonline}
                  catalog_manager={plan2.catalog_manager2}
                  sales_person_license_count={plan2.sales_person_license_count2}
                  heading={plan1.heading}
                  taxes={plan2.taxes}
                  handleClick={() => handleShow(plan2.plantitle2, plan2.plan2Id)}
                  handleonClick={() => handleanotherShow(plan2.plantitle2, plan2.plan2Id)}

                />
              </div>
              <div className="col-board">
                <Plan
                  key={key + 2}
                  planId={plan3.plan3Id}
                  title={plan3.plantitle3}
                  price={plan3.modifiedAmount3}
                  features={plan3.featKeys}
                  variant={plan3.buttonVariant3}
                  commonline={plan3.commonline}
                  catalog_manager={plan3.catalog_manager3}
                  sales_person_license_count={plan3.sales_person_license_count3}
                  inclusions={plan3.inclKeys3}
                  heading={plan1.heading}
                  taxes={plan3.taxes}
                  handleClick={() => handleShow(plan3.plantitle3, plan3.plan3Id)}
                  handleonClick={() => handleanotherShow(plan3.plantitle3, plan3.plan3Id)}
                />
              </div>

            </div>
          </div>
        </div>
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          centered={true}
          className="form-modal"
          dialogClassName="my-modal"
        >
          <div className="modal-container">
            <Modal.Header closeButton onClick={clearCoupon}>
              <img src={logolink2} alt="Logo" style={{ width: "130px", color: "black" }} />
            </Modal.Header>
            <div className="row mt-3">
              <div className='col-6'>

                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    height={620}
                    border={1}
                    borderColor="grey.400"
                    borderRadius={5}
                    px={2}
                  >
                    <h4 className="mt-2 info-head" >User Details</h4>
                    <div className="modal-content">
                      <div className="info-container">
                        <div className="info-item mt-4">
                          <label className="info-label">Name:  </label>
                          <span className="info-value">{name}</span>
                        </div>
                        <div className="info-item">
                          <label className="info-label">Email: </label>
                          <span className="info-value">{email}</span>
                        </div>
                        <div className="info-item">
                          <label className="info-label">Phone Number:</label>
                          <span className="info-value">{phone}</span>
                        </div>
                      </div>
                      {/* <Grid container spacing={2} alignItems="center">
                        <Grid item xs={9}>
                          <TextField
                            value={selectedCoupon}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            onChange={(e) => setSelectedCoupon(e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={3}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleApplyCoupon}
                          >
                            Apply
                          </Button>
                        </Grid>

                        <Grid item xs={12}>
                          {errorMessage && <div className="error-message">{errorMessage}</div>}
                          {successMessage && <div className="success-message">{successMessage}</div>}
                        </Grid>
                      </Grid> */}
                      <div className="info-item">
                        <label className="info-label">Subscribed Price:</label>
                        <span className="info-value"> {modifiedAmount}</span>
                      </div>
                      {/* <div className="info-item">
                        <label className="info-label">Discount Price:</label>
                        <span className="info-value">{discountValue}</span>
                      </div> */}
                      <div className="info-item">
                        <label className="info-label">Final Price: Subscribed Price + 10% GST  : </label>
                        <span className="info-value">{finalPrice}</span>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <Button
                        className='modal-button primary' // Add "primary" class for primary button style
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubscribeSubmit(selectedPlan.item.name, selectedPlan.id)}
                        style={{ fontSize: "16px" }}
                      >
                        Pay
                      </Button>
                      <Button
                        className='modal-button secondary' // Add "secondary" class for secondary button style
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                        style={{ fontSize: "16px" }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </Grid>
              </div>


              <div className='col-6'>
                <Grid item xs={12}>
                  {isLoading ? <Loader /> : null}
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center" // Set alignItems to "center" to horizontally center the content
                    //  justifyContent="center" 
                    height={620}
                    // border={1}
                    // borderColor="grey.400"
                    borderRadius={5}
                    // px={2}
                    bgcolor="grey.200"
                  >
                    <div className="review-purchase-box">
                      <h4 className="mt-2 review-purchase-heading">Review and Purchase</h4>
                    </div>

                    {selectedPlan && (
                      <div>
                        <div className="plan" >
                          <h3 className="plan-title mt-2">{selectedPlan.item.name}</h3>
                          <h3 className="plan-price mt-2">{modifiedAmount}</h3>
                          <h5 className="plan-taxes">{plan1.taxes}</h5>
                          <ul className="plan-features">
                            {featKeys.map((feature, index) => (
                              <li key={index}>  <FaCheck style={{ marginRight: "8px" }} />  {feature}</li>
                            ))}
                          </ul>
                          <label></label>
                          <h5 className="plan-heading">{plan1.heading}</h5>
                          <ul className="plan-inclusions mt-2" style={{ listStyleType: "none" }}>
                            {inclKeys.map((feature, index) => (
                              <li key={index}>  <FaCheck style={{ marginRight: "8px" }} /> {feature}</li>
                            ))}
                          </ul>
                        </div>
                        <div>
                        </div>
                      </div>
                    )}
                  </Box>
                </Grid>
              </div>
            </div>
          </div>
        </Modal>
      </div>
      <div>
        <Modal
          show={showAnotherModal}
          onHide={handleCloseAnotherModal}
          backdrop="static"
          keyboard={false}
          centered={true}
          className="form-modal"
          style={{paddingLeft:"0"}}
          dialogClassName="my-modal"
        >
          <div className="modal-container">
            <Modal.Header closeButton onClick={clearCoupon}>
              <img src={logolink2} alt="Logo" style={{ width: "130px", color: "black" }} />
            </Modal.Header>
            {/* Modal content */}
            <div className="row mt-3" style={{rowGap:"20px"}}>
              <div className='col-6'>

                <Grid item xs={12}>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    justifyContent="flex-start"
                    height={620}
                    border={1}
                    borderColor="grey.400"
                    borderRadius={5}
                    px={2}
                  >
                    <h4 className="mt-2 info-head" >User Details</h4>
                    <div className="modal-content">
                      <div className="info-container">
                        <div className="info-item mt-4">
                          <label className="info-label">Name:  </label>
                          <span className="info-value">{name}</span>
                        </div>
                        <div className="info-item">
                          <label className="info-label">Email: </label>
                          <span className="info-value">{email}</span>
                        </div>
                        <div className="info-item">
                          <label className="info-label">Phone Number:</label>
                          <span className="info-value">{phone}</span>
                        </div>
                      </div>
                      <Grid container spacing={2} alignItems="center">
                        <Grid item xs={9}>
                        <label className="info-label">Apply coupon Here</label>
                          <TextField
                            value={selectedCoupon}
                            variant="outlined"
                            fullWidth
                            margin="normal"
                            onChange={(e) => setSelectedCoupon(e.target.value)}
                          />
                        </Grid>

                        <Grid item xs={3}>
                        <label className="info-label mt-3"></label>
                          <Button
                          className='mt-2'
                            variant="contained"
                            color="primary"
                            onClick={handleApplyCoupon}
                          >
                            Apply
                          </Button>
                        </Grid>

                        <Grid item xs={12}>
                          {errorMessage && <div className="error-message">{errorMessage}</div>}
                          {successMessage && <div className="success-message">{successMessage}</div>}
                        </Grid>
                      </Grid>
                      <div className="info-item">
                        <label className="info-label">Subscribed Price:</label>
                        <span className="info-value"> {modifiedAmount}</span>
                      </div>
                      <div className="info-item">
                        <label className="info-label">Discount Price:</label>
                        <span className="info-value">{discountValue}</span>
                      </div>
                      <div className="info-item">
                        <label className="info-label">Final Price: Subscribed Price - Discount + 10%GST  :</label>
                        <span className="info-value">   {discountValue ? finalPriceWithGST : purchaseFinalPrice}</span>
                      </div>
                    </div>

                    <div className="modal-footer">
                      <Button
                        className='modal-button primary' // Add "primary" class for primary button style
                        variant="contained"
                        color="primary"
                        onClick={() => handlePurchaseSubmit(selectedPlan.item.name, selectedPlan.id)}
                        style={{ fontSize: "16px" }}
                      >
                        Pay
                      </Button>
                      <Button
                        className='modal-button secondary' // Add "secondary" class for secondary button style
                        variant="contained"
                        color="secondary"
                        onClick={handlepurchaseClose}
                        style={{ fontSize: "16px" }}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Box>
                </Grid>
              </div>


              <div className='col-6'>
                <Grid item xs={12}>
                  {isLoading ? <Loader /> : null}
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center" // Set alignItems to "center" to horizontally center the content
                    //  justifyContent="center" 
                    height={620}
                    // border={1}
                    // borderColor="grey.400"
                    borderRadius={5}
                    // px={2}
                    bgcolor="grey.200"
                  >
                   

                    {selectedPlan && (
                      <div>
                        <div className="plan" >
                          <h3 className="plan-title mt-2">{selectedPlan.item.name}</h3>
                          <h3 className="plan-price mt-2">{modifiedAmount}</h3>
                          <h5 className="plan-taxes">{plan1.taxes}</h5>
                          <label></label>
                          <ul className="plan-features">
                            {featKeys.map((feature, index) => (
                              <li key={index}>  <FaCheck style={{ marginRight: "8px" }} />  {feature}</li>
                            ))}
                          </ul>
                          <label></label>
                          <h5 className="plan-heading">{plan1.heading}</h5>
                          <ul className="plan-inclusions mt-2" style={{ listStyleType: "none" }}>
                            {inclKeys.map((feature, index) => (
                              <li key={index}>  <FaCheck style={{ marginRight: "8px" }} /> {feature}</li>
                            ))}
                          </ul>
                        </div>
                        <div>
                        </div>
                      </div>
                    )}
                  </Box>
                </Grid>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
}
//For Qc
// function PlanBox() {
//   const [key, setKey] = useState(0);
//   const [id, setId] = useState([]);
//   const [isLoading, setIsLoading] = useState(true);
//   const [plan1, setPlan1] = useState({});
//   const [plan2, setPlan2] = useState({});
//   const [plan3, setPlan3] = useState({});
//   const [showThankYouPage, setShowThankYouPage] = useState(false);
//   const [name, setName] = useState('');
//   const [email, setEmail] = useState('');
//   const [phone, setPhone] = useState('');
//   const [discount, setDiscount] = useState('');
//   const [coupon, setCoupon] = useState('');
//   const [finalPrice, setFinalPrice] = useState('');
//   const [finalPriceWithGST, setFinalPriceWithGST] = useState('');
//   const [purchaseFinalPrice, setPurchaseFinalPrice] = useState('');
//   const [userData, setUserData] = useState(null);
//   const navigate = useNavigate();
//   const [selectedCoupon, setSelectedCoupon] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');
//   const [selectedPlan, setSelectedPlan] = useState(null);
//   const [featKeys, setFeatKeys] = useState([]);
//   const [inclKeys, setInclKeys] = useState([]);
//   const [modifiedAmount, setmodifiedAmount] = useState("");
//   const [discountValue, setDiscountValue] = useState("");
//   const [show, setShow] = useState(false);
//   const [showAnotherModal, setShowAnotherModal] = useState(false);

//   const handleCloseAnotherModal = () => {
//     setShowAnotherModal(false);
//     setFinalPriceWithGST(0);
//     setDiscountValue(0);
//   };

//   const handleClose = () => {
//     setShow(false);
//     clearCoupon();
//   };
//   const handlepurchaseClose = () => {
//     setShowAnotherModal(false);
//     clearCoupon();
//     setFinalPriceWithGST(0);
//     setDiscountValue(0);
//   };

//   const handleShow = async (planTitle, planId) => {
//     setShow(true);
//     debugger;
//     try {
//       showLoader();
//       const planData = await getPlans();
//       const selectedPlan = planData.find((plan) => plan.item.name === planTitle && plan.id === planId);
//       setSelectedPlan(selectedPlan);
//       const dataplan = selectedPlan.notes;
//       // const dataArray = Object.keys(dataplan).map(key => ({ name: key, value: dataplan[key] }));
//       const planprice = selectedPlan.item.amount;
//       const princeshow = planprice / 100;
//       var modifiedAmount = `INR ${princeshow.toLocaleString('en-IN')}/ per Annum`;
//       setmodifiedAmount(modifiedAmount);
//       // Calculate GST amount
//       const gstAmount = (princeshow * 0.10).toFixed(2); // Assuming GST is 18%

//       // Calculate final price by adding GST amount to modified amount
//       const finalPrice = princeshow + parseFloat(gstAmount);
//       var modifiedfinalPriceAmount = `INR ${finalPrice.toLocaleString('en-IN')}/ per Annum`;
//       setFinalPrice(modifiedfinalPriceAmount);
//       const featKeys = [];
//       const inclKeys = [];
//       const indexesToRemove = [0, 3, 5, 7];

//       for (const key in dataplan) {
//         if (key.startsWith('feat_key')) {
//           featKeys.push(dataplan[key]);
//         }

//         if (key.startsWith('incl_key')) {
//           inclKeys.push(dataplan[key]);
//         }
//         inclKeys.sort();

//       }
//       const sortedInclKeys = inclKeys
//         .filter((_, index) => !indexesToRemove.includes(index));

//       console.log(sortedInclKeys);

//       // Update the state with featKeys and inclKeys
//       setFeatKeys(featKeys);
//       setInclKeys(sortedInclKeys);
//       console.log(inclKeys)
//       hideLoader();
//     } catch (error) {
//       console.error('Error retrieving plan data:', error);
//       // Handle error case here
//       hideLoader();
//     }
//   };
//   const handleanotherShow = async (planTitle, planId) => {
//     setShowAnotherModal(true);
//     debugger;
//     try {
//       showLoader();
//       // Call getPlans function to retrieve plan data
//       const planData = await getPlans();
//       // Find the selected plan in the retrieved plan data
//       const selectedPlan = planData.find((plan) => plan.item.name === planTitle && plan.id === planId);
//       setSelectedPlan(selectedPlan);
//       const dataplan = selectedPlan.notes;
//       // const dataArray = Object.keys(dataplan).map(key => ({ name: key, value: dataplan[key] }));
//       const planprice = selectedPlan.item.amount;
//       const princeshow = planprice / 100;
//       var modifiedAmount = `INR ${princeshow.toLocaleString('en-IN')}/ per Annum`;
//       setmodifiedAmount(modifiedAmount);
//       // const subscribedDiscount = 30000; // Assuming subscribed discount of 100
//       const gstPercentage = 10; // Assuming GST is 10%
//       const purchaseFinalPrice = princeshow + (princeshow * gstPercentage / 100);
//       setPurchaseFinalPrice(purchaseFinalPrice);   
//       const featKeys = [];
//       const inclKeys = [];
//       const indexesToRemove = [0, 3, 5, 7];
//       for (const key in dataplan) {
//         if (key.startsWith('feat_key')) {
//           featKeys.push(dataplan[key]);
//         }

//         if (key.startsWith('incl_key')) {
//           inclKeys.push(dataplan[key]);
//         }
//         inclKeys.sort();
//       }
//       const sortedInclKeys = inclKeys
//         .filter((_, index) => !indexesToRemove.includes(index));

//       console.log(sortedInclKeys);
//       // Update the state with featKeys and inclKeys
//       setFeatKeys(featKeys);
//       setInclKeys(sortedInclKeys);
//       hideLoader();
//     } catch (error) {
//       console.error('Error retrieving plan data:', error);
//       // Handle error case here
//       hideLoader();
//     }
//   };

//   console.log(featKeys)
//   useEffect(() => {
//     showLoader();
//     hideLoader();
//   }, []);

//   const clearCoupon = () => {
//     setSelectedCoupon('');
//     setErrorMessage('');
//     setSuccessMessage('');
//   };

//   const getCookie = (name) => {
//     const cookies = document.cookie.split(";");

//     for (let i = 0; i < cookies.length; i++) {
//       const cookie = cookies[i].trim();

//       if (cookie.startsWith(`${name}=`)) {
//         return cookie.substring(name.length + 1);
//       }
//     }

//     return "";
//   };
//   const getUser = async () => {
//     try {
//       const jwt = getCookie('jwt');
//       if (!jwt) {
//         console.error('JWT token not found in cookies');
//         return;
//       }
//       debugger;

//       const response = await fetch(
//         `${url}users/getme`,
//         {
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${jwt}`,
//             'Cookie': `jwt=${jwt}`,
//           },
//           method: 'GET',
//         }
//       );

//       if (response.ok) {
//         const data = await response.json();
//         console.log(data, "data");
//         setUserData(data);
//         // Pre-fill the name, email, and phone fields in the modal
//         setName(data.name);
//         setEmail(data.email);
//         setPhone(data.phone);
//       } else {
//         throw new Error('Failed to retrieve user data');
//       }
//     } catch (error) {
//       console.error(error);
//     }
//   };
//   const fetchCoupons = async () => {
//     try {
//       const jwt = getCookie('jwt');
//       if (!jwt) {
//         setErrorMessage('JWT token not found in cookies');
//         return;
//       }
//       const response = await fetch(`${urlDiscount}coupons/all`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${getCookie('jwt')}`,
//           'Cookie': `jwt=${getCookie('jwt')}`,
//         },
//       });

//       if (response.ok) {
//         const coupons = await response.json();
//         console.log(coupons.data);
//         setCoupon(coupons.data);
//         return coupons.data; // Return the fetched data
//       } else {
//         throw new Error('Failed to fetch coupons');
//       }
//     } catch (error) {
//       console.error(error);
//       throw error; // Rethrow the error to be handled in the calling function
//     }
//   };


//   const handleApplyCoupon = async () => {
//     try {
//       const jwt = getCookie('jwt');
//       if (!jwt) {
//         setErrorMessage('JWT token not found in cookies');
//         return;
//       }

//       if (!selectedCoupon) {
//         setErrorMessage('No coupon selected');
//         return;
//       }
//       const coupons = await fetchCoupons();
//       console.log(coupons);
//       const response = await fetch(
//         `${urlDiscount}coupons/apply?name=${selectedCoupon}`,
//         {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${jwt}`,
//             'Cookie': `jwt=${jwt}`,
//           },
//         }
//       );

//       if (response.ok) {
//         setErrorMessage('');
//         const responseData = await response.json(); // Parse the response data as JSON
//         const discountValue = responseData.data;
//         setDiscountValue(discountValue);
//         const planprice = selectedPlan.item.amount;
//         const princeshow = planprice / 100;
//         const finalPrice = princeshow - discountValue;
//         const gstPercentage = 18; // Assuming GST is 10%
//         const finalPriceWithGST = finalPrice + (finalPrice * gstPercentage / 100);
//         setFinalPriceWithGST(finalPriceWithGST);
//         console.log(finalPriceWithGST);
//         setSuccessMessage('Coupon applied successfully');
//       } else {
//         setErrorMessage('Failed to apply coupon . Try with another coupon code.');
//         setSuccessMessage('');
//       }
//     } catch (error) {
//       console.error(error);
//       setErrorMessage('Failed to apply coupon. Please try again.');
//       setSuccessMessage('');
//     }
//   };

//   const handleSubscribeSubmit = (planTitle, planId) => {
//     debugger;
//     handleClick(planTitle, planId);
//     handleClose();
//   };
//   const handlePurchaseSubmit = (planTitle, planId) => {
//     debugger;
//     handleonClick(planTitle, planId)
//     handleClose();
//   };

//   const handleonClick = (planTitle, planId) => {
//     try {
//       showLoader();
//       let amount, description;
//       debugger;
//       switch (planId) {
//         case "plan_M1LpDpYHOlKPci": // Platinum Plan
//           amount = 36000000;
//           description = "Platinum Plan";
//           break;
//         case "plan_M4r4hvI86Rlp2V": // Gold Plan
//           amount = 24000000;
//           description = "Gold Plan";
//           break;
//         case "plan_M4r3qWHOUCajop": // Silver Plan
//           amount = 8500000;
//           description = "Silver Plan";
//           break;
//         default:
//           throw new Error(`Invalid planId: ${planId}`);
//       }
//       const jwt = getCookie('jwt');
//       if (!jwt) {
//         setErrorMessage('JWT token not found in cookies');
//         return;
//       }
//       const finalAmount = finalPriceWithGST * 100;
//       const currentDate = new Date();
//       const endDate = new Date(
//         currentDate.getFullYear() + 1,
//         currentDate.getMonth(),
//         currentDate.getDate()
//       );
//       const startDate = Math.floor(currentDate.getTime() / 1000); // Unix timestamp of the current date
//       const endDateUnixTimestamp = Math.floor(endDate.getTime() / 1000); // Unix timestamp of the endDate
//       const requestBody = {
//         userId: email,
//         planId: planId, // Use the actual planId here
//         amount: finalAmount !== 0  ? finalAmount : (amount + (amount * 0.1)),
//         currency: "INR",
//         offerId: "example_offer",
//         startDate: startDate,
//         endDate: endDateUnixTimestamp
//       };

//       fetch(`${urlSubscribe}orders`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${jwt}`,
//           'Cookie': `jwt=${jwt}`,
//         },
//         body: JSON.stringify(requestBody),
//       })
//         .then((response) => response.json())
//         .then((data) => {
//           console.log('API Response:', data);
//           const orderID = data.orderId; // Get the order ID from the API response
//             const rzpOptions = {
//             key: 'rzp_test_FEBZJLEOVAIXDS',
//             amount: finalAmount !== 0 ? finalAmount : amount,
//             currency: 'INR',
//             name: planTitle,
//             description: description,
//             order_id: orderID,
//             theme: {
//               color: '#0000ff',
//             },
//             handler: function (response) {
//               console.log('Payment success!', response);
//               const razorpay_payment_id = response.razorpay_payment_id;
//               // API call to capture payment
//               fetchPayment(razorpay_payment_id , 'Purchase')

//                 .then((data) => {
//                   console.log('API Response:', data);
//                   navigate("/thankyoupage"); // Replace with your desired page or route
//                 })
//                 .catch((error) => {
//                   console.error('Error capturing payment:', error);
//                   // Handle the error appropriately (e.g., show an error message to the user)
//                 });
//             },
//             modal: {
//               ondismiss: function () {
//                 hideLoader();
//                 console.log('Payment modal closed');
//                 // Handle the modal dismissal logic here
//               },
//             },
//           };

//           const razorpay = new window.Razorpay(rzpOptions);
//           razorpay.open();
//         })
//         .catch((error) => {
//           console.error('Error creating order:', error);
//           // Handle the error appropriately (e.g., show an error message to the user)
//         });
//     } catch (error) {
//       console.error('Error opening Razorpay payment modal:', error);
//       // Handle the error appropriately (e.g., show an error message to the user)
//     }
//   };


//   const getPlans = async () => {
//     try {
//       const jwt = getCookie('jwt');
//       if (!jwt) {
//         setErrorMessage('JWT token not found in cookies');
//         return;
//       }
//       const response = await fetch(`${urlSubscribe}fetchPlans`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${jwt}`,
//           'Cookie': `jwt=${jwt}`,
//         },
//         body: JSON.stringify({
//           "platinumPlanId": "plan_M1LpDpYHOlKPci",
//           "goldPlanId": "plan_M4r4hvI86Rlp2V",
//           "silverPlanId": "plan_M4r3qWHOUCajop"
//         }),
//       });

//       if (!response.ok) {
//         throw new Error('Failed to fetch plans');
//       }

//       const planData = await response.json();

//       return planData;
//     } catch (error) {
//       console.error('Error creating plan:', error);
//       throw error;
//     }
//   };

//   const showLoader = () => {
//     setIsLoading(true);
//   }
//   const hideLoader = () => {
//     setIsLoading(false);
//   };

//   const displayPlans = async () => {
//     try {
//       showLoader();
//       const planData = await getPlans();
//       console.log(planData);
//       // const [plan1, plan2, plan3] = planData;
//       const [plan1] = planData.filter(plan => plan.id === 'plan_M1LpDpYHOlKPci');
//       const [plan2] = planData.filter(plan => plan.id === 'plan_M4r4hvI86Rlp2V');
//       const [plan3] = planData.filter(plan => plan.id === 'plan_M4r3qWHOUCajop');

//       console.log(plan1); // [1, 2, 3]
//       console.log(plan2); // [4, 5, 6]
//       console.log(plan3); // [7, 8, 9]
//       const plantitle = plan1.item.name;
//       const plantitle2 = plan2.item.name;
//       const plantitle3 = plan3.item.name;
//       const planprice = plan1.item.amount;
//       const planprice2 = plan2.item.amount;
//       const planprice3 = plan3.item.amount;
//       const princeshow1 = planprice / 100;
//       // var modifiedAmount = princeshow1.toLocaleString('en-IN');
//       var modifiedAmount = `INR ${princeshow1.toLocaleString('en-IN')}/ per Annum`;
//       const princeshow2 = planprice2 / 100;
//       var modifiedAmount2 = `INR ${princeshow2.toLocaleString('en-IN')}/ per Annum`;
//       const princeshow3 = planprice3 / 100;
//       var modifiedAmount3 = `INR ${princeshow3.toLocaleString('en-IN')}/ per Annum`;
//       const plan1Id = plan1.id;
//       const plan2Id = plan2.id;
//       const plan3Id = plan3.id;
//       const dataPlan1 = plan1.notes;
//       const dataPlan2 = plan2.notes;
//       const dataPlan3 = plan3.notes;
//       console.log(planprice);
//       console.log(plantitle);
//       const dataArray = Object.keys(dataPlan1).map(key => ({ name: key, value: dataPlan1[key] }));
//       const dataArray2 = Object.keys(dataPlan2).map(key => ({ name: key, value: dataPlan2[key] }));
//       const dataArray3 = Object.keys(dataPlan3).map(key => ({ name: key, value: dataPlan3[key] }));
//       console.log(dataArray);
//       console.log(dataArray2);
//       console.log(dataArray3);
//       const featKeys = dataArray.filter(item => item.name.includes("feat_key")).map(item => item.value);
//       const inclKeys = dataArray
//         .filter(item => item.name.includes("incl_key"))
//         .sort((a, b) => {
//           const inclKeyA = parseInt(a.name.match(/\d+/)[0]);
//           const inclKeyB = parseInt(b.name.match(/\d+/)[0]);

//           return inclKeyA - inclKeyB; // Sort based on the numeric value of incl_key
//         })
//         .map(item => item.value);

//       inclKeys.splice(1, 4); // Remove elements at indices 1, 2, 3, and 4

//       console.log(inclKeys);
//       const inclKeys2 = dataArray2
//         .filter(item => item.name.includes("incl_key"))
//         .sort((a, b) => {
//           const inclKeyA = parseInt(a.name.match(/\d+/)[0]);
//           const inclKeyB = parseInt(b.name.match(/\d+/)[0]);

//           return inclKeyA - inclKeyB; // Sort based on the numeric value of incl_key
//         })
//         .map(item => item.value);

//       inclKeys2.splice(1, 4); // Remove elements at indices 1, 2, 3, and 4

//       console.log(inclKeys2);

//       // Similarly for inclKeys3
//       const inclKeys3 = dataArray3
//         .filter(item => item.name.includes("incl_key"))
//         .sort((a, b) => {
//           const inclKeyA = parseInt(a.name.match(/\d+/)[0]);
//           const inclKeyB = parseInt(b.name.match(/\d+/)[0]);

//           return inclKeyA - inclKeyB; // Sort based on the numeric value of incl_key
//         })
//         .map(item => item.value);

//       inclKeys3.splice(1, 4); // Remove elements at indices 1, 2, 3, and 4

//       console.log(inclKeys3);


//       console.log(featKeys);
//       const buttonVariant1 = "primary";
//       const buttonVariant2 = "danger";
//       const buttonVariant3 = "success";

//       setPlan1({ ...plan1, featKeys: featKeys, buttonVariant1: buttonVariant1, plan1Id: plan1Id, inclKeys: inclKeys, modifiedAmount: modifiedAmount, plantitle: plantitle, heading: "Inclusions", taxes: "+ taxes as applicable at the time of invoicing" });
//       setPlan2({ ...plan2, featKeys: featKeys, buttonVariant2: buttonVariant2, plan2Id: plan2Id, inclKeys2: inclKeys2, modifiedAmount2: modifiedAmount2, plantitle2: plantitle2, heading: "Inclusions", taxes: "+ taxes as applicable at the time of invoicing" });
//       setPlan3({ ...plan3, featKeys: featKeys, plan3Id: plan3Id, buttonVariant3: buttonVariant3, inclKeys3: inclKeys3, modifiedAmount3: modifiedAmount3, plantitle3: plantitle3, heading: "Inclusions", taxes: "+ taxes as applicable at the time of invoicing" });
//       // set state with the fetched plans
//       setIsLoading(false);
//     } catch (error) {
//       console.error(error);
//     }
//     finally {
//       hideLoader(); // Hide the loading component after the operation is completed
//     }
//   };

//   useEffect(() => {
//     displayPlans(); // fetch and display the plans on component mount
//   }, []);

//   useEffect(() => {
//     getUser();
//   }, []);

//   useEffect(() => {
//     document.title = 'Payment Page'; // Set the desired page title
//   }, []);
//   const handleClick = async (planTitle, planId) => {
//     debugger;
//     showLoader();
//     let amount, description;
//     const userEmail = localStorage.getItem('email');
//     switch (planId) {
//       case "plan_M1LpDpYHOlKPci": // Platinum Plan
//         amount = 3600000;
//         description = "Platinum Plan Subscription";
//         break;
//       case "plan_M4r4hvI86Rlp2V": // Gold Plan
//         amount = 2400000;
//         description = "Gold Plan Subscription";
//         break;
//       case "plan_M4r3qWHOUCajop": // Silver Plan
//         amount = 850000;
//         description = "Silver Plan Subscription";
//         break;
//       default:
//         throw new Error(`Invalid planId: ${planId}`);
//     }

//     const planBody = {
//       userId: userEmail,
//       palnId: planId,
//       subId: 'sub_50',
//       lisenceKey: 'gdfgfvfgddgs',
//       autoRenew: 'no',
//       startDate: null,
//       endDate: null,
//       name: planTitle,
//       amount: amount,
//       currency: 'INR',
//       status: 'active',
//       total_count: 5,
//       customer_notify: 1,

//     };

//     try {
//       const jwt = getCookie('jwt'); // Retrieve the token cookie
//       console.log(jwt);
//       const response = await fetch(`${urlSubscribe}createSubscription`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${jwt}`,
//           'Cookie': `jwt=${jwt}`,
//         },
//         body: JSON.stringify(planBody),

//       });
//       console.log('Request Payload:', JSON.stringify(planBody));


//       if (!response.ok) {
//         throw new Error(`API request failed with status ${response.status}`);
//       }

//       const data = await response.json();
//       console.log('Subscription created:', data);
//       const key = 'rzp_test_FEBZJLEOVAIXDS';
//       const rzpOptions = {
//         key, // Replace with your actual API key
//         amount: planBody.amount,
//         currency: planBody.currency,
//         name: planBody.name,
//         email: planBody.userId,
//         description: description,
//         subscription_id: data.id, // Replace with the appropriate field from the response data
//         notes: {
//           notes: "Razorpay Corporate Office"
//         },
//         handler: function (response) {
//           console.log('Payment success!', response);
//           const amount = planBody.amount;
//           const razorpay_payment_id = response.razorpay_payment_id;
//           // capturePayment(razorpay_payment_id, amount);
//           fetchPayment(razorpay_payment_id ,'Subscription')
//             .then(() => {
//               navigate("/thankyoupage"); // Replace with your login screen URL or route
//             })
//             .catch((error) => {
//               console.error('Error capturing payment:', error);
//               // Handle the error appropriately (e.g., show an error message to the user)
//             });
//         },
//         theme: {
//           color: '#0000ff',
//         },
//       };

//       const razor = new window.Razorpay(rzpOptions);
//       razor.on('payment.success', function (response) {
//         const razorpay_payment_id = response.razorpay_payment_id;
//         capturePayment(razorpay_payment_id);
//       });
//       razor.on("payment.cancel", function () {
//         hideLoader(); // Hide the loader if payment is canceled
//       });

//       razor.open();
//     } catch (error) {
//       console.error('Error creating subscription:', error);
//     }
//     hideLoader();
//   };


//   const capturePayment = async (paymentData) => {
//     try {
//       debugger;
//       const jwt = getCookie('jwt');
//       if (!jwt) {
//         console.error('JWT token not found in cookies');
//         return;
//       }
//       const requestBody = {
//         paymentId: paymentData.id,
//         orderId: paymentData.order_id,
//         amount: paymentData.amount,
//         currency: paymentData.currency
//       };

//       const response = await fetch(`${urlSubscribe}capturePayment`, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${jwt}`,
//           'Cookie': `jwt=${jwt}`,
//         },
//         body: JSON.stringify(requestBody)
//       });

//       if (response.ok) {
//         const responseData = await response.json();
//         console.log('Payment captured successfully');
//         console.log(responseData);
//       } else {
//         const errorMessage = await response.text();
//         console.error(`Failed to capture payment: ${response.status} - ${errorMessage}`);
//       }
//     } catch (error) {
//       console.error('Error capturing payment:', error);
//     }
//   };

//   const fetchPayment = async (razorpay_payment_id ,purchaseType) => {
//     debugger;
//     try {
//       const jwt = getCookie('jwt');
//       if (!jwt) {
//         console.error('JWT token not found in cookies');
//         // Handle the error, display an appropriate message to the user, or redirect to the login page
//         return;
//       }

//       // const apiUrl = `http://125.63.115.254:8080/subscriptionservice/api/v1/fetchPayment/pay_${razorpay_payment_id}`;
//       const response = await fetch(`${urlSubscribe}fetchPayment/pay_${razorpay_payment_id}`, {
//         method: 'GET',
//         headers: {
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${jwt}`,
//           'Cookie': `jwt=${jwt}`,
//         },
//       });

//       if (response.ok) {
//         const paymentData = await response.json();
//         console.log('Payment data:', paymentData);
//         await addPayment(paymentData ,purchaseType);
//         await capturePayment(paymentData);
//         // Process the payment data or perform any other actions with it
//       } else {
//         console.error('Failed to fetch payment data');
//         // Handle the error or display an error message
//       }
//     } catch (error) {
//       console.error(error);
//       // Handle errors here
//     }
//   };
//   const showMessage = (message, type) => {
//     // Implement your logic to display the message
//     console.log(`Message: ${message}, Type: ${type}`);
//   };

//   const addPayment = async (paymentData ,purchaseType) => {
//     try {
//       debugger;
//      const plandescription  = paymentData.description;
//      const description = plandescription.split(' ')[0];
//      const currentDate = new Date();
// const year = currentDate.getFullYear();
// const month = currentDate.getMonth() + 1; // Adding 1 because month is zero-based
// const day = currentDate.getDate();
// const hours = currentDate.getHours();
// const minutes = currentDate.getMinutes();
// const seconds = currentDate.getSeconds();
// const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}, ${day} ${hours}:${minutes}:${seconds}`;
// console.log(formattedDate); // Output: Current date in the format yyyy-mm-dd

//       const planDetails = {
//         email: email,
//         name: name,
//         product: "Showcase",
//         plan: description,
//         paymentId: paymentData.id,
//         transactionDate: currentDate,
//         amount: paymentData.amount/100,
//         status: 0,
//         purchaseType: purchaseType,
//         orderId: paymentData.order_id,
//         offerId: "23242353",
//         discountValue : discountValue,
//       };

//       const jwt = getCookie('jwt');
//       if (!jwt) {
//         console.error('JWT token not found in cookies');
//         // Handle the error, display an appropriate message to the user, or redirect to the login page
//         return;
//       }

//       const response = await fetch(`${url}payments/addpayment`, {
//         method: 'POST',
//         headers: {
//           'Cookie': `jwt=${jwt}`,
//           'Content-Type': 'application/json',
//           'Authorization': `Bearer ${jwt}`, // Add the JWT token as a bearer token
//         },
//         body: JSON.stringify(planDetails),
//       });
//       if (response.ok) {
//         const responseData = await response.json();
//         // Pass responseData as a query parameter to the payment page
//         localStorage.setItem('paymentId', planDetails.paymentId);
//         navigate("/thankyoupage");
//         hideLoader();
//         showMessage('Payment added successfully', 'success');
//       } else {
//         console.log('Failed to add payment');
//         hideLoader();
//         showMessage('Failed to add payment', 'error');
//       }
//     } catch (error) {
//       // Handle network error
//       console.log('Network error occurred');
//       // Perform any error handling here

//       // Clear the loader and show error message
//       hideLoader();
//       showMessage('Network error occurred', 'error');
//     }
//   };


//   return (
//     <div>
//       {isLoading ? <Loader /> : null}
//       <div className="box8"  style={{display:"grid"}}>
//         <Navbarplanbox />
//         <div className="plain-main-container">
//           <div className="plain-container">
//             <div className="text">Select a Plan</div>
//             <div className="row-planboard">
//               <div className="col-board">
//                 <Plan
//                   key={key}
//                   planId={plan1.plan1Id}
//                   title={plan1.plantitle}
//                   price={plan1.modifiedAmount}
//                   features={plan1.featKeys}
//                   variant={plan1.buttonVariant1}
//                   inclusions={plan1.inclKeys}
//                   heading={plan1.heading}
//                   taxes={plan1.taxes}
//                   commonline={plan1.commonline}
//                   // catalog_manager={plan1.catalog_manager1}
//                   // sales_person_license_count={plan1.sales_person_license_count1}
//                   // handleClick={handleShow}
//                   handleClick={() => handleShow(plan1.plantitle, plan1.plan1Id, 'subscribe')}
//                   handleonClick={() => handleanotherShow(plan1.plantitle, plan1.plan1Id, 'purchase')}
//                 />
//               </div>
//               <div className="col-board">
//                 <Plan
//                   key={key + 1}
//                   planId={plan2.plan2Id}
//                   title={plan2.plantitle2}
//                   price={plan2.modifiedAmount2}
//                   features={plan2.featKeys}
//                   variant={plan2.buttonVariant2}
//                   inclusions={plan2.inclKeys2}
//                   commonline={plan2.commonline}
//                   catalog_manager={plan2.catalog_manager2}
//                   sales_person_license_count={plan2.sales_person_license_count2}
//                   heading={plan1.heading}
//                   taxes={plan2.taxes}
//                   handleClick={() => handleShow(plan2.plantitle2, plan2.plan2Id)}
//                   handleonClick={() => handleanotherShow(plan2.plantitle2, plan2.plan2Id)}

//                 />
//               </div>
//               <div className="col-board">
//                 <Plan
//                   key={key + 2}
//                   planId={plan3.plan3Id}
//                   title={plan3.plantitle3}
//                   price={plan3.modifiedAmount3}
//                   features={plan3.featKeys}
//                   variant={plan3.buttonVariant3}
//                   commonline={plan3.commonline}
//                   catalog_manager={plan3.catalog_manager3}
//                   sales_person_license_count={plan3.sales_person_license_count3}
//                   inclusions={plan3.inclKeys3}
//                   heading={plan1.heading}
//                   taxes={plan3.taxes}
//                   handleClick={() => handleShow(plan3.plantitle3, plan3.plan3Id)}
//                   handleonClick={() => handleanotherShow(plan3.plantitle3, plan3.plan3Id)}
//                 />
//               </div>

//             </div>
//           </div>
//         </div>
//       </div>
//       <div>
//         <Modal
//           show={show}
//           onHide={handleClose}
//           backdrop="static"
//           keyboard={false}
//           centered={true}
//           className="form-modal"
//           dialogClassName="my-modal"
//         >
//           <div className="modal-container">
//             <Modal.Header closeButton onClick={clearCoupon}>
//               <img src={logolink2} alt="Logo" style={{ width: "130px", color: "black" }} />
//             </Modal.Header>
//             <div className="row mt-3">
//               <div className='col-6'>

//                 <Grid item xs={12}>
//                   <Box
//                     display="flex"
//                     flexDirection="column"
//                     alignItems="flex-start"
//                     justifyContent="flex-start"
//                     height={620}
//                     border={1}
//                     borderColor="grey.400"
//                     borderRadius={5}
//                     px={2}
//                   >
//                     <h4 className="mt-2 info-head" >User Details</h4>
//                     <div className="modal-content">
//                       <div className="info-container">
//                         <div className="info-item mt-4">
//                           <label className="info-label">Name:  </label>
//                           <span className="info-value">{name}</span>
//                         </div>
//                         <div className="info-item">
//                           <label className="info-label">Email: </label>
//                           <span className="info-value">{email}</span>
//                         </div>
//                         <div className="info-item">
//                           <label className="info-label">Phone Number:</label>
//                           <span className="info-value">{phone}</span>
//                         </div>
//                       </div>
//                       {/* <Grid container spacing={2} alignItems="center">
//                         <Grid item xs={9}>
//                           <TextField
//                             value={selectedCoupon}
//                             variant="outlined"
//                             fullWidth
//                             margin="normal"
//                             onChange={(e) => setSelectedCoupon(e.target.value)}
//                           />
//                         </Grid>

//                         <Grid item xs={3}>
//                           <Button
//                             variant="contained"
//                             color="primary"
//                             onClick={handleApplyCoupon}
//                           >
//                             Apply
//                           </Button>
//                         </Grid>

//                         <Grid item xs={12}>
//                           {errorMessage && <div className="error-message">{errorMessage}</div>}
//                           {successMessage && <div className="success-message">{successMessage}</div>}
//                         </Grid>
//                       </Grid> */}
//                       <div className="info-item">
//                         <label className="info-label">Subscribed Price:</label>
//                         <span className="info-value"> {modifiedAmount}</span>
//                       </div>
//                       {/* <div className="info-item">
//                         <label className="info-label">Discount Price:</label>
//                         <span className="info-value">{discountValue}</span>
//                       </div> */}
//                       <div className="info-item">
//                         <label className="info-label">Final Price: Subscribed Price + 10% GST  : </label>
//                         <span className="info-value">{finalPrice}</span>
//                       </div>
//                     </div>

//                     <div className="modal-footer">
//                       <Button
//                         className='modal-button primary' // Add "primary" class for primary button style
//                         variant="contained"
//                         color="primary"
//                         onClick={() => handleSubscribeSubmit(selectedPlan.item.name, selectedPlan.id)}
//                         style={{ fontSize: "16px" }}
//                       >
//                         Pay
//                       </Button>
//                       <Button
//                         className='modal-button secondary' // Add "secondary" class for secondary button style
//                         variant="contained"
//                         color="secondary"
//                         onClick={handleClose}
//                         style={{ fontSize: "16px" }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </Box>
//                 </Grid>
//               </div>


//               <div className='col-6'>
//                 <Grid item xs={12}>
//                   {isLoading ? <Loader /> : null}
//                   <Box
//                     display="flex"
//                     flexDirection="column"
//                     alignItems="center" // Set alignItems to "center" to horizontally center the content
//                     //  justifyContent="center" 
//                     height={620}
//                     // border={1}
//                     // borderColor="grey.400"
//                     borderRadius={5}
//                     // px={2}
//                     bgcolor="grey.200"
//                   >
//                     <div className="review-purchase-box">
//                       <h4 className="mt-2 review-purchase-heading">Review and Purchase</h4>
//                     </div>

//                     {selectedPlan && (
//                       <div>
//                         <div className="plan" >
//                           <h3 className="plan-title mt-2">{selectedPlan.item.name}</h3>
//                           <h3 className="plan-price mt-2">{modifiedAmount}</h3>
//                           <h5 className="plan-taxes">{plan1.taxes}</h5>
//                           <ul className="plan-features">
//                             {featKeys.map((feature, index) => (
//                               <li key={index}>  <FaCheck style={{ marginRight: "8px" }} />  {feature}</li>
//                             ))}
//                           </ul>
//                           <label></label>
//                           <h5 className="plan-heading">{plan1.heading}</h5>
//                           <ul className="plan-inclusions mt-2" style={{ listStyleType: "none" }}>
//                             {inclKeys.map((feature, index) => (
//                               <li key={index}>  <FaCheck style={{ marginRight: "8px" }} /> {feature}</li>
//                             ))}
//                           </ul>
//                         </div>
//                         <div>
//                         </div>
//                       </div>
//                     )}
//                   </Box>
//                 </Grid>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       </div>
//       <div>
//         <Modal
//           show={showAnotherModal}
//           onHide={handleCloseAnotherModal}
//           backdrop="static"
//           keyboard={false}
//           centered={true}
//           className="form-modal"
//           style={{paddingLeft:"0"}}
//           dialogClassName="my-modal"
//         >
//           <div className="modal-container">
//             <Modal.Header closeButton onClick={clearCoupon}>
//               <img src={logolink2} alt="Logo" style={{ width: "130px", color: "black" }} />
//             </Modal.Header>
//             {/* Modal content */}
//             <div className="row mt-3" style={{rowGap:"20px"}}>
//               <div className='col-6'>

//                 <Grid item xs={12}>
//                   <Box
//                     display="flex"
//                     flexDirection="column"
//                     alignItems="flex-start"
//                     justifyContent="flex-start"
//                     height={620}
//                     border={1}
//                     borderColor="grey.400"
//                     borderRadius={5}
//                     px={2}
//                   >
//                     <h4 className="mt-2 info-head" >User Details</h4>
//                     <div className="modal-content">
//                       <div className="info-container">
//                         <div className="info-item mt-4">
//                           <label className="info-label">Name:  </label>
//                           <span className="info-value">{name}</span>
//                         </div>
//                         <div className="info-item">
//                           <label className="info-label">Email: </label>
//                           <span className="info-value">{email}</span>
//                         </div>
//                         <div className="info-item">
//                           <label className="info-label">Phone Number:</label>
//                           <span className="info-value">{phone}</span>
//                         </div>
//                       </div>
//                       <Grid container spacing={2} alignItems="center">
//                         <Grid item xs={9}>
//                         <label className="info-label">Apply coupon Here</label>
//                           <TextField
//                             value={selectedCoupon}
//                             variant="outlined"
//                             fullWidth
//                             margin="normal"
//                             onChange={(e) => setSelectedCoupon(e.target.value)}
//                           />
//                         </Grid>

//                         <Grid item xs={3}>
//                         <label className="info-label mt-3"></label>
//                           <Button
//                           className='mt-2'
//                             variant="contained"
//                             color="primary"
//                             onClick={handleApplyCoupon}
//                           >
//                             Apply
//                           </Button>
//                         </Grid>

//                         <Grid item xs={12}>
//                           {errorMessage && <div className="error-message">{errorMessage}</div>}
//                           {successMessage && <div className="success-message">{successMessage}</div>}
//                         </Grid>
//                       </Grid>
//                       <div className="info-item">
//                         <label className="info-label">Subscribed Price:</label>
//                         <span className="info-value"> {modifiedAmount}</span>
//                       </div>
//                       <div className="info-item">
//                         <label className="info-label">Discount Price:</label>
//                         <span className="info-value">{discountValue}</span>
//                       </div>
//                       <div className="info-item">
//                         <label className="info-label">Final Price: Subscribed Price - Discount + 10%GST  :</label>
//                         <span className="info-value">   {discountValue ? finalPriceWithGST : purchaseFinalPrice}</span>
//                       </div>
//                     </div>

//                     <div className="modal-footer">
//                       <Button
//                         className='modal-button primary' // Add "primary" class for primary button style
//                         variant="contained"
//                         color="primary"
//                         onClick={() => handlePurchaseSubmit(selectedPlan.item.name, selectedPlan.id)}
//                         style={{ fontSize: "16px" }}
//                       >
//                         Pay
//                       </Button>
//                       <Button
//                         className='modal-button secondary' // Add "secondary" class for secondary button style
//                         variant="contained"
//                         color="secondary"
//                         onClick={handlepurchaseClose}
//                         style={{ fontSize: "16px" }}
//                       >
//                         Cancel
//                       </Button>
//                     </div>
//                   </Box>
//                 </Grid>
//               </div>


//               <div className='col-6'>
//                 <Grid item xs={12}>
//                   {isLoading ? <Loader /> : null}
//                   <Box
//                     display="flex"
//                     flexDirection="column"
//                     alignItems="center" // Set alignItems to "center" to horizontally center the content
//                     //  justifyContent="center" 
//                     height={620}
//                     // border={1}
//                     // borderColor="grey.400"
//                     borderRadius={5}
//                     // px={2}
//                     bgcolor="grey.200"
//                   >
                   

//                     {selectedPlan && (
//                       <div>
//                         <div className="plan" >
//                           <h3 className="plan-title mt-2">{selectedPlan.item.name}</h3>
//                           <h3 className="plan-price mt-2">{modifiedAmount}</h3>
//                           <h5 className="plan-taxes">{plan1.taxes}</h5>
//                           <label></label>
//                           <ul className="plan-features">
//                             {featKeys.map((feature, index) => (
//                               <li key={index}>  <FaCheck style={{ marginRight: "8px" }} />  {feature}</li>
//                             ))}
//                           </ul>
//                           <label></label>
//                           <h5 className="plan-heading">{plan1.heading}</h5>
//                           <ul className="plan-inclusions mt-2" style={{ listStyleType: "none" }}>
//                             {inclKeys.map((feature, index) => (
//                               <li key={index}>  <FaCheck style={{ marginRight: "8px" }} /> {feature}</li>
//                             ))}
//                           </ul>
//                         </div>
//                         <div>
//                         </div>
//                       </div>
//                     )}
//                   </Box>
//                 </Grid>
//               </div>
//             </div>
//           </div>
//         </Modal>
//       </div>
//     </div>
//   );
// }
export default PlanBox;
