import React, { useState, useEffect } from "react";
import "./UserList.css";
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography
} from "@material-ui/core";
import Autocomplete from '@mui/material/Autocomplete';
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, TableSortLabel, } from '@material-ui/core';
import Sidebar from "./sidebar";
import { Edit as EditIcon, Delete as DeleteIcon } from '@material-ui/icons';
import ReactPaginate from 'react-paginate';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { baseUrl, interceptor, baseUrlUser } from "../utils/common";
import Swal from 'sweetalert2';


const url = baseUrlUser;
const UserList = ( searchQuery , UserList) => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(7);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [pinCode, setPincode] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isEdit, setIsEdit] = useState(false);
  const [editValue, setEditValue] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [order, setOrder] = useState('asc');
  const [editingEnabled, setEditingEnabled] = useState(false);
  const [filteredData, setFilteredData] = useState(data);


  const navigate = useNavigate();
  // Function to retrieve cookie by name
  const getCookie = (name) => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }

    return "";
  };

  const initialFormState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    role: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    country: '',
    state: '',
    pinCode: ''
  };


  // Define the clearForm function
  const clearForm = () => {
    setFirstName(initialFormState.firstName);
    setLastName(initialFormState.lastName);
    setEmail(initialFormState.email);
    setPhone(initialFormState.phone);
    setRole(initialFormState.role);
    setAddressLine1(initialFormState.addressLine1);
    setAddressLine2(initialFormState.addressLine2);
    setCity(initialFormState.city);
    setCountry(initialFormState.country);
    setState(initialFormState.state);
    setPincode(initialFormState.pinCode);
  };

  const handlePincodeChange = (e) => {
    const pincode = e.target.value;
    const sanitizedPincode = pincode.replace(/\D/g, "");
    setPincode(sanitizedPincode);
    debugger;
    fetch(`https://api.postalpincode.in/pincode/${sanitizedPincode}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        if (data && data.length > 0) {
          const { Name, State, Country } =data && data[0].PostOffice[0];
          setCity(Name);
          setState(State);
          setCountry(Country);
        } else {
          console.log("No data found for the given pincode.");
        }
      })
      .catch((error) => {
        console.log("An error occurred:", error.message);
      });
  };

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    // Remove any non-digit characters from the input
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");
    setPhone(sanitizedPhoneNumber);
  };

  const validatePhoneNumber = () => {
    if (phone && phone.length !== 10) {
      return;
    }
    return "";
  };

  const validatePincode = () => {
    // Ensure pincode is defined and has a length property
    if (pinCode && pinCode.length !== 6) {
      return;
    }
    return "";
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setError("");
      setEditingEnabled(false); // Disable form during submission
      let modifiedRole = role;
      if (role === "Catalog Manager") {
        modifiedRole = "Catalog_Manager";
      }
      const userData = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        role: modifiedRole,
        phone: phone,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        country: country,
        pinCode: pinCode,
        state: state
      };
  
      const phoneNumberError = validatePhoneNumber();
      const pincodeError = validatePincode();
  
      if (phoneNumberError || pincodeError) {
        setError("Check all the fields properly");
        return;
      }
  
      if (!pinCode || !role || !firstName || !lastName || !email || !phone || !addressLine1 || !addressLine2) {
        setError("All fields are required.");
        return;
      }
  debugger;
      const jwt = getCookie('treziAccessToken');
      const response = await fetch(`${url}users`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`
        },
        body: JSON.stringify(userData),
      });
  
      if (response.status === 417) {
        console.error(`Request failed with status ${response.status}`);
        setError(`Can't create : ${role} as limit reached for maximum allowed users`);
        return;
      }
  
      const data =  response.data;
      console.log(data);
      if(response.status === 201){
        setShowModal(false);
        clearForm();
        Swal.fire({
          icon: 'success',
          title: 'User Added Successfully!',
          text: 'User data has been submitted successfully.',
        });
        await fetchData();
        return;
      }
      const msg = `User already exists with email: ${email}`;
      const adminExistsMsg = "One Admin user already exists. Can't create more than 1 admin users.";
      const salesExistingMsg = "Can't create Sales user as limit reached for maximum allowed Sales users";
  
      if (data.message === msg) {
        setError(`User already exists with email: ${email}`);
        setShowModal(true);
        return;
      } else if (data.message === adminExistsMsg) {
        setError("One Admin user already exists. Can't create more than 1 admin users.");
        setShowModal(true);
        return;
      } else if (data.message === salesExistingMsg) {
        setError("Can't create Sales user as limit reached for maximum allowed Sales users");
        setShowModal(true);
        return;
      }
 return;
    } catch (error) {
      console.error('Error adding user:', error);
      setError("An error occurred while processing your request. Please try again later.");
    } finally {
      setEditingEnabled(true); 
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    debugger;
    try {
      const jwt = getCookie('treziAccessToken');
      console.log(jwt, "aaa")
      if (!jwt) {
        console.error('JWT token not found in cookies');
        window.location.href = '/'; // Redirect to the login page
        return;
      }

      const response = await fetch(`${url}users`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Cookie': `jwt=${jwt}`,
          'Authorization': `Bearer ${jwt}`
        },
      });
 console.log(response)
      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData)
        setData(responseData);
      } else if(response.status === 401){
        Swal.fire({
          title: 'Unauthorized!',
          text: 'Jwt token Expired. Please Login again.',
          icon: 'warning',
          showConfirmButton: false,
          timer: 2000 // 2 seconds
        }).then(() => {
          window.location.href = "/";
        });
      } else{
        const errorMessage = await response.text();
        console.error(`Failed to fetch user data: ${response.status} - ${errorMessage}`);
        // Handle the error, display an appropriate message to the user, or perform any necessary actions
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      // Handle errors here
    }
  };
  const filterData = (searchQuery) => {
    debugger;
    if (!searchQuery) {
      setFilteredData(data);
      return;
    } else {
      const filteredData = data.filter(
        (user) =>
          user.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          user.email.toLowerCase().includes(searchQuery.toLowerCase().trim())
      );
      setFilteredData(filteredData);
      console.log(filteredData ,"aaa")
    }
  };
  function convertRole(role) {
    if (role === null || role === undefined) {
      return ''; // Return blank if role is null or undefined
    }
  
    return role.split('_').map((word, index) => {
      if (index === 0) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      } else {
        return " " + word.charAt(0).toUpperCase() + word.slice(1);
      }
    }).join('');
  }
  
  
  const handleEdit = async (user) => {
    debugger;
    try {
      setEditingEnabled(false);
      setIsEdit(true);
      setEditValue(user);
      setShowModal(true);

      const userEmail = user.email;
      const jwt = getCookie('treziAccessToken');
      console.log(jwt, "aaa");
      if (!jwt) {
        console.error('JWT token not found in cookies');
        // Handle the error, display an appropriate message to the user, or redirect to the login page
        return;
      }

      const response = await fetch(`${url}users/${userEmail}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Cookie': `jwt=${jwt}`,
          'Authorization': `Bearer ${jwt}`
        }
      });

      if (!response.ok) {
        throw new Error(`API request failed with status ${response.status}`);
      }else{
        const userData = await response.json();
        console.log('User data:', userData);
        const data = userData;
        const formattedRole = convertRole(data.role);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setEmail(data.email);
        setPhone(data.phone);
        setRole(formattedRole);
        setAddressLine1(data.addressLine1);
        setAddressLine2(data.addressLine2);
        setCity(data.city);
        setCountry(data.country);
        setState(data.state);
        setPincode(data.pinCode);
      }
    } catch (error) {
      console.error(error);
      // Handle errors here
    }
  };

  const showLoader = () => {
    setIsLoading(true);
  }
  const hideLoader = () => {
    setIsLoading(false);
  };

  const updateUser = async () => {
    try {
      debugger;
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        console.error('JWT token not found in cookies');
        // Handle the error, display an appropriate message to the user, or redirect to the login page
        return;
      }
  
      const phoneNumberError = validatePhoneNumber();
      const pincodeError = validatePincode();
  
      if (phoneNumberError || pincodeError) {
        // Handle validation errors
        console.log("Validation Errors:", phoneNumberError, pincodeError);
        setError("Check all the fields properly");
        return;
      }
  
      if (!pinCode) {
        setError("Pincode cannot be empty");
        return;
      }
  
      if (!role) {
        setError("Role cannot be empty");
        return;
      }
  
      if (!firstName) {
        setError("First Name is required.");
        return;
      }
  
      if (!lastName) {
        setError("Last Name is required.");
        return;
      }
  
      if (!email) {
        setError("Email is required.");
        return;
      }
  
      if (!phone) {
        setError("Phone is required.");
        return;
      }
  
      if (!addressLine1) {
        setError("Address Line 1 is required.");
        return;
      }
  
      if (!addressLine2) {
        setError("Address Line 2 is required.");
        return;
      }
  
      if (!pinCode) {
        setError("Pincode is required.");
        return;
      }
      let modifiedRole = role;
      if (role === "Catalog Manager") {
        modifiedRole = "Catalog_Manager";
      }
      const userToUpdate = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        role: modifiedRole,
        phone: phone,
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        country: country,
        pinCode: pinCode,
        state: state
      };
  
      const response = await fetch(`${url}users`, {
        method: 'PUT',
        headers: {
          'Cookie': `jwt=${jwt}`,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(userToUpdate),
      });
      if(response.status === 202){
        setShowModal(false);
        clearForm();
        Swal.fire({
          icon: 'success',
          title: 'User Updated Successfully!',
          text: 'Your user has been updated successfully.',
        });
        await fetchData(); // Assuming fetchData is a function to fetch updated user data
        return;
      }
      if (response.status === 417) {
        console.error(`Request failed with status ${response.status}`);
        setError(`Can't create : ${role} as limit reached for maximum allowed users`);
        return;
      }
  
      if (!response.ok) {
        console.error(`Update user request failed with status ${response.status}`);
        // Handle the error, display an appropriate message to the user, or retry the request
        setShowModal(true);
        setError("Failed to update User. Please check the details and try again.");
        return;
      }
    } catch (error) {
      console.error('An error occurred during user update:', error);
    }
  };
  


  const handleAdd = () => {
    setIsEdit(false);
    setShowModal(true);
  };


  const handlePageClick = ({ selected }) => {
    setCurrentPage(selected);
  };

  const usersToDisplay = data.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const handleStatusChange = async (user) => {
    const newStatus = !user.isActive; // Toggle the status
    debugger;
    try {
      // Fetch the JWT token from the appropriate source (e.g., localStorage, state, etc.)
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        console.error('JWT token not found in cookies');
        // Handle the error, display an appropriate message to the user, or redirect to the login page
        return;
      }

      const requestBody = {
        isActive: newStatus,
        email: user.email,
        // role : user.role
      };

      const response = await fetch(`${url}users/status`, {
        method: 'PUT',
        headers: {
          'Cookie': `jwt=${jwt}`,
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${jwt}`,
        },
        body: JSON.stringify(requestBody),
      });

      if (response.status === 202) {
        console.log('User status updated successfully');

        const updatedUsers = data.map((u) => {
          if (u.email === user.email) {
            return { ...u, isActive: newStatus };
          }
          return u;
        });
        setData(updatedUsers);
      }else if (response.status === 417){
        Swal.fire({
          title: 'Alert!',
          text: 'Cannot change the status as limit reached for maximum allowed users',
          icon: 'warning',
          showConfirmButton: true,
        })
      } else {
        console.error('Failed to update user status');
        // Handle the error or display an error message
      }
    } catch (error) {
      console.error(error);
      // Handle errors here
    }
  };


  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    // Perform sorting here based on the `orderBy` and `order` state
  };
  function descendingComparator(a, b, orderBy) {
    const valueA = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy];
    const valueB = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy];

    if (valueB < valueA) {
      return -1;
    }
    if (valueB > valueA) {
      return 1;
    }
    return 0;
  }
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  useEffect(() => {
    document.title = 'User List'; // Set the desired page title
  }, []);
  return (
    <div className="user-container">
      <div className="">
      </div>
      <div className="p-4 tablebox">
        <div className="">
          <div className="col-12">
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="h6" className="my-heading" >User Management</Typography>
              <Box display="flex" alignItems="center">
                <Button onClick={() => handleAdd(data)} variant="contained" color="primary">
                  Add User
                </Button>
              </Box>
            </Box>

            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow className="header-row">
                    <TableCell active={orderBy === 'name'}
                      direction={orderBy === 'name' ? order : 'asc'}
                      onClick={() => handleSort('name')}>
                      Name
                      <KeyboardArrowDownIcon />
                    </TableCell>
                    <TableCell active={orderBy === 'email'}
                      direction={orderBy === 'email' ? order : 'asc'}
                      onClick={() => handleSort('email')}>
                      Email  <KeyboardArrowDownIcon />
                    </TableCell>
                    <TableCell active={orderBy === 'lastActive'}
                      direction={orderBy === 'lastActive' ? order : 'asc'}
                      onClick={() => handleSort('lastActive')} >Created on<KeyboardArrowDownIcon />
                    </TableCell>
                    <TableCell active={orderBy === 'role'}
                      direction={orderBy === 'role' ? order : 'asc'}
                      onClick={() => handleSort('role')} >Role  <KeyboardArrowDownIcon /> </TableCell>
                    <TableCell active={orderBy === 'status'}
                      direction={orderBy === 'status' ? order : 'asc'}
                      onClick={() => handleSort('status')}>Status  < KeyboardArrowDownIcon /> </TableCell>
                    <TableCell
                    >Settings </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {data  && 
                    stableSort(data, getComparator(order, orderBy))
                      .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
                      .map((user, index) => (

                        <TableRow key={index}>
                          <TableCell>{user.name}</TableCell>
                          <TableCell>{user.email}</TableCell>
                          <TableCell>
                            {new Date(user.createdAt).toLocaleDateString()}
                          </TableCell>

                          <TableCell>{convertRole(user.role)}</TableCell>
                          <TableCell >
                            <Select class="MuiInputBase-root MuiInput-root MuiInput"
                              value={user.isActive ? "Enabled" : "Disabled"}
                              onChange={(event) => handleStatusChange(user)}
                              style={{ fontSize: 'small' }}
                            >
                              <MenuItem value="Enabled">Enabled</MenuItem>
                              <MenuItem value="Disabled">Disabled</MenuItem>
                            </Select>
                          </TableCell>

                          <TableCell style={{ color: '#777' }}>
                            <EditIcon className="edit-icon" onClick={() => handleEdit(user)} style={{ marginRight: 18, cursor: 'pointer' }} />
                            {/* <DeleteIcon style={{ cursor: 'pointer' }} className="delete-icon" onClick={() => { 
                                handleDelete(user);                       
                            }} /> */}
                          </TableCell>
                        </TableRow>
                      ))}
                </TableBody>

              </Table>
            </TableContainer>

            <ReactPaginate className="pagination mt-4"
              previousLabel="Previous"
              nextLabel="Next"
              pageCount={Math.ceil(data.length / itemsPerPage)}
              onPageChange={handlePageClick}
              containerClassName="pagination"
              activeClassName="active"
            />
            <Modal
              show={showModal}
              onHide={() => {
                setShowModal(false);
                clearForm();
                setError("");// Reset the form when hiding the modal
              }}
              centered={true}
              dialogClassName="my-modal"
              editValue={editValue}
              backdrop="static"
              keyboard={false}
            >
              <Modal.Header closeButton>
                <Modal.Title>{isEdit ? 'Edit User' : 'Add User'}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* <div className="line mt-0">
                        </div> */}
                <div className="user-modal-content">
                  {error && <span className="text-danger">- {error}</span>}
                  {success && <span className="text-success">- {success}</span>}
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2} >
                      <Grid item xs={12} sm={6}>
                        <InputLabel shrink>First Name*</InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          required
                          autoFocus
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Last Name*</InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          size="small"
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Email Address*</InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          size="small"
                          disabled={isEdit && !editingEnabled}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Phone*</InputLabel>
                        <TextField
                          type="tel"
                          fullWidth
                          variant="outlined"
                          value={phone}
                          onChange={handlePhoneChange}
                          size="small"
                          inputProps={{
                            maxLength: 10,
                          }}
                          //  error={phone.length !== 10}
                          helperText={validatePhoneNumber()}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel shrink>Role*</InputLabel>
                        <Autocomplete
                          value={role}
                          onChange={(event, newValue) => {
                            setRole(newValue);
                          }}
                          options={['Catalog Manager', 'Sales']}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              fullWidth
                              size="small"

                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} >
                        <InputLabel shrink >Address Line 1*</InputLabel>
                        <TextField fullWidth
                          value={addressLine1}
                          onChange={(e) => setAddressLine1(e.target.value)}
                          variant="outlined"
                          size="small" />
                      </Grid>
                      <Grid item xs={12} sm={6} >
                        <InputLabel shrink >Address Line 2*</InputLabel>
                        <TextField fullWidth
                          value={addressLine2}
                          onChange={(e) => setAddressLine2(e.target.value)}
                          variant="outlined"
                          size="small" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Pincode*</InputLabel>
                        <TextField
                          fullWidth
                          variant="outlined"
                          value={pinCode}
                          onChange={handlePincodeChange}
                          size="small"
                          inputProps={{
                            maxLength: 6,
                          }}
                          //  error={pincode.length !== 6}
                          helperText={validatePincode()}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel shrink>City</InputLabel>
                        <FormControl fullWidth variant="outlined">
                          <TextField
                            value={city} // Set the value of the TextField to the city state variable
                            variant="outlined"
                            style={{ height: '45px' }}
                            size="small"
                            disabled
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <InputLabel shrink>State</InputLabel>
                        <TextField
                          fullWidth
                          value={state}
                          variant="outlined"
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel shrink>Country</InputLabel>
                        <TextField
                          fullWidth
                          value={country}
                          variant="outlined"
                          size="small"
                          disabled
                        />
                      </Grid>


                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="flex-start"
                          justifyContent="flex-start"
                          height={150}
                          border={1}
                          borderColor="grey.400"
                          borderRadius={5}
                          px={2}
                        >
                          <div className="role-container">
                            <h8 className="role-description" style={{ fontSize: "12px" }}>Role Description</h8>
                            <div className="role-list" style={{ fontSize: "10px", listStyleType: "none" }}>
                              {role === 'Catalog Manager' && (
                                <>
                                  <li>Catalog Management</li>
                                  <li>User Management</li>
                                  <li>Settings</li>
                                  <li>Share</li>
                                  <li>Publish and Unpublish</li>
                                  <li>Add storage</li>
                                </>
                              )}
                              {role === 'Sales' && (
                                <>
                                  <li>Share</li>
                                  <li>View rights</li>
                                </>
                              )}
                            </div>
                          </div>
                 </Box>
                      </Grid>

                    </Grid>
                  </form>
                </div>

              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="contained"
                  className="submit-btn"
                  type="submit"
                  color="primary"
                  onClick={isEdit ? updateUser : handleSubmit}
                >
                  {isEdit ? 'Save' : 'Add User'}
                </Button>
                <Button variant="secondary" class="cancel-btn"
                  onClick={() => {
                    setShowModal(false);
                    clearForm();
                    setError(""); // Reset the form when closing the modal
                  }}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );

};


export default UserList;