import React, { useState, useEffect } from "react";
import { Button, Box } from "@material-ui/core";
import { baseUrl } from "../utils/common";
const urlUser =  baseUrl;

const Mysubscription = () => {
  const [subscriptionDetails, setSubscriptionDetails] = useState({
    plan: "",
    product: "",
    planStartDate: "",
    planExpirationDate: "",
    purchaseType: ""
  });

  useEffect(() => {
    document.title = 'My subscription'; // Set the desired page title
  }, []);

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }

    return "";
  };

  const fetchSubscriptionDetails = async () => {
    const jwt = getCookie('treziAccessToken');
  
    if (!jwt) {
      console.error('JWT token not found in local storage');
      return;
    }
  
    try {
      const response = await fetch(`${urlUser}users/getsubscription`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          // 'Cookie': `jwt=${jwt}`,
          'Authorization': `Bearer ${jwt}`
        },
      });
  
      if (response.ok) {
        const data = await response;
        setSubscriptionDetails(data);
      } else {
        console.error('Error fetching subscription details:', response);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };
  
  useEffect(() => {
    fetchSubscriptionDetails();
  }, []);

  const handleChangeSubscription = () => {
    // Handle changing subscription logic here
  };

  return (
    <div className="box5" style={{ display: "flex", justifyContent: "center" }}>
    <div className="p-4 box7
     subscription-container" style={{ border: "none" }}>
      <div className="row">
        <div className="col-12 mt-5">
        <Box
  sx={{
    display: "flex",
    flexDirection: ["column", "row"],
    alignItems: ["center"],
    justifyContent: ["center", "space-between"],
    p: 6,
    border: "1px solid lightgrey",
    borderRadius: "2px",
  }}
>
  <h1 style={{marginRight :"150px"}} sx={{ mb: [3, 0]}}>My Subscription</h1>
  <Button variant="contained" onClick={handleChangeSubscription}>
    Change Subscription
  </Button>
</Box>


        </div>
      </div>
      <div className="col-12 mt-2">
        <div className="row">
          <div className="col-12 mt-5">
            <label htmlFor="field1">Subscription type:</label>
            <input
              type="text"
              id="field1"
              style={{ marginLeft: "15px", border: "none" }}
              value={subscriptionDetails.plan}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <label htmlFor="field2">Next renewal date:</label>
            <input
              type="text"
              id="field2"
              style={{ marginLeft: "15px", border: "none" }}
              value={new Date(subscriptionDetails.planExpirationDate).toLocaleDateString()}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <label htmlFor="field3">Auto renewal:</label>
            <input
              type="text"
              id="field3"
              style={{ marginLeft: "15px", border: "none" }}
              value={subscriptionDetails.purchaseType === "subscription" ? "Yes" : "No"}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mt-3">
            <label htmlFor="field4">Payment receipt:</label>
            <input
              type="text"
              id="field4"
              style={{ marginLeft: "15px", border: "none" }}
              value="N/A"
              readOnly
            />
          </div>
        </div>
        {/* <div className="row">
          <div className="col-12 mt-5">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                p: 16,
              }}
            ></Box>
          </div>
        </div> */}
      </div>
    </div>
  </div>
  );
};

export default Mysubscription;
