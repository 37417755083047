import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Col, Container } from "react-bootstrap";
import {
  Grid,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
} from "@material-ui/core";
import { useUserAuth } from "../context/UserAuthContext";
import logolink2 from "../assests/public/trezi_logo.png";
import "./signupboard.css";
import axios from "axios";
import { baseUrlUser } from "../utils/common";
import { makeStyles } from "@material-ui/core/styles";
import Swal from 'sweetalert2';


const useStyles = makeStyles((theme) => ({
  label: {
    color: "red",
    // add any other CSS styles that you want to apply to the label here
  },
}));
const url = baseUrlUser ;
const SignupBoard = () => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [organization, setOrganization] = useState("");
  const [position, setPosition] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [password, setPassword] = useState("");
  const [newsletter, setNewsletter] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [state, setState] = useState("");
  const [pinCode, setPincode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const classes = useStyles();
  let navigate = useNavigate();

  useEffect(() => {
    // This code will be executed once the component mounts
    console.log("Component mounted!");

    // You can return a function to execute when the component unmounts
    return () => {
      console.log("Component unmounted!");
    };
  }, []);

  const handlePhoneChange = (e) => {
    const phoneNumber = e.target.value;
    // Remove any non-digit characters from the input
    const sanitizedPhoneNumber = phoneNumber.replace(/\D/g, "");
    setPhone(sanitizedPhoneNumber);
  };

  const validatePhoneNumber = () => {
    if (phone.length !== 10) {
      return ;
    }
    return "";
  };
  const handlePincodeChange = (e) => {
    const pinCode = e.target.value;
    const sanitizedPincode = pinCode.replace(/\D/g, "");
    setPincode(sanitizedPincode);
    debugger;
    fetch(`https://api.postalpincode.in/pincode/${sanitizedPincode}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        if (data && data.length > 0) {
          const { Name, State, Country,Block } = data[0].PostOffice[0];
          setCity(Block);
          setState(State);
          setCountry(Country);
        } else {
          console.log("No data found for the given pincode.");
        }
      })
      .catch((error) => {
        console.log("An error occurred:", error.message);
      });
  };
  const validatePincode = () => {
    if (pinCode.length !== 6) {
        return ;
    }
    return "";
  };

  useEffect(() => {
    document.title = 'SignUp'; // Set the desired page title
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
  
    // Perform validations for each field
    let errorMessage = "";
  
    if (!email || !email.trim()) {
      setError("Email is required.\n");
      return;
    }
  
    if (!firstName || !firstName.trim()) {
      setError("First name is required.\n");
      return;
    }
  
    if (!lastName || !lastName.trim()) {
      setError("Last name is required.\n");
      return;
    }
  
    if (!password || !password.trim()) {
      setError("Password is required.\n");
      return;
    }
  
    if (!confirmPassword || !confirmPassword.trim()) {
      setError("Confirm Password is required.\n");
      return;
    }
  
    if (password !== confirmPassword) {
      setError("The Password confirmation does not match");
      return;
    }
  
    if (!organization || !organization.trim()) {
      setError("Organization is required.\n");
      return;
    }
  
    if (!position || !position.trim()) {
      setError("Position is required.\n");
      return;
    }
  
    if (!phone || phone.length !== 10) {
      setError("Phone number must be exactly 10 digits.");
      return;
    }
  
    if (!country || !country.trim()) {
      setError("Country is required.");
      return;
    }
  
    if (!city || !city.trim()) {
      setError("City is required.");
      return;
    }
  
    if (!addressLine1 || !addressLine1.trim()) {
      setError("Address Line 1 is required.");
      return;
    }
  
    if (!state || !state.trim()) {
      setError("State is required.");
      return;
    }
  
    if (!pinCode || pinCode.length !== 6) {
      setError("Pincode must be exactly 6 digits.");
      return;
    }
    if (!termsAccepted) {
      setError("Terms and conditions must be accepted.");
      return;
    }

    const data = {
      email: email,
      password: password,
      firstName: firstName,
      lastName: lastName,
      addressLine1: addressLine1,
      addressLine2: addressLine2,
      city: city,
      country: country,
      org: organization,
      position: position,
      state: state,
      pinCode : pinCode,
      phone:phone,
      gstNumber : gstNumber
    };
  
    try {
      debugger;
      const response = await axios.post(`${url}users/signup`, data);
      console.log(response);
      const msg = `A verification mail has been sent to ${email}`;
      const msg2 = `${email} is already a Trezi account.Please try a different email address.`;
      if (response.status === 201) {
        await Swal.fire({
          icon: 'success',
          title: 'Verification Mail Sent',
          text: msg,
          confirmButtonText: 'OK',
        });
        setSuccessMessage(msg);
        navigate("/");
      } else if (response.data.message === msg2) {
        setError(
          `${email} is already a Trezi account. Please try a different email address.`
        );
      }
    } catch (err) {
      // if(err.response.status){
        setError(` Please check the ${err.response.data}`);
      // }
    
    }
  };
  

  return (
    <>
      {/* {error && <Alert variant="danger">{error}</Alert>} */}
      <div className="box8">
        <Container
          className="box2 sign-up-container"
          style={{
            backgroundColor: "white",
          }}
        >
          <div>
            <img src={logolink2} alt="Logo" style={{ width: "95px"}}  />
          </div>
          <h5
            className="mb-1"
            style={{ backgroundColor: "white", fontSize: "20px" }}
          >
            Signup
          </h5>
          {error && <span className="text-danger">- {error}</span>}
          {success && <span className="text-success">- {success}</span>}
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <InputLabel className="mt-2" shrink>
                  First Name*
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  autoFocus
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel className="mt-2" shrink>
                  Last Name*
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Email Address*</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Phone Number*</InputLabel>
                <TextField
                  type="tel"
                  fullWidth
                  variant="outlined"
                  value={phone}
                  onChange={handlePhoneChange}
                  size="small"
                  inputProps={{
                    maxLength: 10,
                  }}
                  //  error={phone.length !== 10}
                  helperText={validatePhoneNumber()}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Password*</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Confirm Password*</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  type="password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  size="small"
                />
              </Grid>

              <ul className="list-manage">
                <li>
                  Password must contain a number, capital letter, small letter
                  and special character.
                </li>
                <li>Password must be at least 6 characters long.</li>
              </ul>
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Organization*</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={organization}
                  onChange={(e) => setOrganization(e.target.value)}
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Position*</InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  value={position}
                  onChange={(e) => setPosition(e.target.value)}
                  size="small"
                />
              </Grid>

              <Grid item xs={12}>
                <InputLabel shrink>Address Line 1*</InputLabel>
                <TextField
                  fullWidth
                  id="fullWidth"
                  value={addressLine1}
                  onChange={(e) => setAddressLine1(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel shrink>Address Line 2*</InputLabel>
                <TextField
                  fullWidth
                  id="fullWidth"
                  value={addressLine2}
                  onChange={(e) => setAddressLine2(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel shrink>GST Number</InputLabel>
                <TextField
                  fullWidth
                  id="fullWidth"
                  value={gstNumber}
                  onChange={(e) => setGstNumber(e.target.value)}
                  variant="outlined"
                  size="small"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Pincode*</InputLabel>
                <FormControl fullWidth variant="outlined" size="small">
                  <TextField
                    fullWidth
                    variant="outlined"
                    value={pinCode}
                    onChange={handlePincodeChange}
                    size="small"
                    inputProps={{
                      maxLength: 6,
                    }}
                    // error={pincode.length !== 6}
                    helperText={validatePincode()}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink>Country*</InputLabel>
                <FormControl fullWidth variant="outlined" size="small">
                  <TextField
                    value={country}
                    onChange={(e) => setCountry(e.target.value)}
                    variant="outlined"
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink>City*</InputLabel>
                <FormControl fullWidth variant="outlined" size="small">
                  <TextField
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    variant="outlined"
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6}>
                <InputLabel shrink>State*</InputLabel>
                <FormControl fullWidth variant="outlined" size="small">
                  <TextField
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    variant="outlined"
                    size="small"
                    disabled
                  />
                </FormControl>
              </Grid>

              <div className="row-signboard">
                <Form.Group controlId="formBasicCheckbox">
                  <Form.Check
                    className="custom-link"
                    style={{ fontWeight: "normal" }}
                    type="checkbox"
                    label={
                      <>
                       <span style={{ fontWeight: "normal" }}>
                      I would like to sign up for  Trezi news
                        and  Product updates .
                    </span>
                      </>
                    }
                    checked={newsletter}
                    onChange={(e) => setNewsletter(e.target.checked)}
                  />
                </Form.Group>
              </div>

              <div className="row-signboard">
                <Form.Group className="mb-2" controlId="formBasicCheckbox2">
                  <Form.Check
                    className="custom-link"
                    type="checkbox"
                    label={
                      <>
                        <span style={{ fontWeight: "normal" }}>
                          By checking the box, I adhere to the{" "}
                        </span>
                        <a href="https://trezi.com/terms-of-use/">
                          Terms & Conditions
                        </a>{" "}
                        and{" "}
                        <a href="https://trezi.com/privacy-policy/">
                          Privacy Policy .
                        </a>
                        <span style={{}}></span>
                      </>
                    }
                    checked={termsAccepted}
                    onChange={(e) => setTermsAccepted(e.target.checked)}
                  />
                </Form.Group>
              </div>

              <Button
                style={{
                  height: "55px",
                  textTransform: "none",
                  backgroundColor: "#2F80ED",
                  color: "white",
                  fontSize: "15px",
                }}
                type="submit"
                variant="contained"
                fullWidth
              >
                Signup
              </Button>
              <div
                className="custom-link mt-2"
                textAlign="center"
                variant="subtitle2"
                color="textSecondary"
              >
                Already have an account ? <Link to="/">Log in here</Link>
              </div>
            </Grid>
          </form>
        </Container>
      </div>
    </>
  );
};

export default SignupBoard;
