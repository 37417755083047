import { Component } from "react";
import { Table } from "react-bootstrap";

export default class AccordionBody extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { text, data } = this.props;
    return (
      <>
        {text === "Product Attributes" ? (
          <Table className="tz-product-attributes">
            <tbody className="align-left">
              <tr>
                <td>Title</td>
                <td>{data?.title}</td>
              </tr>
              <tr>
                <td>SKU</td>
                <td>{data?.sku}</td>
              </tr>
              <tr>
                <td>Brand</td>
                <td>{data?.brand}</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>{data?.price}</td>
              </tr>
              <tr>
                <td>Model Number</td>
                <td>{data?.modelNumber}</td>
              </tr>
              <tr>
                <td>Model Name</td>
                <td>{data?.modelName}</td>
              </tr>
              {/* {data?.additionalAttributes.map((attribute, index) =>
                <tr key={index}>
                  <td>{attribute.name}</td>
                  <td>{attribute.value}</td>
                </tr>
              )} */}
              {data?.additionalAttributes.map((attribute, index) => {
  // Check if the attribute's clusterName is "Color"
  if (attribute.clusterName === "Color" ) {
    if (attribute.value !== "" ) {
      return (
        <tr key={index}>
          <td>{attribute.value}</td>
          <td>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                className="color-preview"
                style={{ backgroundColor: attribute.value || "transparent" }}
              ></div>
            </div>
          </td>
        </tr>
      );
    } else {
      // If attribute.value is null, don't render the entire row
      return null;
    }
  }else if(attribute.clusterName === "Texture"){
    return (
      <tr key={index}>
        <td>{attribute.name}</td>
        <td>
        {attribute.name === "Texture Image" ? (
        <div>
          {attribute.value && attribute.value.startsWith("http") ? (
            <img
              src={attribute.value}
              alt="Texture Preview"
              className="texture-preview"
            />
          ) : (
            "Image not found"
          )}
        </div>
      ) : (
        attribute.value
      )}
        </td>
      </tr>
    );
  }else{
    return (
      <tr key={index}>
        <td>{attribute.name}</td>
        <td>{attribute.value}</td>
      </tr>
    );
  }
})}

            </tbody>
            </Table>
) : text === "Product Description" ? (
  data?.description !== "undefined" && data?.description !== null ? (
    <span style={{ marginLeft: "8px" }}>{data?.description}</span>
  ) : null
) : (
  <></>
)}

      </>
    );
  }
}
