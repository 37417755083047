import React from 'react';
import { Card , Table} from 'react-bootstrap';
import "../Header/AccordionContent.css";
import logolink from "../../assests/public/Small size error icon 1.1 (1).png"
import logolink2 from "../../assests/public/Error Icon with text.png"
import { getInventoryLog ,downloadProductforcsm , getClientCsmCategories, getProducts} from "../../services/ProductsService";
class AccordionComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    data: [] ,
    };
  }
  componentDidMount() {
    document.title = 'Inventory Log';
  this.fetchInventoryLog();
  }
  async fetchInventoryLog() {
    debugger;
    try {
      const logs = await getInventoryLog();

      this.setState(
        {
          data: logs.data,
        },
      );
    } catch (err) {
      console.log(err);
    }
  }
  
  render() {
    const { item, isOpen, onClick } = this.props;

    if (item.bulkUploadErrors && item.bulkUploadErrors.length > 0) {
      // const { errorLogs, rowNumber } = item.bulkUploadErrors[0];

      return (
        <tr className='tz-inventory-table-body-row mt-2'>
          <td colSpan="9" onClick={onClick}>
            <Card>
              <Card.Header >
                <Card.Title><img src={logolink2} height={15} /></Card.Title>
              </Card.Header>
              <Card.Body className="custom-card-body">
              <div className={`accordion-container ${isOpen ? 'open' : ''}`}>
  <Table className="custom-table" >
    <thead >
      <tr>
        <th className="custom-table-header">Row No.</th>
        <th className="custom-table-header-2">Remarks</th>
      </tr>
    </thead>
    <tbody className='custom-tbody'>
      {item.bulkUploadErrors.map((errorObj, errorIndex) => (
        <tr  className="tz-error-table-body-row "  key={errorIndex} >
          <td  className='custom-text' >{errorObj.rowNumber}</td>
          <td>
  {errorObj.errorLogs.map((error, innerIndex) => (
    <li key={innerIndex} className="custom-list-item">
      <img src={logolink} alt="Cross Sign" height={15} />
   <span className='custom-text'>{error}</span> 
    </li>
  ))}
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
</div>

              </Card.Body>
            </Card>
          </td>
        </tr>
      );
    } else {
      // Render a blank accordion if bulkUploadErrors is empty
      return (
        <tr className='tz-inventory-table-body-row mt-2'>
          <td colSpan="9" onClick={onClick}>
            <Card>
              <Card.Header>
                <Card.Title>Errors</Card.Title>
              </Card.Header>
              <Card.Body>
                <div className={`accordion-container ${isOpen ? 'open' : ''}`}>
                  <div className='row'>
                    <div className="col-12 custom-text">
                      No error Found.
                     </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </td>
        </tr>
      );
    }
  }
}

export default AccordionComponent;
