import React, { Component } from 'react';
import { getCartItems, getCartItemsWithAmount  ,RemoveProducts ,PlaceOrder , UpdateandAddaddress ,UpdateQuantityIncart , getCartValue} from "../../services/ProductsService";
import { Table, Form, Dropdown, Stack, Pagination, Modal, Button, Row, Col } from 'react-bootstrap';
import "../Header/Cart.css";
import Swal from 'sweetalert2';
import { Link, useNavigate, useParams } from "react-router-dom";
function withNavigation(Component) {
    return (props) => <Component {...props} navigate={useNavigate()} />;
  }
class Cart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartItems: [],
            totalAmount: 0,
            itemCount: 0,
            address: "",
            checkoutClicked: false,
            PlaceOrder :false,
            city :"",
            state:"",
            pincode :"",
            landmark:"",
            addressType :"",
            flatNumber:"",
            addingAddress: false, 
            missingField: null,
            modalIsOpen: false,
modalProductIndex: null,
        };

    }

    handlePincodeChange = (e) => {
        const pincode = e.target.value;
        this.setState({ pincode });
       debugger;
        // Fetch additional details based on the pincode
        const sanitizedPincode = pincode.replace(/\D/g, "");
        fetch(`https://api.postalpincode.in/pincode/${sanitizedPincode}`)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Network response was not ok.");
                }
            })
            .then((data) => {
                if (data && data.length > 0) {
                    const { Name, State, Country, Block } = data[0].PostOffice[0];
                    this.setState({
                        city: Block,
                        state: State,
                        country: Country,
                    });
                } else {
                    console.log("No data found for the given pincode.");
                }
            })
            .catch((error) => {
                console.log("An error occurred:", error.message);
            });
    };
    
    validatePincode = () => {
        if (this.state.pincode.length !== 6) {
            return;
        }
        return ;
    };
    addProductToCart = (product) => {
        this.setState((prevState) => ({
            cartItems: [...prevState.cartItems, product],
        }));
    };
    openModal = (index, productDetails) => {
        this.setState({ modalIsOpen: true, modalProductIndex: index, productDetails: productDetails });
    };
    
    closeModal = () => {
        this.setState({ modalIsOpen: false, modalProductIndex: null });
    };
    async fetchAllCartItems() {
        try {
          const logs = await getCartValue(); 
          this.setState({
            CartData: logs.data,
          });
          this.props.updatedCartData(logs.data);
          console.log(logs ,"sadasdlogdata")
        } catch (err) {
          console.log(err);
        }
      }
    removeProductFromCart = (index, productDetails) => {
        debugger;
        this.openModal(index, productDetails);
    };
    
    
    handleRemoveConfirmed = async () => {
        const { modalProductIndex, cartItems ,productDetails} = this.state;
    debugger;
        try {
            const removed = await RemoveProducts(productDetails.productId, productDetails.color);
            if (removed) {
                this.setState((prevState) => {
                    const newCartItems = [...prevState.cartItems];
                    newCartItems.splice(modalProductIndex, 1);
                      this.setState(prevState => {
                         const updatedCartData = { itemCount: prevState.cartItems.length -1 };
        return { CartData: updatedCartData , modalIsOpen: false, modalProductIndex: null , cartItems: newCartItems,};
      }, () => {
        this.fetchAllCartItems();
      });
                });
            } else {
                console.log('Failed to remove product from the server.');
            }
        } catch (error) {
            console.error('Error removing product:', error);
        }
    };
    
    componentDidMount() {
        this.fetchAllCartValuesWithTotalamount();
        document.title = 'Cart';
    }
    handlePlaceorder = async () => {    
        try {
            debugger;
          const orderResult = await PlaceOrder();
          if (orderResult.status === 201) {
            Swal.fire({
              title: 'Order Placed!',
              text: 'Thank you for your purchase.',
              icon: 'success',
            });
            this.setState({
                PlaceOrder: false,
                checkoutClicked: false,
                cartItems: [],
            });
            setTimeout(() => {
                window.location.href = "/product-catalog";
            }, 2000);
          } else {
            console.error('Failed to place order:', orderResult.message);
          }
        } catch (error) {
          // Handle API error
          console.error('Error placing order:', error);
        }
      };
handleAddadreess = async ()=>{
    debugger;
    try { 
        const requiredFields = ['fullName', 'phoneNumber', 'emailAddress', 'pincode', 'city', 'state', 'address', 'landmark', 'addressType'];

        const missingField = requiredFields.find(field => !this.state[field]);

        if (missingField) {
            this.setState({ missingField });
            return;
        }
        this.setState({ missingField: null });
        const addressData = {
            name: this.state.fullName,
            email: this.state.emailAddress,
            phone: this.state.phoneNumber,
            flatNumber: this.state.flatNumber,
            address: this.state.address,
            city: this.state.city,
            state: this.state.state,
            pinCode: this.state.pincode,
            landmark: this.state.landmark,
            addressType: this.state.addressType,
          };
          const addressResult = await UpdateandAddaddress(addressData);
          if (addressResult.status === 200) {
            this.setState({ PlaceOrder: true , checkoutClicked: false }); 
            return;
          }else {
            Swal.fire({
                title: 'Warning',
                text: 'Something went wrong. Please recheck your information.',
                icon: 'warning',
              });
              return;
            }
    } catch (error) {
        Swal.fire({
            title: 'Error',
            text: 'An error occurred. Please try again later.',
            icon: 'error',
          });
          return;
      }
}
handleAddressUpdate = async()=>{
    this.setState({ PlaceOrder: false , checkoutClicked: true }); 
}
    async fetchAllCartValuesWithTotalamount() {
        try {
            const ItemCartlogs = await getCartItemsWithAmount();
            this.setState({
                cartItems: ItemCartlogs.data.cartItems,
                totalAmount: ItemCartlogs.data.totalAmount,
                itemCount: ItemCartlogs.data.itemCount,
            });
            console.log(ItemCartlogs, "Fetched Cart Items with Amount");
            return;
        } catch (err) {
            console.log(err, "Fetched Cart Items with Amount");
        }
    }
    handleIncreaseQuantity = async (index) => {
        const updatedCartItems = [...this.state.cartItems];
        updatedCartItems[index].quantity += 1;
        try {
          await UpdateQuantityIncart(
            updatedCartItems[index].productId,
            updatedCartItems[index].color,
            updatedCartItems[index].quantity
          );
          const newTotalAmount = updatedCartItems.reduce((total, item) => {
            return total + item.price * item.quantity;
        }, 0);
          this.setState({ cartItems: updatedCartItems ,totalAmount: newTotalAmount});
        } catch (error) {
          console.error('Error updating quantity:', error);
        }
      };
      
      handleDecreaseQuantity = async (index) => {
        const updatedCartItems = [...this.state.cartItems];
        if (updatedCartItems[index].quantity > 1) {
          updatedCartItems[index].quantity -= 1;
          try {
            await UpdateQuantityIncart(
              updatedCartItems[index].productId,
              updatedCartItems[index].color,
              updatedCartItems[index].quantity
            );
            const newTotalAmount = updatedCartItems.reduce((total, item) => {
                return total + item.price * item.quantity;
            }, 0);

            this.setState({ cartItems: updatedCartItems, totalAmount: newTotalAmount });
          } catch (error) {
            console.error('Error updating quantity:', error);
          }
        }
      };
    handleAddressChange = (e) => {
        this.setState({ address: e.target.value });
    };
  
    handleCheckout = () => {
        this.setState({ checkoutClicked: true  });
    };
    // handlePlaceorder = () => {
    //     this.setState({ PlaceOrder: true , checkoutClicked: false });
    // };
    render() {
        const { cartItems,addingAddress,itemCount,PlaceOrder ,totalAmount,flatNumber, checkoutClicked,addressType, fullName, phoneNumber,landmark, emailAddress ,pincode,city, state, address} = this.state;
        if (cartItems.length === 0) {
            return (
                <div className="centered-content-container">
                    <Stack className='table-inventory-product-container '>
                    <div className="upload-heading">
                        Cart
                    </div>
                    <div className="empty-cart-message">
                    <p>Cart is Empty. Please add products.</p>
                </div>
                    </Stack>
                </div>
            );
        }
    
        return (
            <div className="centered-content-container">
                <Stack className='table-inventory-product-container '>
                    <div className="upload-heading">
                        Cart
                    </div>
                    <div className='cart-container mt-2'>
                        {checkoutClicked ? (
                                    <div className="cart-right-container mr-4">
                                <div className="upload-heading">
                                    Add Delievery Address
                                </div>
                                <div>
                                    
    {this.state.missingField && (
        <p className="text-danger mt-2">
            Please fill in the {this.state.missingField.replace(/([A-Z])/g, ' $1').toLowerCase()}.
        </p>
    )}
                                    </div>
                                <Form className='mt-4'>
                                    <Row>
                                        <Col>
                                            {/* Full Name */}
                                            <Form.Group controlId="formFullName">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter full name"
                                                    value={fullName}
                                                    onChange={(e) => this.setState({ fullName: e.target.value })}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            {/* Phone Number */}
                                            <Form.Group controlId="formPhoneNumber">
    <Form.Control
        type="text"
        placeholder="Enter phone number"
        value={phoneNumber}
        onChange={(e) => {
            const validatedValue = e.target.value.replace(/\D/g, '');
            const limitedValue = validatedValue.slice(0, 10);
            this.setState({ phoneNumber: limitedValue });
        }}
    />
</Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group className='mt-4' controlId="formEmailAddress">
                                    <Form.Group controlId="formEmailAddress">
    <Form.Control
        type="email"
        placeholder="Enter email address"
        value={emailAddress}
        onChange={(e) => {
            const emailValue = e.target.value;
            const isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(emailValue);
            this.setState({
                emailAddress: isValidEmail ? emailValue : this.state.emailAddress,
            });
        }}
    />
</Form.Group>

                                    </Form.Group>
                                    <Row  className='mt-4' >
                                    <Col>
                            {/* Pincode Input */}
                            <Form.Group controlId="formPincode">
                                <Form.Control
                                    type="text"
                                    placeholder="Enter Pincode"
                                    value={this.state.pincode}
                                    onChange={this.handlePincodeChange}
                                />
                            </Form.Group>
                            <p>{this.validatePincode()}</p>
                        </Col>
                                        <Col>
                                            <Form.Group controlId="formCity">

                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter city"
                                                    value={city}
                                                    onChange={(e) => this.setState({ city: e.target.value })}
                                                  
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group controlId="formState">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter State"
                                                    value={state}
                                                    onChange={(e) => this.setState({ state: e.target.value })}
                                                
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group  className='mt-4'  controlId="formAddress">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Flat Number / House Number"
                                                    value={flatNumber}
                                                    onChange={(e) => this.setState({ flatNumber: e.target.value })}
                                                />
                                            </Form.Group>
                                    <Form.Group  className='mt-4'  controlId="formAddress">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Address"
                                                    value={address}
                                                    onChange={(e) => this.setState({ address: e.target.value })}
                                                />
                                            </Form.Group>
                                            <Form.Group  className='mt-4'  controlId="formAddress">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Landmark"
                                                    value={landmark}
                                                    onChange={(e) => this.setState({ landmark: e.target.value })}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="formAddressType" className='mt-4'>
        <Form.Label  >Address Type</Form.Label>
        <div>
        <button
    className={`address-type-button ${addressType === 'Office' ? 'selected' : ''}`}
    onClick={(e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        this.setState({ addressType: 'Office' });
    }}
>
    Office
</button>{' '}
<button
    className={`address-type-button ${addressType === 'Home' ? 'selected' : ''}`}
    onClick={(e) => {
        e.preventDefault(); // Prevents the default form submission behavior
        this.setState({ addressType: 'Home' });
    }}
>
    Home
</button>


        </div>
    </Form.Group>
    
                                    <Button className='mt-4 checkout-button' onClick={this.handleAddadreess} >
                                     Add Address
                                    </Button>
                                </Form>
                            </div>
                        ) : PlaceOrder ?  (
                            <div className="cart-right-container mr-4">
                                <div   >
                                    <div className='cart-address-box'>
                                    <div className='row ' >
                                    <div className='col-md-11'>
    <div style={{ fontWeight: 'bold', fontSize: '1.2em' }}> {fullName}</div>
    <div  style={{ marginTop: '3px' }}>  <button disabled> {addressType}</button> </div>
    <div style={{ marginTop: '3px' }}>
    <div style={{ marginTop: '3px' }}>
        <span>Address: {address}</span>
    </div>
    <div style={{ marginTop: '3px' }}>
        <span>Landmark: {landmark}</span>
    </div>
       <div style={{ marginTop: '3px' }}>
       <span>{emailAddress}</span>
        <span>{pincode}</span>
        <span style={{ marginLeft: '16px' }}>{city}</span>
        <span style={{ marginLeft: '16px' }}>{state}</span>
        </div>
    </div>
    <div style={{ marginTop: '8px', marginBottom: '8px' }}>
    <span style={{ marginRight: '16px' }}>Mobile Number: {phoneNumber}</span>
    </div>
</div>

                              <div className='col-md-1'> <button className='remove-button' onClick={this.handleAddressUpdate}>Change</button></div> 
                            </div>
                                        </div>
                               
                            <div className='total-products-box'>
                            <div className="upload-heading">
                       <div>
                        {itemCount} Product 
                        </div>
                    </div>
                                </div>
                                    {cartItems.map((item, index) => (
                                        <div >
                                            <div className="cart-item" key={index}>
                                                <div className="thumbnail-container">
                                                    <img src={item.thumbnailUrl} alt={item.title} width={100} height={100} />
                                                </div>
                                                <div className="details-container">
                                                    <p className="product-name">{item.title}</p>
                                                    <p className="company-name">By {item.brand}</p>
                                                    <p className="price">Price: ₹{item.price.toLocaleString()}</p>
                                                </div>
                                                <div className="quantity-container">
                                                    <button onClick={() => this.handleDecreaseQuantity(index)}>-</button>
                                                    <span>{item.quantity}</span>
                                                    <button onClick={() => this.handleIncreaseQuantity(index)}>+</button>
                                                </div>
                                            </div>
                                            <div className="attach-cart-item">
                                                <div className="color-box" style={{ backgroundColor: item.color }}> <span className="color-text">{item.color}</span></div>
                                                <div> <button  className='remove-button' onClick={() => this.removeProductFromCart(index, item)}>Remove</button>  </div>
                                            </div>
                                        </div>
                                    ))}

                                </div>
                                <div className='small-container' >{cartItems.itemCount}</div>
                            </div>
                        ) : (
                            <div className="cart-right-container mr-4">
                              <div>
                              {cartItems.map((item, index) => (
                                        <div >
                                            <div className="cart-item" key={index}>
                                                <div className="thumbnail-container">
                                                    <img src={item.thumbnailUrl} alt={item.title} width={100} height={100} />
                                                </div>
                                                <div className="details-container">
                                                    <p className="product-name">{item.title}</p>
                                                    <p className="company-name">By {item.brand}</p>
                                                    <p className="price">Price: ₹{item.price.toLocaleString()}</p>
                                                </div>
                                                <div className="quantity-container">
                                                    <button onClick={() => this.handleDecreaseQuantity(index)}>-</button>
                                                    <span>{item.quantity}</span>
                                                    <button onClick={() => this.handleIncreaseQuantity(index)}>+</button>
                                                </div>
                                            </div>
                                            <div className="attach-cart-item">
                                                <div className="color-box" style={{ backgroundColor: item.color }}> <span className="color-text">{item.color}</span></div>
                                                <div> <button className='remove-button'  onClick={() => this.removeProductFromCart(index, item)}>Remove</button>  </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {/* <Button className='mt-4 checkout-button' onClick={this.handleCheckout}>
                                    Checkout
                                </Button> */}
                            </div>
                        )}
                        <div className="cart-left-container">
                            <div className="left-cart-item">
                                <h2  className='totalamount'>Price Details</h2>
                                <div>Cart Items ({cartItems.length} items)<span className='cart-price'> ₹{totalAmount.toLocaleString()}</span> </div>
<div className='totalamount'>Total (inc. all taxes):  <div className='price-container'>
                <span className='cart-price'> ₹{totalAmount.toLocaleString()}</span>
            </div></div>
                            </div>
                            {checkoutClicked && !PlaceOrder ? (
        <div> </div>
    ) : PlaceOrder ? (
        <Button className='mt-4 checkout-button' onClick={this.handlePlaceorder}>Place Order</Button>
    ) : (
        <Button className='mt-4 checkout-button' onClick={this.handleCheckout}>Checkout</Button>
    )}
                        </div>
                    </div>
                </Stack>
{this.state.modalIsOpen && (
  <Modal show={this.state.modalIsOpen} onHide={this.closeModal} centered>
    <Modal.Header closeButton>
      <Modal.Title>Confirm Removal</Modal.Title>
    </Modal.Header>
<Modal.Body>
Are you sure you want to remove this product?
</Modal.Body>
    <Modal.Footer>
      <Button variant="primary" onClick={() => this.handleRemoveConfirmed()}>
      Remove
      </Button>
      <Button variant="primary" onClick={this.closeModal}>
      Cancel
      </Button>
    </Modal.Footer>
  </Modal>
)}
            </div>
        );
    }
 }
export default Cart;
