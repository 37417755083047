import React, { Component } from 'react';
import './BulkUpload.css';
import './FileUpload.css';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload, faFileArchive, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { getAllCustomerCategories, downloadProductXlsxFile, uploadTemplatefiles , getProducts} from "../../src/services/ProductsService";
import Swal from 'sweetalert2';
import { FileUpload } from '@mui/icons-material';
import Download from "../../src/assests/public/Download (1).png"
import GreenDownload from "../../src/assests/public/Download Template done.png"
import Upload from "../../src/assests/public/after download  template_Upload.png"
import GreyUpload from "../../src/assests/public/Grey blur Upload (1).png"
import Excel from "../../src/assests/public/Excel File.png"
import Zip from "../../src/assests/public/Zip File.png"
import fileUpload from "../../src/assests/public/Upload files.png"
import { ProgressBar } from "react-bootstrap"; 
class BulkUpload extends Component {
  constructor(props) {
    super(props);
    this.excelFile = React.createRef();
    this.zipFile = React.createRef();
    this.state = {
      customerCatageory: [],
      selectedCategories: [],
      showFileUpload: false,
      excelFile: null,
      zipFile: null,
      searchQuery: '', 
      uploadingFiles: false,
      showNotYetProductCategory: false,
      uploadProgress: 0,
    };
  }

  fetchAllCustomerCategories = () => {
    debugger;
    getAllCustomerCategories()
      .then((data) => {
        this.setState({ customerCatageory: data });
      })
      .catch((error) => {
        console.error('Error retrieving clusters:', error);
      });
  };
  fetchAllProducts= () => {
    debugger;
    getProducts()
      .then((data) => {
        this.setState({ productData: data });
      })
      .catch((error) => {
        console.error('Error retrieving clusters:', error);
      });
  };
  handleSearch = (event) => {
    const searchQuery = event.target.value;
    this.setState({ searchQuery });
  };
  handlecategory = (e) => {
    e.preventDefault();
    window.location.href = "/product-category";
  }
  handleAddProductCategory = (e) => {
    e.preventDefault();
    window.location.href = "/product-category";
 };
 
  handleNextButtonClick = () => {
    const { selectedCategories } = this.state;
    if (selectedCategories.length === 0) {
      Swal.fire({
        icon: 'error',
        title: 'Select Category',
        text: 'Please select a category before proceeding to the next step.',
      });
      return;
    }
    this.setState({ showFileUpload: true });
  };
  handleCancel= () => {
    window.location.href = "/product-catalog";
  };

  componentDidMount() {
    document.title = 'Bulk-Upload';
    this.fetchAllCustomerCategories();
  }
  handleCategorySelect = (category) => {
    debugger;
    const { selectedCategories } = this.state;

    if (selectedCategories.includes(category)) {
      this.setState({ selectedCategories: [] }); // Unselect the category if already selected
    } else {
      this.setState({ selectedCategories: [category] }); // Select the category and unselect others
    }
  };
  handleExcelFileChange = (event) => {
    const excelFile = event.target.files[0];
    this.setState({ excelFile });
  };
  
  handleZipFileChange = (event) => {
    const zipFile = event.target.files[0];
    this.setState({ zipFile });
  };
  
  downloadTemplate = async () => {
    debugger;
    const { selectedCategories } = this.state;

    if (selectedCategories.length === 0) {
      return;
    }
    const label = selectedCategories[0].label;
    try {
      await downloadProductXlsxFile(label);
      Swal.fire({
        icon: 'success',
        title: 'Download Successful',
        text: "Fils is downloading",
      });
    } catch (error) {
      if (error.message === 'Authorization failed') {
        Swal.fire({
          icon: 'error',
          title: 'Authorization Failed',
          text: 'You are not authorized to download this file.',
        });
        this.setState({ selectedCategories: [] });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Download Failed',
          text: "Error",
        });
      }
    }
  };
  UploadfilesTemplate = async () => {
    const { selectedCategories, excelFile, zipFile } = this.state;

    if (selectedCategories.length === 0 || !excelFile || !zipFile) {
      Swal.fire({
        icon: 'error',
        title: 'Invalid Files',
        text: 'Please select both Excel and Zip files before uploading.',
      });
      return;
    }
    const label = selectedCategories[0].label;
    const formData = new FormData();
    formData.append('excelFile', excelFile);
    formData.append('assetsZipFile', zipFile);

    try {
      this.setState({ uploadingFiles: true, uploadProgress: 0 }); // Start uploading state
      await uploadTemplatefiles(formData, label, this.updateUploadProgress); // Assuming this function handles the API call
      const totalSize = 1000000; // Replace with the actual total file size
      const simulateProgress = (percentage) => {
        this.setState({ uploadProgress: percentage });
      };
  
      // Simulate progress at 0%, 20%, 60%, and 100%
      simulateProgress(0);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay
      simulateProgress(20);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay
      simulateProgress(60);
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate a delay
      simulateProgress(100);

      Swal.fire({
        icon: 'success',
        title: 'Files received.',
        text: 'Files received. Processing will start shortly.',
      });
    
      this.setState({
        excelFile: null,
        zipFile: null,
        uploadingFiles: false, // End uploading state
            uploadProgress: 0, 
      });
      this.excelFile.current.value = null;
      this.zipFile.current.value = null;
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Uploading Failed',
        text: 'Error',
      });
      this.setState({
        excelFile: null,
        zipFile: null,
        uploadingFiles: false, // End uploading state
      });
    }
  };
  updateUploadProgress = (percentage) => {
    this.setState({ uploadProgress: percentage });
  };
  
  render() {
    const { customerCatageory, selectedCategories, showFileUpload, assetsZipFile ,showNotYetProductCategory ,uploadProgress  } = this.state;
    const { uploadingFiles } = this.state;
    return (
      <div className="bulk-big-container">
        <div className="bulk-table-container">
          <div className="bulk-tz-heading">Upload Bulk Product </div>
          {showFileUpload ? (
            <div className="bulk-one-big-box">
              <div className="bulk-big-box">
                <div className="first-nested-box">
                  <div className='bulk-icon-container' >
                    <div className='icon-text'>
                    <img src={GreenDownload} className='download-icon' width={40} />
                      <div className='text'>Download Template</div>
                    </div>
                    <div className='borderline'></div>
                    <div className='icon-text'>
                    <img src={Upload} className='upload-icon' width={40} />
                      <div className='text'>Upload Files</div>
                    </div>
                  </div>
                </div>
                <div></div>
                <div className="nested-box mt-4 fileupload-heading">
                Upload Template File & Asset Zip Folder
                </div>
                <div className="files-nested-box mt-4">
                  <div className='fileupload-Container'>
                    <div className='fileUpload-text'>
                    <img src={Excel} width={20} />
                    </div>
                    <div className='fileUpload-text'>
                      Select excel file
                    </div>
                    <div className='input-fileUpload-text'>
                    <input 
  type="file"
  accept=".xlsx"
  onChange={this.handleExcelFileChange}
  ref={this.excelFile} 
  style={{ width: '80%' }}
/>
                    </div>
                    <div className='fileUpload-borderline'></div>
                    <div className='fileUpload-text'>
                    <img src={fileUpload} className='upload-icon' width={15} />
                    </div>
                  </div>
                </div>
                <div className="files-nested-box mt-4">
                  <div className='fileupload-Container'>
                    <div className='fileUpload-text'>
                    <img src={Zip} width={20} />
                    </div>
                    <div className='fileUpload-text'>
                      Select asset zip file
                    </div>
                    <div className='fileUpload-text'>
                    <input
  type="file"
  accept=".zip"
  onChange={this.handleZipFileChange}
  ref={this.zipFile} 
  style={{ width: '100%' }}
/>
                    </div>
                    <div className='fileUpload-borderline'></div>
                    <div className='fileUpload-text'>
                    <img src={fileUpload} className='upload-icon' width={15} />
                    </div>
                  </div>
                </div>
                <div className="button-nested-box mt-4">
                  <Button className='file-upload-button' onClick={this.UploadfilesTemplate}>Upload Files</Button>
                </div>
                <div className="mt-3">
                {uploadingFiles && (
          <div className='fileupload-heading'>
            Please wait for some time, Your files are currently uploading...
          </div>
          
        )} 
                </div>
                <div>
                {uploadingFiles && (
        <div className="progress-nested-box mt-4">
                  <div className='fileupload-Container'>
                    <div className='fileUpload-text'>
                    <img src={Excel} width={20} />
                    </div>
        <div className="fileupload-heading">
           <div>
        <ProgressBar
        className='custom-progress-bar '
          now={uploadProgress}
          label={`${uploadProgress}%`} // Display the progress as a label
        />
      </div>
        </div>
                    {/* <div className='fileUpload-borderline'></div> */}
                    {/* <div className='fileUpload-text'>
                    <img src={fileUpload} className='upload-icon' width={15} />
                    </div> */}
                  </div>
                  <div className='fileupload-Container'>
                    <div className='fileUpload-text'>
                    <img src={Zip} width={20} />
                    </div>
        <div className="fileupload-heading">
           <div>
        <ProgressBar
        className='custom-progress-bar '
          now={uploadProgress}
          label={`${uploadProgress}%`} // Display the progress as a label
        />
      </div>
        </div>
                    {/* <div className='fileUpload-borderline'></div> */}
                    {/* <div className='fileUpload-text'>
                    <img src={fileUpload} className='upload-icon' width={15} />
                    </div> */}
                  </div>
                </div>
                   )}
      </div>
      
              </div>
            </div>
          ) : (
            <div className="bulk-one-big-box">
              <div className="bulk-big-box">
                <div className="first-nested-box">
                  <div className='bulk-icon-container' >
                    <div className='icon-text'>
                      <img src={Download} className='download-icon' width={40} />
                      <div className='text'>Download Template</div>
                    </div>
                    <div className='borderline'></div>
                    <div className='icon-text'>
                      <img src={GreyUpload} className='upload-icon' width={40} />
                      <div className='text'>Upload Files</div>
                    </div>
                  </div>
                </div>
                <div className="nested-box">
                  <div className='bulk-tz-first-heading'>
                    Select a category to download template
                  </div>
                  <div>
                    <Form className="d-flex tz-bulk-textbox-with-icon i tz-bulk-search-textbox-container">
                    <Form.Control
       type="search"
        placeholder="Search Category"
      className="tz-textbox"
       aria-label="Search"
      value={this.state.searchQuery}
      onChange={this.handleSearch} 
  />
                      <i className="bi bi-search"></i>
                    </Form>
                  </div>
                </div>
                <div className="prod-nested-box"> 
                <div className='prod-tz-first-heading'>
   If your product category is not in the list, please add your  
   <a href="#" onClick={this.handleAddProductCategory}>Product Category</a>.
</div>
                   </div>
                <div className="nested-nested-box tz-nested mb-2">
                  {customerCatageory.length > 0 ? (
                    <div>
                      <ul className="category-list">
               {customerCatageory
        .filter(category =>
          category.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
        ).map((category, index) => (
                          <div key={index} className='bulk-category-container'>
                            <div className='cat-text'>
                              <li>   <input
                                type='radio'
                                checked={selectedCategories.includes(category)}
                                onChange={() => this.handleCategorySelect(category)}
                              /> <span className='space'>{category.name}</span></li>
                            </div>
                            <div className='category-borderline'></div>
                            <div className='cat-text'>
                              <Button
                                variant="outlined"
                                className={`down-btn ${selectedCategories.includes(category) ? '' : 'disabled'}`}
                                onClick={this.downloadTemplate}
                              >
                                <span className='bulk-fa-download'>
                                  <FontAwesomeIcon icon={faDownload} /> <span className='space'>Download Template</span>
                                </span>
                              </Button>
                            </div>
                          </div>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className='blank-nested-nested-box' >
                      <div className='bulk-heading mt-5'>
                        Not yet a product category
                      </div>
                      <div className='bulk-second-heading  mt-2'>
                        Go to Product Categories and copy Category into my product category.
                      </div>
                      <div className='mt-3 mb-4'>
                        <Button className='bulk-btn' onClick={this.handlecategory}>
                          Go to Product Category
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div>
                </div>
              </div>
              <div className='bulk-button-shift'>
                <div className='bulk-btn-container'>
                  <Button className="white-cancel-button" variant='outlined' onClick={this.handleCancel}>Cancel</Button>
                  <Button   className={selectedCategories.length === 0 ||showNotYetProductCategory? 'greyed-out-button' : ''} onClick={this.handleNextButtonClick} disabled={selectedCategories.length === 0|| showNotYetProductCategory}>Next</Button>
                </div>
              </div>
            </div>
          )}
           {/* {uploadingFiles && (
          <div className="uploading-message">
            Please wait for some time, Your files are currently uploading...
          </div>
        )} */}
        </div>
      </div>
    );
  }
}
export default BulkUpload;
