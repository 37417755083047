import React, { useState } from 'react';
import Razorpay from 'razorpay';
import './Payment.css';

const Payment = () => {
  const [cardNumber, setCardNumber] = useState('');
  const [expiryDate, setExpiryDate] = useState('');
  const [fullName, setFullName] = useState('');
  const [gstin, setGstin] = useState('');
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState('');
  const [paymentError, setPaymentError] = useState('');

  const razorpayOptions = {
    key_id: 'rzp_test_TCL1PypJTbuuEO',
    amount: 10000, // Amount in paisa
    currency: 'INR',
    name: 'MyStore',
    description: 'Test transaction',
    image: 'https://cdn.razorpay.com/logos/7K3b6d18wHwKzL_medium.png',
    handler: (response) => {
      setPaymentLoading(false);
      setPaymentSuccess('Payment successful!');
    },
    prefill: {
      name: fullName,
      email: 'johndoe@example.com', // you can also include email as a field in the form
      contact: '9999999999',
      'customer[name]': fullName,
      'customer[gstin]': gstin,
    },
    theme: {
      color: '#F37254',
    },
  };

  const openRazorpay = () => {
    setPaymentLoading(true);

    const razorpay = new Razorpay(razorpayOptions);
    razorpay.once('payment.error', (response) => {
      setPaymentLoading(false);
      setPaymentError(response.error.description);
    });
    razorpay.once('payment.cancel', (response) => {
      setPaymentLoading(false);
      setPaymentError('Payment cancelled!');
    });
    razorpay.once('payment.success', (response) => {
      setPaymentLoading(false);
      setPaymentSuccess('Payment successful!');
    });
    razorpay.open();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // perform any additional validation or processing here
    openRazorpay();
  };

  return (
    <div className="payment-container">
      <form className="payment-form" onSubmit={handleSubmit}>
        <h1>Payment Portal</h1>
        <div>
          <label htmlFor="cardNumber">Card number:</label>
          <input
            type="text"
            id="cardNumber"
            value={cardNumber}
            onChange={(e) => setCardNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="expiryDate">Expiry date:</label>
          <input
            type="text"
            id="expiryDate"
            value={expiryDate}
            onChange={(e) => setExpiryDate(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="fullName">Full name:</label>
          <input
            type="text"
            id="fullName"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="gstin">GST number:</label>
          <input
            type="text"
            id="gstin"
            value={gstin}
            onChange={(e) => setGstin(e.target.value)}
            required
          />
        </div>
        <button type="submit" disabled={paymentLoading}>
          {paymentLoading ? 'Processing payment...' : 'Pay now'}
        </button>
      </form>
      {paymentSuccess && <div>{paymentSuccess}</div>}
      {paymentError && <div>{paymentError}</div>}
    </div>
  );
};

export default Payment;


