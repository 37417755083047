import React from "react";
import { Nav, Form, Dropdown } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Logo from "../assets/trezi-logo.png";
import UserImage from "../assets/UserImage.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import logoutIcon from "../assests/public/icon_logout.png";
import { baseUrlUser } from "../../src/utils/common";
import { ListItemIcon } from "@material-ui/core";
import Swal from 'sweetalert2';
import "./CustomerHeader.css";

const urlUser = baseUrlUser;

function getCookie(name) {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
}

function withParams(Component) {
  return (props) => <Component {...props} params={useParams()} />;
}

function withNavigation(Component) {
  return (props) => <Component {...props} navigate={useNavigate()} />;
}

class CustomerHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eventKey: "",
      searchQuery: "",
    };
  }

  componentDidMount() {
    this.updateActiveTab();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.updateActiveTab();
    }
  }
  handleSearch = (event) => {
    debugger;
    const query = event.target.value;
    this.props.setSearchQuery(query);

  };
  updateActiveTab() {
    const { pathname } = window.location;
    const { productId } = this.props.params;
    const eventKey =
      pathname === "/CategoryApproval" || pathname === `CategoryApproval`
        ? "2"
        : pathname === "/CustomerSuccessTable"
          ? "1"
          : "";
    this.setState({ eventKey });
  }

  handleLogout = async (e) => {
    e.preventDefault();
    const { navigate } = this.props;
    const treziAccessToken = getCookie("treziAccessToken");
     if (!treziAccessToken) {
      console.error("JWT token or email not found in cookies"); 
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'JWT token or email not found in cookies',
      });
      return;
    }
  
    try {
      const response = await fetch(`${urlUser}users/logout`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Cookie: `treziAccessToken=${treziAccessToken}`,
          Authorization: `Bearer ${treziAccessToken}`,
        },
      });
  
      if (response.ok) {
        document.cookie = "trezi-access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "orgIdentifier=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "treziAccessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem("role");
        localStorage.removeItem("treziAccessToken");
        localStorage.removeItem("organizationIdentifier");
        navigate("/");
        console.log("User logged out successfully.");
        // Swal.fire({
        //   icon: 'success',
        //   title: 'Logged Out',
        //   text: 'User logged out successfully.',
        // });
      } else {
        // Logout failed
        console.error("User logout failed");
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'User logout failed',
        });
      }
    } catch (error) {
      console.error("An error occurred during user logout:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred during user logout',
      });
    }
  };

  render() {
    const { eventKey } = this.state;
    const { searchQuery, setSearchQuery } = this.props;

    return (
      <>
        {[false].map((expand, index) => (
          <React.Fragment key={index}>
            <Navbar
              className="tz-navbar"
              sticky="top"
              key={`navbar-${expand}-${index}`}
              bg="dark"
              variant="dark"
              expand={expand}
            >
              <Container fluid className="tz-toggle">
                <div className="tz-header-left-section">
                  <Navbar.Brand className="tz-logo-container">
                    <img src={Logo} alt="Logo" />
                    <span>Trezi</span>
                  </Navbar.Brand>
                </div>

                <Form className="d-flex tz-textbox-with-icon tz-search-textbox-container">
                  <Form.Control
                    type="search"
                    placeholder="Search"
                    className="tz-textbox tz-textbox-dark"
                    aria-label="Search"
                    onChange={(event) => {
                      this.handleSearch(event);
                      setSearchQuery(event.target.value); // Update the searchQuery prop
                    }}
                    value={searchQuery} // Set the input value to the searchQuery
                  />
                  <i className="bi bi-search"></i>
                </Form>

                <Dropdown className="tz-header-right-section">
                  <Dropdown.Toggle className="tz-user-dropdown">
                    <img src={UserImage} alt="User" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="tz-user-menu">
                    <Dropdown.Item onClick={this.handleLogout} href="#">
                      <ListItemIcon>
                        <img
                          src={logoutIcon}
                          alt="Logout"
                          width={20}
                          height={20}
                        />{" "}
                        <span></span> Logout
                      </ListItemIcon>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Container>
            </Navbar>
            <Navbar
              key={`navbar-header-${expand}-${index}`}
              bg="dark"
              variant="dark"
              className="tz-header-navbar"
            >
              <Container fluid>
                <Nav
                  className="d-flex justify-content-center tab tz-header-tabs"
                  key={`${expand}-${index}`}
                  fill
                  variant="tabs"
                  activeKey={eventKey}
                  onSelect={(selectedTab) =>
                    this.setState({ eventKey: selectedTab })
                  }
                >
                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      eventKey="1"
                      active={eventKey === "1"}
                      to="/CustomerSuccessTable"
                    >
                      Catalog queue
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      as={Link}
                      eventKey="2"
                      active={eventKey === "2"}
                      to="/CategoryApproval"
                    >
                      Category Approval
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Container>
            </Navbar>
          </React.Fragment>
        ))}
      </>
    );
  }
}
export default withNavigation(withParams(CustomerHeader));
