import React, { Component } from 'react';
import { Container, Row, Col, Card, Form, Button } from "react-bootstrap";
import "./SingleUploadcustomer.css"; // Replace with your custom CSS file name
import { getAllCustomerSucessCategory, customerSuccessProductUpload,getProducts ,customerSuccessProductUpload2d } from "../services/ProductsService";
import Swal from 'sweetalert2';
import { FaUpload } from "react-icons/fa";
import Loader from "../context/loader.js";
import { faL } from '@fortawesome/free-solid-svg-icons';

class SingleUploadcustomer extends Component {
  constructor(props) {
    super(props);
    this.image1InputRef = React.createRef();
    this.image2InputRef = React.createRef();
    this.image3InputRef = React.createRef();
    this.image4InputRef = React.createRef();
    this.model3DFile1InputRef = React.createRef();
    this.state = {
      selectedCluster: null,
      attributeValues: {},
      customerCatageory: [],
      // Add category state to store the selected category ID
      customerId:"",
      category: '',
      clusters: [],
      productTitle: "",
      sku: "",
      brandName: "",
      modelName: "",
      modelNumber: "",
      price: "",
      ifcCode: "",
      searchKeywords: "",
      image1File: null, // Add image1File state variable
      image2File: null, // Add image2File state variable
      image3File: null, // Add image3File state variable
      image4File: null, // Add image3File state variable
      model3DFile: null,
      errorMessage: "",
      is3DSelected :false,
      isLoading: false,
      clusterAttributeValues: {},
      previewTextureUrl :"",
      textureFile :''
    };
  }


  componentDidMount() {
    // Fetch all attributes when the component mounts
    document.title = 'Customer Success';
 
  }
  
  fetchAllCustomerSucessCategories = (customerId) => {
    debugger;
    if (!customerId) {
        Swal.fire({
          icon: 'warning',
          title: 'Customer ID is Blank',
          text: 'Please fill in the Customer ID else you would not be able to select categories.',
        });
        return;
      }
    getAllCustomerSucessCategory(customerId)
      .then((data) => {
        this.setState({ customerCatageory: data });
      })
      .catch((error) => {
        console.error('Error retrieving clusters:', error);
      });
  };

  handleClusterItemClick = (cluster) => {
    this.setState({ selectedCluster: cluster });
  };
  handleAttributeValueChange = (clusterName, attribute, newValue) => {
    debugger;
    this.setState((prevState) => {
      const updatedAttributeValues = { ...prevState.attributeValues };
  
      // Ensure the clusterName exists in the attributeValues object
      if (!updatedAttributeValues[clusterName]) {
        updatedAttributeValues[clusterName] = {};
      }
      updatedAttributeValues[clusterName][attribute.name] = {
        name :attribute.name,
        value: newValue,
        variableType: attribute.variableType,
        clusterName: clusterName,
        isHidden :false,
      };
  
      return { attributeValues: updatedAttributeValues };
    });
  };
  handleTextureFileChange = (clusterName, attribute, file) => {
    if (!clusterName || !attribute || !file) {
      return;
    }
  debugger;
  this.setState({
    textureFile: file,
  });
  const reader = new FileReader();
  reader.onload = (e) => {
    this.setState({
      previewTextureUrl: e.target.result,
    });
  };
  reader.readAsDataURL(file);
  this.setState((prevState) => {
    const updatedAttributeValues = { ...prevState.attributeValues };
    if (!updatedAttributeValues[clusterName]) {
      updatedAttributeValues[clusterName] = {};
    }
    updatedAttributeValues[clusterName][attribute.name] = {
      name :attribute.name,
      value: file.name,
      variableType: attribute.variableType,
      clusterName: clusterName,
      isHidden :false,
    };

    return { attributeValues: updatedAttributeValues };
  });
    
  };
  
  // Get all products
  async fetchProducts() {
    debugger;
    try {
      const products = await getProducts();

      this.setState(
        {
          data: products,
          productData: [],
        },
      );
    } catch (err) {
      console.log(err);
    }
  }
  handleSwitchToggle = () => {
    this.setState((prevState) => ({
      is3DSelected: !prevState.is3DSelected,
    }));
    // Add any additional logic you need here
  };

  handleInputChange = (event) => {
    debugger;
    const { name, value } = event.target;
    if (name === "price" && isNaN(value)) {
      return;
    }
    this.setState({ [name]: value });
  };
  // Method to reset the form fields
  resetForm = () => {
    this.setState({
      selectedCluster: null,
      attributeValues: {},
      category: '',
      clusters: [],
      productTitle: "",
      sku: "",
      price :"",
      brandName: "",
      modelName: "",
      modelNumber: "",
      ifcCode: "",
      searchKeywords: "",
      image1File: null,
      image2File: null,
      image3File: null,
      image4File: null,
      model3DFile: null,
      errorMessage: "",
      productDescription :"",
      customerId:"",
      clusterAttributeValues: {},
    });
  };

  handleCancel =(e)=>{
    window.location.href = "/CustomerSuccessTable";
  }
  handleSubmit3d = async (e) => {
    if (e) e.preventDefault();
    this.setState({ isLoading: true });
  debugger;
    const {
      customerId,
      productTitle,
      category,
      sku,
      brandName,
      price,
      modelName,
      modelNumber,
      ifcCode,
      searchKeywords,
      productDescription,
      selectedCluster,
      attributeValues,
      image1File,
      image2File,
      image3File,
      image4File,
      model3DFile,
      textureFile
    } = this.state;
  
    const requiredFields = ["productTitle", "category", "sku", "brandName", "modelName", "modelNumber", "ifcCode", "searchKeywords"];
    
    for (const field of requiredFields) {
      if (!this.state[field]) {
        this.setState({ errorMessage: `Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.` });
        this.setState({ isLoading: false });
        return; // Stop execution if any required field is empty
      }
    }
    if (!image1File) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'It is mandatory to have atleast first thumbnail.',
    });
    this.setState({ isLoading: false });
    return;
  }
  if (!model3DFile) {
    Swal.fire({
      icon: 'error',
      title: 'Error',
      text: 'To upload a 3D product, you need to choose the geometry file.',
  });
  this.setState({ isLoading: false });
  return;
}
   if (!this.state.selectedCluster) {
    this.setState({
      errorMessage: "Please select a cluster before saving.",
      isLoading: false,
    });
    return; // Stop execution
  }
    const formData = new FormData();
 formData.append('clientId', customerId);
    formData.append('title', productTitle);
    formData.append('categoryName', category);
    formData.append('SKU', sku);
    formData.append('brand', brandName);
    formData.append('price', price);
    formData.append('modelName', modelName);
    formData.append('modelNumber', modelNumber);
    formData.append('IFCCode', ifcCode);
    formData.append('searchKeywords', searchKeywords);
    formData.append('description', productDescription);
    const additionalAttributes = [];
    for (const clusterName in attributeValues) {
      for (const attributeName in attributeValues[clusterName]) {
        additionalAttributes.push(attributeValues[clusterName][attributeName]);
      }
    }
    
    // Now, you can append additionalAttributes to your formData
    formData.append('additionalAttributes', JSON.stringify(additionalAttributes));
    formData.append('thumbnail1', image1File);
    formData.append('thumbnail2', image2File);
    formData.append('thumbnail3', image3File);
    formData.append('thumbnail4', image4File);
    formData.append('textureFile', textureFile);
    formData.append('geometryFile', model3DFile);
   try {
    const response=  await customerSuccessProductUpload(formData); 
    if(response === "Product successfully added." ){
      Swal.fire({
        icon: 'success',
        title: 'Product Uploaded',
        text: 'Your product has been successfully Uploaded!',
      });
      this.setState({ errorMessage: "" });
      this.resetForm();
      this.image1InputRef.current.value = null;
      this.image2InputRef.current.value = null;
      this.image3InputRef.current.value = null;
      this.image4InputRef.current.value = null;
      this.model3DFile1InputRef.current.value = null;
      this.fetchProducts();
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: response.response.data, 
      });
    }  
    
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while Uploading the product. Please try again later.',
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };
  handleSubmit2d = async (e) => {
    if (e) e.preventDefault();
    this.setState({ isLoading: true });
   debugger;
    const {
      customerId,
      productTitle,
      category,
      sku,
      brandName,
      price,
      modelName,
      modelNumber,
      ifcCode,
      searchKeywords,
      productDescription,
      selectedCluster,
      attributeValues,
      image1File,
      image2File,
      image3File,
      image4File,
      model3DFile,
      textureFile
    } = this.state;
    const requiredFields = ["productTitle", "category", "sku", "brandName", "modelName", "modelNumber", "ifcCode", "searchKeywords"];
    for (const field of requiredFields) {
      if (!this.state[field]) {
        this.setState({ errorMessage: `Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()}.` });
        this.setState({ isLoading: false });
        return; // Stop execution if any required field is empty
      }
    }
    if (!image1File) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'It is mandatory to have atleast first thumbnail.',
    });
    this.setState({ isLoading: false });
    return;
  }
   if (!this.state.selectedCluster) {
    this.setState({
      errorMessage: "Please select a cluster before saving.",
      isLoading: false,
    });
    return; // Stop execution
  }
    const formData = new FormData();
  formData.append('clientId', customerId);
    formData.append('title', productTitle);
    formData.append('categoryName', category);
    formData.append('SKU', sku);
    formData.append('brand', brandName);
    formData.append('price', price);
    formData.append('modelName', modelName);
    formData.append('modelNumber', modelNumber);
    formData.append('IFCCode', ifcCode);
    formData.append('searchKeywords', searchKeywords);
    formData.append('description', productDescription);
    const additionalAttributes = [];
    for (const clusterName in attributeValues) {
      for (const attributeName in attributeValues[clusterName]) {
        additionalAttributes.push(attributeValues[clusterName][attributeName]);
      }
    }
    formData.append('additionalAttributes', JSON.stringify(additionalAttributes));
    formData.append('thumbnail1', image1File);
    formData.append('thumbnail2', image2File);
    formData.append('thumbnail3', image3File);
    formData.append('thumbnail4', image4File);
    formData.append('textureFile', textureFile);
    // formData.append('geometryFile', model3DFile);
   try {
    const response=  await customerSuccessProductUpload2d(formData); 
    if(response === "Product successfully added." ){
      Swal.fire({
        icon: 'success',
        title: 'Product Uploaded',
        text: 'Your product has been successfully Uploaded!',
      });
      this.setState({ errorMessage: "" });
      this.resetForm();
      this.image1InputRef.current.value = null;
      this.image2InputRef.current.value = null;
      this.image3InputRef.current.value = null;
      this.image4InputRef.current.value = null;
      this.model3DFile1InputRef.current.value = null;
      this.fetchProducts();
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: response.response.data, 
      });
    }  
    
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while Uploading the product. Please try again later.',
      });
    } finally {
      this.setState({ isLoading: false });
    }
  };
  handleSubmit = (e) => {
    if (e) e.preventDefault();
    const {
      is3DSelected
    } = this.state;
    if (is3DSelected) {
      Swal.fire({
        title: 'Confirm',
        text: 'Are you sure want to save this 2D product?',
        showCancelButton: true,
        confirmButtonText: 'Yes, save it!',
        cancelButtonText: 'No, cancel!',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.handleSubmit2d();
        }
      });
    } else {
      this.handleSubmit3d();
    }
  };
isImageFileSizeValid = (file) => {
  const maxSize = 3 * 1024 * 1024; // 3MB in bytes
  return file.size <= maxSize;
};
isImageResolutionValid = (file) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function() {
      const width = this.width;
      const height = this.height;
      if (width >= 747 && height >= 747) {
        resolve(true);
      } else {
        reject(false);
      }
    };
    img.onerror = function() {
      reject(false);
    };
    img.src = URL.createObjectURL(file);
  });
};

handleImage1Upload = async (event) => {
  const file = event.target.files[0];
   debugger;
  if (!this.isImageFileSizeValid(file)) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Image Size',
      text: 'Image size should not exceed 3MB.',
    });
    return;
  }
  try {
    await this.isImageResolutionValid(file);
    this.setState({ image1File: file });
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Image Resolution',
      text: 'Image resolution should be 747x747 pixels.',
    });
  }
};
handleImage2Upload = async (event) => {
  const file = event.target.files[0];
   debugger;
  if (!this.isImageFileSizeValid(file)) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Image Size',
      text: 'Image size should not exceed 3MB.',
    });
    return;
  }
  try {
    await this.isImageResolutionValid(file);
    this.setState({ image2File: file });
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Image Resolution',
      text: 'Image resolution should be 747x747 pixels.',
    });
  }
};
handleImage3Upload = async (event) => {
  const file = event.target.files[0];
   debugger;
  if (!this.isImageFileSizeValid(file)) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Image Size',
      text: 'Image size should not exceed 3MB.',
    });
    return;
  }
  try {
    await this.isImageResolutionValid(file);
    this.setState({ image3File: file });
  } catch (error) {
    Swal.fire({
      icon: 'error',
      title: 'Invalid Image Resolution',
      text: 'Image resolution should be 747x747 pixels.',
    });
  }
};
handleImage4Upload = async (event) => {
  const file = event.target.files[0];
  debugger;
 if (!this.isImageFileSizeValid(file)) {
   Swal.fire({
     icon: 'error',
     title: 'Invalid Image Size',
     text: 'Image size should not exceed 3MB.',
   });
   return;
 }
 try {
   await this.isImageResolutionValid(file);
   this.setState({ image4File: file });
 } catch (error) {
   Swal.fire({
     icon: 'error',
     title: 'Invalid Image Resolution',
     text: 'Image resolution should be 747x747 pixels.',
   });
 }
};
  // Add this function to the ProductSingleUpload class
isSupported3DModelFile = (file) => {
  const supportedFormats = [".obj", ".stl", ".fbx", ".gltf", ".glb", ".dae", ".igs", ".iges", ".stp", ".step"];
  const fileName = file.name.toLowerCase();
  return supportedFormats.some((format) => fileName.endsWith(format));
};

  handle3DFileUpload = (event) => {
    const file = event.target.files[0];
    if (this.isSupported3DModelFile(file)) {
      this.setState({ model3DFile: file });
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Invalid File Format',
        text: 'Please upload a supported 3D model file (OBJ, STL, FBX, GLTF, GLB, DAE, IGS, IGES, STP, STEP).',
      });
    }
  };
  handlecheck=(event)=>{
    console.log("hooiio")
  }
  
  handleCategoryChange = (event) => {
    debugger;
    const selectedCategoryId = event.target.value;
    const selectedCategory = this.state.customerCatageory.find(
      (category) => category.name === selectedCategoryId
    );

    if (selectedCategory) {
      const clusters = selectedCategory.clusters.map((cluster) => cluster.name);
      const attributes = selectedCategory.clusters[0]?.attributes || [];

      // Update the state with the selected category ID, clusters, and attributes
      this.setState({
        category: selectedCategoryId,
        selectedCluster: null, // Clear the selected cluster
        clusters: clusters,
        attributes: attributes,
        attributeValues: {}, // Clear existing attribute values
      });
    }
  };

  handleClusterItemClick = (cluster) => {
    const selectedCategory = this.state.customerCatageory.find(
      (category) => category.name === this.state.category
    );
  
    if (selectedCategory) {
      const selectedCluster = selectedCategory.clusters.find(
        (c) => c.name === cluster
      );
  
      if (selectedCluster) {
        const attributes = selectedCluster.attributes;
  
        this.setState((prevState) => {
          const updatedAttributeValues = { ...prevState.attributeValues };
          
          // Ensure the clusterName exists in the attributeValues object
          if (!updatedAttributeValues[selectedCluster.name]) {
            updatedAttributeValues[selectedCluster.name] = {};
          }
  
          // Create or update attribute values for the selected cluster
          attributes.forEach((attribute) => {
            if (!updatedAttributeValues[selectedCluster.name][attribute.name]) {
              updatedAttributeValues[selectedCluster.name][attribute.name] = {
                value: '',
                variableType: attribute.variableType,
                clusterName: selectedCluster.name,
                isHidden:false,
                name :attribute.name,
              };
            }
          });
  
          return {
            selectedCluster: selectedCluster.name,
            attributes: attributes,
            attributeValues: updatedAttributeValues,
          };
        });
      }
    }
  };
  render() {
    const { image1File, image2File, image3File, errorMessage ,model3DFile ,image4File, is3DSelected,isLoading ,customerId ,previewTextureUrl} = this.state;
  const is3DModelUploaded = model3DFile !== null;
    return (
      <div className="custom-upload-catalog-container">
         {isLoading && (
        <Loader />
      )}
        <Container fluid >
        <h1 className="upload-heading">Upload Product</h1>
          <Row className="custom-product-details-row mt-4">
            {/* First Column */}
            <Col md={6} className="custom-small-image-container">
            <Card className="custom-product-view-card mt-3" style={{ border: "1px solid #C7CFDE", backgroundColor: "#FFFFFF", width: "100%", height: "63%" }}>
            <div className="file-select-center">
                <label htmlFor="model3DFile" className="file-upload-label">
                  <FaUpload size={30} className="upload-icon" />
                  <span>Upload 3D Model...</span>
                </label>
              <input type="file" disabled={is3DSelected === true}   ref={this.model3DFile1InputRef}  accept=".fbx,.obj,.stl,.dae,.hdr,.gtlf,.glb,.tzi" onChange={this.handle3DFileUpload} id="model3DFile" />
            </div>
          </Card>
          <Row>
            <Col md={6}>
            <Card className="custom-image-card mt-3" style={{ width: "300px", height: "250px" }}>
                {/* Image 1 upload */}
                <div className='file-select-center'>
  {image1File ? (
    <img src={URL.createObjectURL(image1File)} alt="Uploaded Image 1"            className="uploaded-image"    />
  ) : (
    <>
          <label htmlFor="image1File" className="file-upload-label">
            <FaUpload size={30} className="upload-icon" />
            <span>Upload Image 1</span>
          </label>
        </>
   
  )}
  <input type="file" ref={this.image1InputRef}  style={{ width: "100%", maxWidth: "300px" }}  id="image1File" accept=".jpg,.png,.svg" onChange={this.handleImage1Upload} />
</div>
              </Card>
              <Card className="custom-image-card mt-3" style={{width: "300px", height: "250px"}}>
                <div className='file-select-center'>
                {image3File ? (
    <img src={URL.createObjectURL(image3File)} alt="Uploaded Image 1"   className="uploaded-image"  />
  ) : (
    <label htmlFor="image1File" className="file-upload-label">
      <FaUpload size={30} className="upload-icon" />
      <span>Upload Image 3</span>
    </label>
  )}
                  <input type="file"  style={{ width: "100%", maxWidth: "300px" }} ref={this.image3InputRef}  accept=".jpg,.png,.svg" onChange={this.handleImage3Upload}  />
                </div>
              </Card>
            </Col>
          <Col md={6}>
          <Card className="custom-image-card mt-3" style={{width: "300px", height: "250px"}}>
                {/* Image 2 upload */}
                <div className='file-select-center' >
                {image2File ? (
    <img src={URL.createObjectURL(image2File)} alt="Uploaded Image 1"   className="uploaded-image"  />
  ) : (
    <label htmlFor="image1File" className="file-upload-label">
      <FaUpload size={30} className="upload-icon" />
      <span>Upload Image 2</span>
    </label>
  )}
        <input type="file" ref={this.image2InputRef}  style={{ width: "100%", maxWidth: "300px" }}   accept=".jpg,.png,.svg" onChange={this.handleImage2Upload} />
                </div>
              </Card>
              <Card className="custom-image-card mt-3" style={{ width: "300px", height: "250px" }}>
                      {/* Image 4 upload */}
                      <div className='file-select-center' >
                        {image4File ? (
                          <img src={URL.createObjectURL(image4File)} alt="Uploaded Image 2" className="uploaded-image"  />
                        ) : (
                          <label htmlFor="image4File" className="file-upload-label">
                            <FaUpload size={30} className="upload-icon" />
                            <span>Upload Image 4</span>
                          </label>
                        )}
                        <input type="file" style={{ width: "100%", maxWidth: "300px" }} ref={this.image4InputRef} id="image4File" accept=".jpg,.png,.svg" onChange={this.handleImage4Upload} />
                      </div>
                    </Card>
          </Col>
          </Row>
              <Row>
              </Row>
            </Col>

            <Col md={6} className="custom-product-details">
            <Card className="custom-product-details-card mt-3" style={{ width: "100%", height: "7.6%", border: "1px solid #C7CFDE" ,color :"rgba(106, 109, 116, 1)" }}>
  <h1 className="heading-imp">Select Product Type</h1>
  <div className='tz-border-up' style={{ display: "flex" }}>
    <div className="text-left mt-2">
      <span className="model-label" style={{ color :"rgba(106, 109, 116, 1)"}}>3D File</span>
    </div>
    <div className='mt-2 '>
      <Form.Check
        type="switch"
        id="custom-switch"
        label=""
        onChange={this.handleSwitchToggle}
        checked={is3DSelected}
      />
    </div>
    <div className="mt-2">
      <span className="model-label">2D File</span>
    </div>
  </div>
</Card>
            <Card className="custom-product-details-card generaldetailscrollbar mt-3" style={{ width: "100%", height: "10%" }}>
                <h1 className="heading-imp">Customer Details</h1>
                <div className='tz-border-up'>
                <div className=''>             
                  <form className='form-manage' onSubmit={this.handleSubmit}>
                    <div className="form-row">
                      <label>Customer ID*</label>
                      <input
                        type="text"
                        name="productTitle"
                        value={this.state.customerId}
                        onChange={(e) => this.setState({ customerId: e.target.value })}
                        required
                        // className="form-manage input"
                      />
                    </div>
                  </form>
                </div>
                </div>
              </Card>
              <Card className="custom-product-details-card  generaldetailscrollbar mt-3" style={{ width: "100%", height: "30%" }}>
                <h1 className="heading-imp">Product General Details</h1>
                   {/* Error message */}
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
                <div className=''>             
                  <form className='form-manage' onSubmit={this.handleSubmit}>
                    <div className="form-row">
                      <label>Product Title:</label>
                      <input
                        type="text"
                        name="productTitle"
                        value={this.state.productTitle}
                        onChange={this.handleInputChange}
                        required
                        // className="form-manage input"
                      />
                    </div>

                    <div className="form-row">
                      <label>Select Category:</label>
                      <select
                       onClick={(e) => this.fetchAllCustomerSucessCategories(customerId)}
                        className="custom-select"
                        name="category"
                        value={this.state.category}
                        onChange={this.handleCategoryChange}
                        required
                      >
                        <option value="">Select the category</option>
                        {this.state.customerCatageory.map((category) => (
                          <option key={category.id} value={category.id}>
                            {category.name}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-row">
                      <label>SKU:</label>
                      <input
                        type="text"
                        name="sku"
                        value={this.state.sku}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-row">
                      <label>Brand Name:</label>
                      <input
                        type="text"
                        name="brandName"
                        value={this.state.brandName}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-row">
                      <label>Price:</label>
                      <input
                        type="text"
                        name="price"
                        value={this.state.price}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>
                    <div className="form-row">
                      <label>Model Name:</label>
                      <input
                        type="text"
                        name="modelName"
                        value={this.state.modelName}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-row">
                      <label>Model Number:</label>
                      <input
                        type="text"
                        name="modelNumber"
                        value={this.state.modelNumber}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-row">
                      <label>IFC Code:</label>
                      <input
                        type="text"
                        name="ifcCode"
                        value={this.state.ifcCode}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>

                    <div className="form-row">
                      <label>Search Keywords:</label>
                      <input
                        type="text"
                        name="searchKeywords"
                        value={this.state.searchKeywords}
                        onChange={this.handleInputChange}
                        required
                      />
                    </div>

                  </form>
                </div>
              </Card>
              <Card
                className="custom-product-details-card scrollbar-attach mt-4"
                style={{ width: "100%", height: "18%" }}
              >
                <h1 className="heading-imp">Product Attributes</h1>
                {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
                <div className='tz-border-up' style={{ display: "flex" }}>
                  <div className='tz-border-side' style={{ flex: 0.5 }}>
                    <h2 className="heading-imp">Cluster</h2>
                    <ul className="product-single-upload-list">
                      {this.state.clusters.map((cluster, index) => (
                        <li
                          key={index}
                          onClick={() => this.handleClusterItemClick(cluster)}
                          className={
                            this.state.selectedCluster === cluster ? "selected-cluster" : ""
                          }
                        >
                          {cluster}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div style={{ flex: 1 }}>
                    <h2 className='heading-imp'>Attributes</h2>
                    {this.state.selectedCluster && (
    <form>
      {this.state.attributes.map((attribute, index) => (
        <div className="attribute-row" key={index}>
          <label className="attribute-label1">{attribute.name}:</label>
  
          {this.state.selectedCluster === 'Color' ? (
             <div className="color-input-container">
              <div
        className="color-preview"
        style={{
          backgroundColor: this.state.attributeValues[this.state.selectedCluster]?.[attribute.name]?.value || 'transparent',
        }}
      ></div>
             <input
               type="text"
               name={`${attribute.name}`}
               value={
                this.state.attributeValues[this.state.selectedCluster]?.[attribute.name]?.value || ''
              }
               onChange={(e) =>
                 this.handleAttributeValueChange(
                   this.state.selectedCluster,
                   attribute,
                   e.target.value ,
                 )
               }
               className="attribute-input"
               placeholder={`${attribute.name}`}
             />
           </div>
          ) : this.state.selectedCluster === 'Texture' ? (
            attribute.name === 'Texture Name' ? (
              <input
                type="text"
                name={attribute.name}
                value={
                  this.state.attributeValues[this.state.selectedCluster]?.[attribute.name]?.value || ''
                }
                 onChange={(e) =>
                   this.handleAttributeValueChange(
                     this.state.selectedCluster,
                     attribute,
                     e.target.value ,
                   )
                 }
                className="attribute-input"
                placeholder={`${attribute.name}`}
              />
            ) : (
              <div className="texture-input-container">
           {previewTextureUrl && (
        <div>
          <img className="texture-preview" src={previewTextureUrl} alt="Texture Preview" />
        </div>
      )}
      <input
        type="file"
        name={attribute.name}
        onChange={(e) =>
          this.handleTextureFileChange(
            this.state.selectedCluster,
            attribute,
            e.target.files[0]
          )
        }
        className="attribute-input"
        accept=".jpg, .png, .svg"
      />
    </div>
            )
          ) : (
            <input
              type="text"
              name={attribute.name}
              value={
                this.state.attributeValues[this.state.selectedCluster]?.[attribute.name]?.value || ''
              }
               onChange={(e) =>
                 this.handleAttributeValueChange(
                   this.state.selectedCluster,
                   attribute,
                   e.target.value ,
                 )
               }
              className="attribute-input"
            />
          )}
        </div>
      ))}
    </form>
  )}

                  </div>
                </div>
              </Card>
              <Card className="custom-product-details-card mt-4" style={{ width: "100%", height: "10%" ,border: "1px solid #C7CFDE" }}>
                <h1 className="heading-imp">Product Description</h1>
                <div className='tz-border-up'>
                  <textarea
                  style={{ height: "6vh" }}
                    name="productDescription"
                    rows={5} // You can adjust the number of rows to fit the desired height of the text box
                    cols={70} // You can adjust the number of columns to fit the desired width of the text box
                    value={this.state.productDescription || ''}
                    onChange={(e) => this.setState({ productDescription: e.target.value })}
                    placeholder="Enter product description here..."
                  />
                </div>
              </Card>
              <div className='tz-arrang-button mt-4'>
                <Button variant='outlined' onClick={this.handleCancel}>
                  Cancel
                </Button>
                <Button variant='primary' onClick={this.handleSubmit}>
                  Save
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
export default SingleUploadcustomer;
