import { Component } from "react";
import { Table } from "react-bootstrap";

export default class AccordionCustBody extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { text, data } = this.props;
    console.log(data , "data chahiye mujhe");
    return (
      <>
        {text === "Product Attributes" ? (
          <Table className="tz-product-attributes">
            <tbody className="align-left">
              <tr>
                <td>Title</td>
                <td>{data?.title}</td>
              </tr>
              <tr>
                <td>SKU</td>
                <td>{data?.sku}</td>
              </tr>
              <tr>
                <td>Brand</td>
                <td>{data?.brand}</td>
              </tr>
              <tr>
                <td>Price</td>
                <td>{data?.price}</td>
              </tr>
              <tr>
                <td>Model Number</td>
                <td>{data?.modelNumber}</td>
              </tr>
              <tr>
                <td>Model Name</td>
                <td>{data?.modelName}</td>
              </tr>
              {/* {data?.additionalAttributes.map((attribute, index) =>
                <tr key={index}>
                  <td>{attribute.name}</td>
                  <td>{attribute.value}</td>
                </tr>
              )} */}
    { data?.clusters && Object.entries(data.clusters).map(([clusterName, attributes]) => (
  attributes.map((attribute, index) => {
    if (attribute.clusterName === "Color") {
      if (attribute.value !== "" ) {
        return (
          <tr key={index}>
            <td>{attribute.value}</td>
            <td>
              <div className="color-preview" style={{ backgroundColor: attribute.value }}></div>
            </td>
          </tr>
        );
      } else {
        return null;
      }
    } else if (attribute.clusterName === "Texture") {
      return (
        <tr key={index}>
          <td>{attribute.name}</td>
          <td>
            {attribute.name === "Texture Image" && attribute.value && attribute.value.startsWith("http") ? (
              <div>
                <img src={attribute.value} alt="Texture Preview" className="texture-preview" />
              </div>
            ) : (
              attribute.value || "Image not found"
            )}
          </td>
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <td>{attribute.name}</td>
          <td>{attribute.value}</td>
        </tr>
      );
    }
  })
))}



            </tbody>
            </Table>
) : text === "Product Description" ? (
  data?.description !== "undefined" && data?.description !== null ? (
    <span style={{ marginLeft: "8px" }}>{data?.description}</span>
  ) : null
) : (
  <></>
)}

      </>
    );
  }
}
