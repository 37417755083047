import React, { useState, useEffect } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import DashboardIndex from './DashboardIndex';

const DailyUsers = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // const endpoint = 'http://localhost:8080/vendors/api/v1/captureSearchData';
        const endpoint = 'https://showcaseapi.trezi.com/vendors/api/v1/captureSearchData';
        const response = await axios.get(endpoint);
        console.log(response.data); // Log the response to see its structure
        
        const result = Array.isArray(response.data)
          ? response.data
          : response.data.data || [];
  
        const reversedData = result.reverse(); // Reverse the data
        setData(reversedData); // Set the reversed data
      } catch (error) {
        setError('Error making API call');
        console.error('Error making API call:', error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, []);
  

  const convertToDate = (isoString) => {
    const date = new Date(isoString);
    return date.toLocaleDateString();
  };

  const exportToExcel = () => {
    const headers = ['ID', 'Client ID', 'Prompt', 'Request Time', 'Success', 'Failure Message', 'Product'];
    const worksheetData = [
      headers,
      ...data.map(item => [
        item.id,
        item.clientId,
        item.prompt,
        convertToDate(item.requestTime),
        item.success ? 'Yes' : 'No',
        item.failureMessage || 'N/A',
        item.product,
      ])
    ];

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Concept AI');
    XLSX.writeFile(workbook, 'ConceptAI_SearchData.xlsx');
  };

  const renderTable = () => {
    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;
    if (data.length === 0) return <p>No data available</p>;

    const headers = [ 'Client ID', 'Prompt', 'Request Time', 'Success', 'Failure Message', 'Product'];
    const rows = data.map((item, index) => (
      <tr key={index}>
        <td>{item.clientId}</td>
        <td>{item.prompt}</td>
        <td>{convertToDate(item.requestTime)}</td>
        <td>{item.success ? 'Yes' : 'No'}</td>
        <td>{item.failureMessage || 'N/A'}</td>
        <td>{item.product}</td>
      </tr>
    ));

    return (
      <div>
        <h2 id="heading">Concept AI</h2>
        <div id="button">
          <button id="exportbutton" onClick={exportToExcel}>Export to Excel</button>
        </div>
        <div className='other_table_container'>
          <table className="result-table">
            <thead>
              <tr>
                {headers.map((header, index) => (
                  <th key={index}>{header}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {rows
                .sort((a, b) => new Date(b.props.children[3]) - new Date(a.props.children[3]))} {/* Sorting by Request Time */}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

  return (
    <>
      <DashboardIndex />
      <div className='home-container'>
        <div className='options-container'>
          {/* No button required as data is fetched automatically */}
        </div>
        <div className="data-container">
          {renderTable()}
        </div>
      </div>
    </>
  );
};

export default DailyUsers;
