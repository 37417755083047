import "./financeportal.css";
import { Table, TableBody, TableCell, TableHead, TableRow, TableContainer, Box } from '@material-ui/core';
import React, { useState, useEffect } from "react";
import Navbarfinbox from "../financePortal/navbarfinbox";
import ReactPaginate from 'react-paginate';
import { Button } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { baseUrlUser} from "../utils/common";
import { getAllFinanceData ,getFinanceDataBasedOnStatus} from "../services/ProductsService";

const urlPayment = baseUrlUser;
const FinancePortal = () => {
  const [paymentData, setPaymentData] = useState([]);
  const [verifiedpaymentData, setVerifiedPaymentData] = useState([]);
  const [unverifiedpaymentData, setUnVerifiedPaymentData] = useState([]);
  const [displayedPayments, setDisplayedPayments] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [currentFilter, setCurrentFilter] = useState('all');


  

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }

    return "";
  };
  useEffect(() => {
    fetchPayments();
  }, []);
  const fetchPayments = async () => {
    try {
        const response = await getAllFinanceData(20);

        if (response.status === 200) {
            const payments = response.data.payments;
            setDisplayedPayments(payments);
            console.log("Payments data:", payments);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: `${response.response.data}`,
          });
        }
    } catch (error) {
        // Handle fetch or processing errors
        console.error('Error fetching or processing payments:', error);
    }
};

const handleFilterClick = async (Status) => {
  setCurrentFilter(Status);
debugger;
  try {
    const response = await getFinanceDataBasedOnStatus(Status , 10);
console.log(response , response)
    if (response.status === 200) {
      setDisplayedPayments(response.data.payments);
    } else {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: `${response.response.data}`,
        });
    }
  } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: `${error.response.message}`,
      });
    console.error('Error:', error.message);
  }
};


  const paginatedPaymentData = displayedPayments.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );
  const handleVerificationConfirmation = (item) => {
    if (!item.isChecked) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'Do you want to verify this payment?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, verify it!'
      }).then((result) => {
        if (result.isConfirmed) {
          // Handle the verification logic here
          handleCheckboxToggle(item.paymentId);
          Swal.fire({
            title: 'Verified!',
            text: 'The payment has been verified.',
            icon: 'success'
          });
        } else {
          // Uncheck the checkbox if the user cancels
          handleCheckboxToggle(item.paymentId);
        }
      });
    } else {
      // Handle the unverification logic here
      handleCheckboxToggle(item.paymentId);
    }
  };
  

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };
  useEffect(() => {
    document.title = 'Finanace'; // Set the desired page title
  }, []);
  const handleCheckboxToggle = async (paymentId) => {
    try {
      debugger;
      const updatedData = displayedPayments.map((item) => {
        if (item.paymentId === paymentId) {
          const updatedStatus = item.status === 0 ? 1 : 0;
          return { ...item, status: updatedStatus };
        }
        return item;
      });
      if (currentFilter === 'verified') {
        setVerifiedPaymentData(updatedData);
      } else if (currentFilter === 'unverified') {
        setUnVerifiedPaymentData(updatedData);
      } else { 
        setPaymentData(updatedData);
      }
      setDisplayedPayments(updatedData);
      if (currentFilter === 'unverified') {
        const updatedPayment = updatedData.find(item => item.paymentId === paymentId);
        if (updatedPayment && updatedPayment.status === 1) {
          const updatedVerifiedData = [...verifiedpaymentData, updatedPayment];
          setVerifiedPaymentData(updatedVerifiedData);
          const updatedUnverifiedData = unverifiedpaymentData.filter(item => item.paymentId !== paymentId);
          setUnVerifiedPaymentData(updatedUnverifiedData);
          const updatedAllPayments = paymentData.map(item => {
            if (item.paymentId === paymentId) {
              return { ...item, status: updatedPayment.status };
            }
            return item;
          });
          setPaymentData(updatedAllPayments);
        }
      }
      const jwt = getCookie('treziAccessToken');
      if (!jwt) {
        console.error('JWT token not found in cookies');
        return;
      }
      const url = `${urlPayment}payments/finance/verify?paymentId=${paymentId}`;

      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Cookie': `jwt=${jwt}`,
          'Authorization': `Bearer ${jwt}`
        }
      });
      
      if (!response.ok) {
        console.error(`Failed to update payment status: ${response.status}`);

      } else {
        const responseData =  response.ok;
        console.log('Payment update response:', responseData);
        if (responseData.status === 1 && currentFilter !== 'Verified') {
          const updatedVerifiedData = [...verifiedpaymentData, responseData.data];
          setVerifiedPaymentData(updatedVerifiedData);
        }
      }
    } catch (error) {
      console.error('Error updating payment status:', error);
    }
  };
  
  
  
  return (
    <div className="box5">
      <Navbarfinbox />
      <div className="p-4">
      <div className="row">
        <div className="mt-4">
      <Button variant="primary" onClick={() => fetchPayments()}  className="mr-2">
              All Payments
            </Button>
            <Button variant="success" onClick={() => handleFilterClick('Verified')} className="mr-2">
              Verified Payments
            </Button>
            <Button variant="danger" onClick={() => handleFilterClick('Unverified')}>
              Unverified Payments
            </Button>
        </div></div>
        <div className ="mt-2">
        <TableContainer>
          <Table>
            <TableHead >
              <TableRow class="colorful-row">
                <TableCell>Payment ID</TableCell>
                <TableCell>Email ID</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Transaction Value</TableCell>
                <TableCell>Transaction Date</TableCell>
                <TableCell>Product</TableCell>
                <TableCell>Discount Applied</TableCell>
                <TableCell>Checkbox</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {paginatedPaymentData.map((item , index) => (
                <TableRow key={index}>
                  <TableCell>{item.paymentId}</TableCell>
                  <TableCell>{item.clientId}</TableCell>
                  <TableCell>{item.product}</TableCell>
                <TableCell>{(item.amount).toLocaleString('en-IN')}</TableCell>
                  <TableCell>{new Date(item.paymentDate).toLocaleDateString()}</TableCell>
            <TableCell>{item.product}</TableCell>
            <TableCell>{item.discountValue != null ? item.discountValue.toLocaleString('en-IN') : ''}</TableCell>
                  <TableCell>
                    {item.status === "Unverified" ? (
                      <input
                        type="checkbox"
                        checked={item.isChecked}
                        // onChange={() => handleCheckboxToggle(item.paymentId)}
                        onClick={() => handleVerificationConfirmation(item)}
                      />
                    ) : (
                      <span>Verified Payment</span>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </div>
        

        {/* <ReactPaginate
          previousLabel="Previous"
          nextLabel="Next"
          breakLabel="..."
          breakClassName="break-me"
          // pageCount={Math.ceil(paymentData.length / itemsPerPage)}
          pageCount={Math.ceil(displayedPayments.length / itemsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageChange}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          activeClassName="active"
        /> */}
      </div>
    </div>
    
  );
};
export default FinancePortal;
