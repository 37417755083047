import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
} from "@material-ui/core";
import { Link, useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "@material-ui/icons";
import logolink2 from "../assests/public/trezilogowhite.png";
import profilepicture from "../assests/public/icon_profile.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { AccountCircle, Settings, ExitToApp } from "@mui/icons-material";
import logoutIcon from "../assests/public/icon_logout.png";
import profileIcon from "../assests/public/icon_profile.png";
import { baseUrlUser } from "../utils/common";

 const urlUser = baseUrlUser;
const useStyles = makeStyles((theme) => ({
  navlinks: {
    marginLeft: theme.spacing(10),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    height: "10vh",
    marginLeft: theme.spacing(6),
  },
  logoright: {
    flexGrow: "1",
    height: "3vh",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(0),
  },
  link: {
    textDecoration: "none",
    color: "grey",
    fontSize: "13px",
    marginLeft: theme.spacing(8),
    "&:hover": {
      borderBottom: "none",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f1f1f1",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      width: "auto",
    },
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: "20px",
    [theme.breakpoints.up("md")]: {
      width: "80ch",
    },
  },
  toolbar2: {
    justifyContent: "center",
    backgroundColor: "black",
  },
  iconButton: {
    padding: 0,
    backgroundColor: "black",
    // marginLeft: theme.spacing(-1),
    // marginRight: theme.spacing(2),
    color: "lightgrey",
  },
}));

function Navbarplanbox() {
  const classes = useStyles();
  const [profilePicture, setProfilePicture] = useState(
    "default-profile-picture.png"
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  // open menu handler
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // close menu handler
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getCookie = (name) => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();

      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }

    return "";
  };

  // Function to handle logout
  const handleLogout = async (e) => {
    e.preventDefault();
    debugger;
    const jwt = getCookie("treziAccessToken");
    console.log(jwt);
    if (!jwt) {
      console.error("JWT token or email not found in cookies");
      // Handle the error, display an appropriate message to the user, or redirect to the login page
      return;
    }

    try {
      const response = await fetch(
        `${urlUser}users/signout`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      if (response.ok) {
        e.preventDefault();
        navigate("/");
        console.log("User logged out successfully.");
        // Perform any additional actions after logout
      } else if (response.status === 402){
      alert("User sales@trezi.com don't have active Showcase subscription.");
        console.error("User logout failed.");
        navigate("/");
      }else{
        alert("Something went wrong.");
        navigate("/");
      }
    } catch (error) {
      console.error("An error occurred during user logout:", error);
    }
  };
  const handlehome = async (e) => {
    e.preventDefault();
    navigate("/planbox");
  };

  return (
    <AppBar className="MuiAppBar-colorPrimary" style={{ position: "sticky",padding: "0 4%", backgroundColor:"black", zIndex:"10"}}>
      <CssBaseline />
      <Toolbar
        className="nav-header"
        style={{ justifyContent: "space-between", padding:"0" }}
      >
        {/* <Typography className={classes.logo}> */}
        <img
          src={logolink2}
          alt="Logo"
          style={{ height: "100px", width: "auto" }}
          onClick={handlehome}
        />
        {/* </Typography> */}

        <div className={classes.root}>
          <IconButton>
            <img
              src={profilepicture}
              alt="Profile"
              className={classes.logoright}
              
            />
               <KeyboardArrowDownIcon
              onClick={handleMenuOpen}
              className={classes.iconButton}
            />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            PaperProps={{
              style: {
                marginTop: "40px",
              },
            }}
          >
            <MenuItem onClick={handleMenuClose} className={classes.root}>
              <ListItemIcon>
                <img
                  src={profileIcon}
                  alt="Profile"
                  className="icon-image small-icon"
                />
              </ListItemIcon>
              Profile
            </MenuItem>
            <MenuItem onClick={handleLogout} className={classes.root}>
              <ListItemIcon>
                <img
                  src={logoutIcon}
                  alt="Logout"
                  className="icon-image small-icon"
                />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
}
export default Navbarplanbox;
