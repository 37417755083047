import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  makeStyles,
  InputBase,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon 
} from "@material-ui/core";
import { Link , useNavigate } from "react-router-dom";
import { Search as SearchIcon } from "@material-ui/icons";
import "./Navbar.css";
import logolink2 from "../assests/public/trezilogowhite.png";
import logo1 from "../assests/public/icon-07.png"
import Search from "../assests/public/search_icon-08.png";
import notification from "../assests/public/notification_icon-09.png"
import profilepicture from "../assests/public/profile_icon-15.png"  
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { AccountCircle, Settings, ExitToApp } from '@mui/icons-material';
// import settingsIcon from '../assets/settings.png';
import logoutIcon from '../assests/public/icon_logout.png';
import profileIcon from '../assests/public/icon_profile.png';
import settingIcon from '../assests/public/settings.png';
import subscriptionIcon from '../assests/public/icon_My subscription.png';
import { baseUrlUser } from "../utils/common";

const urlUser = baseUrlUser;
const useStyles = makeStyles((theme) => ({
  root:{
    display: 'flex',
    '&:hover': {
      backgroundColor:  "lightblue",
      cursor: 'pointer',
    },
  },
  navlinks: {
    marginLeft: theme.spacing(10),
    display: "flex",
  },
  logo: {
    flexGrow: "1",
    cursor: "pointer",
    width :"5vh",
    // height: "5vh",
    marginLeft: theme.spacing(16),
  },
  logoright: {
    flexGrow: "1",
    // cursor: "pointer",
    height: "3vh",
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
  },
  link: {
    textDecoration: "none",
    color: "grey",
    fontSize: "16px",
    marginLeft: theme.spacing(8),
    "&:hover": {
       borderBottom: "none",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "#f1f1f1",
    "&:hover": {
      backgroundColor: "#f8f8f8",
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(30),
      width: "auto",
    },
  },

  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    height: '20px',
    [theme.breakpoints.up("md")]: {
      width: "80ch",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "grey",
  },
  toolbar2: {
    justifyContent: "center",
    backgroundColor:"black",
  },
  menuItem: {
    "&:hover": {
      "& .MuiTypography-root": {
        color: "blue", // Set your desired hover color here
      },
    },
  },
  iconButton: {
    padding: 0,
    backgroundColor:"black",
    marginLeft: theme.spacing(-1),
    marginRight: theme.spacing(2),
    color:"lightgrey"
  },
}));

function Navbar() {
  const classes = useStyles();
  const [profilePicture, setProfilePicture] = useState("default-profile-picture.png");
  const [anchorEl, setAnchorEl] = useState(null); 

  const navigate = useNavigate();

   // open menu handler
   const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // close menu handler
  const handleMenuClose = () => {
    setAnchorEl(null);
  };


  const getCookie = (name) => {
    const cookies = document.cookie.split(";");
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
  
      if (cookie.startsWith(`${name}=`)) {
        return cookie.substring(name.length + 1);
      }
    }
  
    return "";
  }; 

    // Function to handle logout
    const handleLogout = async (e) => {
      e.preventDefault();
      debugger;
      const jwt = getCookie('jwt');
      console.log(jwt);
      if (!jwt) {
        console.error('JWT token or email not found in cookies');
        // Handle the error, display an appropriate message to the user, or redirect to the login page
        return;
      }
    
      try {
        const response = await fetch(`${urlUser}signout`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Cookie': `jwt=${jwt}`,
            'Authorization': `Bearer ${jwt}`
          }
        });
    
        if (response.ok) {
          document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
          e.preventDefault();
          navigate("/");
          console.log('User logged out successfully.');
          // Perform any additional actions after logout
        } else {
          // Logout failed
          console.error('User logout failed.');
        }
      } catch (error) {
        console.error('An error occurred during user logout:', error);
      }
    };

    const handleMyProfile = async (e) => {
      e.preventDefault();
       debugger;
    navigate("/adduser");
    };
    const handleMySubscription = async (e) => {
      e.preventDefault();
      navigate('/mysubscription'); 
     
    };

    const handleMySetting = async (e) => {
      e.preventDefault();
      navigate('/userlist'); 
     
    };
    const handlehome = async (e) => {
      e.preventDefault();
      navigate('/dashboard'); 
     
    };

  return (
    <AppBar className="MuiAppBar-colorPrimary">
      <CssBaseline />
      <Toolbar className="nav-header">
        
      <Typography className={classes.logo}>
      <img src={logolink2} alt="Logo" style={{ height: '100px', width: 'auto' }} onClick={handlehome} />
        </Typography>

        <Typography>
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: classes.inputRoot,
              input: classes.inputInput,
            }}
            inputProps={{ "aria-label": "search" }}
          />
        </div>
        </Typography>

        <div className={classes.navlinks}>
          <Link to="/contact" className={classes.link}>
          <img src={notification} alt="Logo" className={classes.logoright} />
          </Link>
          </div>
          <div>  
            <IconButton >
        <img src={profilepicture} alt="Profile" className={classes.logoright} />
        <KeyboardArrowDownIcon onClick={handleMenuOpen} className={classes.iconButton} />
      </IconButton>
      <Menu  className={classes.menuItem}
  anchorEl={anchorEl}
  keepMounted
  open={Boolean(anchorEl)}
  onClose={handleMenuClose}
  PaperProps={{
    style: {
      marginTop: '40px',
    },
  }}
>
  <MenuItem onClick={handleMyProfile}  className={classes.root} >
    <ListItemIcon>
    <img src={profileIcon} alt="Profile"   className="icon-image small-icon" />
    </ListItemIcon>
    My Profile   
  </MenuItem>
  <MenuItem onClick={handleMySubscription}  className={classes.root}>
    <ListItemIcon>
    <img src={subscriptionIcon} alt="Subscription"   className="icon-image small-icon" />
    </ListItemIcon>
   My Subscription
  </MenuItem>
  <MenuItem onClick={handleMySetting}  className={classes.root}>
    <ListItemIcon>
    <img src={settingIcon} alt="Setting"   className="icon-image small-icon" />
    </ListItemIcon>
  User Management
  </MenuItem>
  <MenuItem onClick={handleLogout}  className={classes.root}>
    <ListItemIcon>
    <img src={logoutIcon} alt="Logout"   className="icon-image small-icon" />
    </ListItemIcon>
    Logout
  </MenuItem>
</Menu>

    </div>
      </Toolbar>
      <Toolbar   className={classes.toolbar2}>
  <Link to="/dashboard" className={classes.link}>
    Home
  </Link>
  <Link to="/product-catalog" className={classes.link}>
    Product Catalog
  </Link>
</Toolbar>

    </AppBar>
  );
}
export default Navbar;
