import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import rejectionIcon from '../assests/public/group_3275_360.png'; // Replace with your actual rejection icon path
import './CustomerSuccessTable.css'; // Create a separate CSS file for styling

const RejectionModal = ({ show, onClose, message }) => {
  return (
    <Modal show={show} centered onHide={onClose}>
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center mt-4">
        <div className="text-center mt-4">
          <img className="mt-4" src={rejectionIcon} alt="rejection" width={70} height={70} />
          <div className='Message-1 mt-2'>{message}</div>
        </div>
      </Modal.Body>

      <div className="d-flex justify-content-center mb-4">
        <Button className="error-btn" onClick={onClose} >
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default RejectionModal;
