import React from 'react';
import { Modal, Button } from 'react-bootstrap'; // Import necessary components
import successIcon from "../../src/assests/public/Group 3323.png"
import './CustomerSuccessTable.css';
const SuccessModal = ({ show, onClose, message }) => {
  return (
    <Modal show={show} centered onHide={onClose}   >
      {/* <Modal.Header closeButton>
        <Modal.Title>Success</Modal.Title>
      </Modal.Header> */}
      <Modal.Body className="d-flex flex-column align-items-center justify-content-center mt-4">
        <div className="text-center mt-4">
          <img  className="mt-4" src={successIcon} alt="success" width={70} height={70} />
          <div  className='Message-1 mt-2'>{message}</div>
        </div>
      </Modal.Body>

      <div  className="d-flex justify-content-center mb-4">
        <Button variant="primary" onClick={onClose} >
          OK
        </Button>
      </div>
    </Modal>
  );
};

export default SuccessModal; // Make sure to export the component

  