import React, { Component } from "react";
import logolink2 from "../assests/public/landing_page__1_.jpg";
import "./Dashboard.css";
import Header from "../components/Header/Header";
import { fetchRole, getRole } from "../services/ProductsService";
const getCookie = (name) => {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }

  return "";
};
class Dashboard extends Component {
  state = {
    searchQuery: "",
    role: "",
  };
   
  componentDidMount() {
    this.handleGetRole();
    document.title = "Dashboard"; // Set the page title dynamically
  }
  handleSearch = (query) => {
    this.setState({ searchQuery: query });
  };

  handleGetRole = async () => {
    try {
 const getRole = localStorage.getItem('role');
      this.setState({ role: getRole });
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const { searchQuery, role } = this.state;

    return (
      <>
        <div className="box5 dashboard-container">
          <div className="p-4 box7">
            <div className="row">
              <div className="col-12">
                <img src={logolink2} alt="Logo" className="image-with-border" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Dashboard;
