import React, { Component } from "react";
import "../css/ProductCatalog.css";
import { Button, Dropdown, Stack , Form } from "react-bootstrap";
import ProductTable from "../components/ProductTable";
import {getCategoriesForfilter , ProductListOnthebaseOfcategories} from "../services/ProductsService";
import Swal from 'sweetalert2';
import  Filter  from "../assests/public/Filter-01-01.png";
import Cookies from 'js-cookie';
export default class ProductCatalog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewOption: "List", // Default selected option
      shareCount: "",
      categories: [], // Holds the fetched category data
      selectedCategories: [], 
      showDropdown: false,
      filteredProducts: [],
      MetaData : {},
      page : 1,
      pageSize :12,
      currentPage: 1,
    };
  }
  componentDidMount() {
    document.title = "Product-Catalogue"; // Set the page title dynamically
    this.fetchCategories();
  }
  handleShareCount = (count) => {
    this.setState({ shareCount: count });
  };

  async fetchCategories() {
    debugger;
    try {
      const response  = await getCategoriesForfilter();
      if (response) {
       console.log(response.data , "response"); 
        const categories = response.data;
        this.setState({ categories });   
      } else {
        Swal.fire({
          title: 'Error!',
          text: 'Failed to fetch the Categories.',
          icon: 'error',
        });
      }
    } catch (err) {
      console.error(err);
      if(err.response.status === 401){
        Swal.fire({
          icon: 'Unauthorised',
          title: 'Error fetching products',
          text: 'Error fetching products'
        });
        window.location.href = '/'; // Redirect to the login page
      }else {
        Swal.fire({
          icon: 'error',
          title: 'Error fetching Products/Categories',
          text: 'Error fetching products'
        });
      }
    }
  }
  handleViewOptionChange = (option) => {
    this.setState({ viewOption: option });
  };
  handleSingleUploadClick = () => {
    // Use window.location.href to navigate to the "ProductSingleUpload" page
    window.location.href = "/ProductSingleUpload";
  };
  handleBulkUploadClick=()=>{
    window.location.href = "/bulk-upload";
  }
  toggleDropdown = () => {
    this.setState(prevState => ({
      showDropdown: !prevState.showDropdown
    }));
  }

  handleClear = () => {
    this.setState({ selectedCategories: [],showDropdown: false ,filteredProducts:[] , MetaData :{} , page : 1 });
  };
  handleCategorySelection = (category) => {
    const { selectedCategories } = this.state;
    const selectedIndex = selectedCategories.indexOf(category);
    let newSelected = [];
  debugger;
    if (selectedIndex === -1) {
      newSelected = [...selectedCategories, category];
      this.setState({ selectedCategories: newSelected, showDropdown: true });
    } else if (selectedIndex === 0) {
      newSelected = [...selectedCategories.slice(1)];
      this.setState({ selectedCategories: newSelected, showDropdown: true });
    } else if (selectedIndex === selectedCategories.length - 1) {
      newSelected = [...selectedCategories.slice(0, -1)];
      this.setState({ selectedCategories: newSelected, showDropdown: true });
    } else if (selectedIndex > 0) {
      newSelected = [
        ...selectedCategories.slice(0, selectedIndex),
        ...selectedCategories.slice(selectedIndex + 1),
      ];
      this.setState({ selectedCategories: newSelected, showDropdown: true });
    }
    this.setState({ selectedCategories: newSelected, showDropdown: true });
  };
  
  handleApply = async (page) => {
    const { selectedCategories , pageSize } = this.state;
    this.setState({showDropdown: false })
  if(selectedCategories){
    try {
      debugger;
      const response  = await ProductListOnthebaseOfcategories(selectedCategories ,page , pageSize);
       console.log(response ,"rhgjlk")
      if (response.status === 200) {
        this.setState({ filteredProducts: response.data.products ,MetaData : response.data.meta , showDropdown: false ,selectedCategories: selectedCategories });
      } else {
        console.error('Failed to send categories to API');
      }
    } catch (error) {
      this.setState({ showDropdown: false ,selectedCategories: [] });
    }
  }else{
    console.error('Failed to send categories to API');
  }
  };
  
  render() {
    const { viewOption  , categories, selectedCategories ,showDropdown , filteredProducts ,page, MetaData} = this.state;
    const { searchQuery  } = this.props;
    return (
      <>
        <div className="tz-product-catalog-container">
          <Stack
            direction="horizontal"
            gap={3}
            className={`${
              viewOption === "List"
                ? "list-dashboard-header"
                : "grid-dashboard-header"
            }`}
          >
            <div className="custom-font-20 product-catalog">
              Product Catalog
            </div>
            <div className="ms-auto tz-list-dropdown">
              <Dropdown>
                <Dropdown.Toggle id="dropdown-basic">
                  View By: {viewOption}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => this.handleViewOptionChange("List")}
                    active={viewOption === "List"}
                  >
                    View By: List
                  </Dropdown.Item>
                  <Dropdown.Item
                    onClick={() => this.handleViewOptionChange("Grid")}
                    active={viewOption === "Grid"}
                  >
                    View By: Grid
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div >
              <Dropdown >
                <Dropdown.Toggle >
               Upload
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={this.handleSingleUploadClick}
                    // active={viewOption === "List"}
                  >
                   Single Upload
                  </Dropdown.Item>
                  <Dropdown.Item
                   onClick={this.handleBulkUploadClick}
                  >
                  Bulk Upload
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            {/* <div>
              <Button className="tz-primary-btn">Bulk Upload</Button>
            </div> */}
            
          </Stack>
          <div className="mt-4" style={{ display: 'flex', alignItems: 'center'  }}>
        <span className="catalog-custom-font" style={{ marginRight: '10px' }}><span><img src={Filter} alt="Filter"  style={{ marginRight: '3px' }} width={14} /></span>Filter</span>
        <Dropdown show={showDropdown} onToggle={this.toggleDropdown}>
          <Dropdown.Toggle  className="catalog-custom-button" id="dropdown-basic">
            Product Category
          </Dropdown.Toggle>

          <Dropdown.Menu style={{ minWidth: '220px' , maxWidth :"250px" }}>
            <div className="prod-caategory">Product Categories</div>
          {categories.map(category => (
  <Dropdown.Item key={category}    onClick={(e) => {
    e.stopPropagation();
    this.handleCategorySelection(category);
  }} >
    <label>
      <input 
        checked={selectedCategories.includes(category)}
        type="checkbox"  
        value={category}  // Ensure value corresponds to the category
        onClick={(e) => {
          // Prevent the click event from propagating to the dropdown
          e.stopPropagation();
          this.handleCategorySelection(category);
        }}
      /> 
     <span className="catalog-spacing">{category}</span> 
    </label>
  </Dropdown.Item>
))}

            {/* <Dropdown.Divider />          */}
              <Button variant="secondary" style={{maxHeight :"30px" , minWidth:"45px" , marginRight:"5px" , marginLeft :"11px"}} className="tz-primary-btn reverse" onClick={this.handleClear}>Clear</Button>
              <Button type='submit' style={{maxHeight :"30px" , minWidth:"50px"}} variant="secondary" className="cat-primary-btn"  onClick={() => this.handleApply(page)}
>Apply</Button>        
          </Dropdown.Menu>
        </Dropdown>
      </div>
          <ProductTable
            view={viewOption}
            searchQuery={searchQuery}
            setShareCount={this.handleShareCount}
            filteredProducts={filteredProducts}
            MetaData ={MetaData}
            selectedCategories ={selectedCategories}
            handleApply ={this.handleApply}
          />
        </div>
      </>
    );
  }
}
