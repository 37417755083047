import loader from "../context/loader.gif";
import "./loader.css";

const Loader = () => {
  return (
    <div className="loader-contentV1">
      <div className="loader">
        <div></div>
        <h5></h5>
      </div>
    </div>
  );
};

export default Loader;