import React, { Component } from 'react';
import './BulkuploadForcsm.css';
import { Button, Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faUpload, faFileArchive, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import { getClientCsmCategories, downloadProductxslxfileforCsm, uploadTemplatefilesforCsm, uploadTemplatefiles } from "../services/ProductsService";
import Download from "../../src/assests/public/Download (1).png"
import GreenDownload from "../../src/assests/public/Download Template done.png"
import Upload from "../../src/assests/public/after download  template_Upload.png"
import GreyUpload from "../../src/assests/public/Grey blur Upload (1).png"
import Excel from "../../src/assests/public/Excel File.png"
import Zip from "../../src/assests/public/Zip File.png"
import fileUpload from "../../src/assests/public/Upload files.png"
import { useParams } from "react-router-dom";

function withParams(Component) {
    return (props) => <Component {...props} params={useParams()} />;
}
class BulkUploadforCsm extends React.Component {
    constructor(props) {
        super(props);
        this.excelFile = React.createRef();
        this.zipFile = React.createRef();
        this.state = {
            customerCatageory: [],
            selectedCategories: [],
            showFileUpload: false,
            excelFile: null,
            zipFile: null,
            searchQuery: '',
            uploadingFiles: false,
        };
    }

    fetchAllCustomerCategories = () => {
        debugger;
        const { bulkUploadClientId } = this.props.params;
        getClientCsmCategories(bulkUploadClientId)
            .then((res) => {
                this.setState({ customerCatageory: res.data });
            })
            .catch((error) => {
                console.error('Error retrieving clusters:', error);
            });
    };
    handleSearch = (event) => {
        const searchQuery = event.target.value;
        this.setState({ searchQuery });
    };
    handlecategory = (e) => {
        // window.location.href = "/product-category";
        Swal.fire({
            icon: 'error',
            title: 'Select Category',
            text: 'User has not selected any category.',
        });
    }
    handleNextButtonClick = () => {
        const { selectedCategories } = this.state;
        if (selectedCategories.length === 0) {
            Swal.fire({
                icon: 'error',
                title: 'Select Category',
                text: 'Please select a category before proceeding to the next step.',
            });
            return;
        }
        this.setState({ showFileUpload: true });
    };
    handleCancel=()=>{
        window.location.href = "/CustomerSuccessTable";   
    }
    componentDidMount() {
        document.title = 'Bulk-Upload-Csm';
        this.fetchAllCustomerCategories();
    }
    handleCategorySelect = (category) => {
        debugger;
        const { selectedCategories } = this.state;

        if (selectedCategories.includes(category)) {
            this.setState({ selectedCategories: [] }); // Unselect the category if already selected
        } else {
            this.setState({ selectedCategories: [category] }); // Select the category and unselect others
        }
    };
    handleExcelFileChange = (event) => {
        const excelFile = event.target.files[0];
        this.setState({ excelFile });
    };

    handleZipFileChange = (event) => {
        const zipFile = event.target.files[0];
        this.setState({ zipFile });
    };

    downloadTemplate = async () => {
        const { selectedCategories } = this.state;
        const { bulkUploadClientId } = this.props.params;
        if (selectedCategories.length === 0) {
            return;
        }

        const label = selectedCategories[0].label;
        try {
            debugger;
            await downloadProductxslxfileforCsm(label, bulkUploadClientId);
            Swal.fire({
                icon: 'success',
                title: 'Download Successful',
                text: `Template downloaded successfully.`,
            });
        } catch (error) {
            if (error.message === 'Authorization failed') {
                Swal.fire({
                    icon: 'error',
                    title: 'Authorization Failed',
                    text: 'You are not authorized to download this file.',
                });
                this.setState({ selectedCategories: [] });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Download Failed',
                    text: 'Error',
                });
            }
        }
    };

    UploadfilesTemplate = async () => {
        debugger;
        const { selectedCategories, excelFile, zipFile } = this.state;
        if (selectedCategories.length === 0 || !excelFile || !zipFile) {
            Swal.fire({
              icon: 'error',
              title: 'Invalid Files',
              text: 'Please select both Excel and Zip files before uploading.',
            });
            return;
          }
        const { bulkUploadClientId } = this.props.params;
        const label = selectedCategories[0].label;
        const formData = new FormData();
        formData.append('excelFile', excelFile);
        formData.append('assetsZipFile', zipFile);
        try {
            this.setState({ uploadingFiles: true }); 
            await uploadTemplatefilesforCsm(formData, label, bulkUploadClientId); // Assuming this function handles the API call
            Swal.fire({
                icon: 'success',
                title: 'Files received.',
                text: "Files received. Processing will start shortly.",
            });
            this.setState({ excelFile: null, zipFile: null ,uploadingFiles: false,});
            this.excelFile.current.value = null;
            this.zipFile.current.value = null;
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Uploading Failed',
                text: "Error",
            });
            this.setState({ excelFile: null, zipFile: null ,uploadingFiles: false, });
        }
    };
    render() {
        const { customerCatageory, selectedCategories, showFileUpload, assetsZipFile ,uploadingFiles} = this.state;
        return (
            <div className="bulk-big-container">
                <div className="bulk-table-container">
                    <div className="bulk-tz-heading">Upload Bulk Product </div>
                    {showFileUpload ? (
                        <div className="bulk-one-big-box">
                            <div className="bulk-big-box">
                                <div className="first-nested-box">
                                    <div className='bulk-icon-container' >
                                        <div className='icon-text'>
                                            <img src={GreenDownload} className='download-icon' width={40} />
                                            <div className='text'>Download Template</div>
                                        </div>
                                        <div className='borderline'></div>
                                        <div className='icon-text'>
                                            <img src={Upload} className='upload-icon' width={40} />
                                            <div className='text'>Upload Files</div>
                                        </div>
                                    </div>
                                </div>
                                <div></div>
                                <div className="nested-box mt-4 fileupload-heading">
                                Upload Template File & Asset Zip Folder
                                </div>
                                <div className="files-nested-box mt-4">
                                    <div className='fileupload-Container'>
                                        <div className='fileUpload-text'>
                                            <img src={Excel} width={20} />
                                        </div>
                                        <div className='fileUpload-text'>
                                            Select excel file
                                        </div>
                                        <div className='fileUpload-text'>
                                            <input
                                                type="file"
                                                accept=".xlsx"
                                                onChange={this.handleExcelFileChange}
                                                ref={this.excelFile}
                                            />
                                        </div>
                                        <div className='fileUpload-borderline'></div>
                                        <div className='fileUpload-text'>
                                            <img src={fileUpload} className='upload-icon' width={15} />
                                        </div>
                                    </div>
                                </div>
                                <div className="files-nested-box mt-4">
                                    <div className='fileupload-Container'>
                                        <div className='fileUpload-text'>
                                            <img src={Zip} width={20} />
                                        </div>
                                        <div className='fileUpload-text'>
                                            Select asset zip file
                                        </div>
                                        <div className='fileUpload-text'>
                                            <input
                                                type="file"
                                                accept=".zip"
                                                onChange={this.handleZipFileChange}
                                                ref={this.zipFile}
                                            />
                                        </div>
                                        <div className='fileUpload-borderline'></div>
                                        <div className='fileUpload-text'>
                                            <img src={fileUpload} className='upload-icon' width={15} />
                                        </div>
                                    </div>
                                </div>
                                <div className="button-nested-box mt-4">
                                    <Button className='file-upload-button' onClick={this.UploadfilesTemplate}>Upload File</Button>
                                </div>
                                <div className="mt-3">
                {uploadingFiles && (
          <div className='fileupload-heading'>
            Please wait for some time, Your files are currently uploading...
          </div>
        )} 
                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="bulk-one-big-box">
                            <div className="bulk-big-box">
                                <div className="first-nested-box">
                                    <div className='bulk-icon-container' >
                                        <div className='icon-text'>
                                            <img src={Download} className='download-icon' width={40} />
                                            <div className='text'>Download Template</div>
                                        </div>
                                        <div className='borderline'></div>
                                        <div className='icon-text'>
                                            <img src={GreyUpload} className='upload-icon' width={40} />
                                            <div className='text'>Upload Files</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="nested-box">
                                    <div className='bulk-tz-first-heading'>
                                        Select a category to download template
                                    </div>
                                    <div>
                                        <Form className="d-flex tz-bulk-textbox-with-icon i tz-bulk-search-textbox-container">
                                            <Form.Control
                                                type="search"
                                                placeholder="Search Category"
                                                className="tz-textbox"
                                                aria-label="Search"
                                                value={this.state.searchQuery}
                                                onChange={this.handleSearch}
                                            />
                                            <i className="bi bi-search"></i>
                                        </Form>
                                    </div>
                                </div>
                                <div className="nested-nested-box  mb-2">
                                    {customerCatageory.length > 0 ? (
                                        <div>
                                            <ul className="category-list">
                                                {customerCatageory
                                                    .filter(category =>
                                                        category.name.toLowerCase().includes(this.state.searchQuery.toLowerCase())
                                                    ).map((category, index) => (
                                                        <div key={index} className='bulk-category-container'>
                                                            <div className='cat-text'>
                                                                <li>   <input
                                                                    type='radio'
                                                                    checked={selectedCategories.includes(category)}
                                                                    onChange={() => this.handleCategorySelect(category)}
                                                                /><span className='imp-space'>{category.name}</span></li>
                                                            </div>
                                                            <div className='category-borderline'></div>
                                                            <div className='cat-text'>
                                                                <Button
                                                                    variant="outlined"
                                                                    className={`down-btn ${selectedCategories.includes(category) ? '' : 'disabled'}`}
                                                                    onClick={this.downloadTemplate}
                                                                >
                                                                    <span className='bulk-fa-download'>
                                                                        <FontAwesomeIcon icon={faDownload} /><span className='imp-space'>Download Template</span>
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                    ))}
                                            </ul>
                                        </div>
                                    ) : (
                                        <div className='blank-nested-nested-box' >
                                            <div className='bulk-heading mt-5'>
                                                Not yet a product category
                                            </div>
                                            <div className='bulk-second-heading  mt-2'>
                                                Go to Product Categories and copy Category into my product category.
                                            </div>
                                            <div className='mt-3 mb-4'>
                                                <Button className='bulk-btn' onClick={this.handlecategory}>
                                                    Go to Product Category
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div>
                                </div>
                            </div>
                            <div className='bulk-button-shift'>
                                <div className='bulk-btn-container'>
                                    <Button className="white-cancel-button" onClick={this.handleCancel} >Cancel</Button>
                                    <Button onClick={this.handleNextButtonClick} disabled={selectedCategories.length === 0}>Next</Button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
export default withParams(BulkUploadforCsm);
