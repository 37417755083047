import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './DashboardPortal.css';
import UserImage from "../assets/UserImage.png";
import Swal from 'sweetalert2';
import logoutIcon from "../assests/public/icon_logout.png";
import { baseUrlUser } from "../utils/common";
import profileIcon from "../assests/public/icon_profile.png";

const urlUser = baseUrlUser;

function getCookie(name) {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();

    if (cookie.startsWith(`${name}=`)) {
      return cookie.substring(name.length + 1);
    }
  }
}

const Dropdown = ({ title, items, isOpen, onToggle, onClose, customButton }) => {
  const navigate = useNavigate();

  const handleItemClick = (item) => {
    if (item.onClick) {
      item.onClick();
    } else {
      navigate(item.path);
    }
    onClose(); // Close the dropdown after selecting an item
  };

  return (
    <div className="dropdown">
      <button className="dropbtn" onClick={onToggle}>
        {customButton || title}
      </button>
      {isOpen && (
        <div className="dropdown-content">
          {items.map((item, index) => (
            <button key={index} onClick={() => handleItemClick(item)}>
              {item.icon && <img src={item.icon} alt={item.label} className="dropdown-icon" />}
              {item.label}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

function DashboardIndex() {
  const [openDropdown, setOpenDropdown] = useState(null);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const handleToggleDropdown = (dropdownName) => {
    setOpenDropdown(openDropdown === dropdownName ? null : dropdownName);
  };

  const handleLogout = async () => {
    const treziAccessToken = getCookie("treziAccessToken");
    if (!treziAccessToken) {
      console.error("JWT token or email not found in cookies"); 
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'JWT token or email not found in cookies',
      });
      return;
    }
  
    try {
      const response = await fetch(`${urlUser}users/logout`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Cookie: `treziAccessToken=${treziAccessToken}`,
          Authorization: `Bearer ${treziAccessToken}`,
        },
      });
  
      if (response.ok) {
        document.cookie = "trezi-access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "orgIdentifier=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "role=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        document.cookie = "treziAccessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        localStorage.removeItem("role");
        localStorage.removeItem("treziAccessToken");
        localStorage.removeItem("organizationIdentifier");
        navigate("/");
        console.log("User logged out successfully.");
      } else {
        console.error("User logout failed");
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'User logout failed, JWT Token expired.',
        });
        window.location.href = "/";
      }
    } catch (error) {
      console.error("An error occurred during user logout:", error);
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred during user logout',
      });
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="App" ref={dropdownRef}>
      <header className="App-header">
        <nav className="navbar">
          <div className="navbar-left">
            <img src="/LOGO.png" className="App-logo" alt="Company Logo" />
          </div>
          <div className="navbar-right">
            <ul className="navbar-links">
              <li>
              <Dropdown
                  title="Daily Balance"
                  items={[
                    { label: 'Overview', path: '/client-overview' },
                    { label: 'Details', path: '/client-details' }
                    // { label: 'Showcase SKU', path: '/showcase-SKU' },
                  ]}
                  isOpen={openDropdown === 'dailybalance'}
                  onToggle={() => handleToggleDropdown('dailybalance')}
                  onClose={() => setOpenDropdown(null)}
                />
                <Dropdown
                  title="Features Count"
                  items={[
                    { label: 'Flipbook Used', path: '/flipbook-used' },
                    { label: 'Concept AI', path: '/concept-ai' },
                    { label: 'Showcase SKU', path: '/showcase-SKU' },
                  ]}
                  isOpen={openDropdown === 'features'}
                  onToggle={() => handleToggleDropdown('features')}
                  onClose={() => setOpenDropdown(null)}
                />
              </li>
              <li>
                <Dropdown
                  title="Active Users"
                  items={[
                    { label: 'Detailed Daily Active Users', path: '/detailed-daily-active-users' },
                    { label: 'Daily Active Users', path: '/daily-active-users' },
                    { label: 'Weekly Active Users', path: '/weekly-active-users' },
                    { label: 'Monthly Active Users', path: '/monthly-active-users' },
                  ]}
                  isOpen={openDropdown === 'activeUsers'}
                  onToggle={() => handleToggleDropdown('activeUsers')}
                  onClose={() => setOpenDropdown(null)}
                />
              </li>
              <li>
                <Dropdown
                  title="Signed Up Users"
                  items={[
                    { label: 'Lens', path: '/lens-signedup' },
                    { label: 'Showcase', path: '/showcase-signedup' },
                  ]}
                  isOpen={openDropdown === 'signedUpUsers'}
                  onToggle={() => handleToggleDropdown('signedUpUsers')}
                  onClose={() => setOpenDropdown(null)}
                />
              </li>
              <li id="nav_usage">
                <span 
                  className="usage-link" 
                  id="nav_usage_link" 
                  onClick={() => navigate('/trezi-dashboard')}
                  style={{ cursor: 'pointer' }}
                >
                  Usage
                </span>
              </li>
              <li id="nav_profile">
                <Dropdown
                  customButton={<img src={UserImage} alt="Profile Photo" className="profile-photo" />}
                  items={[
                    { label: 'Logout', onClick: handleLogout, icon: logoutIcon }
                  ]}
                  isOpen={openDropdown === 'profile'}
                  onToggle={() => handleToggleDropdown('profile')}
                  onClose={() => setOpenDropdown(null)}
                />
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </div>
  );
}

export default DashboardIndex;
