import React, { Component } from 'react';
import "./ProductCategory.css";
import { Form, Button, Modal, FormCheck, OverlayTrigger, Tooltip } from "react-bootstrap";
import Swal from 'sweetalert2';
import Loader from "../../context/loader";
import mycategory from "../../assets/attribute.svg";
import category from "../../assets/category.svg";
import { baseUrl } from '../../utils/common';
import { CreateCategory, getAllpublishedCategories, getAllpublishedsuperCategories, getAllClusters, getAllCustomerCategories, deleteCustomerCategory, getSinglecustomerCategory, updateCustomerCategory, moveCategory } from "../../services/ProductsService";
import CopyIcon from "../../assests/public/imp_copy_icon.png"
import ArrowIcon from "../../assests/public/Layer_1-1.png"
import subcategory from "../../assests/public/Sub-Category.png"
import { Edit as EditIcon, Delete as DeleteIcon, ArrowDownward } from '@material-ui/icons';

const url = baseUrl;
class ProductCategory extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      categoryName: "",
      categories: [], // Added state for categories
      superCategories: [],
      selectedCluster: [],
      showsubcategoryModal: false,
      clusters: [],
      customerCatageory: [],
      clusterSearchQuery: "",
      selectedSubcategoryName: "",
      showCreatesubcategoryModal: false,
      SubcategoryName: "",
      isEdit: false,
      searchQuery: "",
      searchSubcatQuery: "",
    };
  }

  componentDidMount() {
    // Fetch all attributes when the component mounts
    document.title = 'Product-Category';
    this.fetchSuperCategories();
    this.fetchAllClusters();
    this.fetchAllCustomerCategories();
  }

  handleCreateClick = () => {
    this.setState({ showModal: true, });
  };

  // Function to handle the modal close event
  handleCloseModal = () => {
    this.setState({ showModal: false, categoryName: "", SubcategoryName: "" });
  };
  handleSubcategoryCloseModal = () => {
    this.setState({ showsubcategoryModal: false, SubcategoryName: "", categoryName: "" });
  };
  handleSubcategoryIconClick = (category) => {
    this.setState({ showsubcategoryModal: true, categoryName: category.name, isEdit: false, });
  };
  handleCreatesubcategoryClick = (category) => {
    debugger;
    this.setState({ showCreatesubcategoryModal: true, categoryName: category.name });
  }
  handleCreateSubcategoryCloseModal = () => {
    this.setState({ showCreatesubcategoryModal: false, SubcategoryName: "", categoryName: "" });
  };

  handleSubmitCategory = async () => {
    const { categoryName } = this.state;
    const enteredValue = categoryName.replace(/\s+/g, '_').toLowerCase();

    try {
      const categoryData = {
        name: categoryName,
        label: enteredValue,
        parentCategoryName: categoryName,
      };

      const response = await CreateCategory(categoryData);
      if (response === 201) {
        Swal.fire('Success', 'Thanks for your request, shortly you will receive the confirmation in your mail', 'success');
      }else {
        console.error('Error creating category:',);
      Swal.fire('Error', `Failed to create category`, 'error');
      }
      this.setState({ categoryName: '', showModal: false });
      // this.fetchAllCustomerCategories();
    } catch (error) {
      console.error('Error creating category:', error);
      Swal.fire('Error', `Failed to create category: ${error.response.data.name}`, 'error');
    }
  };
  handleSubmitSubCategory = async () => {
    const { categoryName, SubcategoryName } = this.state;
    debugger;
    const enteredValue = SubcategoryName.replace(/\s+/g, '_').toLowerCase();
    try {
      // Make the API call to create a cluster
      const categoryData = {
        name: SubcategoryName,
        label: enteredValue,
        parentCategoryName: categoryName,
      };
      const response = await CreateCategory(categoryData);
      if (response === 201) {
        Swal.fire('Success', 'Thanks for your request, shortly you will receive the confirmation in your mail', 'success');
      }
      this.setState({ SubcategoryName: '', showCreatesubcategoryModal: false });
      this.fetchAllCustomerCategories();
    } catch (error) {
      console.error('Error creating category:', error);
      Swal.fire('Error', `Failed to create Subcategory: ${error.response.data.name}`, 'error');
    }
  };
  toggleSubcategories = (superCategoryId) => {
    this.setState((prevState) => ({
      superCategories: prevState.superCategories.map((superCategory) =>
        superCategory.label === superCategoryId
          ? { ...superCategory, showSubcategories: !superCategory.showSubcategories }
          : superCategory
      )
    }));
  };

  toggleChildCategories = (superCategoryId, subcategoryId) => {
    debugger;
    const { selectedSubcategory } = this.state;
    this.setState((prevState) => ({
      superCategories: prevState.superCategories.map((superCategory) => {
        if (superCategory.childCategories) {
          return {
            ...superCategory,
            childCategories: this.toggleChildCategoriesRecursive(superCategory.childCategories, subcategoryId)
          };
        }
        return superCategory;
      })
    }));
  };


  toggleChildCategoriesRecursive = (categories, subcategoryId) => {
    return categories.map((category) => {
      if (category.name === subcategoryId) {
        return {
          ...category,
          showChildCategories: !category.showChildCategories
        };
      }
      if (category.childCategories && category.childCategories.length > 0) {
        return {
          ...category,
          childCategories: this.toggleChildCategoriesRecursive(category.childCategories, subcategoryId)
        };
      }
      return category;
    });
  };

  handleCategoryNameChange = (event) => {
    this.setState({ categoryName: event.target.value });
  };
  handleSubCategoryNameChange = (event) => {
    this.setState({ SubcategoryName: event.target.value });
  };
  fetchAllClusters = () => {
    debugger;
    getAllClusters()
      .then((data) => {
        this.setState({ clusters: data });
      })
      .catch((error) => {
        console.error('Error retrieving clusters:', error);
      });
  };


  fetchSuperCategories = async () => {
    try {
      const superCategories = await getAllpublishedsuperCategories();
      this.setState({ superCategories });
      console.log('data le lo:', superCategories);
    } catch (error) {
      console.error('Error fetching super categories:', error);
    }
  };

  handlesinglesubCategory = async (categoryId) => {
    debugger;
    try {
      // Fetch the single customer category
      const category = await getSinglecustomerCategory(categoryId);

      this.setState({
        showsubcategoryModal: true,
        categoryName: category.name,
        categoryLabel: category.label,
        selectedCluster: category.clusters,
        isEdit: true,
        handleUpdateCategories: this.handleUpdateCategories,
        // Add other category details to the state if needed
      });
      this.fetchAllCustomerCategories();
    } catch (error) {
      console.error('Error updating category:', error);
      Swal.fire('Error', 'Failed to update category', 'error');
    }
  };

  handleUpdateCategories = async () => {
    try {
      const { categoryName, selectedCluster } = this.state;
      // Make the API request to update the customer category
      debugger;
      const clusterNames = selectedCluster.map(item => item.name);
      console.log(clusterNames);
      const response = await updateCustomerCategory(categoryName, clusterNames);
      console.log('Category updated successfully', response.data);

      // Show a Swal success alert
      Swal.fire({
        icon: 'success',
        title: 'Category Updated',
        text: 'The category has been successfully updated.',
        showConfirmButton: false,
        timer: 2000 // Adjust the timer duration as needed
      });

      // Close the modal or perform any other necessary actions
      this.handleSubcategoryCloseModal();

      // Fetch the updated customer categories
      this.fetchAllCustomerCategories();
    } catch (error) {
      // Handle any errors that occur during the API request
      console.error('Error updating category', error);

      // Show a Swal error alert
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Failed to update the category.'
      });
    }
  }



  fetchAllCustomerCategories = () => {
    debugger;
    getAllCustomerCategories()
      .then((data) => {
        if (data.response && data.response.status === 404) {
          console.log("Error 404: Customer categories not found.");
          this.setState({ customerCatageory: [] });
        } else {
          console.log("Data retrieved:", data);
          this.setState({ customerCatageory: data });
        }
      })
      .catch((error) => {
        if (error.response.status === 404) {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Customer categories not found.',
          });
        }
          else if(error.response.status === 401){
            Swal.fire({
              title: 'Unauthorized!',
              text: 'Jwt token Expired. Please Login again.',
              icon: 'warning',
              showConfirmButton: false,
              timer: 2000 // 2 seconds
            }).then(() => {
              window.location.href = "/";
            });
          } else {
            throw new Error('Request failed with status ' + error.response.status);
            return;
          }
      });
  };

  isClusterSelected = (cluster) => {
    const { selectedCluster } = this.state;
    return selectedCluster.some((selectedCluster) => selectedCluster.label === cluster.label);
  };
  // Toggle cluster selection
  toggleClusterSelection = (cluster) => {
    const { selectedCluster } = this.state;
    const isSelected = this.isClusterSelected(cluster);

    if (isSelected) {
      // Remove attribute from selectedAttributes
      const updatedCluster = selectedCluster.filter((selectedCluster) => selectedCluster.label !== cluster.label);
      this.setState({ selectedCluster: updatedCluster });
    } else {
      // Add attribute to selectedAttributes
      const updatedCluster = [...selectedCluster, cluster];
      this.setState({ selectedCluster: updatedCluster });
    }
  };
  handleDeleteCategory = async (categoryId) => {
    const { customerCatageory } = this.state;
    const category = customerCatageory.find((cat) => cat.label === categoryId);

    if (category.hasProducts) {
      Swal.fire({
        title: 'Warning',
        text: 'This category has products. Please remove the products from the category before deleting it.',
        icon: 'warning',
        confirmButtonText: 'OK',
        confirmButtonColor: 'red'
      });
    } else {
      Swal.fire({
        title: 'Confirmation',
        text: 'Are you sure you want to delete this category?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        confirmButtonColor: 'red',
        cancelButtonColor: 'gray'
      }).then((result) => {
        if (result.isConfirmed) {
          // User confirmed deletion, proceed with the deletion
          this.deleteCategory(categoryId);
        }
      });
    }
  };

  deleteCategory = async (categoryId) => {
    try {
      // Call the API to delete the customer category
      await deleteCustomerCategory(categoryId);

      // Perform any necessary actions after successful deletion, such as updating the UI or refreshing the category list
      Swal.fire('Success', 'Category deleted successfully', 'success');

      // Fetch the updated customer categories
      this.fetchAllCustomerCategories();
    } catch (error) {
      console.error('Error deleting category:', error);
      Swal.fire('Error', 'Failed to delete category', 'error');
    }
  };

  handleCopyCategory = async () => {
    const { categoryName, selectedCluster } = this.state;
    const categoryData = {
      name: categoryName,
      clusterNames: selectedCluster.map(cluster => cluster.name)
    };
    try {
      // Call the moveCategory API function
      await moveCategory(categoryData);

      // Show success message or perform other actions
      Swal.fire('Success', 'Category moved successfully', 'success');
      this.fetchAllCustomerCategories();
      // Reset the state values
      this.setState({ showsubcategoryModal: false, categoryName: "", selectedCluster: [] });
    } catch (error) {
      console.error('Error moving category:', error);
      Swal.fire('Error', 'Failed to move category', 'error');
    }
  };

  handleSearch = (event) => {
    const searchQuery = event.target.value.toLowerCase();
    if (!searchQuery) {
      this.setState({
        searchQuery: "",
      });
      this.fetchSuperCategories();
    } else {
      this.setState({ searchQuery }, () => {
        this.setState((prevState) => ({
          superCategories: prevState.superCategories.map((superCategory) => {
            const matchesSuperCategory = superCategory.name.toLowerCase().includes(searchQuery);
            const matchesChildCategories = superCategory.childCategories.some(
              (childCategory) => childCategory.name.toLowerCase().includes(searchQuery)
            );
            return {
              ...superCategory,
              showSubcategories: matchesSuperCategory || matchesChildCategories,
              childCategories: matchesChildCategories
                ? superCategory.childCategories.map((childCategory) => ({
                  ...childCategory,
                  showChildCategories: childCategory.name.toLowerCase().includes(searchQuery),
                }))
                : superCategory.childCategories,
            };
          }),
        }));
      });
    }
  };

  handleSubcatSearch = (event) => {
    const searchSubcatQuery = event.target.value.toLowerCase();
    this.setState({ searchSubcatQuery });
  };

  renderCategories = (categories) => {
    return categories.map((category) => (
      <li key={category.name}>
        <span onClick={() => this.handleSubcategoryIconClick(category)}>
          <OverlayTrigger
            key="subcategoryIconTooltip"
            placement="top"
            overlay={<Tooltip id="CategoryIconTooltip">Use Category</Tooltip>}
          >
            <img src={CopyIcon} width={15} alt="Copy Icon" onClick={() => this.handleSubcategoryIconClick(category)} className="copy-icon" />
          </OverlayTrigger>
        </span>
        <img
          className="arrow-icon"
          src={ArrowIcon}
          width={6}
          onClick={() => this.toggleChildCategories(category.parentCategoryName, category.name)}
        />
        <span className="childcategoryname" onClick={() => this.toggleChildCategories(category.parentCategoryName, category.name)}>
          {category.name}
        </span>
        <OverlayTrigger
          key="subcategoryIconTooltip"
          placement="top"
          overlay={<Tooltip id="subcategoryIconTooltip">Create Subcategory</Tooltip>}
        >
          <img className='subcategoryicon'
            src={subcategory}
            width={80} 
            alt="Subcategory Icon"
            onClick={() => this.handleCreatesubcategoryClick(category)} />
        </OverlayTrigger>
        {category.showChildCategories && category.childCategories && category.childCategories.length > 0 && (
          <ul className="sub-category-list">
            {this.renderCategories(category.childCategories)}
          </ul>
        )}
      </li>
    ));
  };

  render() {
    const { showModal, categoryName, searchQuery, superCategories, searchSubcatQuery, selectedCluster, isEdit, showsubcategoryModal, clusters, customerCatageory, showCreatesubcategoryModal } = this.state;
    const filteredSuperCategories = superCategories.filter((superCategory) => {
      const matchesSuperCategory = superCategory.name.toLowerCase().includes(searchQuery);
      const matchesChildCategories = superCategory.childCategories.some(
        (childCategory) => childCategory.name.toLowerCase().includes(searchQuery)
      );
      return matchesSuperCategory || matchesChildCategories;
    });

    const filteredSubCategories = customerCatageory &&  customerCatageory.filter(
      (customersubCatageory) => customersubCatageory.name.toLowerCase().includes(searchSubcatQuery)
    );
    return (
      <div className="main-category-container">
        <div className='category-heading'>Product Categories</div>
        <div className="tz-category-container">
          <div className="left-container">
            <div className='mt-3'>
              <img src={category} className='cat-icon' alt="Product categories" width={20} />
              <span className='cat-name'>All Product Categories</span>
            </div>
            <div className="d-flex align-items-center mt-3">
              <Form className="d-flex tz-category-textbox-with-icon i tz-category-search-textbox-container">
                <Form.Control
                  type="search"
                  placeholder="Search Category"
                  className="tz-category-textbox"
                  aria-label="Search"
                  value={searchQuery} // bind the searchQuery value to the input value
                  onChange={this.handleSearch}
                />
              </Form>
              <Button className="cat-button ml-3" onClick={this.handleCreateClick}>Create</Button>
            </div>
            <hr className={`mt-1 mb-1 cat-up-plan-line`} />
            <div className="super-category-boxes">
              {filteredSuperCategories.map((superCategory) => (
                <div key={superCategory.label} className="super-category-box">
                  <div className="super-category-details">
                    <h3>
                      {/* <img src={CopyIcon} width={15} alt="Copy Icon" className="copy-icon" onClick={() => this.handleSubcategoryIconClick(superCategory)} /> */}
                      <OverlayTrigger
                        key="copyIconTooltip"
                        placement="top"
                        overlay={<Tooltip id="copyIconTooltip">Use Category</Tooltip>}
                      >
                        <img
                          src={CopyIcon}
                          width={15}
                          alt="Copy Icon"
                          className="copy-icon"
                          onClick={() => this.handleSubcategoryIconClick(superCategory)}
                        />
                      </OverlayTrigger>
                      <img className="arrow-icon" src={ArrowIcon} width={6} onClick={() => this.toggleSubcategories(superCategory.label)} />
                      <span className="sub-category-list" onClick={() => this.toggleSubcategories(superCategory.label)}> {superCategory.name} </span>
                      {/* <img src={subcategory} width={12} alt="Right Icon" onClick={() => this.handleCreatesubcategoryClick(superCategory)} className="right-icon" /> */}
                      <OverlayTrigger
                        key="subcategoryIconTooltip"
                        placement="top"
                        overlay={<Tooltip id="subcategoryIconTooltip">Create Subcategory</Tooltip>}
                      >
                        <img
                          src={subcategory}
                          width={80}
                          alt="Right Icon"
                          onClick={() => this.handleCreatesubcategoryClick(superCategory)}
                          className="right-icon"
                        />
                      </OverlayTrigger>
                    </h3>
                    {superCategory.showSubcategories && superCategory.childCategories && superCategory.childCategories.length > 0 && (
                      <ul className="sub-category-list">
                        {this.renderCategories(superCategory.childCategories)}
                      </ul>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="right-container">
            <div className='mt-3 myProduct-cat'>
              <img src={mycategory} className='cat-icon' alt="Product categories" width={20} />
              <span className='cat-name'>My Product Categories</span>
            </div>
            <Form className="d-flex tz-category-textbox-with-icon i tz-product-category-search-textbox-container mt-3">
              <Form.Control
                type="search"
                placeholder="Search Category"
                className="tz-category-textbox"
                aria-label="Search"
                value={searchSubcatQuery}
                onChange={this.handleSubcatSearch}
              />
              <i className="bi bi-search"></i>
            </Form>
            <hr className="mt-1 mb-1 cat-plan-line" />
            <div className="super-category-boxes">
              {filteredSubCategories.map((cusCategory) => (
                <div key={cusCategory.label} className="super-categories-box">
                  <div className="super-category-details">
                    <div className="row">
                      <div className="col-sm cus-icon">
                        {cusCategory.name}
                      </div>
                      <div className="col-sm category-clusters">
                        {cusCategory.clusters.length} {cusCategory.clusters.length > 0 ? cusCategory.clusters.map(cluster => cluster.name).join(', ') : ''}
                        {cusCategory.clusters.length > 0 && (
                          <span>
                            (
                            {cusCategory.clusters.map((cluster, index) => (
                              <React.Fragment key={cluster.name}>
                                {cluster.attributes.map((attribute, attrIndex) => (
                                  <span key={attribute.label}>
                                    {attribute.name}
                                    {attrIndex < cluster.attributes.length - 1 ? ', ' : ''}
                                  </span>
                                ))}
                                {index < cusCategory.clusters.length - 1 ? ' | ' : ''}
                              </React.Fragment>
                            ))}
                            )
                          </span>
                        )}
                      </div>
                      <div className="col-sm category-in-use centerUse">
                        In Use:{" "}
                        <span className={cusCategory.hasProducts ? "green-text" : "red-text"}>
                          {cusCategory.hasProducts ? "Yes" : "No"}
                        </span>
                      </div>
                      <div className="col-sm upd-icon">
                        <Button
                          className="blue-button"
                          variant="outlined"
                          onClick={() => !cusCategory.hasProducts && this.handlesinglesubCategory(cusCategory.label)}
                          disabled={cusCategory.hasProducts}
                        >
                          Update Clusters
                        </Button>

                      </div>
                      <div className="col-sm del-icon">
                        {cusCategory.hasProducts ? (
                          <DeleteIcon style={{ opacity: 0.5, cursor: 'not-allowed' }} />
                        ) : (
                          <DeleteIcon onClick={() => this.handleDeleteCategory(cusCategory.label)} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

        </div>

        {/* Modal */}
        {showModal && (
          <Modal
            show={showModal}
            onHide={this.handleCloseModal}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="categoryName">
                  <Form.Label className="mt-2">Category Name</Form.Label>
                  <Form.Control
                    className="tz-atr-form-control"
                    type="text"
                    placeholder="Enter category name"
                    value={this.state.categoryName}
                    onChange={this.handleCategoryNameChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button className='tz-primary-btn reverse btn btn-secondary' onClick={this.handleCloseModal}>
                Cancel
              </Button>
              <Button className='tz-primary-btn' onClick={this.handleSubmitCategory}>
                Send Request
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {showsubcategoryModal && (
          <Modal
            show={showsubcategoryModal}
            onHide={this.handleSubcategoryCloseModal}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>{isEdit ? 'Update Category' : 'My Product Category'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="categoryName">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter category name"
                    value={this.state.categoryName}
                    onChange={(event) => this.setState({ categoryName: event.target.value })}
                    disabled
                  />
                </Form.Group>

                <Form.Group controlId="clusterType">
                  <Form.Label className="tz-form-label mt-3">Select Cluster</Form.Label>
                  <div className="tz-cluster-select-formcontrol">
                    <Form.Control
                      className="tz-atr-form-control"
                      type="text"
                      placeholder="Search"
                      value={this.state.clusterSearchQuery}
                      onChange={(event) => this.setState({ clusterSearchQuery: event.target.value })}
                    />
                    <div className="attribute-checkbox-container mt-2">
                      {clusters
                        .filter((cluster) =>
                          cluster.name.toLowerCase().includes(this.state.clusterSearchQuery.toLowerCase())
                        ).map((cluster) => (
                          <FormCheck
                            key={cluster.label}
                            id={`attribute-${cluster.label}`}
                            label={cluster.name}
                            checked={this.isClusterSelected(cluster)}
                            onChange={() => this.toggleClusterSelection(cluster)}
                          />
                        ))}
                    </div>
                  </div>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className='tz-primary-btn reverse btn btn-secondary' onClick={this.handleSubcategoryCloseModal}>
                Cancel
              </Button>
              <Button variant="primary" className='tz-primary-btn' onClick={isEdit ? this.handleUpdateCategories : this.handleCopyCategory}>
                {isEdit ? 'Update' : 'Copy'}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        {showCreatesubcategoryModal && (
          <Modal
            show={showCreatesubcategoryModal}
            onHide={this.handleCreateSubcategoryCloseModal}
            centered
            backdrop="static"
          >
            <Modal.Header closeButton>
              <Modal.Title>Create Category</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group controlId="categoryName">
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter category name"
                    value={this.state.categoryName}
                    onChange={(event) => this.setState({ categoryName: event.target.value })}
                  />
                </Form.Group>
                <Form.Group controlId="categoryName">
                  <Form.Label className="mt-2">Subcategory Name</Form.Label>
                  <Form.Control
                    className="tz-atr-form-control"
                    type="text"
                    placeholder="Enter Subcategory name"
                    value={this.state.SubcategoryName}
                    onChange={this.handleSubCategoryNameChange}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" className='tz-primary-btn reverse btn btn-secondary' onClick={this.handleCreateSubcategoryCloseModal}>
                Cancel
              </Button>
              <Button variant="primary" className='tz-primary-btn'onClick={this.handleSubmitSubCategory}>
                Send Request
              </Button>
            </Modal.Footer>
          </Modal>
        )}

      </div>
    );
  }
}
export default ProductCategory;
